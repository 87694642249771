import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DataServer } from "../../../config.axios";
import { toast } from "react-toastify";
export interface inspectorChecklistParameterCategories {
    id: number,
    name: string,
}
export interface inspectorChecklistSubCategories {
    id: number,
    type: string,
    category_id: number,
    subcategory_id: any,
    name: string
}
export interface horizontalTab {
    id: number,
    name: string,
    coordinate_id: number,
    status: any,
    is_selected: boolean,
    type: string
}
export interface inspectorChecklistParameterInterface {
    test_location: string,
    id: number
}
export interface inspectorBasicInfoInterface {
    selected_checklist_timeline_id: number
    sampling_date: string
    sampling_time: string
    sampling_point_description: string
    latitude_longitude: string
    weather_condition: string
    humidity: string
    water_body_conditions: string
    atmospheric_temperature: number
    in_charge_officer_name: string
    reported_on: string
    schedule_date: string
    user_id: number
    project_id: number
}
export interface inspectorVisualObservationInterface {
    inspector_test_summary_id: number
    user_id: number
    visual_observation: any
}
export interface inspectorSampleDetailInterface {
    selected_checklist_timeline_id: number
    sampling_date: string
    sampling_time: string
    sampling_point_description: string
    latitude_longitude: string
    weather_condition: string
    humidity: string
    water_body_conditions: string
    atmospheric_temperature: number
    in_charge_officer_name: string
    reported_on: string
}
export interface inspectorChecklistDataInterface {
    end(end: any): unknown;
    start(start: any, arg1: string): unknown;
    id: number,
    coordinate_id: number,
    component_id: number,
    user_id: number,
    created_at: Date,
    updated_at: Date,
    project_id: number,
    component_name: string,
    selected_checklist_id: number,
    checklist_assigned_user_id: number,
    category_id: number,
    sub_category_id: number,
    component_checklist_horizontal_tab_id: number,
    frequency: string,
    occurrence: string,
    population_served: string,
    name: string,
    selected_checklist_timelines_id: number,
    timeline: number
}

export interface checklistComponentDetailsInterface {
    id: number,
    selected_checklist_id: number,
    timeline: string,
    checklist_assigned_user_id: number,
    component_checklist_horizontal_tab_id: number,
    coordinate_id: number,
    project_id: number,
    project_name: string,
    scheme_id: string,
    component_name: string,
    test_type: number
}

export interface InspectorProjectListInterface {
    id: number,
    project_id: number,
    user_id: number,
    coordinate_id: number,
    checklist_assigned_date: string,
    checklist_status: string,
    scheme_id: string,
    project_name: string,
    managed_by: string,
    location_name: any
}
export interface InspectorComponentChecklistInterface {
    id: number,
    selected_checklist_id: number,
    timeline: string,
    checklist_assigned_user_id: number,
    checklist_parameter_id: number,
    checklist_parameter_test_location: string,
    name: string,
    coordinate_id: number,
    project_id: number,
    project_name: string,
    scheme_id: string,
    component_name: string
}
export interface TestSummaryInterface {
    id: number,
    selected_checklist_timeline_id: number,
    schedule_date: string,
    user_id: number,
    project_id: number,
    component_name: string,
    selected_checklist_id: string,
    checklist_assigned_user_id: string,
    category_id: string,
    sub_category_id: string,
    component_checklist_horizontal_tab_id: string,
    frequency: string,
    occurrence: string,
    population_served: string,
    name: string,
    selected_checklist_timelines_id: string,
    timeline: string,
    status: string
}

interface InspectorChecklistState {
    inspectorChecklistCategory: {
        load: boolean;
        data: Array<inspectorChecklistParameterCategories>;
    };
    inspectorChecklistCategoryData?: inspectorChecklistParameterCategories;
    inspectorChecklistCalender: {
        load: boolean;
        data: Array<inspectorChecklistDataInterface>;
    };
    inspectorChecklistCalenderSelected?: inspectorChecklistDataInterface;
    inspectorBasicInfo: inspectorBasicInfoInterface;
    inspectorVisualObservation: inspectorVisualObservationInterface;
    inspectorSampleDetails: any
    horizontalTab: {
        load: boolean;
        data: Array<horizontalTab>;
    };

    horizontalTabSelected?: horizontalTab;
    treatmenthorizontalTab: {
        load: boolean;
        data: Array<horizontalTab>;
    };
    treatmenthorizontalTabSelected?: horizontalTab;

    inspectorChecklistData: inspectorChecklistDataInterface,
    checklistComponentDetails: checklistComponentDetailsInterface
    InspectorComponentChecklist: {
        load: boolean,
        data: Array<InspectorProjectListInterface>
    }
    InspectorProjectList: {
        load: boolean;
        data: Array<InspectorComponentChecklistInterface>
    }
    testSummary: {
        load: boolean;
        data: Array<TestSummaryInterface>
    }
    inspecterComponentChecklistData: {
        load: boolean,
        data: []
    }
    updatedData: any;
    inspectorTestCategory: any
    inspectorChecklistFinal:any,
    inspectorChecklistDateSelection:any


}
const initialState = {
    inspectorChecklistCategory: {
        load: false,
        data: [],
    },
    inspectorChecklistCategoryData: {
        id: 1,
        name: "General",
    },
    inspectorChecklistSubCategory: {
        load: false,
        data: [],
    },
    inspectorChecklistCalender: {
        load: false,
        data: [],
    },
    inspectorChecklistSubCategorySelected: {
        id: null,
        type: "",
        category_id: "",
        subcategory_id: null,
        name: ""
    },
    horizontalTab: {
        load: false,
        data: [],
    },
    horizontalTabSelected: {
        id: null,
        name: "",
        coordinate_id: "",
        status: "",
        is_selected: false,
        type: ""
    },
    treatmenthorizontalTab: {
        load: false,
        data: [],
    },
    treatmenthorizontalTabSelected: {
        id: null,
        name: "",
        coordinate_id: "",
        status: "",
        is_selected: false,
        type: ""
    },
    inspectorChecklistData: {
        id: "",
        coordinate_id: "",
        component_id: "",
        user_id: "",
        created_at: null,
        updated_at: null,
        project_id: "",
        component_name: "",
        selected_checklist_id: "",
        checklist_assigned_user_id: "",
        category_id: "",
        sub_category_id: "",
        component_checklist_horizontal_tab_id: "",
        frequency: "",
        occurrence: "",
        population_served: "",
        name: "",
        selected_checklist_timelines_id: "",
        timeline: ""
    },
    checklistComponentDetails: {
        id: "",
        selected_checklist_id: "",
        timeline: "",
        checklist_assigned_user_id: "",
        component_checklist_horizontal_tab_id: "",
        coordinate_id: "",
        project_id: "",
        project_name: "",
        scheme_id: "",
        component_name: "",
        test_type: ""
    },
    InspectorProjectList: {
        load: false,
        data: [],
    },
    inspectorSampleDetails: [],
    inspecterComponentChecklistData: {
        load: false,
        data: []
    },
    updatedData: {
        checklist_parameters: []
    },
    testSummary: {
        load: false,
        data: []
    },
    inspectorChecklistDateSelection: {

    },
    inspectorBasicInfo: {

    },
    inspectorChecklistFinal: {

    },
    isdatecheck:false
} as unknown as InspectorChecklistState;
export const inspectorChecklistSlice = createSlice({
    name: "inspectorChecklist",
    initialState,
    reducers: {

        updateSamplingdata: (state: any, action: any) => {
            state.updatedData = action.payload;
        },
        resetUpdateData: (state: any) => {
            state.updatedData = initialState.updatedData;
            state.inspectorBasicInfo = initialState.inspectorBasicInfo
        },
        resetUpdateDataSample: (state: any) => {
            state.updatedData = initialState.updatedData;
        },
        resetInspectorChecklist: (state: any) => {
            state.inspectorChecklistData = initialState.inspectorChecklistData;
        },
        resetFinalChecklist : (state:any) => {
            state.inspectorChecklistFinal = {};
        },
        resetVisualObservation: (state:any) => {
            state.inspectorVisualObservation = initialState.inspectorVisualObservation
        },
        updateIsCheck :(state:any) => {
            state.inspectorChecklistDateSelection=initialState.inspectorChecklistDateSelection
        }
    },
    extraReducers: (builder: any) => {
        builder
            // .addCase(fetchInspectorChecklistCalender.pending, (state: InspectorChecklistState) => {
            //     state.inspectorChecklistCalender.load = true;
            // })
            // .addCase(fetchInspectorChecklistCalender.fulfilled, (state: InspectorChecklistState, action: any) => {
            //     state.inspectorChecklistCalender.data = action.payload;
            //     state.inspectorChecklistCalender.load = false;
            // })
            // .addCase(fetchInspectorChecklistCalender.rejected, (state: InspectorChecklistState) => {
            //     state.inspectorChecklistCalender.load = false;
            // })

            .addCase(fetchScheduleCalender.pending, (state: InspectorChecklistState) => {
                state.inspectorChecklistCalender.load = true;
            })
            .addCase(fetchScheduleCalender.fulfilled, (state: InspectorChecklistState, action: any) => {
                state.inspectorChecklistCalender.data = action.payload;
                state.inspectorChecklistCalender.load = false;
            })
            .addCase(fetchScheduleCalender.rejected, (state: InspectorChecklistState) => {
                state.inspectorChecklistCalender.load = false;
            })
            .addCase(FetchComponentDetails.pending, (state: InspectorChecklistState) => {

            })
            .addCase(FetchComponentDetails.fulfilled, (state: InspectorChecklistState, action: any) => {
                state.checklistComponentDetails = action.payload;

            })
            .addCase(FetchComponentDetails.rejected, (state: InspectorChecklistState) => {
                state.checklistComponentDetails = initialState.checklistComponentDetails;
            })
            .addCase(storeBasicInfo.pending, (state: InspectorChecklistState) => {
                // state.inspectorBasicInfo = true;
            })
            .addCase(storeBasicInfo.fulfilled, (state: InspectorChecklistState, action: any) => {
                 state.inspectorBasicInfo = action.payload;
                // state.inspectorBasicInfo = false;
            })
            .addCase(storeBasicInfo.rejected, (state: InspectorChecklistState) => {
                // state.inspectorBasicInfo = false;
            })
            .addCase(storeSampleDetails.pending, (state: InspectorChecklistState) => {
                // state.inspectorBasicInfo = true;
            })
            .addCase(storeSampleDetails.fulfilled, (state: InspectorChecklistState, action: any) => {
                state.inspectorSampleDetails = action.payload;
                // state.inspectorBasicInfo = false;
            })
            .addCase(storeSampleDetails.rejected, (state: InspectorChecklistState) => {
                // state.inspectorBasicInfo = false;
            })
            .addCase(FetchInspectorProjectList.pending, (state: InspectorChecklistState) => {
                state.InspectorProjectList.load = true
            })
            .addCase(FetchInspectorProjectList.fulfilled, (state: InspectorChecklistState, action: any) => {
                state.InspectorProjectList.data = action.payload;
                state.InspectorProjectList.load = false

            })
            .addCase(FetchInspectorProjectList.rejected, (state: InspectorChecklistState) => {
                state.InspectorProjectList.data = initialState.InspectorProjectList.data;
                state.InspectorProjectList.load = false
            })
            .addCase(InspectorComponentChecklist.pending, (state: InspectorChecklistState) => {
                state.inspecterComponentChecklistData.load = true
            })
            .addCase(InspectorComponentChecklist.fulfilled, (state: InspectorChecklistState, action: any) => {
                state.inspecterComponentChecklistData.data = action.payload;
                state.inspecterComponentChecklistData.load = false

            })
            .addCase(InspectorComponentChecklist.rejected, (state: InspectorChecklistState) => {
                state.inspecterComponentChecklistData.data = initialState.inspecterComponentChecklistData.data;
                state.inspecterComponentChecklistData.load = false
            })
            .addCase(FetchTestSummary.pending, (state: InspectorChecklistState) => {
                state.testSummary.load = true
            })
            .addCase(FetchTestSummary.fulfilled, (state: InspectorChecklistState, action: any) => {
                state.testSummary.data = action.payload;
                state.testSummary.load = false

            })
            .addCase(FetchTestSummary.rejected, (state: InspectorChecklistState) => {
                state.testSummary.data = initialState.testSummary.data;
                state.testSummary.load = false
            })
            .addCase(getBasicInfo.pending, (state: InspectorChecklistState) => {
                // state.inspectorBasicInfo = true;
            })
            .addCase(getBasicInfo.fulfilled, (state: InspectorChecklistState, action: any) => {
                state.inspectorBasicInfo = action.payload;
                // state.inspectorBasicInfo = false;
            })
            .addCase(getBasicInfo.rejected, (state: InspectorChecklistState) => {
                state.inspectorBasicInfo = initialState.inspectorBasicInfo
            })
            .addCase(getSampleInfo.pending, (state: InspectorChecklistState) => {
                // state.inspectorBasicInfo = true;
            })
            .addCase(getSampleInfo.fulfilled, (state: InspectorChecklistState, action: any) => {
                state.inspectorSampleDetails = action.payload;
                // state.inspectorBasicInfo = false;
            })
            .addCase(getSampleInfo.rejected, (state: InspectorChecklistState) => {
                // state.inspectorBasicInfo = false;
            })
            .addCase(updateBasicInfo.pending, (state: InspectorChecklistState) => {
                // state.inspectorBasicInfo = true;
            })
            .addCase(updateBasicInfo.fulfilled, (state: InspectorChecklistState, action: any) => {
                state.inspectorBasicInfo = action.payload;
                // state.inspectorBasicInfo = false;
            })
            .addCase(updateBasicInfo.rejected, (state: InspectorChecklistState) => {
                // state.inspectorBasicInfo = false;
            })
            .addCase(updateSampleDetails.pending, (state: InspectorChecklistState) => {
                // state.inspectorBasicInfo = true;
            })
            .addCase(updateSampleDetails.fulfilled, (state: InspectorChecklistState, action: any) => {
                // state.inspectorSampleDetails= action.payload;
                // state.inspectorBasicInfo = false;
            })
            .addCase(updateSampleDetails.rejected, (state: InspectorChecklistState) => {
                // state.inspectorBasicInfo = false;
            })
            .addCase(FetchImage.pending, (state: InspectorChecklistState) => {
                // state.inspectorBasicInfo = true;
            })
            .addCase(FetchImage.fulfilled, (state: InspectorChecklistState, action: any) => {
                state.inspectorVisualObservation = action.payload;
                // state.inspectorBasicInfo = false;
            })
            .addCase(FetchImage.rejected, (state: InspectorChecklistState) => {
                // state.inspectorBasicInfo = false;
            })
            .addCase(inspectorChecklistCategories.pending, (state: InspectorChecklistState) => {
                // state.inspectorBasicInfo = true;
            })
            .addCase(inspectorChecklistCategories.fulfilled, (state: InspectorChecklistState, action: any) => {
                state.inspectorTestCategory = action.payload;
                // state.inspectorBasicInfo = false;
            })
            .addCase(inspectorChecklistCategories.rejected, (state: InspectorChecklistState) => {
                // state.inspectorBasicInfo = false;
            })
            .addCase(finalSample.pending, (state: InspectorChecklistState) => {
                // state.inspectorBasicInfo = true;
            })
            .addCase(finalSample.fulfilled, (state: InspectorChecklistState, action: any) => {
                state.inspectorChecklistFinal = action.payload;
                // state.inspectorBasicInfo = false;
            })
            .addCase(finalSample.rejected, (state: InspectorChecklistState) => {
                // state.inspectorBasicInfo = false;
            })
            .addCase(storeIspectorChecklistDate.pending, (state: InspectorChecklistState) => {
                // state.inspectorBasicInfo = true;
            })
            .addCase(storeIspectorChecklistDate.fulfilled, (state: InspectorChecklistState, action: any) => {
                state.inspectorChecklistDateSelection = action.payload;
                // state.isdatecheck = true
                // state.inspectorBasicInfo = false;
            })
            .addCase(storeIspectorChecklistDate.rejected, (state: InspectorChecklistState) => {
                // state.inspectorBasicInfo = false;
            })


    },
});
export const { updateSamplingdata, resetInspectorChecklist, resetUpdateData,resetUpdateDataSample,resetFinalChecklist,resetVisualObservation,updateIsCheck } = inspectorChecklistSlice.actions;

export default inspectorChecklistSlice.reducer;


// export const fetchInspectorChecklistCalender = createAsyncThunk(
//     "inspectorChecklist/fetchInspectorChecklistCalender",
//     async (param: any, thunkAPI) => {
//         try {
//             const { data } = await DataServer.post("api/inspector-checklist", param);
//             return data;
//         } catch (error) {
//             return error;
//         }
//     }
// );

export const fetchScheduleCalender = createAsyncThunk(
    "inspectorChecklist/fetchScheduleCalender",
    async (param: any, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/inspector-schedule-data", {user_id:param});
            return data;
        } catch (error) {
            return error;
        }
    }
);

export const FetchComponentDetails = createAsyncThunk(
    "inspectorChecklist/FetchComponentDetails",
    async (param: number, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/checklist-component-details", {
                selected_checklist_timeline_id: param
            });
            return data.data;
        } catch (error) {
            return error;
        }
    }
);

export const storeBasicInfo = createAsyncThunk(
    "storeBasicInfo/storeBasicInfo",
    async (param: any, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/store-basic-information", param);

            toast.success(data.message, {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });
            return data.data;
        } catch (error) {
            return error;
        }
    }
);

export const updateBasicInfo = createAsyncThunk(
    "storeBasicInfo/updateBasicInfo",
    async (param: any, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/update-basic-information", param);

            toast.success(data.message, {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });
            return data.data;
        } catch (error) {
            return error;
        }
    }
);

export const storeSampleDetails = createAsyncThunk(
    "storeBasicInfo/storeSampleDetails",
    async (param: any, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/store-sample-details", param.sampledata);
             if (data.message == 'Sample Details Saved Successfully') {
                // param.sampledata.checklist_parameters.forEach(async (item: { observation: any; }, index: any) => {
                //     if (item.observation === "null") {
                    
                //     }else{
                        const { data } = await DataServer.post("api/calculate-water-quality-index", param.indexData);
                //     }
                // });
               
            }

            toast.success(data.message, {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });
            return data;
        } catch (error) {
            return error;
        }
    }
);

export const updateSampleDetails = createAsyncThunk(
    "storeBasicInfo/updateSampleDetails",
    async (param: any, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/update-sample-details", param.sampledata);

            
            if (data.message == 'Sample Details Updated Successfully!') {
                param.sampledata.checklist_parameters.forEach(async (item: { observation: any; }, index: any) => {
                    if (item.observation === "null") {
                        // toast.warn("Observation is null", {
                        //     position: "top-right",
                        //     autoClose: 5000,
                        //     theme: "dark",
                        // });
                        // console.log(`Observation is null at index ${index}.`);
                        // You can perform other actions here, like setting a default value or handling the null case
                    }else{
                        const { data } = await DataServer.post("api/calculate-water-quality-index", param.indexData);
                    }
                });
               
            }

            toast.success(data.message, {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });
            return data;
        } catch (error) {
            return error;
        }
    }
);

export const FetchInspectorProjectList = createAsyncThunk(
    "inspectorChecklist/FetchInspectorProjectList",
    async (param: number, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/inspector-project-list", {
                user_id: param
            });
            return data.data;
        } catch (error) {
            return error;
        }
    }
);

export const InspectorComponentChecklist = createAsyncThunk(
    "inspectorChecklist/InspectorComponentChecklist",
    async (param: number, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/inspector-component-checklist", {
                selected_checklist_id: param
            });
            return data.data;
        } catch (error) {
            return error;
        }
    }
);

export const FetchTestSummary = createAsyncThunk(
    "inspectorChecklist/FetchTestSummary",
    async (param: any, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/inspector-filled-checklist", param);
            return data.data;
        } catch (error) {
            return error;
        }
    }
);

export const getBasicInfo = createAsyncThunk(
    "inspectorChecklist/getBasicInfo",
    async (param: any, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/get-basic-information-data", { ...param });
            return data.data;
        } catch (error) {
            return error;
        }
    }
);

export const getSampleInfo = createAsyncThunk(
    "inspectorChecklist/getSampleInfo",
    async (param: any, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/get-sample-details-data", {...param });
            return data.data;
        } catch (error) {
            return error;
        }
    }
);

export const inspectorChecklistCategories = createAsyncThunk(
    "inspectorChecklist/inspectorChecklistCategories",
    async (param: any, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/inspector-checklist-categories", param);
         
            return data.data;
        } catch (error) {
            return error;
        }
    }
);

export const storeImages = createAsyncThunk(
    "storeBasicInfo/storeImages",
    async (formData: FormData, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/store-visual-observation", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            toast.success(data.message, {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });
            return data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);


export const FetchImage = createAsyncThunk(
    "storeBasicInfo/FetchImage",
    async (param: any, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/get-visual-observation", { inspector_test_summary_id: param });


            return data;
        } catch (error) {
            return error;
        }
    }
);

export const finalSample = createAsyncThunk(
    "storeBasicInfo/finalSample",
    async (param: any, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/final-submit-samples", param);

            if(data.message == "Incomplete Submission"){
                toast.warn(data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    theme: "dark",
                });
            }else{
                toast.success(data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    theme: "dark",
                });
            }

            
            return data;
        } catch (error) {
            return error;
        }
    }
);

export const storeIspectorChecklistDate = createAsyncThunk(
    "inspectorChecklist/storeIspectorChecklistDate",
    async (param: any, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/inspector-checklist-date-selection", param);
            // thunkAPI.dispatch((updateIsCheck(true)))
            return data.data;
        } catch (error) {
            return error;
        }
    }
);


