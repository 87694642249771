import { FC } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../hooks";
import LayoutContainer from "../layout";
// import { ROLES } from "../utils/constant";
// import { getItem } from "../utils/getLocalStorage";

interface protextedRoute {
  children: JSX.Element;
  //   roles: ROLES[];
}
const ProtectedRoute: FC<protextedRoute> = ({ children }) => {
  let location = useLocation();

  const token: any = localStorage.getItem("sb-reeqqghunqccmwqdgfma-auth-token");
  const data = JSON.parse(token)
  const accessToken = data?.access_token;



  if (!accessToken) {
    return <Navigate to="/" state={{ from: location }} />;
  }
  //   const roleValue: number = parseInt(ROLES[role]);
  //   const userHasRequiredRole = roles && roles.includes(roleValue) ? true : false;

  //   if (isAuthenticated && !userHasRequiredRole) {
  //     return <a href="/403" />;
  //   }

  return <LayoutContainer>{children}</LayoutContainer>;
};

export default ProtectedRoute;
