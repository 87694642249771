import React, { useEffect } from "react";
import { fetchparametersAlerts } from "../../redux/slice/alerts";
import { useAppDispatch, useAppSelector } from "../../hooks";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

const AlertNotification = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const location = useLocation();
  const monitoring = useAppSelector((state) => state.monitoring);
  const { parameterAlerts } = useAppSelector((state) => state.alerts);
  const notificationData = [
    {
      id: 1,
      alertName: "Critical Issue",
      dateTime: "25 Jun 2024 14:35",
      notification: "TKN levels above threshold at Source of Intake",
    },
    {
      id: 2,
      alertName: "Moderate Concern",
      dateTime: "25 Jun 2024 14:35",
      notification: "Ammonia levels rising at Treatment Facility",
    },
    {
      id: 3,
      alertName: "Routine Maintenance",
      dateTime: "25 Jun 2024 14:35",
      notification: "Scheduled maintenance at Storage Reservoir",
    },
    {
      id: 4,
      alertName: "Urgent Notice",
      dateTime: "25 Jun 2024 14:35",
      notification: "Nitrate levels above threshold at Water Quality Station",
    },
    {
      id: 5,
      alertName: "Watch List",
      dateTime: "25 Jun 2024 14:35",
      notification:
        "Phosphorus levels approaching threshold at Source of Intake",
    },
    {
      id: 6,
      alertName: "Critical Issue",
      dateTime: "25 Jun 2024 14:35",
      notification: "TKN levels above threshold at Source of Intake",
    },
    {
      id: 7,
      alertName: "Critical Issue",
      dateTime: "25 Jun 2024 14:35",
      notification: "TKN levels above threshold at Source of Intake",
    },
  ];
  useEffect(() => {
    if (monitoring.updatedData.coordinate_id) {
      const date = {
        schedule_date: monitoring.updatedData.schedule_date,
        schedule_time: monitoring.updatedData.schedule_time,
      };
      if (monitoring.updatedData.coordinate_id)
        dispatch(
          fetchparametersAlerts({
            coordinate_id: monitoring.updatedData.coordinate_id,
            ...date,
          })
        );
    }
  }, [monitoring.updatedData]);

  const redirect = () => {
    navigation("/Alerts-Solution-Hub", {
      state: {
        coordinate_id: monitoring.updatedData.coordinate_id,
        filtered_date_and_time: moment(
          monitoring.updatedData.schedule_date
        ).format("YYYY-MM-DD hh:mm:ss A"),
        projectData: location.state.projectData,
      },
    });
  };
  return (
    <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full alert-notification-div">
      <div className="w-full py-4 pl-4 sub-div mb-4">
        <h1 className="text-[#000] font-[Inter] text-base not-italic font-normal leading-normal">
          Alerts & Notifications
        </h1>
        <div className="w-full   overflow-y-scroll mt-3 dashboard-custom-height">
          {parameterAlerts?.data?.data?.getAlertParameters &&
            Object.values(parameterAlerts?.data?.data?.getAlertParameters).map(
              (data: any) => (
                <div
                  className="w-full py-4 border-b border-[#EFF1F7] pr-4 cursor-pointer hover:bg-[rgba(64, 66, 68, 1)]"
                  onClick={redirect}
                >
                  <div className="w-full flex justify-between items-center">
                    <div className="flex items-center">
                      <div
                        className={`w-[10px] h-[10px]  rounded-full mr-[6px]  ${
                          data.banned_parameter === true
                            ? "bg-[#F12E52]"
                            : "bg-[#222]"
                        }`}
                      ></div>
                      <h2 className="text-[#262626] font-[Inter] text-sm not-italic font-medium leading-normal">{`Alert`}</h2>
                    </div>

                    <div className="text-[#262626] font-[Inter] text-[10px] not-italic font-medium leading-normal">
                      {data.created_date}
                    </div>
                  </div>
                  <p className="text-[#262626] font-[Inter] text-[13px] not-italic font-light leading-normal mt-2 w-[95%] text-nowrap overflow-hidden text-ellipsis">{`Elevated ${data.parameter_name} Level Detected`}</p>
                </div>
              )
            )}
        </div>
      </div>
    </div>
  );
};

export default AlertNotification;
