import React, { useEffect, useState } from "react";
import FormField from '../Forms/FormField'
import SelectField from '../Forms/SelectField'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as yup from 'yup';
// import { FormValidation } from "../../Utils/formValidation";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import '../../assets/css/reusable/form.css'
import { useAppDispatch, useAppSelector } from "../../hooks";
import { activeComponent, formPopup } from "../../redux/slice/Map";
import { addIntakeStructure, editIntakeStructure, fetchIntakeStructureById } from "../../redux/slice/IntakeStructure";
import { useLocation } from "react-router-dom";
import Loading from "../../views/pages/Loading";
interface FormData {
  type: string;
  system: string;
  capacity: string;
  latitude: string;
  longitude: string;
  dimension: string;
  level: string;
  slab: string;
  bottom: string;
}
const IntakeStucture: any = (props: any) => {
  const components = useAppSelector((state) => state.components)
  const intakeStructure = useAppSelector((state) => state.intakeStructure)
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<any>({
    type: "",
    system: "",
    capacity: "",
    latitude: "",
    longitude: "",
    dimension: "",
    level: "",
    slab: "",
    bottom: ""
  });
  const [resetformData, setResetFormData] = useState<FormData>({
    type: "",
    system: "",
    capacity: "",
    latitude: "",
    longitude: "",
    dimension: "",
    level: "",
    slab: "",
    bottom: ""
  });
  const typeOptions: any = [{ "value": "Select" }, { "value": "River intake" }, { "value": "Lake intake" }, { "value": "Reservoir intake" }, { "value": "Submerged (pipe/pump) intake" }, { "value": "Screened intake" }, { "value": "Crib intake" }, { "value": "Diversion structures  (For eg. Canal)" }, { "value": "Floating (pump/screen) intake" }, { "value": "Desalination intake" }]
  const convertedtypeOptions = typeOptions.map((option: { value: string; }) => option.value);
  const systemOptions: any = [{ "value": "Select" }, { "value": "Dual Intake System (inc. Jackwell)" }, { "value": "Dual Intake System (separate Jackwell)" }]
  const convertedsystemOptions = systemOptions.map((option: { value: string; }) => option.value);
  const formSchema = yup.object({
    type: yup.string()
      .oneOf(convertedtypeOptions, 'Please select a structure type from the list.') // A) Error for text-based
      .required('Please select a structure type from the list.'), // E) Compulsory
    system: yup.string()
      .oneOf(convertedsystemOptions, 'Please select a intake system from the list.') // A) Error for text-based
      .required('Please select a intake system from the list.'), // E) Compulsory
    latitude: yup.string().required("Latitude is required")
      .test('is-text-based', 'Please enter a valid latitude.', value => !isNaN(parseFloat(value)))
      .test('is-symbol-based', 'Only numeric values and symbols (-, .) are allowed.', value => /^-?\d*\.?\d*$/.test(value))
      .test('character-limit', 'Value must be a valid latitude format.', value => value.length <= 8),
    longitude: yup.string().required("Longitude is required")
      .test('is-text-based', 'Please enter a valid Longitude.', value => !isNaN(parseFloat(value)))
      .test('is-symbol-based', 'Only numeric values and symbols (-, .) are allowed.', value => /^-?\d*\.?\d*$/.test(value))
      .test('character-limit', 'Value must be a valid Longitude format.', value => value.length <= 8),
    capacity: yup.string()
      .required('Capacity is required') // E) Compulsory
      .matches(/^\d+$/, 'Please enter a numeric value for capacity.') // A) Error for text-based
      .matches(/^\d+$/, 'Only numeric values are allowed.') // B) Error for symbol-based
      .min(1, 'Value must be between 1 to 100000.') // C) Character limit
      .max(100000, 'Value must be between 1 to 100000.'), // C) Character limit
    // .oneOf(["5000"], 'Capacity must be "5000".') // D) Possible value
    level: yup.string()
      .required('Please enter the Highest Flood Level (HFL) in meters.')
      .matches(/^\d+(\.\d+)?$/, 'Please enter the Highest Flood Level (HFL) in meters.') // Text-based Error
      .matches(/^\d+(\.\d+)?$/, 'Only numbers and decimal points are allowed.') // Symbol-based Error
      .max(10, 'Maximum 10 characters allowed.'), // Character Limit
    // Compulsory
    bottom: yup.string()
      .required('Please enter the Bottom Level in meters.')
      .matches(/^\d+(\.\d+)?$/, 'Please enter the Bottom Level in meters.') // Text-based Error
      .matches(/^\d+(\.\d+)?$/, 'Only numbers and decimal points are allowed.') // Symbol-based Error
      .max(10, 'Maximum 10 characters allowed.') // Character Limit
    , // Compulsory
  });
  const [errors, setErrors] = useState<Partial<FormData>>({});
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const onSelectChangeChange = async (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
    // await FormValidation(formSchema, { ...formData, [name]: value }, setErrors);
  }
  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
    // await FormValidation(formSchema, { ...formData, [name]: value }, setErrors);

  };
  const intakedata = intakeStructure.selectedIntakeStructure.data
  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    // await FormValidation(formSchema, formData, setErrors)
    const formadata = {
      project_id: location?.state?.projectData?.id,
      component_id: components.selectedComponent.component_id,
      latitude: formData.latitude,
      longitude: formData.longitude,
      type_of_structure: formData.type,
      capacity: formData.capacity,
      dimension: formData.dimension,
      highest_flood_level: formData.level,
      slab_level: formData.slab,
      bottom_level: formData.bottom,
      intake_system: formData.system,
    }

    const errors: { [key: string]: string } = {};
    const requiredFields = ["type", "latitude", "longitude", "capacity", "dimension", "level", "slab", "bottom"];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        errors[field] = "Required";
      }
    });

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      intakedata ?
        dispatch(editIntakeStructure({ apiParam: { ...formadata, coordinate_id: intakedata.coordinate_id, id: intakedata.id }, closeModal: dispatch(formPopup(false)) })) :

        dispatch(addIntakeStructure({ apiParam: formadata, closeModal: dispatch(formPopup(false)) }))
    }
  };

  useEffect(() => {
    if (components.selectedComponent) {
      dispatch(activeComponent(components?.selectedComponent?.name))
      if (components?.selectedComponent?.id) {
        dispatch(fetchIntakeStructureById(components?.selectedComponent?.id))
      }
      setFormData({
        ...formData,
        latitude: components?.selectedComponent?.latitude,
        longitude: components?.selectedComponent?.longitude
      })
    }
  }, [components.selectedComponent])

  useEffect(() => {
    if (intakedata) {
      setFormData({
        ...intakedata,
        type: intakedata?.type_of_structure,
        system: intakedata?.intake_system,
        level: intakedata?.highest_flood_level,
        slab: intakedata?.slab_level,
        bottom: intakedata?.bottom_level,
        latitude: components?.selectedComponent?.latitude,
        longitude: components?.selectedComponent?.longitude
      })
    }
  }, [intakedata]);

  useEffect(() => {
    if (!intakedata) {
      setFormData(resetformData)
    }
  }, [!intakedata])

  return (
    <>
      {/* <pre className="text-black">{JSON.stringify(intakeStructure.editIntakeStructure.load)}</pre> */}
      {intakeStructure.editIntakeStructure.load ? <Loading /> :
        <div className="container bg-white  mx-auto  h-[85vh]  text-[#1E2022]">
          {/* <div className="h-[5%]"> */}
          <div className="flex mb-4 justify-between items-center border-b-2 py-4 px-4">
            <h3 className="text-base not-italic font-semibold leading-[18.9px] mt-1">Intake Structure Details</h3>
            <FontAwesomeIcon icon={faXmark}
              onClick={() => { dispatch(formPopup(false)); setFormData(resetformData); }}
              className=" mt-1 pr-4 hover:cursor-pointer" />
          </div>
          {/* </div> */}
          <div className="form-height overflow-y-auto">
            <form className="grid px-4">
              {/* <div className="text-black py-4 font-bold">
          <h3 className="">General Information</h3>
        </div> */}
              {/* <div></div> */}
              <SelectField
                label="Type of structure"
                name="type"
                type="select"
                placeholder="Select"
                value={formData.type}
                onChange={onSelectChangeChange}
                error={formErrors.type}
                options={typeOptions}
                labelRequired
              />
              <SelectField
                label="Intake System"
                name="system"
                type="select"
                placeholder="Select"
                value={formData.system}
                onChange={onSelectChangeChange}
                error={formErrors.system}
                options={systemOptions}
              />
              <FormField
                label="Latitude"
                name="latitude"
                type="text"
                placeholder="Enter Longitude"
                value={formData.latitude}
                onChange={handleChange}
                error={formErrors.latitude}
                labelRequired
              />
              <FormField
                label="Longitude"
                name="longitude"
                type="text"
                placeholder="Enter Longitude"
                value={formData.longitude}
                onChange={handleChange}
                error={formErrors.longitude}
                labelRequired
              />
              <FormField
                label="Capacity (MLD)"
                name="capacity"
                type="text"
                placeholder="Enter Capacity"
                value={formData.capacity}
                onChange={handleChange}
                error={formErrors.capacity}
                labelRequired
              />

              <FormField
                label="Dimension (m)"
                name="dimension"
                type="text"
                placeholder="Enter Dimension"
                value={formData.dimension}
                onChange={handleChange}
                error={formErrors.dimension}
                labelRequired
              />
              {/* <SelectField
                          label="Dimension"
                          name="dimension"
                          type="select"
                          placeholder="Select"
                          value={formData.dimension}
                          onChange={onSelectChangeChange}
                          error={formErrors.dimension}
                          options={systemOptions}
                      /> */}
              <div className="flex flex-row text-black mb-3">
                <h3 className="basis-5/6 font-bold text-base">Levels</h3>
              </div>
              <FormField
                label="Highest Flood Level (HFL) (m)"
                name="level"
                type="text"
                placeholder="Enter Highest Flood Level (HFL)"
                value={formData.level}
                onChange={handleChange}
                error={formErrors.level}
                labelRequired
              />
              <FormField
                label="Slab Level (m)"
                name="slab"
                type="text"
                placeholder="Enter Slab Level"
                value={formData.slab}
                onChange={handleChange}
                error={formErrors.slab}
                labelRequired
              />
              <FormField
                label="Bottom Level (m)"
                name="bottom"
                type="text"
                placeholder="Enter Bottom Level"
                value={formData.bottom}
                onChange={handleChange}
                error={formErrors.bottom}
                labelRequired
              />
              {/* Include other form fields similarly */}
            </form>
          </div>
          {/* <div className="h-[10%] flex justify-end"> */}
          {/* <div className="basis-2/4"></div> */}
          <div className="w-full  flex justify-end pr-8 form-footer">
            <button
              className=" text-[#8A96A4] bg-white my-4 pt-1 pb-1 px-4  rounded focus:outline-none focus:shadow-outline border border-solid border-[#EEF0F7] hover:bg-[#F8F7F7]  hover:scale-105"
              // type="submit"
              onClick={() => { dispatch(formPopup(false)); setFormData(resetformData); }}
            >
              Cancel
            </button>
            <button
              className=" ml-3 font-normal text-sm bg-[#377DFF] my-4  text-white pt-1 pb-1 px-2 rounded focus:outline-none focus:shadow-outline hover:scale-105"
              type="submit"
              onClick={handleSubmit}
            >
              {
                intakedata ? "Update Details" : "Save Details"
              }

            </button>
          </div>
          {/* </div> */}
        </div>
      }

    </>
  )
}
export default IntakeStucture
