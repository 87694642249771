import React, { useEffect, useRef, useState } from "react";
import gptimg from "../../assets/images/AquaGPT-icon.png";
import darkgptimg from "../../assets/images/aquaGPTname.png";
import aquagpttext from "../../assets/images/AquaGPTtext.png";
import aquagpttextdark from "../../assets/images/Gpt-icon-dark.png";
import gptgif from "../../assets/images/Aquagpt-Chatbot-Animation-GIF-06.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlaneTop, faXmark } from "@fortawesome/pro-regular-svg-icons";
import {
  faCopy,
  faNote,
  faThumbsDown,
  faThumbsUp,
  faArrowUpRightAndArrowDownLeftFromCenter,
  faArrowDownLeftAndArrowUpRightToCenter,
} from "@fortawesome/pro-light-svg-icons";
// import '../../assets/css/reusable/screenmonitoring.css'
import { useAppDispatch, useAppSelector } from "../../hooks";
import { fetchChatResponse, resetChatData } from "../../redux/slice/Gpt";
import { useLocation } from "react-router-dom";
import "../../assets/css/reusable/alertssolution.css";
import {
  fetchparametersAlerts,
  storedPreparedSolution,
} from "../../redux/slice/alerts";
import moment from "moment";

interface Message {
  text: string;
  type: "user" | "response";
  id: any;
  ans: any;
}

const Gpt = ({ activeTab, selectedHTab, setExpandGPT, expandGPT }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const chat = useAppSelector((state) => state.chat);
  const messageEndRef = useRef<HTMLDivElement>(null); // Add this line
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState<Message[]>([]);
  const profile = useAppSelector((state) => state.profile);
  const [lastResponse, setLastResponse] = useState<string | null>(null);
  // const responseRef = useRef<HTMLDivElement>(null);
  const responseRef = useRef<(HTMLParagraphElement | null)[]>([]);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const { parameterRadioButton, parameterAlerts } = useAppSelector(
    (state) => state.alerts
  );
  useEffect(() => {
    setMessages([]);
    dispatch(resetChatData());
  }, []);
  const handleSendMessage = () => {
    if (message.trim() !== "") {
      setMessages([
        ...messages,
        { text: message, type: "user", id: "", ans: "" },
      ]);
      dispatch(fetchChatResponse(message));
      setMessage(""); // Clear the textarea after sending the message
    }
  };
  const location = useLocation();

  useEffect(() => {
    // parameterRadioButton.map((item:any)=>{
    //     setMessages([...messages, { text: item.question, type: "user" },{ text: item.gpt_ans, type: "response" }]);
    // })
    parameterRadioButton.map((item: any) => {
      const newMessages = [
        { id: item.id, text: item.question, type: "user", ans: item.ans },
        { id: item.id, text: item.gpt_ans, type: "response", ans: item.ans },
      ];

      setMessages((prevMessages: any) => [...prevMessages, ...newMessages]);
    });
  }, [parameterRadioButton]);
  // useEffect(()=>{
  //     setMessages([...messages, { text: "2", type: "response" }]);

  // },[])

  // useEffect(() => {
  //     if (chat.chat.data.length > 0) {
  //         const lastChatResponse = chat.chat.data[chat.chat.data.length - 1].response;
  //         if (lastChatResponse !== lastResponse) {
  //             setMessages((prevMessages) => [...prevMessages, { text: lastChatResponse, type: "response" }]);
  //             setLastResponse(lastChatResponse);
  //         }
  //     }
  // }, [chat.chat.data, lastResponse]);

  // useEffect(() => {
  //     if (chat.chat.data.length > 0) {
  //         const lastChatResponse = chat.chat.data[chat.chat.data.length - 1].response;
  //         if (lastChatResponse !== lastResponse) {
  //             // setMessages((prevMessages) => [...prevMessages, { text: lastChatResponse, type: "response" }]);
  //             typeText(lastChatResponse, 0);
  //             setLastResponse(lastChatResponse);
  //         }
  //     }
  // }, [chat.chat.data, lastResponse]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessage();
    }
  };
  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  const typeText = (text: string, index: number) => {
    const typingSpeed = 20; // Milliseconds per character
    let charIndex = 0;
    const typingInterval = setInterval(() => {
      if (charIndex <= text.length) {
        if (responseRef.current[index]) {
          responseRef.current[index]!.textContent = text.slice(0, charIndex);
        }
        charIndex++;
      } else {
        clearInterval(typingInterval);
        scrollToBottom();
      }
    }, typingSpeed);
  };

  const autoResizeTextarea = (textarea: any) => {
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
  };

  const handleChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
    autoResizeTextarea(e.target);
  };
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (location.pathname === "/Alerts-Solution-Hub") {
      setIsOpen(true);
    }
  });

  useEffect(() => {
    if (chat.chat.data.length > 0) {
      const lastChatResponse =
        chat.chat.data[chat.chat.data.length - 1].response;
      if (lastChatResponse) {
        const newResponse: Message = {
          text: lastChatResponse,
          type: "response",
          id: parameterRadioButton[0]?.id,
          ans: parameterRadioButton[0]?.ans,
        };
        setMessages((prevMessages) => [...prevMessages, newResponse]);
        const responseIndex = messages.length;
        setTimeout(() => {
          typeText(lastChatResponse, responseIndex);
        }, 0);
      }
    }
  }, [chat.chat.data]);
  const copyText = (text: any) => {
    navigator.clipboard.writeText(text);
  };
  const remedyButton = (msg: any) => {
    let obj = {
      coordinate_id: selectedHTab.id,
      checklist_assigned_user_id:
        parameterAlerts.data.data.checklist_assigned_user_id,
      inspector_test_summary_id:
        parameterAlerts.data.data.inspector_test_summary_id,
      water_quality_index_id: parameterAlerts.data.data.water_quality_index_id,
      question_number_id: msg.id,
      yes_or_no_selection: msg.ans,
      prepared_solution: msg.text,
    };
    // console.log(selectedHTab,obj,msg,parameterRadioButton,parameterAlerts.data.data);

    dispatch(storedPreparedSolution(obj));
    const now = moment();

    const date = {
      schedule_date: now.format("YYYY-MM-DD"), // Formats the date as 'YYYY-MM-DD'
      schedule_time: now.format("HH:mm:ss"), // Formats the time as 'HH:mm:ss'
    };

    dispatch(
      fetchparametersAlerts({ coordinate_id: selectedHTab.id, ...date })
    );
  };

  return (
    <div className="flex flex-wrap w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full">
      <div className="relative w-full">
        {/* Button to open the drawer */}
        <button
          className={`${
            location.pathname === "/Alerts-Solution-Hub" ? "hidden" : "block"
          }`}
          onClick={toggleDrawer}
        >
          {activeTab === 1 ? (
            <img
              src={darkgptimg}
              alt=""
              className="fixed bottom-2 right-[6px] w-48"
            />
          ) : (
            <img
              src={darkgptimg}
              alt=""
              className="fixed bottom-2 right-[6px]"
            />
          )}
        </button>

        {/* Drawer Popup */}
        <div
          className={` 
                        ${
                          location.pathname === "/Alerts-Solution-Hub"
                            ? "w-full gpt-custom-height rounded-md border border-[#EFF1F7]  right-0 shadow-light margin-bottm"
                            : "fixed bottom-0 h-[72vh] right-20 w-[40%]   shadow-lg transform transition-transform duration-300 ease-in-out z-50"
                        } ${
            isOpen ? "translate-y-0 mb-24" : "translate-y-full mb-0"
          } ${
            activeTab === 1
              ? "bg-[#44444F] text-[#FFF] "
              : "bg-[#FFF] text-[#000]"
          }`}
        >
          <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full h-full pb-12">
            {location.pathname === "/Alerts-Solution-Hub" ? (
              <div
                className={`w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full text-start border-b border-[#EFF1F7] p-3 flex items-center justify-between`}
              >
                <img src={aquagpttext} alt="" className="h-[23px] w-[115px] " />
                {!expandGPT ? (
                  <button onClick={() => setExpandGPT(!expandGPT)}>
                    <FontAwesomeIcon
                      icon={faArrowUpRightAndArrowDownLeftFromCenter}
                      size="sm"
                    />
                  </button>
                ) : (
                  <button onClick={() => setExpandGPT(!expandGPT)}>
                    <FontAwesomeIcon
                      icon={faArrowDownLeftAndArrowUpRightToCenter}
                      size="sm"
                    />
                  </button>
                )}
              </div>
            ) : (
              <div
                className={`w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full flex flex-wrap justify-end p-4`}
              >
                <FontAwesomeIcon
                  icon={faXmark}
                  onClick={() => setIsOpen(!isOpen)}
                  size="xl"
                  className="cursor-pointer"
                />
              </div>
            )}
            <div className="flex h-full">
              {expandGPT && (
                <div className="h-full w-80 border-r border-[#EFF1F7]">
                  <p className="text-black font-[inter] text-xs font-medium leading-normal px-3 py-4 mb-2">
                    Today
                  </p>
                  <p className="text-black bg-[#F2F3F6] font-[inter] text-sm leading-[16px] px-5 py-4">
                    Some water quality parameters degrade
                  </p>
                </div>
              )}
              <div className="flex-1 flex flex-col items-center justify-center">
                {chat.chat.data.length === 0 &&
                parameterRadioButton.length === 0 ? (
                  <>
                    <div
                      className={`${
                        expandGPT ? "w-[722px] mx-auto" : "w-full"
                      } flex flex-col justify-center items-center  text-center p-4 ${
                        location.pathname === "/Alerts-Solution-Hub"
                          ? "mt-16"
                          : "mt-32"
                      }`}
                    >
                      {activeTab === 1 ? (
                        <img src={aquagpttextdark} alt="" />
                      ) : (
                        <img
                          src={gptgif}
                          alt=""
                          className="h-[104px] w-[ 83.561px]"
                        />
                      )}

                      <h2 className="text-[16px] not-italic font-semibold leading-[24.041px]">
                        <span
                          className={`${
                            activeTab === 1 ? "text-[#FFF]" : "custom-gradient"
                          }`}
                        >
                          AquaGPT
                        </span>{" "}
                        Is AI-Powered Insights for Your Water Quality Analysis
                      </h2>

                      <p className="mt-2 text-[#999] text-[12px] not-italic font-normal leading-normal">
                        Get AI-powered recommendations tailored to your water
                        systems.
                      </p>
                    </div>
                  </>
                ) : null}

                <div
                  className={`${
                    messages.length === 0 || parameterRadioButton
                      ? "h-auto w-full"
                      : "h-auto max-h-[55vh] w-full"
                  } overflow-y-auto mt-1 pb-16 px-4 ${
                    location.pathname === "/Alerts-Solution-Hub"
                      ? "h-auto max-h-[52vh]"
                      : null
                  } ${expandGPT ? "w-[722px] mx-auto" : "w-full"}`}
                  ref={messagesContainerRef}
                >
                  {messages.map((msg, index) => (
                    <>
                      <div
                        className={`${
                          msg.type === "user" ? "justify-end" : "justify-start"
                        } flex`}
                      >
                        <p
                          className={`text-[#121C2D] font-inter text-[15px] font-semibold leading-[20px] ${
                            location.pathname === "/Alerts-Solution-Hub"
                              ? "text-[#121C2D]"
                              : "text-[#fafafb]"
                          }  `}
                        >
                          {msg.type === "user"
                            ? profile?.profile?.data?.first_name
                            : "GPT"}
                        </p>
                      </div>
                      <div
                        key={index}
                        className={`flex whitespace-pre-wrap ${
                          msg.type === "user"
                            ? "flex justify-end"
                            : "justify-start"
                        }`}
                      >
                        <p
                          ref={
                            msg.type === "response"
                              ? (el) => (responseRef.current[index] = el)
                              : null
                          }
                          className={`text-[15.46px] font-normal leading-normal py-1 px-3 rounded-2xl m-2 ${
                            activeTab === 1 ? "bg-[#7f7f92]" : "bg-slate-200"
                          }`}
                        >
                          {msg.text}
                        </p>
                      </div>
                      {msg.type === "response" ? (
                        <div className="flex justify-between items-center self-stretch">
                          {location.pathname === "/Alerts-Solution-Hub" ? (
                            <>
                              <div className="flex items-center gap-2">
                                <FontAwesomeIcon
                                  icon={faCopy}
                                  onClick={() => {
                                    copyText(index);
                                  }}
                                />
                                <FontAwesomeIcon icon={faThumbsUp} />
                                <FontAwesomeIcon
                                  icon={faThumbsDown}
                                  flip="horizontal"
                                />
                              </div>
                              <div className="items-center gap-[10px] px-[10px] py-[8px] hidden">
                                <button
                                  className="rounded-[6px] bg-[#FFF]"
                                  onClick={() => {
                                    remedyButton(msg);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faNote} /> Add to
                                  remedy plan
                                </button>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ))}
                  {/* <div ref={responseRef}></div> */}
                </div>

                <div
                  className={`w-full flex items-center gap-4 px-4 fixed bottom-0 py-5 bg-white ${
                    expandGPT ? "w-[722px] mx-auto" : "w-full"
                  } ${location.pathname === "/Alerts-Solution-Hub" ? "" : ""}`}
                >
                  <textarea
                    name="message"
                    value={message}
                    onChange={handleChangeTextArea}
                    onKeyDown={handleKeyDown}
                    id=""
                    placeholder="Ask AquaGPT"
                    className={`w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full gpt-shadow  px-3 pt-2 placeholder:text-xs text-sm  min-h-10 max-h-32 placeholder:pr-6 ${
                      activeTab === 1
                        ? "bg-[#4F5058] border-none"
                        : "bg-[#FFF] border border-solid border-slate-200"
                    }`}
                    rows={Math.max(2)}
                  ></textarea>
                  <FontAwesomeIcon
                    icon={faPaperPlaneTop}
                    className={`cursor-pointer  ${
                      location.pathname === "/Alerts-Solution-Hub"
                        ? "text-[#42526E] "
                        : ""
                    }`}
                    size={activeTab === "tab1" ? "xl" : "sm"}
                    onClick={handleSendMessage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gpt;
