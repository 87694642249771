// FormField.tsx
import React from "react";
import { number } from "yup";
interface FormFieldProps {
  label?: string;
  labelRequired?: boolean;
  readOnlyLable?: boolean;
  isDisable?: boolean;
  name: string;
  type: string;
  placeholder: string;
  value: string;
  isRequired?:boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  maxLength?:any;
}
const FormField: React.FC<FormFieldProps> = ({
  label,
  readOnlyLable,
  isDisable,
  labelRequired,
  name,
  type,
  placeholder,
  value,
  onChange,
  isRequired,
  error,
  maxLength,
}) => {
  return (
    <div className="mb-4 text-[#1E2022]">
      {
        label &&
        <label className="block text-[#1E2022] text-xs not-italic font-medium leading-[21px] mb-2 " htmlFor={name}>
          {label} <span className="text-[#ED4C78]">{labelRequired ? "*" : ""}</span>
        </label>
      }
      <input
        readOnly={readOnlyLable}
        className={`
         focus:border-indigo-600 focus:ring-0 appearance-none  rounded  w-[100%] py-2 px-3 text-xs text-gray-700 leading-tight  hover:border-[#8C98A4] placeholder:text-[#8C98A4] placeholder:text-xs placeholder:not-italic placeholder:font-medium placeholder:leading-[21px] border border-solid border-gray-300  ${isDisable ? "bg-[#f0f0f7]" : "bg-white"} `}
         // $
      // {error ? "border-red-500" : "border-gray-300"} 
        disabled={isDisable}
        id={name}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={isRequired}
        maxLength={maxLength}
      />
      {error && <p className="text-red-500 text-xs italic">{error}</p>}
    </div>
  );
};
export default FormField;
