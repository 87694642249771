import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/reusable/newdashboard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons";
import EChartsReact from "echarts-for-react";
import Modal from "react-modal";
import "../../assets/css/reusable/modal.css";
import ParameterGraphPopup from "../ParameterGraphPopup";
import { useAppDispatch, useAppSelector } from "../../hooks";
import monitoring, { fetchTopParameters } from "../../redux/slice/monitoring";
// import axios from 'axios';

// const ROOT_PATH = 'http://localhost:3000';

const ParameterComparision = ({ monitoringData }: any) => {
  const [option, setOption] = React.useState<any>(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);
  const [modalIsOpen2, setIsOpen2] = useState(false);
  const [currentPopup, setCurrentPopup] = useState<number | null>(null);
  const dispatch = useAppDispatch();
  const monitoring = useAppSelector((state) => state.monitoring);
  const [tooltipData, setTooltipData] = useState<any>(null);
  // const topParameterComparision=useAppSelector((state)=>state.topParameterComparision)
  const [selectedValue, setSelectedValue] = useState<string>("Daily");

  const selectOptions = [{ value: "Daily" }, { value: "Within a year" }].filter(
    (option) => option !== undefined
  );

  const onSelectChange = (e: any) => {
    const value = e.target.value;
    setSelectedValue(value);
  };

  useEffect(() => {
    if (monitoring && selectedValue) {
      let obj = {
        ...monitoringData,
        category_id: monitoring.updateCategoryId,
        occurrence: selectedValue,
      };

      if (
        obj.category_id &&
        obj.occurrence &&
        obj.coordinate_id &&
        obj.schedule_date &&
        obj.schedule_time
      ) {
        dispatch(fetchTopParameters(obj));
      }
    }
  }, [monitoringData, selectedValue]);

  // monitoring, monitoringData, selectedValue

  const topParameterComparision =
    monitoring.topParameterComparision?.data?.data;

  const parameterName = topParameterComparision?.map(
    (data: any) => data?.parameter_name
  );

  useEffect(() => {
    const fetchData = () => {
      if (topParameterComparision?.length === 0) return;

      const _rawData =
        topParameterComparision?.map((item: any) => {
          // const samplingTime = item?.sampling_time ? parseFloat(item.sampling_time.split(':')[0]) : null;
          let samplingTime;
          if (typeof item?.sampling_time === "string") {
            // When sampling_time is a string, parse the hour part
            samplingTime = parseFloat(item.sampling_time.split(":")[0]);
          } else {
            // When sampling_time is already a number, just use it directly
            samplingTime = item?.sampling_time;
          }
          // Provide a static value if observation is missing
          const observationValue =
            item?.observation !== undefined ? parseFloat(item.observation) : 10; // static value
          return {
            sampling_time: samplingTime ? samplingTime : 0,
            parameter_name: item?.parameter_name,
            observation: observationValue,
          };
        }) || [];

      run(_rawData);
    };

    const run = (_rawData: any) => {
      const parameters = Array.from(
        new Set(_rawData?.map((item: any) => item.parameter_name))
      );

      const datasetWithFilters: any = [];
      const seriesList: any = [];
      const samplingTimeConditions = Array.from({ length: 25 }, (_, i) => i);
      const monthMapping = {
        Jan: 1,
        Feb: 2,
        Mar: 3,
        Apr: 4,
        May: 5,
        Jun: 6,
        Jul: 7,
        Aug: 8,
        Sep: 9,
        Oct: 10,
        Nov: 11,
        Dec: 12,
      };

      const monthConditions = [monthMapping["Jan"], monthMapping["Dec"]];

      parameters?.length > 0 &&
        parameters?.forEach((parameter) => {
          const datasetId = `dataset_${parameter}`;
          // Define the filter based on the selected value
          let filterCondition;
          if (selectedValue === "Daily") {
            filterCondition = [
              {
                dimension: "sampling_time",
                gte: Math.min(...samplingTimeConditions),
                lte: Math.max(...samplingTimeConditions),
              },
              { dimension: "parameter_name", "=": parameter },
            ];
          } else if (selectedValue === "Within a year") {
            filterCondition = [
              {
                dimension: "sampling_time",
                gte: monthConditions[1],
                lte: monthConditions[1],
              },
              { dimension: "parameter_name", "=": parameter },
            ];
          }
          datasetWithFilters.push({
            id: datasetId,
            fromDatasetId: "dataset_raw",
            transform: {
              type: "filter",
              config: {
                and: filterCondition,
              },
            },
          });

          seriesList.push({
            type: "line",
            datasetId,
            showSymbol: true, // Always show symbols (dots)
            symbolSize: 8, // Set the size of the symbols (dots)
            smooth: true,
            name: parameter,
            endLabel: {
              show: false,
              formatter: (params: any) => `Parameter: ${params.seriesName}`,
            },
            labelLayout: {
              moveOverlap: "shiftY",
            },
            emphasis: {
              focus: "series",
            },
            encode: {
              x: "sampling_time",
              y: "observation",
              label: ["parameter_name", "observation"],
              itemName: "sampling_time",
              tooltip: ["observation"],
            },
          });
        });
      const xAxisConfig =
        selectedValue === "Daily"
          ? {
              type: "category",
              name: "Time",
              nameLocation: "middle",
              nameTextStyle: {
                fontSize: 14,
                padding: 10,
              },

              axisLabel: {
                formatter: (value: number) => `${value}`,
              },
              data: Array.from({ length: 25 }, (_, i) => i),
            }
          : {
              type: "category",
              name: "Month",
              nameLocation: "middle",
              nameTextStyle: {
                fontSize: 14,
                padding: 10,
                left: 10,
              },
              data: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ],
            };

      const option = {
        animationDuration: 6000,
        dataset: [
          {
            id: "dataset_raw",
            source: _rawData,
          },
          ...datasetWithFilters,
        ],
        title: {
          text: "",
        },
        tooltip: {
          order: "valueDesc",
          trigger: "axis",
        },
        xAxis: xAxisConfig,
        yAxis: [
          {
            type: "value",
            // name: 'mg/L',
            min: 0,
            max:
              Math.max(..._rawData?.map((item: any) => item?.observation), 0) +
              6, // Adjust according to your data
            // interval: 5,
            nameLocation: "start",
            // nameTextStyle: {
            //     padding: [10, 0, 0, -20]
            // },
            axisLabel: {
              formatter: "{value}",
            },
          },
        ],
        grid: {
          right: 30,
          left: 25,
          bottom: 40,
          top: 25,
        },
        series: seriesList,
      };

      setOption(option);
    };

    fetchData();
  }, [topParameterComparision, selectedValue]);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0px",
      transform: "translate(-50%, -50%)",
      height: "692px",
      width: "1197px",
      borderRadius: "0px",

      // background: 'transparent'
    },
  };

  Modal.setAppElement("#root");
  function openModal(id: number) {
    // Find the clicked item in popupData
    const clickedItem = popupData.find((item) => item.id === id);

    // If the item has a component, open the modal and close the popup
    if (clickedItem && clickedItem.componentName) {
      setCurrentPopup(id);
      setIsOpen2(true);
      setIsOpen(false);
    }
    // If the item has no component, don't change modalIsOpen2 or close the popup
    else {
      setCurrentPopup(null);
    }
  }

  function closeModal() {
    setIsOpen2(false);
    setCurrentPopup(null);
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);
    // Remove event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const popupData = [
    {
      id: 1,
      btnName: "View All Parameters",
      componentName: (
        <ParameterGraphPopup
          closeModal={closeModal}
          monitoringData={monitoringData}
          selectedValue={selectedValue}
        />
      ),
      onclickevent: closeModal,
    },
    {
      id: 2,
      btnName: "Edit Graph",
      componentName: null,
      onclickevent: closeModal,
    },
    {
      id: 3,
      btnName: "Remove Graph",
      componentName: null,
      onclickevent: closeModal,
    },
    {
      id: 4,
      btnName: "Download image",
      componentName: null,
      onclickevent: closeModal,
    },
    {
      id: 5,
      btnName: "Set Top 5 Parameters",
      componentName: (
        <ParameterGraphPopup
          closeModal={closeModal}
          id={5}
          monitoringData={monitoringData}
          selectedValue={selectedValue}
        />
      ),
      onclickevent: closeModal,
    },
  ];

  useEffect(() => {
    // Close the popup when the modal is opened
    if (modalIsOpen2) {
      setIsOpen(!modalIsOpen);
    }
  }, [modalIsOpen2]);

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  return (
    <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full parameter-comparision relative">
      <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full sub-div p-4">
        <div
          className="w-full flex items-center justify-between"
          ref={popupRef}
        >
          <div className="text-[#000] text-base not-italic font-normal leading-normal font-[inter]">
            Top 5 Parameter's Comparison
          </div>
          <div>
            <select
              onChange={onSelectChange}
              name="select"
              id=""
              className="text-[#6D6D6D] text-xs not-italic font-normal leading-normal"
            >
              {selectOptions?.map((opt: any, index: any) => (
                <>
                  <option key={index} value={opt.value}>
                    {opt.value}
                  </option>
                </>
              ))}
            </select>
            <FontAwesomeIcon
              icon={faEllipsisVertical}
              className="ml-7 mr-4 cursor-pointer px-2"
              onClick={() => setIsOpen(!modalIsOpen)}
            />
            {modalIsOpen && !modalIsOpen2 && (
              <div
                className={`w-[186px] h-52 z-50 bg-[#FFF] border border-[#EFF1F7] rounded-md absolute top-12 right-11 p-4`}
              >
                {popupData.map((data) => (
                  <ul key={data?.id}>
                    <li
                      onClick={() => openModal(data?.id)}
                      className="text-[#677788] text-[13px] not-italic font-normal leading-[20px] py-2 cursor-pointer"
                    >
                      {data?.btnName}
                    </li>
                  </ul>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full pt-[14px]">
          <div className="w-full flex">
            <ul className="flex text-[#2D2D2D] flex-wrap  text-xs not-italic font-normal leading-normal tracking-[0.5px]">
              {parameterName?.map((item: any, index: any) => (
                <li key={index} className="pr-4 flex items-center">
                  <div
                    className={`w-[10px] h-[10px] rounded-full mr-2 bg-[#000]`}
                    style={{ backgroundColor: getRandomColor() || "#000" }}
                  ></div>
                  {item}
                </li>
              ))}

              {/* <li className='pr-4 flex items-center '><div className='bg-[#695CFB] w-[10px] h-[10px] rounded-full mr-2'></div>Parameter 1 </li>
              <li className='pr-4 flex items-center'><div className='bg-[#0FCA7A] w-[10px] h-[10px] rounded-full mr-2'></div>Parameter 2 </li>
              <li className='pr-4 flex items-center'><div className='bg-[#F7A23B] w-[10px] h-[10px] rounded-full mr-2'></div>Parameter 3 </li>
              <li className='pr-4 flex items-center'><div className='bg-[#F75D5F] w-[10px] h-[10px] rounded-full mr-2'></div>Parameter 4 </li>
              <li className='pr-4 flex items-center'><div className='bg-[#7ab5dd] w-[10px] h-[10px] rounded-full mr-2'></div>Parameter 5 </li> */}
            </ul>
          </div>
          {option && <EChartsReact option={option} />}
        </div>
      </div>
      {popupData?.map(
        (data) =>
          data?.componentName && (
            <Modal
              key={data?.id}
              isOpen={currentPopup === data?.id}
              shouldCloseOnOverlayClick={true}
              onRequestClose={data?.onclickevent}
              style={customStyles}
              contentLabel="Popup Modal"
            >
              <div className="modal_body">
                <div>{data?.componentName}</div>
              </div>
            </Modal>
          )
      )}
    </div>
  );
};

export default ParameterComparision;
