import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DataServer } from "../../../config.axios";
export interface ProfileData {
    first_name: string;
    last_name: string;
    role: string;
}
export interface Token {
    authentication_user_info: any;
    id: number,
    first_name: string,
    last_name: string,
    designation: string,
    email: string,
    email_verified_at: any,
    role_id: number,
    role: string
}
interface ProfileState {
    profile: {
        load: boolean;
        data: Array<ProfileData>;
    };
    profileData?: ProfileData;
}
const initialState = {
    profile: {
        load: false,
        data: [],
    },
    profileData: {
        first_name: "",
        last_name: "",
        role: "",
    },
    token: {
        id: 0,
        first_name: '',
        last_name: '',
        designation: '',
        email: '',
        email_verified_at: undefined,
        role_id: 0,
        role: '',
        authentication_user_info: {}
    },
} as ProfileState;
export const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        resetProfileData: (state: any) => {
            state.profile = initialState.profile;

        },
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchProfile.pending, (state: ProfileState) => {
                state.profile.load = true;
            })
            .addCase(fetchProfile.fulfilled, (state: ProfileState, action: any) => {
                state.profile.data = action.payload;
                state.profile.load = false;
            })
            .addCase(fetchProfile.rejected, (state: ProfileState) => {
                state.profile.load = false;
            })
            .addCase(fetchEmail.pending, (state: ProfileState) => {
                // state.token = true;
            })
            .addCase(fetchEmail.fulfilled, (state: any, action: any) => {
                state.token = action.payload;
                // state.token = false;
            })
            .addCase(fetchEmail.rejected, (state: ProfileState) => {
                // state.token = false;
            })
    },
});
export const { resetProfileData } = profileSlice.actions;
export default profileSlice.reducer;
export const fetchProfile = createAsyncThunk(
    "profile/fetchProfile",
    async (param: any, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/fetch-user-using-email", {
                email: param
            });
            return data;
        } catch (error) {
            return error;
        }
    }
);

export const fetchEmail = createAsyncThunk(
    "profile/fetchEmail",
    async (param, thunkAPI) => {
        try {
            const { data } = await DataServer.post("/api/get-invitation-token-data",
                {
                    token: param
                }
            );
            return data;
        } catch (error) {
            return error;
        }
    }
);