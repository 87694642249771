import {
  faArrowsMaximize,
  faEllipsisVertical,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../assets/css/reusable/newdashboard.css";
import * as echarts from "echarts";
import EChartsReact from "echarts-for-react";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  fetchParametersSourcePollutantsData,
  getComparisionGraph,
  getCorelationData,
  getWaterQualityStaions,
} from "../../redux/slice/monitoring";
import { fetchHorizontalTab } from "../../redux/slice/checklist";
import { useLocation } from "react-router-dom";
import Modal from "react-modal";

import "../../assets/css/reusable/modal.css";
import CorelationPopup from "../CorelationPopup";
// import { text } from "@fortawesome/fontawesome-svg-core";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import React from "react";

const DashboardGraphs = ({ monitoringData }: any) => {
  const dispatch = useAppDispatch();
  // const corelationData=useAppSelector((state)=>state.corelationData)
  const monitoring = useAppSelector((state) => state.monitoring);
  const horizontalTab = useAppSelector((state) => state.checklist);
  const location = useLocation();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [truncate, setTruncate] = useState<any>([]);
  // const monitoring = useAppSelector((state) => state.monitoring);
  useEffect(() => {
    dispatch(getCorelationData());
  }, []);

  const coordinate_id = monitoring.updatedData.coordinate_id;
  useEffect(() => {
    if (coordinate_id) {
      dispatch(fetchHorizontalTab(coordinate_id));
    }
  }, [coordinate_id]);
  // console.log('location',location?.state?.selectedComponent?.id)
  // console.log("selectedComponent",location?.state?.selectedComponent?.id)

  //************************ */ paramter corelation data code starts here ***********
  const allParameterData =
    monitoring?.corelationData?.data?.data?.allParameters;
  const parameterCorelations =
    monitoring?.corelationData?.data?.data?.parameterCorelations;
  // console.log('corelationData',corelationData)

  const dirDist50 = "#E8544E";
  const dirDist10 = "#FFD265";
  const dirDistLess10 = "#2AA775";
  const colors = [dirDist50, dirDist10, dirDistLess10];
  const getRandomColor = () =>
    colors[Math.floor(Math.random() * colors.length)];

  const truncateName = (name: string) => {
    return name?.length > 3 ? name?.substring(0, 8) + "..." : name;
  };

  useEffect(() => {
    if (allParameterData?.length > 0) {
      const newTruncatedParameters = allParameterData.map((param: string) =>
        param.substring(0, 8)
      );
      // Update state with truncated values
      setTruncate(newTruncatedParameters);
    }
  }, [allParameterData]);

  const getRandomSize = (minSize = 15, maxSize = 40) => {
    return Math.floor(Math.random() * (maxSize - minSize + 1)) + minSize;
  };

  // Mapping through all parameters to create the data array for the graph
  const ParameterData = allParameterData
    ?.map((param: any, index: any) => {
      if (!param) {
        console.error("Parameter is undefined", param);
        return null;
      }
      const truncatedName = truncateName(param);
      return {
        // name: param?.length > 3 ? param?.substring(0, 3) + '...' : param,
        name: param,

        // name: param,
        symbolSize: getRandomSize(), // You can set a default or fetch this value from the API
        itemStyle: { color: param.color || getRandomColor() }, // Default color or fetch from param
        draggable: true,
        label: {
          show: true,
          fontSize: 10, // Adjust font size here
          color: "black",
        },
        key: index,
      };
    })
    .filter((item: null) => item !== null);
  const parametername = ParameterData?.map((data: any) => data?.name);
  // Mapping through parameter correlations to create the links array for the graph
  const graphLinks = parameterCorelations?.map((correlation: any) => {
    return {
      source: correlation.base_parameter,
      target: correlation.relation_parameter,
    };
  });

  //************************* */ paramter corelation data code ends here ****************************

  //*************** */ code for top 7 parameter comparision starts here*********************************8

  const selectOptions = horizontalTab.horizontalTab?.data?.map(
    (data: any) => data?.name
  );

  const selectId = horizontalTab.horizontalTab?.data?.map((data: any) => ({
    name: data?.name,
    horizontal_tab_id_first: data?.id,
    horizontal_tab_id_second: data?.id,
  }));

  // Initialize formData state
  const [formData, setFormData] = useState({
    test1: {
      name: selectOptions[0],
      id: selectId?.find(
        (option: { name: any }) => option.name === selectOptions[0]
      )?.horizontal_tab_id_first,
    },
    test2: {
      name: selectOptions[0],
      id: selectId?.find(
        (option: { name: any }) => option.name === selectOptions[0]
      )?.horizontal_tab_id_second,
    },
  });

  // Change handler for the first select (test1)
  const onSelectChange = (e: any) => {
    const { name, value } = e.target;

    // Find the corresponding ID for the selected name in test1
    const selectedId = selectId.find(
      (option: { name: any }) => option.name === value
    )?.horizontal_tab_id_first;

    // Update formData for test1
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: { name: value, id: selectedId },
    }));
  };

  // Change handler for the second select (test2)
  const onSelectChangeSecond = (e: any) => {
    const { name, value } = e.target;

    // Find the corresponding ID for the selected name in test2
    const selectedIdSecond = selectId.find(
      (option: { name: any }) => option.name === value
    )?.horizontal_tab_id_second;

    // Update formData for test2
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: { name: value, id: selectedIdSecond },
    }));
  };

  useEffect(() => {
    const data = {
      ...monitoringData,
      category_id: monitoring.updateCategoryId,
      horizontal_tab_id_first: formData.test1.id,
      horizontal_tab_id_second: formData.test2.id,
    };

    if (
      data.category_id &&
      data.horizontal_tab_id_first &&
      data.horizontal_tab_id_second &&
      data.coordinate_id &&
      data.schedule_date
    ) {
      dispatch(getComparisionGraph(data));
    }
  }, [
    formData.test1.id,
    formData.test2.id,
    monitoringData,
    monitoring.updateCategoryId,
  ]);

  const topparameterComparisionGraphdata =
    monitoring?.topParameterComparisionGraph?.data?.data;

  const getParametersFirstData =
    topparameterComparisionGraphdata?.getParametersFirst || [];
  const getParametersSecondData =
    topparameterComparisionGraphdata?.getParametersSecond || [];

  const firstParameterNames = Array.isArray(getParametersFirstData)
    ? getParametersFirstData?.map((item: any) => item.parameter_name)
    : [];
  const secondParameterNames = Array.isArray(getParametersSecondData)
    ? getParametersSecondData?.map((item: any) => item.parameter_name)
    : [];

  const allParameterNames = Array.from(
    new Set([...firstParameterNames, ...secondParameterNames])
  );
  const truncatedParameterNames = allParameterNames?.map((name: string) =>
    name.length > 10 ? name.slice(0, 10) + "..." : name
  );
  const firstSeriesData = allParameterNames?.map((paramName: string) => {
    const item = getParametersFirstData.find(
      (item: any) => item?.parameter_name === paramName
    );
    return item ? parseFloat(item?.observation || 0) : 0;
  });

  const secondSeriesData = allParameterNames?.map((paramName: string) => {
    const item = getParametersSecondData?.find(
      (item: any) => item?.parameter_name === paramName
    );
    return item ? parseFloat(item?.observation || 0) : 0;
  });

  //*************** */ code for top 7 parameter comparision ends here*********************************8

  //*************** */ code for water quality station starts here*********************************

  // const schedule_date=monitoring?.updatedData?.schedule_date
  // const schedule_time=monitoring?.updatedData?.schedule_time

  const dateTime = {
    schedule_date: monitoring?.updatedData?.schedule_date,
    schedule_time: monitoring?.updatedData?.schedule_time,
    project_id: location.state.projectData.project_id,
  };
  useEffect(() => {
    if (
      dateTime.schedule_date &&
      dateTime.schedule_time &&
      dateTime.project_id
    ) {
      dispatch(getWaterQualityStaions(dateTime));
    }
  }, [dateTime.schedule_date && dateTime.schedule_time && dateTime.project_id]);

  const classMapping: any = {
    A: 1,
    B: 2,
    C: 3,
    D: 4,
    E: 5,
    F: 6,
    // Add more mappings as needed
  };

  const waterQualityStations = monitoring?.waterQualityStaions?.data?.data;
  const xAxisData = waterQualityStations?.map(
    (item: { component_name: any }) => item.component_name
  );
  const seriesData = waterQualityStations?.map(
    (item: { class_name: string | number }) => classMapping[item.class_name]
  );
  const element_id = waterQualityStations?.map(
    (item: { Element_id: any }) => item.Element_id
  );

  //*************** */ code for water quality station2 ends here*********************************

  //*************** */ code for piechart starts here*********************************
  useEffect(() => {
    const data = {
      ...monitoringData,
      category_id: monitoring.updateCategoryId,
    };
    if (data.category_id && data.coordinate_id && data.schedule_date) {
      dispatch(fetchParametersSourcePollutantsData(data));
    }
  }, [monitoringData]);
  const pieChartData = monitoring?.parameterPollutantData?.data?.data;

  //*************** */ code for piechart ends here*********************************

  const chartRef = useRef<any>(null); // Ref for ECharts instance

  const graphData = [
    {
      id: 1,
      graphHeading: "Source of Pollutants / Contaminants",
      graphName: "Source of Pollutants / Contaminants",
      option: {
        title: {
          text: "Source of Pollutants / Contaminants",
          left: "center",
          bottom: -18,
          textStyle: {
            fontSize: 12,
            fontWeight: 400,
            color: "#2D2D2D",
          },
          padding: [30, 0, 20, 0],
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: -5,
          left: "left",
          orient: "horizontal", // Set the orientation to vertical
          // top: 'middle', // Center the legend vertically
          // left: 'center', // Center the legend horizontally
          itemWidth: 10, // Adjust the width of the legend symbol
          itemHeight: 10, // Adjust the height of the legend symbol
          icon: "circle", // Set the legend icon to a circle
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 20,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: pieChartData
              ? Object.keys(pieChartData)
                  .filter((key) => key.endsWith("_percent")) // Filter keys that end with '_percent'
                  .map((key) => ({
                    name: key
                      .replace("_parameters_percent", "") // Remove '_parameters_percent' from key for the name
                      .replace(/_/g, " ") // Replace underscores with spaces
                      .replace(/^\w/, (c) => c.toUpperCase()), // Capitalize the first letter
                    value: parseFloat(pieChartData[key]), // Convert the percentage string to a float for the value
                  }))
              : [], // Return an empty array if pieChartData is undefined or null
          },
        ],
      },
    },
    {
      id: 2,
      graphHeading: "Top 5 Parameters Comparison",
      option: {
        title: {
          text: "Top 5 Parameters Comparison",
          left: "center",
          bottom: -18,
          textStyle: {
            fontSize: 12,
            fontWeight: 400,
            color: "#2D2D2D",
          },
          padding: [10, 0, 20, 0],
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        toolbox: {
          feature: {
            dataView: { show: false, readOnly: false },
            magicType: { show: false, type: ["line", "bar"] },
            restore: { show: false },
            saveAsImage: { show: false },
          },
        },
        legend: {
          // data: ['First Series', 'Second Series']
        },
        xAxis: [
          {
            type: "category",
            data: allParameterNames,
            // data: ['(BOD)', '(COD)', 'Nitrate', 'Phosphate', 'Magnesium'],
            axisPointer: {
              type: "shadow",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "mg/L",
            min: 0,
            max: 50, // Adjust according to your data
            interval: 10,
            nameLocation: "start",
            nameTextStyle: {
              padding: [10, 0, 0, -20],
            },
            axisLabel: {
              formatter: "{value}",
            },
          },
        ],
        series: [
          {
            // name: 'First Series',
            type: "bar",
            barWidth: "12%",
            color: "#377DFF",
            barGap: "1",
            tooltip: {
              valueFormatter: function (value: any) {
                return value + " ml";
              },
            },
            data: firstSeriesData,
          },
          {
            // name: 'Second Series',
            type: "bar",
            barWidth: "12%",
            color: "rgba(55, 125, 255, 0.38)",
            tooltip: {
              valueFormatter: function (value: any) {
                return value + " ml";
              },
            },
            data: secondSeriesData,
          },
        ],
        grid: {
          top: 25,
          left: 25,
          right: 10, // Increase this value to add more space on the right
        },
      },
    },

    {
      id: 3,
      graphHeading: "Comparison of Water Quality Station-wise",
      graphName: "Water Quality Value",
      option: {
        title: {
          text: "Comparison of Water Quality Station-wise",
          left: "center",
          bottom: -18,
          textStyle: {
            fontSize: 12,
            fontWeight: 400,
            color: "#2D2D2D",
          },
          padding: [10, 0, 20, 0],
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params: any) {
            // const elementId = waterQualityStations?.Element_id;
            // console.log("ParamsElementID", params);

            // Return custom tooltip content
            return `<div>${params[0]?.axisValueLabel} : ${params[0]?.data}</div>`;
          },
        },
        grid: {
          left: "0%",
          right: "0%",
          bottom: "8%",
          top: 25,
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: xAxisData,
            axisTick: {
              alignWithLabel: true,
            },
            // boundaryGap: false,
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "Direct",
            type: "bar",
            // barWidth: "6%",
            color: "#377DFF",
            data: seriesData,
          },
        ],
      },
    },
    {
      id: 4,
      graphHeading: "Parameter Correlation",
      option: {
        tooltip: {
          formatter: function (params: any) {
            if (params.dataType === "edge") {
              return "";
            }
            return `${params.data.name}`;
          },
        },
        series: [
          {
            type: "graph",
            layout: "force",
            force: {
              repulsion: 80,
              edgeLength: 50,
              gravity: 0.05, // Adjust gravity to keep nodes closer to the center
            },
            label: {
              formatter: function (params: any) {
                return params.data.name.length > 3
                  ? params.data.name.slice(0, 3) + "..."
                  : params.data.name;
              },
              show: true,
            },
            data: ParameterData,
            links: graphLinks,
            itemStyle: {
              color: "#000", // Node color
            },
            roam: false, // Enable zoom and pan
            focusNodeAdjacency: true, // Highlight adjacent nodes
            lineStyle: {
              color: "#000", // Line color
              width: 1, // Line width
              curveness: 0, // No curve on lines
            },
            emphasis: {
              lineStyle: {
                width: 2, // Highlight line width on hover
              },
            },
          },
        ],
        grid: {
          left: "0%",
          right: "0%",
          bottom: "3%",
          containLabel: true, // Ensure labels are contained within the canvas
        },
        scaleLimit: {
          min: 0.8,
          max: 1.2,
        },
        animationDurationUpdate: 1500, // Adjust this if you want smoother movement within the canvas
        animationEasingUpdate: "quinticInOut", // Easing for smooth transitions
      },
    },
  ];

  // const onDragEnd = (result:any) => {
  //     if (!result.destination) return;
  //     const items = Array.from(graphData);
  //     const [reorderedItem] = items.splice(result.source.index, 1);
  //     items.splice(result.destination.index, 0, reorderedItem);
  //     setGraphData(items);
  // };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0px",
      transform: "translate(-50%, -50%)",
      height: "692px",
      width: "1197px",
      borderRadius: "0px",

      // background: 'transparent'
    },
  };

  Modal.setAppElement("#root");
  function openModal(id: any, edit: any) {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <>
      <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full flex flex-wrap mt-3 dashboard-graph justify-between">
        {graphData?.map((data, index) => (
          <div className="w-[49%] sm:w-full md:w-full lg:w-[49%] xl:w-[49%] 2xl:w-[49%]  my-3 margin-div">
            <div className="w-full p-4 sub-div">
              <div className="w-full flex  items-center justify-between">
                <h2 className="text-[#000]  font-[Inter] text-base not-italic font-normal leading-normal ">
                  {data.graphHeading}
                </h2>
                {data.id === 2 ? (
                  <div className="flex">
                    <select
                      name="test1"
                      className="text-[#6D6D6D] text-xs not-italic font-normal leading-normal text-ellipsis overflow-hidden text-nowrap w-20"
                      onChange={onSelectChange}
                      value={formData.test1.name}
                    >
                      {selectOptions &&
                        selectOptions?.map((option: any, index: any) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                    </select>
                    <select
                      name="test2"
                      className="text-[#6D6D6D] text-xs not-italic font-normal leading-normal text-ellipsis overflow-hidden text-nowrap w-20"
                      onChange={onSelectChangeSecond}
                      value={formData.test2.name}
                    >
                      {selectOptions &&
                        selectOptions?.map((option: any, index: any) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                    </select>
                  </div>
                ) : data.id === 4 ? (
                  <FontAwesomeIcon
                    icon={faArrowsMaximize}
                    className="cursor-pointer"
                    onClick={() => openModal("", "add")}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faEllipsisVertical}
                    className="cursor-pointer"
                  />
                )}
              </div>
              <div
                className={`flex items-center mt-2 
                                                        ${
                                                          data.id === 4
                                                            ? "invisible mb-[6px]"
                                                            : data.id === 2
                                                            ? "hidden"
                                                            : null
                                                        }`}
              >
                <div
                  className={`w-[10px] h-[10px] rounded-full mr-[6px] bg-[#377DFF] ${
                    data.id === 1 ? "invisible" : null
                  }`}
                ></div>

                <h4
                  className={`text-[#2D2D2D] font-[inter] text-xs not-italic font-normal leading-normal tracking-[0.5px] ${
                    data.id === 1 ? "invisible" : null
                  }`}
                >
                  {data.graphName}
                </h4>
              </div>
              {data.id === 2 ? (
                <>
                  <div className="flex flex-wrap items-center mt-2">
                    <div className="flex items-center mr-3">
                      <div
                        className={`w-[10px] h-[10px] rounded-full mr-[6px] bg-[#377DFF]`}
                      ></div>
                      <h4 className="text-[#2D2D2D] font-[inter] text-xs not-italic font-normal leading-normal tracking-[0.5px]">
                        {/* Pre monsoon */}
                        {formData?.test1?.name}
                      </h4>
                    </div>

                    <div className="flex items-center">
                      <div
                        className={`w-[10px] h-[10px] rounded-full mr-[6px] bg-[#92b2ee]`}
                      ></div>
                      <h4 className="text-[#2D2D2D] font-[inter] text-xs not-italic font-normal leading-normal tracking-[0.5px]">
                        {/* Post monsoon */}
                        {formData?.test2?.name}
                      </h4>
                    </div>
                  </div>
                </>
              ) : null}
              {data.option && (
                <EChartsReact
                  ref={chartRef}
                  option={data.option}
                  style={{ width: "100%", height: "255px" }}
                />
              )}
            </div>
          </div>
        ))}
      </div>

      <Modal
        isOpen={modalIsOpen}
        shouldCloseOnOverlayClick={false}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="modal_body">
          {/* <CreateProjectForm onclickevent={closeModal}></CreateProjectForm> */}
          <CorelationPopup onclickevent={closeModal}></CorelationPopup>
        </div>
      </Modal>
    </>
  );
};

export default DashboardGraphs;
