import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { fetchComponents } from "../Map";
import { fetchCoordinates } from "../coordinate";

export interface IntakeStructureData {
    component_id: number,
    coordinate_id: number,
    type_of_structure: string,
    capacity: number,
    dimension: number,
    highest_flood_level: number,
    slab_level: number,
    bottom_level: number,
    intake_system: string,
    id: number
}

interface IntakeStructuresState {
    intakeStructures: {
        load: boolean;
        data: Array<IntakeStructureData>;
        error: Array<IntakeStructureData>;
    };
    selectedIntakeStructure?: IntakeStructureData;
    editIntakeStructure: {
        load: boolean;
    };
}
const initialState = {
    intakeStructures: {
        load: false,
        data: [],
        error: []
    },
    selectedIntakeStructure: {
        component_id: "",
        coordinate_id: "",
        type_of_structure: "",
        capacity: "",
        dimension: "",
        highest_flood_level: "",
        slab_level: "",
        bottom_level: "",
        intake_system: "",
        id: ""
    },
    editIntakeStructure: {
        load: false,
    },
    deleteIntakeStructure: {
        load: false,
    },
} as unknown as IntakeStructuresState;

export const intakeStructuresSlice = createSlice({
    name: "intakeStructures",
    initialState,
    reducers: {
        resetIntakeStructureData: (state: IntakeStructuresState) => {
            state.selectedIntakeStructure = initialState.selectedIntakeStructure;
            state.intakeStructures.error = []
        },
        updateData: (state: IntakeStructuresState, action: { payload: IntakeStructureData }) => {
            state.selectedIntakeStructure = action.payload;
        },
        addIntakeStructureError: (state: IntakeStructuresState, action: any) => {
            state.intakeStructures.error = action.payload;
        },
        addIntakeStructureSuccess: (state: IntakeStructuresState, action: any) => {
            if (action.payload) {
                state.intakeStructures.data = [{ ...action.payload }, ...state.intakeStructures.data];
            }
            state.intakeStructures.load = false;
        },
        editIntakeStructureSuccess: (state: IntakeStructuresState, action: any) => {
            if (action.payload) {

                const editedIntakeStructureIndex = state.intakeStructures.data.findIndex(intakeStructure => intakeStructure.id === action.payload.id);

                if (editedIntakeStructureIndex !== -1) {
                    state.intakeStructures.data[editedIntakeStructureIndex] = { ...action.payload };
                }
            }
            state.intakeStructures.load = false;
        },
        deleteIntakeStructureSuccess: (state: IntakeStructuresState, action: any) => {
            if (action.payload) {
                const intakeStructureIdToDelete = action.payload.id;
                const indexToDelete = state.intakeStructures.data.findIndex(intakeStructure => intakeStructure.id === intakeStructureIdToDelete);
                if (indexToDelete !== -1) {
                    state.intakeStructures.data.splice(indexToDelete, 1);
                }
            }
            state.intakeStructures.load = false;
        }
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchIntakeStructures.pending, (state: IntakeStructuresState) => {
                state.intakeStructures.load = true;
            })
            .addCase(fetchIntakeStructures.fulfilled, (state: IntakeStructuresState, action: any) => {
                state.intakeStructures.data = action.payload;
                state.intakeStructures.load = false;
            })
            .addCase(fetchIntakeStructures.rejected, (state: IntakeStructuresState) => {
                state.intakeStructures.load = false;
            })
            .addCase(fetchIntakeStructureById.pending, (state: IntakeStructuresState) => {
                state.editIntakeStructure.load = true;
            })
            .addCase(fetchIntakeStructureById.fulfilled, (state: IntakeStructuresState, action: any) => {
                state.selectedIntakeStructure = action.payload;
                state.editIntakeStructure.load = false;
            })
            .addCase(fetchIntakeStructureById.rejected, (state: IntakeStructuresState) => {
                state.editIntakeStructure.load = false;
            });
    },
});
export const { updateData, resetIntakeStructureData, addIntakeStructureError, addIntakeStructureSuccess, editIntakeStructureSuccess, deleteIntakeStructureSuccess } = intakeStructuresSlice.actions;
export default intakeStructuresSlice.reducer;

export const fetchIntakeStructures = createAsyncThunk(
    "intakeStructures/fetchIntakeStructures",
    async (param: any, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("/api/user-wise-intakeStructure-list", {
                user_id: param
            });
            // thunkAPI.dispatch(toggleLoading());
            return data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const fetchIntakeStructureById = createAsyncThunk(
    "intakeStructures/fetchIntakeStructureById",
    async (param: any, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/fetch-intake-structure-details", {
                coordinate_id: param
            });
            // thunkAPI.dispatch(toggleLoading());
            return data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const addIntakeStructure = createAsyncThunk(
    "intakeStructures/addIntakeStructure",
    async (param: { apiParam: any, closeModal: any }, thunkAPI) => {
        try {
            const coordinates = await DataServer.post(
                "api/coordinates",
                { component_id: param.apiParam.component_id, latitude: param.apiParam.latitude, longitude: param.apiParam.longitude }
            );
            const { data } = await DataServer.post(
                "api/intake-structure",
                { ...param.apiParam, coordinate_id: coordinates.data.data.id }
            );
            thunkAPI.dispatch(fetchComponents({ apiParam: { id: param.apiParam.project_id, current_step: "mapping" } }))
            thunkAPI.dispatch(fetchCoordinates(param.apiParam.project_id))


            toast.success('Intake Structure Added Successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });
            param.closeModal();

            thunkAPI.dispatch(addIntakeStructureSuccess({ ...data.data, role: param.apiParam.role, id: data.data.id }));

            return { ...data.data };

        } catch (error: any) {
            thunkAPI.dispatch(addIntakeStructureError(error));
            return error;
        }
    }
);

export const editIntakeStructure = createAsyncThunk(
    "intakeStructures/editIntakeStructure",
    async (param: { apiParam: any, closeModal: any }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/update-intake-structure-details", param.apiParam);
            // thunkAPI.dispatch(toggleLoading());
            thunkAPI.dispatch(editIntakeStructureSuccess({ ...data.data }));

            toast.success('Intake Structure Updated Successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });
            param.closeModal();

            return data.data;
        } catch (error: any) {
            // thunkAPI.dispatch(toggleLoading());
            // thunkAPI.dispatch(ErrorMessage(error));
            thunkAPI.dispatch(addIntakeStructureError(error));
            return error;
        }
    }
);

export const deleteIntakeStructure = createAsyncThunk(
    "intakeStructures/deleteIntakeStructure",
    async (param: { apiParam: any }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/delete-intakeStructure", {
                id: param.apiParam.id
            });
            // thunkAPI.dispatch(toggleLoading());
            param.apiParam.closeModal();
            thunkAPI.dispatch(deleteIntakeStructureSuccess({ ...data.data, role: param.apiParam.role, id: param.apiParam.id }))
            toast.success('Intake Structure deleted Successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });

            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            // thunkAPI.dispatch(ErrorMessage(error));
            return error;
        }
    }
);
