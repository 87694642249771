import React, { useEffect, useState } from "react";
import FormField from '../Forms/FormField'
// import SelectField from '../SelectField'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as yup from 'yup';
// import { FormValidation } from "../../Utils/formValidation";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import '../../assets/css/reusable/form.css'
import { useAppDispatch, useAppSelector } from "../../hooks";
import { activeComponent, formPopup } from "../../redux/slice/Map";
import { addPumpingStation, editPumpingStation, fetchPumpingStationById } from "../../redux/slice/pumpingStation";
import { useLocation } from "react-router-dom";
import Loading from "../../views/pages/Loading";
interface FormData {
    capacity: string;
    latitude: string;
    longitude: string;
    level: string;
    area: string;
}
const PumpingStation: any = (props: any) => {
    const components = useAppSelector((state) => state.components)
    const pumpingStation = useAppSelector((state) => state.pumpingStation)
    const dispatch = useAppDispatch();
    const [formData, setFormData] = useState<any>({
        capacity: "",
        latitude: "",
        longitude: "",
        level: "",
        area: "",
    });
    const [resetformData, setResetFormData] = useState<FormData>({
        capacity: "",
        latitude: "",
        longitude: "",
        level: "",
        area: "",
    });
    const location = useLocation();
    const formSchema = yup.object({
        capacity: yup.string()
            .matches(/^\d+$/, 'Please enter a numeric value for pump capacity.') // A) Error for text-based
            .matches(/^\d+$/, 'Only numeric values are allowed.') // B) Error for symbol-based
            .min(1, 'Value must be between 1 to 100000.') // C) Character limit
            .max(100000, 'Value must be between 1 to 100000.') // C) Character limit
            .required('Pump capacity is required'), // E) Compulsory
        latitude: yup.string().required("Latitude is required")
            .test('is-text-based', 'Please enter a valid latitude.', value => !isNaN(parseFloat(value)))
            .test('is-symbol-based', 'Only numeric values and symbols (-, .) are allowed.', value => /^-?\d*\.?\d*$/.test(value))
            .test('character-limit', 'Value must be a valid latitude format.', value => value.length <= 8),
        longitude: yup.string().required("Longitude is required")
            .test('is-text-based', 'Please enter a valid Longitude.', value => !isNaN(parseFloat(value)))
            .test('is-symbol-based', 'Only numeric values and symbols (-, .) are allowed.', value => /^-?\d*\.?\d*$/.test(value))
            .test('character-limit', 'Value must be a valid Longitude format.', value => value.length <= 8),
        level: yup.string()
            .required('Please enter the Level in meters.')  // Compulsory
            .matches(/^\d+(\.\d+)?$/, 'Please enter the Level in meters.') // Text-based Error
            .matches(/^\d+(\.\d+)?$/, 'Only numbers and decimal points are allowed.') // Symbol-based Error
            .max(10, 'Maximum 10 characters allowed.'), // Character Limit
    });
    const [errors, setErrors] = useState<Partial<FormData>>({});
    const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
    // const onSelectChangeChange = async (e: any) => {
    //     const { name, value } = e.target;
    //     setFormData({ ...formData, [e.target.name]: e.target.value });
    //     await FormValidation(formSchema, { ...formData, [name]: value }, setErrors);
    // }
    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setFormErrors({ ...formErrors, [name]: "" });
        // await FormValidation(formSchema, { ...formData, [name]: value }, setErrors);
    };
    const pumpingdata = pumpingStation.selectedPumpingStation.data
    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        // await FormValidation(formSchema, formData, setErrors)
        let pumpingData = {
            ...formData,
            project_id: location?.state?.projectData?.id,
            component_id: components?.selectedComponent?.component_id,
            pump_capacity: formData.capacity,
            level: formData.level,
            area: formData.area,
            latitude: formData.latitude,
            longitude: formData.longitude
        }
        const errors: { [key: string]: string } = {};
        const requiredFields = ["latitude", "longitude", "capacity", "level"];

        requiredFields.forEach((field) => {
            if (!formData[field]) {
                errors[field] = "Required";
            }
        });

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
        } else {
            pumpingdata ? dispatch(editPumpingStation({ apiParam: pumpingData, closeModal: dispatch(formPopup(false)) })) :
                dispatch(addPumpingStation({ apiParam: pumpingData, closeModal: dispatch(formPopup(false)) }))
        }
    };

    useEffect(() => {
        if (components?.selectedComponent) {
            dispatch(activeComponent(components?.selectedComponent?.name))
            if (components?.selectedComponent?.id) {
                dispatch(fetchPumpingStationById(components?.selectedComponent?.id))
            }
            setFormData({
                ...formData,
                latitude: components?.selectedComponent?.latitude,
                longitude: components?.selectedComponent?.longitude
            })
        }
    }, [components.selectedComponent])


    useEffect(() => {
        if (pumpingdata) {
            setFormData({
                ...pumpingdata,
                capacity: pumpingdata.pump_capacity,
                latitude: components?.selectedComponent?.latitude,
                longitude: components?.selectedComponent?.longitude
            })
        }

    }, [pumpingStation]);

    useEffect(() => {
        if (!pumpingdata) {
            setFormData(resetformData)
        }
    }, [!pumpingdata])
    return (
        <>
            {/* <pre className="text-black">{JSON.stringify(pumpingStation.editPumpingStation.load)}</pre> */}
            {
                pumpingStation?.editPumpingStation.load ? <Loading /> :
                    <div className="container bg-white  mx-auto h-[85vh] text-[#1E2022]">
                        {/* <div className="h-[5%]"> */}
                        <div className="flex mb-4 justify-between items-center border-b-2 py-4 px-4">
                            <h3 className="text-base not-italic font-semibold leading-[18.9px] mt-1">Pumping Station Details</h3>
                            <FontAwesomeIcon icon={faXmark}
                                onClick={() => { dispatch(formPopup(false)); setFormData(resetformData); }}
                                className="mt-1  hover:cursor-pointer" />
                        </div>
                        {/* </div> */}
                        <div className="form-height overflow-y-auto ">
                            <form className="grid px-4">
                                {/* <div className="text-black py-4 font-bold">
            <h3 className="">General Information</h3>
          </div> */}
                                <FormField
                                    label="Pump Capacity (m³/s)"
                                    name="capacity"
                                    type="text"
                                    placeholder="Enter Pump Capacity"
                                    value={formData.capacity}
                                    onChange={handleChange}
                                    error={formErrors.capacity}
                                    labelRequired
                                />
                                <FormField
                                    label="Latitude"
                                    name="latitude"
                                    type="text"
                                    placeholder="Enter Latitude"
                                    value={formData.latitude}
                                    onChange={handleChange}
                                    error={formErrors.latitude}
                                    labelRequired
                                />
                                <FormField
                                    label="Longitude"
                                    name="longitude"
                                    type="text"
                                    placeholder="Enter Longitude"
                                    value={formData.longitude}
                                    onChange={handleChange}
                                    error={formErrors.longitude}
                                    labelRequired
                                />
                                <FormField
                                    label="Level (m)"
                                    name="level"
                                    type="text"
                                    placeholder="Enter Level"
                                    value={formData.level}
                                    onChange={handleChange}
                                    error={formErrors.level}
                                    labelRequired
                                />
                                <FormField
                                    label="Area (sq. m)"
                                    name="area"
                                    type="text"
                                    placeholder="Enter Area"
                                    value={formData.area}
                                    onChange={handleChange}
                                    error={errors.area}
                                />
                            </form>
                        </div>
                        {/* <div className="h-[10%] flex justify-end"> */}
                        {/* <div className="basis-2/4"></div> */}
                        <div className="w-full  flex justify-end pr-4 form-footer">
                            <button
                                className=" text-[#8A96A4] bg-white my-4 pt-1 pb-1 px-4  rounded focus:outline-none focus:shadow-outline border border-solid border-[#EEF0F7] hover:bg-[#F8F7F7]  hover:scale-105"
                                type="submit"
                                onClick={() => { dispatch(formPopup(false)); setFormData(resetformData) }}
                            >
                                Cancel
                            </button>
                            <button
                                className=" ml-3 font-normal text-sm bg-[#377DFF] my-4  text-white pt-1 pb-1 px-2 rounded focus:outline-none focus:shadow-outline hover:scale-105"
                                type="submit"
                                onClick={handleSubmit}
                            >
                                {pumpingdata ? "Update Details" : "Save Details"}
                            </button>
                        </div>
                        {/* </div> */}
                    </div>

            }
        </>
    )
}
export default PumpingStation
