// SelectField.tsx
import React from "react";
import '../../assets/css/reusable/form.css'
interface SelectFieldProps {
  label: string;
  labelRequired?: boolean;
  name: string;
  type?: string;
  placeholder?: string;
  value: string;
  onChange: any
  error?: string;
  options?: any
  isRequired?: any
}

const SelectField: React.FC<SelectFieldProps> = ({
  label,
  labelRequired,
  name,
  // type?,
  // placeholder?,
  value,
  onChange,
  error,
  options,
  isRequired
}) => {

  return (
    <div className="mb-4 w-full">
      {
        label &&
        <label htmlFor="" className="block text-[#1E2022] text-xs not-italic font-medium leading-[21px]">
          {label}<span className="text-[#ED4C78]">{labelRequired ? "*" : ""}</span>
        </label>
      }

      <div className="mt-2 w-full relative border-violet-600 text-xs">
        <select
          key={name}
          id={name}
          name={name}
          autoComplete={name}
          onChange={onChange}
          value={value}
          className={`text-xs  not-italic font-medium leading-[21px] placeholder:text-[#8C98A4] placeholder:text-xs placeholder:not-italic placeholder:font-medium placeholder:leading-[21px] block w-[100%] py-[11px] rounded-md border-0  bg-white 
shadow-sm ring-1 ring-inset focus:ring-indigo-600 focus:ring-1 appearance-none
 sm:leading-6 ring-gray-300 select-field`}
            required={isRequired}
        // $
        // {error ? "ring-red-500" : "ring-gray-300"}
        >
          <option value="" disabled hidden>
            Select
          </option>
          {options.map((e: any) => {
            return (
              <option className="capitalize w-[100%] text-xs sm:text-xs md:text-text-xs lg:text-text-xs xl:text-text-xs 2xl:text-text-xs  not-italic font-medium leading-[21px] tab-font-size" key={e.id} value={e.value}>{e.value}</option>
            )
          })}
        </select>
        {error && <p className="text-red-500 text-xs italic">{error}</p>}
      </div>
    </div>
  )
};
export default SelectField;
