import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { fetchComponents } from "../Map";
import { fetchCoordinates } from "../coordinate";

export interface SourceOfIntakeData {
    component_id: number,
    country_id: number,
    name: string,
    type_of_source: string,
    type_of_water_body: string,
    basin: string,
    sub_basin: string,
    city: string,
    highest_flood_level: number,
    maximum_water_level: number,
    lowest_storage_level: number,
    minimum_drawdown_level: number,
    dead_storage_level: number,
    bottom_level: number,
    top_level_of_the_adjacent_structure: null,
    width_of_water_body: number,
    average_rainfall: number,
    atmospheric_temperature_current_of_seasonal: number,
    atmospheric_temperature_average_annual: number,
    volume_of_water_available_in_cumec: number,
    coordinate_id: number,
    id: number
}

interface SourceOfIntakesState {
    sourceOfIntakes: {
        load: boolean;
        data: Array<SourceOfIntakeData>;
        error: Array<SourceOfIntakeData>;
    };
    selectedSourceOfIntake?: SourceOfIntakeData;
    editSourceOfIntake: {
        load: boolean;
    };
}
const initialState = {
    sourceOfIntakes: {
        load: false,
        data: [],
        error: []
    },
    selectedSourceOfIntake: {
        component_id: "",
        country_id: "",
        name: "",
        type_of_source: "",
        type_of_water_body: "",
        basin: "",
        sub_basin: "",
        city: "",
        highest_flood_level: "",
        maximum_water_level: "",
        lowest_storage_level: "",
        minimum_drawdown_level: "",
        dead_storage_level: "",
        bottom_level: "",
        top_level_of_the_adjacent_structure: "",
        width_of_water_body: "",
        average_rainfall: "",
        atmospheric_temperature_current_of_seasonal: "",
        atmospheric_temperature_average_annual: "",
        volume_of_water_available_in_cumec: "",
        coordinate_id: "",
        id: ""

    },
    editSourceOfIntake: {
        load: false,
    },
    deleteSourceOfIntake: {
        load: false,
    },
} as unknown as SourceOfIntakesState;

export const sourceOfIntakesSlice = createSlice({
    name: "sourceOfIntakes",
    initialState,
    reducers: {
        resetSourceOfIntakeData: (state: SourceOfIntakesState) => {
            state.selectedSourceOfIntake = initialState.selectedSourceOfIntake;
            state.sourceOfIntakes.error = []
        },
        updateData: (state: SourceOfIntakesState, action: { payload: SourceOfIntakeData }) => {
            state.selectedSourceOfIntake = action.payload;
        },
        addSourceOfIntakeError: (state: SourceOfIntakesState, action: any) => {
            state.sourceOfIntakes.error = action.payload;
        },
        addSourceOfIntakeSuccess: (state: SourceOfIntakesState, action: any) => {
            if (action.payload) {
                state.sourceOfIntakes.data = [{ ...action.payload }, ...state.sourceOfIntakes.data];
            }
            state.sourceOfIntakes.load = false;
        },
        editSourceOfIntakeSuccess: (state: SourceOfIntakesState, action: any) => {
            if (action.payload) {

                const editedSourceOfIntakeIndex = state.sourceOfIntakes.data.findIndex(sourceOfIntake => sourceOfIntake.id === action.payload.id);

                if (editedSourceOfIntakeIndex !== -1) {
                    state.sourceOfIntakes.data[editedSourceOfIntakeIndex] = { ...action.payload };
                }
            }
            state.sourceOfIntakes.load = false;
        },
        deleteSourceOfIntakeSuccess: (state: SourceOfIntakesState, action: any) => {
            if (action.payload) {
                const sourceOfIntakeIdToDelete = action.payload.id;
                const indexToDelete = state.sourceOfIntakes.data.findIndex(sourceOfIntake => sourceOfIntake.id === sourceOfIntakeIdToDelete);
                if (indexToDelete !== -1) {
                    state.sourceOfIntakes.data.splice(indexToDelete, 1);
                }
            }
            state.sourceOfIntakes.load = false;
        }
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchSourceOfIntakes.pending, (state: SourceOfIntakesState) => {
                state.sourceOfIntakes.load = true;
            })
            .addCase(fetchSourceOfIntakes.fulfilled, (state: SourceOfIntakesState, action: any) => {
                state.sourceOfIntakes.data = action.payload;
                state.sourceOfIntakes.load = false;
            })
            .addCase(fetchSourceOfIntakes.rejected, (state: SourceOfIntakesState) => {
                state.sourceOfIntakes.load = false;
            })
            .addCase(fetchSourceOfIntakeById.pending, (state: SourceOfIntakesState) => {
                state.editSourceOfIntake.load = true;
            })
            .addCase(fetchSourceOfIntakeById.fulfilled, (state: SourceOfIntakesState, action: any) => {
                state.selectedSourceOfIntake = action.payload;
                state.editSourceOfIntake.load = false;
            })
            .addCase(fetchSourceOfIntakeById.rejected, (state: SourceOfIntakesState) => {
                state.editSourceOfIntake.load = false;
            });
    },
});
export const { updateData, resetSourceOfIntakeData, addSourceOfIntakeError, addSourceOfIntakeSuccess, editSourceOfIntakeSuccess, deleteSourceOfIntakeSuccess } = sourceOfIntakesSlice.actions;
export default sourceOfIntakesSlice.reducer;

export const fetchSourceOfIntakes = createAsyncThunk(
    "sourceOfIntakes/fetchSourceOfIntakes",
    async (param: any, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("/api/user-wise-sourceOfIntake-list", {
                user_id: param
            });
            // thunkAPI.dispatch(toggleLoading());
            return data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const fetchSourceOfIntakeById = createAsyncThunk(
    "sourceOfIntakes/fetchSourceOfIntakeById",
    async (param: any, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/fetch-source-of-intake-details", {
                coordinate_id: param
            });
            // thunkAPI.dispatch(toggleLoading());
            return data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const addSourceOfIntake = createAsyncThunk(
    "sourceOfIntakes/addSourceOfIntake",
    async (param: { apiParam: any, closeModal: any }, thunkAPI) => {
        try {
            const coordinates = await DataServer.post(
                "api/coordinates",
                { component_id: param.apiParam.component_id, latitude: param.apiParam.latitude, longitude: param.apiParam.longitude }
            );
            const { data } = await DataServer.post(
                "api/source-of-intake",
                { ...param.apiParam, coordinate_id: coordinates.data.data.id }
            );
            thunkAPI.dispatch(fetchComponents({ apiParam: { id: param.apiParam.project_id, current_step: "mapping" } }))
            thunkAPI.dispatch(fetchCoordinates(param.apiParam.project_id))


            toast.success('Source of Intake Added Successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });
            param.closeModal();
            // thunkAPI.dispatch(addSourceOfIntakeSuccess({...data.data,role:param.apiParam.role,id:data.data.id}));
            return { ...data.data };
        } catch (error: any) {

            thunkAPI.dispatch(addSourceOfIntakeError(error));
            return error;
        }
    }
);

export const editSourceOfIntake = createAsyncThunk(
    "sourceOfIntakes/editSourceOfIntake",
    async (param: { apiParam: any, closeModal: any }, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/update-source-of-intake-details", param.apiParam);
            // thunkAPI.dispatch(toggleLoading());
            thunkAPI.dispatch(editSourceOfIntakeSuccess({ ...data.data }));
            const coordinates = await DataServer.post(
                "api/update-coordinates",
                {id:param.apiParam.coordinate_id, component_id: param.apiParam.component_id, latitude: param.apiParam.latitude, longitude: param.apiParam.longitude }
            );
            toast.success('Source of Intake Updated Successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });
            param.closeModal();

            return data.data;
        } catch (error: any) {
            // thunkAPI.dispatch(toggleLoading());
            // thunkAPI.dispatch(ErrorMessage(error));
            thunkAPI.dispatch(addSourceOfIntakeError(error));
            return error;
        }
    }
);

export const updateCoordinate = createAsyncThunk(
    "sourceOfIntakes/updateCoordinate",
    async (param: any, thunkAPI) => {
        try {
           
            const coordinates = await DataServer.post(
                "api/update-coordinates",
                {id:param.coordinate_id, component_id: param.component_id, latitude: param.latitude, longitude: param.longitude }
            );
            

            return coordinates;
        } catch (error: any) {
            // thunkAPI.dispatch(toggleLoading());
            // thunkAPI.dispatch(ErrorMessage(error));
            // thunkAPI.dispatch(addSourceOfIntakeError(error));
            return error;
        }
    }
);

export const deleteSourceOfIntake = createAsyncThunk(
    "sourceOfIntakes/deleteSourceOfIntake",
    async (param: { apiParam: any }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/delete-sourceOfIntake", {
                id: param.apiParam.id
            });
            // thunkAPI.dispatch(toggleLoading());
            param.apiParam.closeModal();
            thunkAPI.dispatch(deleteSourceOfIntakeSuccess({ ...data.data, role: param.apiParam.role, id: param.apiParam.id }))
            toast.success('Source of Intake deleted successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });

            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            // thunkAPI.dispatch(ErrorMessage(error));
            return error;
        }
    }
);
