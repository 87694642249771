
import React, { useState } from 'react';
import { Range, getTrackBackground } from 'react-range';
import '../../assets/css/reusable/timeline.css';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { updateMonitoring } from '../../redux/slice/monitoring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseBlank } from '@fortawesome/pro-solid-svg-icons';

const STEP = 2;
const MIN = 0;
const MAX = 24;

const Timeline = () => {
  const [values, setValues] = useState([5]);
  const dispatch = useAppDispatch();
  const monitoring = useAppSelector((state) => state.monitoring);
  const [selectedvalues, setSelectedValues] :any= useState([0]);

  const convertValueToTime = (value:any) => {
    const hours = value;
    const formattedHours = hours.toString().padStart(2, '0');
    setValues(value);
    setSelectedValues(`${formattedHours}:00:00`)
    dispatch(updateMonitoring({
      ...monitoring.updatedData,
      schedule_time:`${formattedHours}:00:00`
    }))
  };

  const createScale = () => {
    let scale = [];
    for (let i = MIN; i <= MAX; i = i + STEP) {
      scale.push(
        <span key={i} className="scale_item">
          {`${i < 10 ? '0' + i : i}`} 
        </span>
      );
    }
    return scale;
  };

  return (
    <div className="card">
    <Range 
      values={values}
      step={STEP}
      min={MIN}
      max={MAX}
        onChange={values => convertValueToTime(values)}
      renderTrack={({ props, children }) => (
        <div
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          style={{
            ...props.style,
            height: "36px",
            display: "flex",
            width: "100%"
          }}
        >
          <div  className='px-2 flex justify-between '
            ref={props.ref}
            style={{
              height: "3px",
              width: "100%",
              borderRadius: "5px",
              background: getTrackBackground({
                values: values,
                colors: ["#FFF", "#35354B"],
                min: MIN,
                max: MAX
              }),
              alignSelf: "center",
           
            }}
          >
            {children}
         
            <div className="scale font-[Poppins] text-[10px] not-italic font-medium leading-normal" >{createScale()}</div>
          </div>
        </div>
      )}
      renderThumb={({ props, isDragged }) => (
        <div
          {...props}
          style={{
            ...props.style,
            height: "30px",
            width: "30px",
            borderRadius: "15px",
            // backgroundColor: "rgb(57, 48, 49)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // border: "3px solid rgb(230, 192, 179)"
          }}
        >
          <FontAwesomeIcon icon={faHouseBlank} size='xl' className='mt-7 text-[#377DFF]'/>
        </div>
      )}
    />
    </div>
  );
};

export default Timeline;
