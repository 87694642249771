import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "../../assets/css/reusable/newscreenmonitoring.css";
import EChartsReact from "echarts-for-react";
import PopupComparisonChart from "../PopupComparisonChart";

const MapviewPopup = ({ values, data, loading }: any) => {
  const [chartHeight, setChartHeight] = useState<number>(298); // Default height

  const [options, setOptions] = useState(null);
  // const [graphOption,setGraphOption]=useState();
  const featureData = [
    {
      id: 1,
      name: "Water level",
      subName: "80%",
    },
    {
      id: 2,
      name: "Capacity",
      subName: "5.00 mld",
    },
    {
      id: 3,
      name: "Water level",
      subName: "80%",
    },
    {
      id: 4,
      name: "Diameter",
      subName: "150 mm",
    },
  ];

  const onChange = (index: any, item: any) => {
    // console.log(`Changed to ${index}`, item);
  };

  const onClickItem = (index: any, item: any) => {
    // console.log(`Clicked item at ${index}`, item);
  };

  const onClickThumb = (index: any, item: any) => {
    // console.log(`Clicked thumb at ${index}`, item);
  };

  const graphData = [
    {
      id: 1,
      heading: "General Parameter",
      parametersData: [
        { Time: 7, Country: "Parameter1", Income: 1 },
        { Time: 8, Country: "Parameter1", Income: 2 },
        { Time: 9, Country: "Parameter1", Income: 3 },
        { Time: 7, Country: "Parameter2", Income: 4 },
        { Time: 7.5, Country: "Parameter2", Income: 5 },
        { Time: 7, Country: "Parameter3", Income: 1 },
        { Time: 7, Country: "Parameter3", Income: 3 },
        { Time: 8, Country: "Parameter3", Income: 2 },
        { Time: 9, Country: "Parameter3", Income: 7 },
        { Time: 9.8, Country: "Parameter3", Income: 3 },
        { Time: 7, Country: "Parameter4", Income: 7 },
        { Time: 8, Country: "Parameter4", Income: 3 },
        { Time: 9, Country: "Parameter4", Income: 3 },
        { Time: 10, Country: "Parameter4", Income: 2 },
        { Time: 11, Country: "Parameter4", Income: 6 },
        { Time: 12, Country: "Parameter4", Income: 7 },
        { Time: 1, Country: "Parameter4", Income: 3 },
        { Time: 2, Country: "Parameter4", Income: 2 },
        { Time: 3, Country: "Parameter4", Income: 1 },
        { Time: 7, Country: "Parameter5", Income: 5 },
        { Time: 8, Country: "Parameter5", Income: 7 },
        { Time: 9, Country: "Parameter5", Income: 6 },
        { Time: 10, Country: "Parameter5", Income: 5 },
        { Time: 11, Country: "Parameter5", Income: 7 },
        { Time: 12, Country: "Parameter5", Income: 3 },
        { Time: 1, Country: "Parameter5", Income: 2 },
        { Time: 2, Country: "Parameter5", Income: 8 },
        { Time: 3, Country: "Parameter5", Income: 4 },
      ],
      countries: [
        "Parameter1",
        "Parameter2",
        "Parameter3",
        "Parameter4",
        "Parameter5",
      ],
      datasetWithFilters: [
        {
          id: "dataset_Parameter1",
          fromDatasetId: "dataset_raw",
          transform: {
            type: "filter",
            config: {
              and: [
                { dimension: "Time", gte: 7 },
                { dimension: "Country", "=": "Parameter1" },
              ],
            },
          },
        },
        {
          id: "dataset_Parameter2",
          fromDatasetId: "dataset_raw",
          transform: {
            type: "filter",
            config: {
              and: [
                { dimension: "Time", gte: 7 },
                { dimension: "Country", "=": "Parameter2" },
              ],
            },
          },
        },
        {
          id: "dataset_Parameter3",
          fromDatasetId: "dataset_raw",
          transform: {
            type: "filter",
            config: {
              and: [
                { dimension: "Time", gte: 7 },
                { dimension: "Country", "=": "Parameter3" },
              ],
            },
          },
        },
        {
          id: "dataset_Parameter4",
          fromDatasetId: "dataset_raw",
          transform: {
            type: "filter",
            config: {
              and: [
                { dimension: "Time", gte: 7 },
                { dimension: "Country", "=": "Parameter4" },
              ],
            },
          },
        },
        {
          id: "dataset_Parameter5",
          fromDatasetId: "dataset_raw",
          transform: {
            type: "filter",
            config: {
              and: [
                { dimension: "Time", gte: 7 },
                { dimension: "Country", "=": "Parameter5" },
              ],
            },
          },
        },
      ],
      seriesList: [
        {
          type: "line",
          datasetId: "dataset_Parameter1",
          showSymbol: false,
          name: "Parameter1",
          endLabel: {
            show: true,
            formatter: (params: any) => `Parameter: ${params.seriesName}`,
          },
          labelLayout: {
            moveOverlap: "shiftY",
          },
          emphasis: {
            focus: "series",
          },
          encode: {
            x: "Time",
            y: "Income",
            label: ["Country", "Income"],
            itemName: "Time",
            tooltip: ["Income"],
          },
        },
        {
          type: "line",
          datasetId: "dataset_Parameter2",
          showSymbol: false,
          name: "Parameter2",
          endLabel: {
            show: true,
            formatter: (params: any) =>
              `${params.value[3]}: ${params.value[0]}`,
          },
          labelLayout: {
            moveOverlap: "shiftY",
          },
          emphasis: {
            focus: "series",
          },
          encode: {
            x: "Time",
            y: "Income",
            label: ["Country", "Income"],
            itemName: "Time",
            tooltip: ["Income"],
          },
        },
        {
          type: "line",
          datasetId: "dataset_Parameter3",
          showSymbol: false,
          name: "Parameter3",
          endLabel: {
            show: true,
            formatter: (params: any) => `Parameter: ${params.seriesName}`,
          },
          labelLayout: {
            moveOverlap: "shiftY",
          },
          emphasis: {
            focus: "series",
          },
          encode: {
            x: "Time",
            y: "Income",
            label: ["Country", "Income"],
            itemName: "Time",
            tooltip: ["Income"],
          },
        },
        {
          type: "line",
          datasetId: "dataset_Parameter4",
          showSymbol: false,
          name: "Parameter4",
          endLabel: {
            show: true,
            formatter: (params: any) => `Parameter: ${params.seriesName}`,
          },
          labelLayout: {
            moveOverlap: "shiftY",
          },
          emphasis: {
            focus: "series",
          },
          encode: {
            x: "Time",
            y: "Income",
            label: ["Country", "Income"],
            itemName: "Time",
            tooltip: ["Income"],
          },
        },
        {
          type: "line",
          datasetId: "dataset_Parameter5",
          showSymbol: false,
          name: "Parameter5",
          endLabel: {
            show: true,
            formatter: (params: any) => `Parameter: ${params.seriesName}`,
          },
          labelLayout: {
            moveOverlap: "shiftY",
          },
          emphasis: {
            focus: "series",
          },
          encode: {
            x: "Time",
            y: "Income",
            label: ["Country", "Income"],
            itemName: "Time",
            tooltip: ["Income"],
          },
        },
      ],
      option: {
        animationDuration: 6000,
        dataset: [
          {
            id: "dataset_raw",
            source: [
              { Time: 7, Country: "Parameter1", Income: 1 },
              { Time: 8, Country: "Parameter1", Income: 2 },
              { Time: 9, Country: "Parameter1", Income: 3 },
              { Time: 7, Country: "Parameter2", Income: 4 },
              { Time: 7.5, Country: "Parameter2", Income: 5 },
              { Time: 7, Country: "Parameter3", Income: 1 },
              { Time: 7, Country: "Parameter3", Income: 3 },
              { Time: 8, Country: "Parameter3", Income: 2 },
              { Time: 9, Country: "Parameter3", Income: 7 },
              { Time: 9.8, Country: "Parameter3", Income: 3 },
              { Time: 7, Country: "Parameter4", Income: 7 },
              { Time: 8, Country: "Parameter4", Income: 3 },
              { Time: 9, Country: "Parameter4", Income: 3 },
              { Time: 10, Country: "Parameter4", Income: 2 },
              { Time: 11, Country: "Parameter4", Income: 6 },
              { Time: 12, Country: "Parameter4", Income: 7 },
              { Time: 1, Country: "Parameter4", Income: 3 },
              { Time: 2, Country: "Parameter4", Income: 2 },
              { Time: 3, Country: "Parameter4", Income: 1 },
              { Time: 7, Country: "Parameter5", Income: 5 },
              { Time: 8, Country: "Parameter5", Income: 7 },
              { Time: 9, Country: "Parameter5", Income: 6 },
              { Time: 10, Country: "Parameter5", Income: 5 },
              { Time: 11, Country: "Parameter5", Income: 7 },
              { Time: 12, Country: "Parameter5", Income: 3 },
              { Time: 1, Country: "Parameter5", Income: 2 },
              { Time: 2, Country: "Parameter5", Income: 8 },
              { Time: 3, Country: "Parameter5", Income: 4 },
            ],
          },
          ...[
            {
              id: "dataset_Parameter1",
              fromDatasetId: "dataset_raw",
              transform: {
                type: "filter",
                config: {
                  and: [
                    { dimension: "Time", gte: 7 },
                    { dimension: "Country", "=": "Parameter1" },
                  ],
                },
              },
            },
            {
              id: "dataset_Parameter2",
              fromDatasetId: "dataset_raw",
              transform: {
                type: "filter",
                config: {
                  and: [
                    { dimension: "Time", gte: 7 },
                    { dimension: "Country", "=": "Parameter2" },
                  ],
                },
              },
            },
            {
              id: "dataset_Parameter3",
              fromDatasetId: "dataset_raw",
              transform: {
                type: "filter",
                config: {
                  and: [
                    { dimension: "Time", gte: 7 },
                    { dimension: "Country", "=": "Parameter3" },
                  ],
                },
              },
            },
            {
              id: "dataset_Parameter4",
              fromDatasetId: "dataset_raw",
              transform: {
                type: "filter",
                config: {
                  and: [
                    { dimension: "Time", gte: 7 },
                    { dimension: "Country", "=": "Parameter4" },
                  ],
                },
              },
            },
            {
              id: "dataset_Parameter5",
              fromDatasetId: "dataset_raw",
              transform: {
                type: "filter",
                config: {
                  and: [
                    { dimension: "Time", gte: 7 },
                    { dimension: "Country", "=": "Parameter5" },
                  ],
                },
              },
            },
          ],
        ],

        tooltip: {
          order: "valueDesc",
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          nameLocation: "middle",
        },
        yAxis: {
          name: "Income",
        },
        grid: {
          right: 10,
        },
        series: [
          {
            type: "line",
            datasetId: "dataset_Parameter1",
            showSymbol: false,
            name: "Parameter1",
            endLabel: {
              show: true,
              formatter: (params: any) =>
                `${params.value[3]}: ${params.value[0]}`,
            },
            labelLayout: {
              moveOverlap: "shiftY",
            },
            emphasis: {
              focus: "series",
            },
            encode: {
              x: "Time",
              y: "Income",
              label: ["Country", "Income"],
              itemName: "Time",
              tooltip: ["Income"],
            },
          },
          {
            type: "line",
            datasetId: "dataset_Parameter2",
            showSymbol: false,
            name: "Parameter2",
            endLabel: {
              show: true,
              formatter: (params: any) =>
                `${params.value[3]}: ${params.value[0]}`,
            },
            labelLayout: {
              moveOverlap: "shiftY",
            },
            emphasis: {
              focus: "series",
            },
            encode: {
              x: "Time",
              y: "Income",
              label: ["Country", "Income"],
              itemName: "Time",
              tooltip: ["Income"],
            },
          },
          {
            type: "line",
            datasetId: "dataset_Parameter3",
            showSymbol: false,
            name: "Parameter3",
            endLabel: {
              show: true,
              formatter: (params: any) =>
                `${params.value[3]}: ${params.value[0]}`,
            },
            labelLayout: {
              moveOverlap: "shiftY",
            },
            emphasis: {
              focus: "series",
            },
            encode: {
              x: "Time",
              y: "Income",
              label: ["Country", "Income"],
              itemName: "Time",
              tooltip: ["Income"],
            },
          },
          {
            type: "line",
            datasetId: "dataset_Parameter4",
            showSymbol: false,
            name: "Parameter4",
            endLabel: {
              show: true,
              formatter: (params: any) =>
                `${params.value[3]}: ${params.value[0]}`,
            },
            labelLayout: {
              moveOverlap: "shiftY",
            },
            emphasis: {
              focus: "series",
            },
            encode: {
              x: "Time",
              y: "Income",
              label: ["Country", "Income"],
              itemName: "Time",
              tooltip: ["Income"],
            },
          },
          {
            type: "line",
            datasetId: "dataset_Parameter5",
            showSymbol: false,
            name: "Parameter5",
            endLabel: {
              show: true,
              formatter: (params: any) =>
                `${params.value[3]}: ${params.value[0]}`,
            },
            labelLayout: {
              moveOverlap: "shiftY",
            },
            emphasis: {
              focus: "series",
            },
            encode: {
              x: "Time",
              y: "Income",
              label: ["Country", "Income"],
              itemName: "Time",
              tooltip: ["Income"],
            },
          },
        ],
      },
    },
    {
      id: 2,
      heading: "Regional specific Parameter",
      parametersData: [
        { Time: 7, Country: "Parameter1", Income: 1 },
        { Time: 8, Country: "Parameter1", Income: 2 },
        { Time: 9, Country: "Parameter1", Income: 3 },
        { Time: 7, Country: "Parameter2", Income: 4 },
        { Time: 7.5, Country: "Parameter2", Income: 5 },
        { Time: 7, Country: "Parameter3", Income: 1 },
        { Time: 7, Country: "Parameter3", Income: 3 },
        { Time: 8, Country: "Parameter3", Income: 2 },
        { Time: 9, Country: "Parameter3", Income: 7 },
        { Time: 9.8, Country: "Parameter3", Income: 3 },
        { Time: 7, Country: "Parameter4", Income: 7 },
        { Time: 8, Country: "Parameter4", Income: 3 },
        { Time: 9, Country: "Parameter4", Income: 3 },
        { Time: 10, Country: "Parameter4", Income: 2 },
        { Time: 11, Country: "Parameter4", Income: 6 },
        { Time: 12, Country: "Parameter4", Income: 7 },
        { Time: 1, Country: "Parameter4", Income: 3 },
        { Time: 2, Country: "Parameter4", Income: 2 },
        { Time: 3, Country: "Parameter4", Income: 1 },
        { Time: 7, Country: "Parameter5", Income: 5 },
        { Time: 8, Country: "Parameter5", Income: 7 },
        { Time: 9, Country: "Parameter5", Income: 6 },
        { Time: 10, Country: "Parameter5", Income: 5 },
        { Time: 11, Country: "Parameter5", Income: 7 },
        { Time: 12, Country: "Parameter5", Income: 3 },
        { Time: 1, Country: "Parameter5", Income: 2 },
        { Time: 2, Country: "Parameter5", Income: 8 },
        { Time: 3, Country: "Parameter5", Income: 4 },
      ],
      countries: [
        "Parameter1",
        "Parameter2",
        "Parameter3",
        "Parameter4",
        "Parameter5",
      ],
      datasetWithFilters: [
        {
          id: "dataset_Parameter1",
          fromDatasetId: "dataset_raw",
          transform: {
            type: "filter",
            config: {
              and: [
                { dimension: "Time", gte: 7 },
                { dimension: "Country", "=": "Parameter1" },
              ],
            },
          },
        },
        {
          id: "dataset_Parameter2",
          fromDatasetId: "dataset_raw",
          transform: {
            type: "filter",
            config: {
              and: [
                { dimension: "Time", gte: 7 },
                { dimension: "Country", "=": "Parameter2" },
              ],
            },
          },
        },
        {
          id: "dataset_Parameter3",
          fromDatasetId: "dataset_raw",
          transform: {
            type: "filter",
            config: {
              and: [
                { dimension: "Time", gte: 7 },
                { dimension: "Country", "=": "Parameter3" },
              ],
            },
          },
        },
        {
          id: "dataset_Parameter4",
          fromDatasetId: "dataset_raw",
          transform: {
            type: "filter",
            config: {
              and: [
                { dimension: "Time", gte: 7 },
                { dimension: "Country", "=": "Parameter4" },
              ],
            },
          },
        },
        {
          id: "dataset_Parameter5",
          fromDatasetId: "dataset_raw",
          transform: {
            type: "filter",
            config: {
              and: [
                { dimension: "Time", gte: 7 },
                { dimension: "Country", "=": "Parameter5" },
              ],
            },
          },
        },
      ],
      seriesList: [
        {
          type: "line",
          datasetId: "dataset_Parameter1",
          showSymbol: false,
          name: "Parameter1",
          endLabel: {
            show: true,
            formatter: (params: any) =>
              `${params.value[3]}: ${params.value[0]}`,
          },
          labelLayout: {
            moveOverlap: "shiftY",
          },
          emphasis: {
            focus: "series",
          },
          encode: {
            x: "Time",
            y: "Income",
            label: ["Country", "Income"],
            itemName: "Time",
            tooltip: ["Income"],
          },
        },
        {
          type: "line",
          datasetId: "dataset_Parameter2",
          showSymbol: false,
          name: "Parameter2",
          endLabel: {
            show: true,
            formatter: (params: any) =>
              `${params.value[3]}: ${params.value[0]}`,
          },
          labelLayout: {
            moveOverlap: "shiftY",
          },
          emphasis: {
            focus: "series",
          },
          encode: {
            x: "Time",
            y: "Income",
            label: ["Country", "Income"],
            itemName: "Time",
            tooltip: ["Income"],
          },
        },
        {
          type: "line",
          datasetId: "dataset_Parameter3",
          showSymbol: false,
          name: "Parameter3",
          endLabel: {
            show: true,
            formatter: (params: any) =>
              `${params.value[3]}: ${params.value[0]}`,
          },
          labelLayout: {
            moveOverlap: "shiftY",
          },
          emphasis: {
            focus: "series",
          },
          encode: {
            x: "Time",
            y: "Income",
            label: ["Country", "Income"],
            itemName: "Time",
            tooltip: ["Income"],
          },
        },
        {
          type: "line",
          datasetId: "dataset_Parameter4",
          showSymbol: false,
          name: "Parameter4",
          endLabel: {
            show: true,
            formatter: (params: any) =>
              `${params.value[3]}: ${params.value[0]}`,
          },
          labelLayout: {
            moveOverlap: "shiftY",
          },
          emphasis: {
            focus: "series",
          },
          encode: {
            x: "Time",
            y: "Income",
            label: ["Country", "Income"],
            itemName: "Time",
            tooltip: ["Income"],
          },
        },
        {
          type: "line",
          datasetId: "dataset_Parameter5",
          showSymbol: false,
          name: "Parameter5",
          endLabel: {
            show: true,
            formatter: (params: any) =>
              `${params.value[3]}: ${params.value[0]}`,
          },
          labelLayout: {
            moveOverlap: "shiftY",
          },
          emphasis: {
            focus: "series",
          },
          encode: {
            x: "Time",
            y: "Income",
            label: ["Country", "Income"],
            itemName: "Time",
            tooltip: ["Income"],
          },
        },
      ],
      option: {
        animationDuration: 6000,
        dataset: [
          {
            id: "dataset_raw",
            source: [
              { Time: 7, Country: "Parameter1", Income: 1 },
              { Time: 8, Country: "Parameter1", Income: 2 },
              { Time: 9, Country: "Parameter1", Income: 3 },
              { Time: 7, Country: "Parameter2", Income: 4 },
              { Time: 7.5, Country: "Parameter2", Income: 5 },
              { Time: 7, Country: "Parameter3", Income: 1 },
              { Time: 7, Country: "Parameter3", Income: 3 },
              { Time: 8, Country: "Parameter3", Income: 2 },
              { Time: 9, Country: "Parameter3", Income: 7 },
              { Time: 9.8, Country: "Parameter3", Income: 3 },
              { Time: 7, Country: "Parameter4", Income: 7 },
              { Time: 8, Country: "Parameter4", Income: 3 },
              { Time: 9, Country: "Parameter4", Income: 3 },
              { Time: 10, Country: "Parameter4", Income: 2 },
              { Time: 11, Country: "Parameter4", Income: 6 },
              { Time: 12, Country: "Parameter4", Income: 7 },
              { Time: 1, Country: "Parameter4", Income: 3 },
              { Time: 2, Country: "Parameter4", Income: 2 },
              { Time: 3, Country: "Parameter4", Income: 1 },
              { Time: 7, Country: "Parameter5", Income: 5 },
              { Time: 8, Country: "Parameter5", Income: 7 },
              { Time: 9, Country: "Parameter5", Income: 6 },
              { Time: 10, Country: "Parameter5", Income: 5 },
              { Time: 11, Country: "Parameter5", Income: 7 },
              { Time: 12, Country: "Parameter5", Income: 3 },
              { Time: 1, Country: "Parameter5", Income: 2 },
              { Time: 2, Country: "Parameter5", Income: 8 },
              { Time: 3, Country: "Parameter5", Income: 4 },
            ],
          },
          ...[
            {
              id: "dataset_Parameter1",
              fromDatasetId: "dataset_raw",
              transform: {
                type: "filter",
                config: {
                  and: [
                    { dimension: "Time", gte: 7 },
                    { dimension: "Country", "=": "Parameter1" },
                  ],
                },
              },
            },
            {
              id: "dataset_Parameter2",
              fromDatasetId: "dataset_raw",
              transform: {
                type: "filter",
                config: {
                  and: [
                    { dimension: "Time", gte: 7 },
                    { dimension: "Country", "=": "Parameter2" },
                  ],
                },
              },
            },
            {
              id: "dataset_Parameter3",
              fromDatasetId: "dataset_raw",
              transform: {
                type: "filter",
                config: {
                  and: [
                    { dimension: "Time", gte: 7 },
                    { dimension: "Country", "=": "Parameter3" },
                  ],
                },
              },
            },
            {
              id: "dataset_Parameter4",
              fromDatasetId: "dataset_raw",
              transform: {
                type: "filter",
                config: {
                  and: [
                    { dimension: "Time", gte: 7 },
                    { dimension: "Country", "=": "Parameter4" },
                  ],
                },
              },
            },
            {
              id: "dataset_Parameter5",
              fromDatasetId: "dataset_raw",
              transform: {
                type: "filter",
                config: {
                  and: [
                    { dimension: "Time", gte: 7 },
                    { dimension: "Country", "=": "Parameter5" },
                  ],
                },
              },
            },
          ],
        ],

        tooltip: {
          order: "valueDesc",
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          nameLocation: "middle",
        },
        yAxis: {
          name: "Income",
        },
        grid: {
          right: 10,
        },
        series: [
          {
            type: "line",
            datasetId: "dataset_Parameter1",
            showSymbol: false,
            name: "Parameter1",
            endLabel: {
              show: true,
              formatter: (params: any) =>
                `${params.value[3]}: ${params.value[0]}`,
            },
            labelLayout: {
              moveOverlap: "shiftY",
            },
            emphasis: {
              focus: "series",
            },
            encode: {
              x: "Time",
              y: "Income",
              label: ["Country", "Income"],
              itemName: "Time",
              tooltip: ["Income"],
            },
          },
          {
            type: "line",
            datasetId: "dataset_Parameter2",
            showSymbol: false,
            name: "Parameter2",
            endLabel: {
              show: true,
              formatter: (params: any) =>
                `${params.value[3]}: ${params.value[0]}`,
            },
            labelLayout: {
              moveOverlap: "shiftY",
            },
            emphasis: {
              focus: "series",
            },
            encode: {
              x: "Time",
              y: "Income",
              label: ["Country", "Income"],
              itemName: "Time",
              tooltip: ["Income"],
            },
          },
          {
            type: "line",
            datasetId: "dataset_Parameter3",
            showSymbol: false,
            name: "Parameter3",
            endLabel: {
              show: true,
              formatter: (params: any) =>
                `${params.value[3]}: ${params.value[0]}`,
            },
            labelLayout: {
              moveOverlap: "shiftY",
            },
            emphasis: {
              focus: "series",
            },
            encode: {
              x: "Time",
              y: "Income",
              label: ["Country", "Income"],
              itemName: "Time",
              tooltip: ["Income"],
            },
          },
          {
            type: "line",
            datasetId: "dataset_Parameter4",
            showSymbol: false,
            name: "Parameter4",
            endLabel: {
              show: true,
              formatter: (params: any) =>
                `${params.value[3]}: ${params.value[0]}`,
            },
            labelLayout: {
              moveOverlap: "shiftY",
            },
            emphasis: {
              focus: "series",
            },
            encode: {
              x: "Time",
              y: "Income",
              label: ["Country", "Income"],
              itemName: "Time",
              tooltip: ["Income"],
            },
          },
          {
            type: "line",
            datasetId: "dataset_Parameter5",
            showSymbol: false,
            name: "Parameter5",
            endLabel: {
              show: true,
              formatter: (params: any) =>
                `${params.value[3]}: ${params.value[0]}`,
            },
            labelLayout: {
              moveOverlap: "shiftY",
            },
            emphasis: {
              focus: "series",
            },
            encode: {
              x: "Time",
              y: "Income",
              label: ["Country", "Income"],
              itemName: "Time",
              tooltip: ["Income"],
            },
          },
        ],
      },
    },
    {
      id: 3,
      heading: "Regional specific Parameter",
      parametersData: [
        { Time: 7, Country: "Parameter1", Income: 1 },
        { Time: 8, Country: "Parameter1", Income: 2 },
        { Time: 9, Country: "Parameter1", Income: 3 },
        { Time: 7, Country: "Parameter2", Income: 4 },
        { Time: 7.5, Country: "Parameter2", Income: 5 },
        { Time: 7, Country: "Parameter3", Income: 1 },
        { Time: 7, Country: "Parameter3", Income: 3 },
        { Time: 8, Country: "Parameter3", Income: 2 },
        { Time: 9, Country: "Parameter3", Income: 7 },
        { Time: 9.8, Country: "Parameter3", Income: 3 },
        { Time: 7, Country: "Parameter4", Income: 7 },
        { Time: 8, Country: "Parameter4", Income: 3 },
        { Time: 9, Country: "Parameter4", Income: 3 },
        { Time: 10, Country: "Parameter4", Income: 2 },
        { Time: 11, Country: "Parameter4", Income: 6 },
        { Time: 12, Country: "Parameter4", Income: 7 },
        { Time: 1, Country: "Parameter4", Income: 3 },
        { Time: 2, Country: "Parameter4", Income: 2 },
        { Time: 3, Country: "Parameter4", Income: 1 },
        { Time: 7, Country: "Parameter5", Income: 5 },
        { Time: 8, Country: "Parameter5", Income: 7 },
        { Time: 9, Country: "Parameter5", Income: 6 },
        { Time: 10, Country: "Parameter5", Income: 5 },
        { Time: 11, Country: "Parameter5", Income: 7 },
        { Time: 12, Country: "Parameter5", Income: 3 },
        { Time: 1, Country: "Parameter5", Income: 2 },
        { Time: 2, Country: "Parameter5", Income: 8 },
        { Time: 3, Country: "Parameter5", Income: 4 },
      ],
      countries: [
        "Parameter1",
        "Parameter2",
        "Parameter3",
        "Parameter4",
        "Parameter5",
      ],
      datasetWithFilters: [
        {
          id: "dataset_Parameter1",
          fromDatasetId: "dataset_raw",
          transform: {
            type: "filter",
            config: {
              and: [
                { dimension: "Time", gte: 7 },
                { dimension: "Country", "=": "Parameter1" },
              ],
            },
          },
        },
        {
          id: "dataset_Parameter2",
          fromDatasetId: "dataset_raw",
          transform: {
            type: "filter",
            config: {
              and: [
                { dimension: "Time", gte: 7 },
                { dimension: "Country", "=": "Parameter2" },
              ],
            },
          },
        },
        {
          id: "dataset_Parameter3",
          fromDatasetId: "dataset_raw",
          transform: {
            type: "filter",
            config: {
              and: [
                { dimension: "Time", gte: 7 },
                { dimension: "Country", "=": "Parameter3" },
              ],
            },
          },
        },
        {
          id: "dataset_Parameter4",
          fromDatasetId: "dataset_raw",
          transform: {
            type: "filter",
            config: {
              and: [
                { dimension: "Time", gte: 7 },
                { dimension: "Country", "=": "Parameter4" },
              ],
            },
          },
        },
        {
          id: "dataset_Parameter5",
          fromDatasetId: "dataset_raw",
          transform: {
            type: "filter",
            config: {
              and: [
                { dimension: "Time", gte: 7 },
                { dimension: "Country", "=": "Parameter5" },
              ],
            },
          },
        },
      ],
      seriesList: [
        {
          type: "line",
          datasetId: "dataset_Parameter1",
          showSymbol: false,
          name: "Parameter1",
          endLabel: {
            show: true,
            formatter: (params: any) =>
              `${params.value[3]}: ${params.value[0]}`,
          },
          labelLayout: {
            moveOverlap: "shiftY",
          },
          emphasis: {
            focus: "series",
          },
          encode: {
            x: "Time",
            y: "Income",
            label: ["Country", "Income"],
            itemName: "Time",
            tooltip: ["Income"],
          },
        },
        {
          type: "line",
          datasetId: "dataset_Parameter2",
          showSymbol: false,
          name: "Parameter2",
          endLabel: {
            show: true,
            formatter: (params: any) =>
              `${params.value[3]}: ${params.value[0]}`,
          },
          labelLayout: {
            moveOverlap: "shiftY",
          },
          emphasis: {
            focus: "series",
          },
          encode: {
            x: "Time",
            y: "Income",
            label: ["Country", "Income"],
            itemName: "Time",
            tooltip: ["Income"],
          },
        },
        {
          type: "line",
          datasetId: "dataset_Parameter3",
          showSymbol: false,
          name: "Parameter3",
          endLabel: {
            show: true,
            formatter: (params: any) =>
              `${params.value[3]}: ${params.value[0]}`,
          },
          labelLayout: {
            moveOverlap: "shiftY",
          },
          emphasis: {
            focus: "series",
          },
          encode: {
            x: "Time",
            y: "Income",
            label: ["Country", "Income"],
            itemName: "Time",
            tooltip: ["Income"],
          },
        },
        {
          type: "line",
          datasetId: "dataset_Parameter4",
          showSymbol: false,
          name: "Parameter4",
          endLabel: {
            show: true,
            formatter: (params: any) =>
              `${params.value[3]}: ${params.value[0]}`,
          },
          labelLayout: {
            moveOverlap: "shiftY",
          },
          emphasis: {
            focus: "series",
          },
          encode: {
            x: "Time",
            y: "Income",
            label: ["Country", "Income"],
            itemName: "Time",
            tooltip: ["Income"],
          },
        },
        {
          type: "line",
          datasetId: "dataset_Parameter5",
          showSymbol: false,
          name: "Parameter5",
          endLabel: {
            show: true,
            formatter: (params: any) =>
              `${params.value[3]}: ${params.value[0]}`,
          },
          labelLayout: {
            moveOverlap: "shiftY",
          },
          emphasis: {
            focus: "series",
          },
          encode: {
            x: "Time",
            y: "Income",
            label: ["Country", "Income"],
            itemName: "Time",
            tooltip: ["Income"],
          },
        },
      ],
      option: {
        animationDuration: 6000,
        dataset: [
          {
            id: "dataset_raw",
            source: [
              { Time: 7, Country: "Parameter1", Income: 1 },
              { Time: 8, Country: "Parameter1", Income: 2 },
              { Time: 9, Country: "Parameter1", Income: 3 },
              { Time: 7, Country: "Parameter2", Income: 4 },
              { Time: 7.5, Country: "Parameter2", Income: 5 },
              { Time: 7, Country: "Parameter3", Income: 1 },
              { Time: 7, Country: "Parameter3", Income: 3 },
              { Time: 8, Country: "Parameter3", Income: 2 },
              { Time: 9, Country: "Parameter3", Income: 7 },
              { Time: 9.8, Country: "Parameter3", Income: 3 },
              { Time: 7, Country: "Parameter4", Income: 7 },
              { Time: 8, Country: "Parameter4", Income: 3 },
              { Time: 9, Country: "Parameter4", Income: 3 },
              { Time: 10, Country: "Parameter4", Income: 2 },
              { Time: 11, Country: "Parameter4", Income: 6 },
              { Time: 12, Country: "Parameter4", Income: 7 },
              { Time: 1, Country: "Parameter4", Income: 3 },
              { Time: 2, Country: "Parameter4", Income: 2 },
              { Time: 3, Country: "Parameter4", Income: 1 },
              { Time: 7, Country: "Parameter5", Income: 5 },
              { Time: 8, Country: "Parameter5", Income: 7 },
              { Time: 9, Country: "Parameter5", Income: 6 },
              { Time: 10, Country: "Parameter5", Income: 5 },
              { Time: 11, Country: "Parameter5", Income: 7 },
              { Time: 12, Country: "Parameter5", Income: 3 },
              { Time: 1, Country: "Parameter5", Income: 2 },
              { Time: 2, Country: "Parameter5", Income: 8 },
              { Time: 3, Country: "Parameter5", Income: 4 },
            ],
          },
          ...[
            {
              id: "dataset_Parameter1",
              fromDatasetId: "dataset_raw",
              transform: {
                type: "filter",
                config: {
                  and: [
                    { dimension: "Time", gte: 7 },
                    { dimension: "Country", "=": "Parameter1" },
                  ],
                },
              },
            },
            {
              id: "dataset_Parameter2",
              fromDatasetId: "dataset_raw",
              transform: {
                type: "filter",
                config: {
                  and: [
                    { dimension: "Time", gte: 7 },
                    { dimension: "Country", "=": "Parameter2" },
                  ],
                },
              },
            },
            {
              id: "dataset_Parameter3",
              fromDatasetId: "dataset_raw",
              transform: {
                type: "filter",
                config: {
                  and: [
                    { dimension: "Time", gte: 7 },
                    { dimension: "Country", "=": "Parameter3" },
                  ],
                },
              },
            },
            {
              id: "dataset_Parameter4",
              fromDatasetId: "dataset_raw",
              transform: {
                type: "filter",
                config: {
                  and: [
                    { dimension: "Time", gte: 7 },
                    { dimension: "Country", "=": "Parameter4" },
                  ],
                },
              },
            },
            {
              id: "dataset_Parameter5",
              fromDatasetId: "dataset_raw",
              transform: {
                type: "filter",
                config: {
                  and: [
                    { dimension: "Time", gte: 7 },
                    { dimension: "Country", "=": "Parameter5" },
                  ],
                },
              },
            },
          ],
        ],

        tooltip: {
          order: "valueDesc",
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          nameLocation: "middle",
        },
        yAxis: {
          name: "Income",
        },
        grid: {
          right: 10,
        },
        series: [
          {
            type: "line",
            datasetId: "dataset_Parameter1",
            showSymbol: false,
            name: "Parameter1",
            endLabel: {
              show: true,
              formatter: (params: any) =>
                `${params.value[3]}: ${params.value[0]}`,
            },
            labelLayout: {
              moveOverlap: "shiftY",
            },
            emphasis: {
              focus: "series",
            },
            encode: {
              x: "Time",
              y: "Income",
              label: ["Country", "Income"],
              itemName: "Time",
              tooltip: ["Income"],
            },
          },
          {
            type: "line",
            datasetId: "dataset_Parameter2",
            showSymbol: false,
            name: "Parameter2",
            endLabel: {
              show: true,
              formatter: (params: any) =>
                `${params.value[3]}: ${params.value[0]}`,
            },
            labelLayout: {
              moveOverlap: "shiftY",
            },
            emphasis: {
              focus: "series",
            },
            encode: {
              x: "Time",
              y: "Income",
              label: ["Country", "Income"],
              itemName: "Time",
              tooltip: ["Income"],
            },
          },
          {
            type: "line",
            datasetId: "dataset_Parameter3",
            showSymbol: false,
            name: "Parameter3",
            endLabel: {
              show: true,
              formatter: (params: any) =>
                `${params.value[3]}: ${params.value[0]}`,
            },
            labelLayout: {
              moveOverlap: "shiftY",
            },
            emphasis: {
              focus: "series",
            },
            encode: {
              x: "Time",
              y: "Income",
              label: ["Country", "Income"],
              itemName: "Time",
              tooltip: ["Income"],
            },
          },
          {
            type: "line",
            datasetId: "dataset_Parameter4",
            showSymbol: false,
            name: "Parameter4",
            endLabel: {
              show: true,
              formatter: (params: any) =>
                `${params.value[3]}: ${params.value[0]}`,
            },
            labelLayout: {
              moveOverlap: "shiftY",
            },
            emphasis: {
              focus: "series",
            },
            encode: {
              x: "Time",
              y: "Income",
              label: ["Country", "Income"],
              itemName: "Time",
              tooltip: ["Income"],
            },
          },
          {
            type: "line",
            datasetId: "dataset_Parameter5",
            showSymbol: false,
            name: "Parameter5",
            endLabel: {
              show: true,
              formatter: (params: any) =>
                `${params.value[3]}: ${params.value[0]}`,
            },
            labelLayout: {
              moveOverlap: "shiftY",
            },
            emphasis: {
              focus: "series",
            },
            encode: {
              x: "Time",
              y: "Income",
              label: ["Country", "Income"],
              itemName: "Time",
              tooltip: ["Income"],
            },
          },
        ],
      },
    },
  ];

  useEffect(() => {
    if (graphData) {
      const newHeight = 300; // Example height
      setChartHeight(newHeight);
    }
  }, [graphData]); // This effect runs when `graphData` changes

  return (
    <div className="w-full h-[540px] p-2 z-50 hover-div">
      {!loading && !data && (
        <div className="flex items-center justify-center h-full">
          <p className="italic text-white">Nothing to show!</p>
        </div>
      )}
      {!loading && data && (
        <>
          <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full flex justify-between border-b border-[#4A4A68] pb-3 items-center">
            <div>
              <h3 className="text-[#FFF] text-sm not-italic font-bold leading-[12px] font-[inter]">
                {data?.coordinateDetails?.id}
              </h3>

              <div className="flex items-center gap-2 mt-5">
                <span className="bg-[#4CB275] w-[10px] h-[10px] rounded-full"></span>
                <div className="text-[#FFF] text-xs not-italic font-bold leading-[12px] font-[inter]">
                  Open
                </div>
              </div>
            </div>
            {data?.coordinateDetails?.weighted_arithmetic_wqi && (
              <div className="flex mt-3 gap-2">
                <span className="text-[#FFF] text-xs not-italic font-bold leading-[21px] font-[inter]">
                  WQI
                </span>
                <button
                  className={`flex items-center justify-center py-[5px] px-[7px] text-[#FFF] text-xs not-italic font-bold leading-3 rounded-[3px]`}
                  style={{
                    backgroundColor:
                      data?.coordinateDetails?.weighted_arithmetic_color_code,
                  }}
                >
                  {parseInt(
                    data?.coordinateDetails?.weighted_arithmetic_wqi
                  )?.toFixed(2)}{" "}
                  {data?.coordinateDetails?.weighted_arithmetic_wqi?.category}
                </button>
              </div>
            )}
          </div>

          <div className="w-full grid grid-cols-2 border-b border-[#4A4A68] pb-3 mt-3">
            {data?.coordinateDetails?.water_quality_class_index && (
              <div>
                <div className="text-[#D2D2DF] font-[inter] text-xs not-italic font-normal leading-3 mb-2">
                  Class of Water
                </div>
                <div className="text-[#FFF] font-[inter] text-xs not-italic font-bold leading-3">
                  {/^-?\d+(\.\d+)?$/.test(
                    data?.coordinateDetails?.water_quality_class_index
                  )
                    ? `Index ${data?.coordinateDetails?.water_quality_class_index}`
                    : `Class ${data?.coordinateDetails?.water_quality_class_index}`}
                </div>
              </div>
            )}
            {data?.coordinateDetails?.water_status && (
              <div>
                <div className="text-[#D2D2DF] font-[inter] text-xs not-italic font-normal leading-3 mb-2">
                  Water Status
                </div>
                <div className="text-[#FFF] font-[inter] text-xs not-italic font-bold leading-3">
                  {data?.coordinateDetails?.water_status}
                </div>
              </div>
            )}
            {data?.coordinateDetails?.treatment_required && (
              <div className="mt-3">
                <div className="text-[#D2D2DF] font-[inter] text-xs not-italic font-normal leading-3 mb-2">
                  Treatment Required
                </div>
                <div className="text-[#FFF] font-[inter] text-xs not-italic font-bold leading-3">
                  {data?.coordinateDetails?.treatment_required}
                </div>
              </div>
            )}
          </div>

          <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full flex flex-wrap mt-3">
            <h1 className="text-[#FFF] text-xs not-italic font-medium leading-[12px] font-[inter]">
              Top 5 Parameters
            </h1>

            <div className="w-full justify-center items-center text-center mt-5">
              <Carousel
                showArrows={true}
                onChange={onChange}
                onClickItem={onClickItem}
                onClickThumb={onClickThumb}
                className="w-full flex h-80"
              >
                {data?.topFiveParametersData
                  ?.filter((data: any) => data.length > 0)
                  ?.map((elem: any, index: any) => (
                    <div
                      className="w-full justify-center items-center"
                      key={`graph_${index}`}
                    >
                      <h1 className="text-[#FFF] text-xs font-[inter] absolute left-0 right-0 mx-auto -top-8">
                        {elem && elem?.length > 0 && elem[0]?.category_name}
                      </h1>
                      <div className="w-full flex">
                        <ul className="grid grid-cols-2 gap-2 text-[#FFF] text-xs">
                          {elem?.map(
                            (param: any, index: any) =>
                              param?.parameter_name && (
                                <li className="flex items-start gap-2">
                                  <div className="bg-[#695CFB] w-[10px] h-[10px] rounded-full flex-shrink-0 mt-[3px]"></div>
                                  <p className="text-left">
                                    {param?.parameter_name}
                                  </p>
                                </li>
                              )
                          )}
                          {/* <li className="pr-4 flex items-center ">
                            <div className="bg-[#695CFB] w-[10px] h-[10px] rounded-full mr-2"></div>
                            Parameter 1{" "}
                          </li>
                          <li className="pr-4 flex items-center ">
                            <div className="bg-[#0FCA7A] w-[10px] h-[10px] rounded-full mr-2"></div>
                            Parameter 2{" "}
                          </li>
                          <li className="pr-4 flex items-center ">
                            <div className="bg-[#F7A23B] w-[10px] h-[10px] rounded-full mr-2"></div>
                            Parameter 3{" "}
                          </li>
                          <li className="pr-4 flex items-center ">
                            <div className="bg-[#F75D5F] w-[10px] h-[10px] rounded-full mr-2"></div>
                            Parameter 4{" "}
                          </li>
                          <li className="pr-4 flex items-center ">
                            <div className="bg-[#7ab5dd] w-[10px] h-[10px] rounded-full mr-2"></div>
                            Parameter 5{" "}
                          </li> */}
                        </ul>
                      </div>
                      {/* {graphData &&
                        graphData.map((data: any, index: any) => (
                          <div className="graph-container">
                            <EChartsReact
                              option={data?.option}
                              key={`graphdata_${data.id}`}
                              className="w-full relative z-[999] h-[298px]"
                              style={{ height: `${chartHeight}px` }}
                            />
                          </div>
                        ))} */}

                      <PopupComparisonChart data={elem} />
                    </div>
                  ))}
              </Carousel>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MapviewPopup;
