import { toast } from "react-toastify";
import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export interface PermissionData {
    // role: string;
}

interface RoleState {
    permission: {
        load: boolean;
        data: PermissionData;
        error: Array<PermissionData>;
    };
    Currentpermission: {
        load: boolean;
        data: Array<PermissionData>;
        error: Array<PermissionData>;
    };
    Sidebarpermission: {
        load: boolean;
        data: PermissionData;
        error: Array<PermissionData>;
    };
    editRole: {
        load: boolean;
    };
    currentUserPermission: {
        data: object
    }
}
const initialState = {
    permission: {
        load: false,
        data: {},
        error: []
    },
    Currentpermission: {
        load: false,
        data: [],
        error: []
    },
    Sidebarpermission: {
        load: false,
        data: {},
        error: []
    },
    currentUserPermission: {
        data: {}
    },
    editRole: {
        load: false,
    },
    deleteRole: {
        load: false,
    },
} as RoleState;

export const roleSlice = createSlice({
    name: "roles",
    initialState,
    reducers: {
        addPermissionError: (state: RoleState, action: any) => {
            state.Currentpermission.error = action.payload;
        },
        fetchPermissionError: (state: RoleState, action: any) => {
            state.Currentpermission.error = action.payload;
        },
        fetchCurrentPermission: (state: RoleState, action: any) => {
            state.currentUserPermission.data = action.payload
        }
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchPermission.pending, (state: RoleState) => {
                // state.permission.load = true;
                // state.Currentpermission.load = true;
            })
            .addCase(fetchPermission.fulfilled, (state: RoleState, action: any) => {
                state.permission.data = action.payload;
                // state.permission.load = false;
                // state.Currentpermission.load = false;
            })
            .addCase(fetchPermission.rejected, (state: RoleState) => {
                // state.permission.load = false;
                // state.Currentpermission.load = false;
            })
            .addCase(fetchLoader.pending, (state: RoleState, action: any) => {
                // state.permission.load = true;
                state.Currentpermission.load = true;
            })
            .addCase(fetchLoader.fulfilled, (state: RoleState, action: any) => {
                // state.permission.data = action.payload;
                // state.permission.load = false;
                state.Currentpermission.load = action.payload;
            })
            .addCase(fetchLoader.rejected, (state: RoleState, action: any) => {
                // state.permission.load = false;
                state.Currentpermission.load = false;
            })
            .addCase(addAssignPermission.pending, (state: RoleState) => {
                // state.Currentpermission.load = true;
            })
            .addCase(addAssignPermission.fulfilled, (state: RoleState, action: any) => {
                state.Currentpermission.data = action.payload;
                // state.Currentpermission.load = false;
            })
            .addCase(addAssignPermission.rejected, (state: RoleState) => {
                // state.Currentpermission.load = false;
            })
            .addCase(fetchAssignPermission.pending, (state: RoleState) => {
                // state.Currentpermission.load = true;
            })
            .addCase(fetchAssignPermission.fulfilled, (state: RoleState, action: any) => {
                if (action.payload.pass == 1) {
                    state.Sidebarpermission.data = action.payload.data;
                } else {
                    state.Currentpermission.data = action.payload.data;
                }

                // state.Currentpermission.load = false;
            })
            .addCase(fetchAssignPermission.rejected, (state: RoleState) => {
                // state.Currentpermission.load = false;
            })
    },
});
export const { addPermissionError, fetchPermissionError, fetchCurrentPermission } = roleSlice.actions;
export default roleSlice.reducer;

export const fetchPermission = createAsyncThunk(
    "roles/fetchPermission",
    async (param, thunkAPI) => {
        thunkAPI.dispatch(fetchLoader(true));
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.get("/api/GetAllPermissionsData");
            // thunkAPI.dispatch(toggleLoading());
            thunkAPI.dispatch(fetchLoader(false));
            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const addAssignPermission = createAsyncThunk(
    "roles/addAssignPermission",
    async (param: { apiParam: any }, thunkAPI) => {
        thunkAPI.dispatch(fetchLoader(true));
        try {
            const { data } = await DataServer.post("/api/store_and_update_permission_data_via_submit_btn",
                param.apiParam
            );


            toast.success('Permission Assigned successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });



            return data;
        } catch (error: any) {

            thunkAPI.dispatch(addPermissionError(error));
            return error;
        }
    }
);

export const fetchLoader = createAsyncThunk(
    "roles/fetchLoader",
    async (param: any, thunkAPI) => {
        try {

            return param;
        } catch (error: any) {


            return error;
        }
    }
);

export const fetchAssignPermission = createAsyncThunk(
    "roles/fetchAssignPermission",
    async (param: any, thunkAPI) => {
        try {
            thunkAPI.dispatch(fetchLoader(true));
            const { data } = await DataServer.post("/api/role_permission_check_data", {
                role_id: param.apiParam.role_id
            });


            // toast.success('Permission Assign successfully!', {
            //     position: "top-right",
            //     autoClose: 5000,
            //     theme: "dark",
            // });
            // localStorage.setItem('selectedRole', JSON.stringify(param.role_id));
            // window.location.reload();
            thunkAPI.dispatch(fetchLoader(false));
            return { data: data, pass: param.apiParam.pass };
        } catch (error: any) {
            thunkAPI.dispatch(fetchPermissionError(error));
            return error;
        }
    }
);



