import React, { useEffect, useState } from "react";
import Map, { Layer, Marker, Source } from "react-map-gl";
import "../../assets/css/reusable/newscreenmonitoring.css";
import Gpt from "../GptScreen";
import { MAPBOX_TOKEN } from "../../config";
import Calendar from "../Calender";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardCheck,
  faCube,
  faKaaba,
  faTemperatureThreeQuarters,
  faTriangleExclamation,
} from "@fortawesome/pro-light-svg-icons";
import Timeline from "../TimeLine";
import { faHeartPulse, faWater } from "@fortawesome/pro-regular-svg-icons";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Modal from "react-modal";
import CodeCompliance from "../CodeCompliance";
import CalculationMethod from "../CalculationMethod";
import CodeComplianceChart from "../CodeComplianceChart";
import { Tooltip } from "flowbite-react";
import MapviewPopup from "../MapviewPopup";
import { faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import MapList from "../MapDashboard/MapList";
import { FeatureCollection, LineString } from "geojson";
import {
  fetchMonitoringStats,
  monitoringScreenWaterStrech,
} from "../../redux/slice/monitoring";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useLocation } from "react-router-dom";
import { fetchNetwork } from "../../redux/slice/Map";
import ImpactAnalysis from "../ImpactAnalysis";
import HealthImpacts from "../HealthImpacts";

const initialViewState = {
  longitude: 73.90959193240296,
  latitude: 18.580587992841764,
  zoom: 12,
};

const NewMapview = ({ activeTab, setActiveTab }: any) => {
  const [viewport, setViewport] = useState(initialViewState);
  const [mapStyle, setMapStyle] = useState<string | null>(
    "mapbox://styles/inodedesign/clxyi5zp9001h01qr84pa6a7a"
  );
  const [mapviewTab, setMapviewTab] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpen2, setIsOpen2] = useState(false);
  const [modalIsOpen3, setIsOpen3] = useState(false);
  const [modalIsOpen4, setIsOpen4] = useState(false);
  const [lines, setLines] = useState<Array<FeatureCollection<LineString>>>([]);
  const [colorCodeArray, setcolorCodeArray]: any[] = React.useState([]);
  const monitoring = useAppSelector((state) => state.monitoring);
  const components = useAppSelector((state) => state.components);
  const coordinate = useAppSelector((state) => state.coordinate);
  const disptach = useAppDispatch();
  const [coordinatesArray, setcoordinatesArray]: any[] = React.useState([]);
  const [tileset, setTileset] = React.useState(null);
  const [layer, setLayer]: any = React.useState(null);
  const [networkdata, setNetwork]: any = React.useState({});
  const location = useLocation();
  const [nearestPointCoords, setNearestPointCoords] = useState<Array<any>>([]);

  useEffect(() => {
    const fetchMapStyle = async () => {
      const response = await fetch(
        `mapbox://styles/inodedesign/clxyi5zp9001h01qr84pa6a7a?access_token=${MAPBOX_TOKEN}`
      );
      const style = await response.json();

      // Modify the background and water layers
      // style.layers = style.layers.map((layer: any) => {
      //   console.log(layer);
      //   if (layer.id === 'background') {
      //     layer.paint = layer.paint || {};
      //     layer.paint['background-color'] = '#0F131F'; // Exact color for background
      //   }
      //   if (layer.id === 'water') {
      //     layer.paint = layer.paint || {};
      //     layer.paint['fill-color'] = '#1C3146'; // Exact color for water
      //   }
      //   if (layer.id.includes('land') || layer.id.includes('landuse') || layer.id.includes('landcover')) {
      //     layer.paint = layer.paint || {};
      //     layer.paint['fill-color'] = '#0F131F'; // Exact color for land
      //   }
      //   if (layer.id.includes('hillshade') || layer.id.includes('terrain')) {
      //     layer.paint = layer.paint || {};
      //     layer.paint['hillshade-shadow-color'] = '#0F131F'; // Match shadow to background
      //     layer.paint['hillshade-highlight-color'] = '#0F131F'; // Match highlight to background
      //     layer.paint['hillshade-accent-color'] = '#0F131F'; // Match accent to background
      //   }
      //   return layer;
      // });

      setMapStyle(style);
    };

    // fetchMapStyle();
  }, []);

  const handleZoomIn = () => {
    setViewport((prevViewport) => ({
      ...prevViewport,
      zoom: prevViewport.zoom + 1,
    }));
  };

  const handleZoomOut = () => {
    setViewport((prevViewport) => ({
      ...prevViewport,
      zoom: prevViewport.zoom - 1,
    }));
  };

  // const customStyles = {
  //   content: {
  //     top: '13%',
  //     left: '57%',
  //     // left: 'auto',
  //     bottom: 'auto',
  //     marginRight: '-50%',
  //     padding: "0px",
  //     border:'0px',
  //     background:'transparent',
  //     // borderRadius:'none',
  //     // height:"254px",
  //     // transform: 'translate(-50%, -50%)',
  //     width: "550px",
  //     maxHeight: 'calc(70vh - 13%)', // Ensure modal does not exceed viewport height
  //     overflow: 'auto' // Scroll within modal if content exceeds maxHeight

  //   },
  // overlay: {
  //   backgroundColor: 'rgba(0, 0, 0, 0)' // Transparent background
  // }
  // };
  // const customSecondStyles = {
  //   content: {
  //     top: 'calc(13% + 262px)',
  //     left: '57%',
  //     // left: 'auto',
  //     bottom: 'auto',
  //     // height:"254px",
  //     marginRight: '-50%',
  //     padding: "0px",
  //     border:'0px',
  //     background:'transparent',
  //     // transform: 'translate(-50%, -50%)',
  //     width: "550px",
  //     maxHeight: 'calc(70vh - 13%)', // Ensure modal does not exceed viewport height
  //     overflow: 'auto' // Scroll within modal if content exceeds maxHeight

  //   },
  // overlay: {
  //   backgroundColor: 'rgba(0, 0, 0, 0)' // Transparent background
  // }
  // };
  // const customThirdStyles = {
  //   content: {
  //     top: 'calc(13% + 2 * 262px)',
  //     left: '57%',
  //     // left: 'auto',
  //     bottom: 'auto',
  //     // height:"254px",
  //     marginRight: '-50%',
  //     border:'0px',
  //     background:'transparent',
  //     padding: "0px",
  //     maxHeight: 'calc(70vh - 13%)', // Ensure modal does not exceed viewport height
  //     overflow: 'auto', // Scroll within modal if content exceeds maxHeight

  //     // transform: 'translate(-50%, -50%)',
  //     width: "550px",
  //   },
  // overlay: {
  //   backgroundColor: 'rgba(0, 0, 0, 0)' // Transparent background
  // }
  // };

  Modal.setAppElement("#root");
  function openModal() {
    setIsOpen(true);
  }

  // function closeModal() {
  //   setIsOpen(false);
  //   setIsOpen2(false);
  //   setIsOpen3(false);
  // }

  // *********************code for stretch  starts here*****************************
  useEffect(() => {
    if (monitoring?.waterStrech?.data?.WaterStrechColorCode) {
      let outputArray =
        monitoring?.waterStrech?.data?.WaterStrechColorCode?.flatMap(
          (item: {
            split: (arg0: string) => {
              (): any;
              new (): any;
              map: { (arg0: (subItem: any) => any): [any, any]; new (): any };
            };
          }) => {
            // Split each string by the comma and trim spaces
            let [num, color] = item
              .split(",")
              .map((subItem: string) => subItem.trim());

            // Parse the numeric value and remove single quotes from the color value
            return [parseFloat(num), color.replace(/'/g, "")];
          }
        );

      setcolorCodeArray(outputArray);
    }
  }, [monitoring?.waterStrech?.data?.WaterStrechColorCode]);

  useEffect(() => {
    let data = {
      ...monitoring.updatedData,

      // schedule_date: 2024-02-04
      // schedule_time: 16:17
      // coordinate_id: 978
      // component_horizontal_tab_id: 1015
    };
    disptach(fetchMonitoringStats(data));
    // dispatch(fetchCompliance(data))
  }, [monitoring.updatedData]);

  useEffect(() => {
    let coData = coordinate?.coordinates?.data?.data;

    const initialView = {
      latitude: coData[0]?.latitude,
      longitude: coData[0]?.longitude,
      zoom: 12,
    };

    setViewport(initialView);

    if (
      monitoring?.waterStrech?.data?.WQI &&
      Object.keys(monitoring?.waterStrech?.data?.WQI).length > 0
    ) {
      let mergedArray = coData?.map((item: { id: string | number }) => {
        if (monitoring?.waterStrech?.data?.WQI[item?.id]) {
          return { ...item, ...monitoring?.waterStrech?.data?.WQI[item?.id] };
        }
        return item;
      });
      setcoordinatesArray(mergedArray);
    } else {
      setcoordinatesArray(coData); // Set the original coData if waterStrech.data.WQI is empty
    }
  }, [coordinate?.coordinates?.data, monitoring?.waterStrech?.data?.WQI]);

  useEffect(() => {
    const fetchNearestPoints = async () => {
      try {
        const nearestPointCoords: Array<any> = [];
        for (const element of coordinatesArray) {
          const { latitude, longitude } = element;
          const nearestPoint = await findNearestPoint(latitude, longitude);
          if (nearestPoint) {
            nearestPointCoords.push([nearestPoint.lon, nearestPoint.lat]);
          }
        }
        setNearestPointCoords(nearestPointCoords);
        await drawLinesSequentially(nearestPointCoords);
      } catch (error) {
        console.error("Error in fetching waterway data:", error);
      }
    };

    fetchNearestPoints();
  }, [coordinatesArray]);

  const findNearestPoint = async (lat: number, lng: number) => {
    const overpassUrl = `https://overpass-api.de/api/interpreter?data=[out:json];way[waterway](around:10000,${lat},${lng});out geom;`;
    const response = await fetch(overpassUrl);
    const data = await response.json();

    if (data.elements && data.elements.length > 0) {
      let nearestPoint: any = null;
      let minDistance = Infinity;

      data.elements.forEach((element: any) => {
        if (element.geometry && element.geometry.length > 0) {
          element.geometry.forEach((point: any) => {
            const distance = calculateDistance(lat, lng, point.lat, point.lon);
            if (distance < minDistance) {
              minDistance = distance;
              nearestPoint = point;
            }
          });
        }
      });

      return nearestPoint;
    } else {
      console.log("No nearby waterways found.");
      return null;
    }
  };

  const drawLinesSequentially = async (coords: Array<[number, number]>) => {
    for (let i = 0; i < coords.length - 1; i++) {
      await fetchAndDrawLine(coords[i], coords[i + 1]);
    }
  };

  const fetchAndDrawLine = async (
    point1: [number, number],
    point2: [number, number]
  ) => {
    const bbox = [
      Math.min(point1[1], point2[1]),
      Math.min(point1[0], point2[0]),
      Math.max(point1[1], point2[1]),
      Math.max(point1[0], point2[0]),
    ];
    const overpassUrl = `https://overpass-api.de/api/interpreter?data=[out:json];way[waterway](${bbox.join(
      ","
    )});out geom;`;

    const response = await fetch(overpassUrl);
    const data = await response.json();

    if (data.elements && data.elements.length > 0) {
      const waterwayCoordinatesArray: [number, number][] = [];

      data.elements.forEach((element: any) => {
        if (element.geometry && element.geometry.length > 0) {
          element.geometry.forEach((point: any) => {
            waterwayCoordinatesArray.push([point.lon, point.lat]);
          });
        }
      });

      const point1Index = waterwayCoordinatesArray.findIndex(
        (coord) => coord[0] === point1[0] && coord[1] === point1[1]
      );
      const point2Index = waterwayCoordinatesArray.findIndex(
        (coord) => coord[0] === point2[0] && coord[1] === point2[1]
      );

      if (point1Index > point2Index) {
        waterwayCoordinatesArray.reverse();
      }

      const valuesBetweenPoints = waterwayCoordinatesArray.slice(
        Math.min(point1Index, point2Index),
        Math.max(point1Index, point2Index) + 1
      );

      const geojson: FeatureCollection<LineString> = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: valuesBetweenPoints,
            },
          },
        ],
      };

      setLines((prevLines) => [...prevLines, geojson]);
      // setLines(prevLines => [...prevLines, { geojson:geojson }]);
    } else {
      console.log("No waterways found in the bounding box.");
    }
  };

  const calculateDistance = (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ) => {
    const R = 6371e3;
    const φ1 = (lat1 * Math.PI) / 180;
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  };

  useEffect(() => {
    if (location?.state?.projectData?.project_id && monitoring?.updatedData) {
      let data = {
        ...monitoring?.updatedData,
        project_id: location?.state?.projectData?.project_id,
      };
      disptach(monitoringScreenWaterStrech(data));
    }
  }, [monitoring?.updatedData, location?.state?.projectData?.project_id]);

  const updateTilesetAndAddLayer = (obj: any) => {
    setTileset(obj.tileset);
  };

  React.useEffect(() => {
    // alert(1)
    if (networkdata?.upload_response) {
      // alert(2)
      // updateTileset(networkdata.upload_response.tileset);
      let data1: any = JSON.stringify(networkdata.upload_response);

      let data2: any = JSON.parse(data1);

      let obj2 = JSON.parse(data2);
      updateTilesetAndAddLayer(obj2);
      setLayer(obj2);
    }
  }, [networkdata?.upload_response]);

  React.useEffect(() => {
    if (components?.network?.data?.data) {
      setNetwork(components?.network?.data?.data);
    }
  }, [components?.network?.data?.data]);

  React.useEffect(() => {
    if (location.state.projectData?.project_id) {
      disptach(
        fetchNetwork({ project_id: location.state.projectData?.project_id })
      );
    }
  }, [location.state.projectData?.project_id]);

  const displayShapefileOnMap = (response: any) => {
    const tileset = response.tileset;
    const name = response.name;
    const shapefileSource = {
      type: "vector",
      url: `mapbox://${tileset}`,
    };

    return (
      <Source id="shapefile-source" type="vector" url={shapefileSource.url}>
        <Layer
          id="shapefile-layer-network"
          type="fill"
          source="shapefile-source"
          source-layer={name}
          paint={{
            "fill-color": "#088",
            "fill-opacity": 0.8,
          }}
        />
      </Source>
    );
  };

  return (
    <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full mapview">
      <Tabs
        onSelect={(index) => setMapviewTab(index)}
        selectedIndex={mapviewTab}
      >
        <TabList
          className={`mr-5 border rounded-[9999px] py-[3.5px] px-[2px]  absolute left-[41rem]  top-[1.5rem] z-10 bg-[#35354B] border-none text-[#FFF] `}
        >
          {/* <Tab>Quality</Tab>
          <Tab>consumption</Tab>
          <Tab>operation & Maintenance</Tab> */}
        </TabList>
        <TabPanel>
          <div className="">
            <div className="h-[100vh] overlay-div">
              {mapStyle ? (
                <Map
                  {...viewport}
                  mapboxAccessToken={MAPBOX_TOKEN}
                  initialViewState={initialViewState}
                  onMove={(evt) => setViewport(evt.viewState)}
                  //   onClick={handleMapClick}
                  // onViewportChange={handleViewportChange}
                  //style={{position:"absolute"}}

                  style={{ width: "100vw", height: "100%" }}
                  mapStyle={mapStyle}
                >
                  {layer &&
                    initialViewState.latitude &&
                    initialViewState.longitude &&
                    displayShapefileOnMap(layer)}

                  <>
                    {/* <MapviewSM /> */}
                    <MapList />

                    <div className="absolute left-6 z-10 top-24 flex flex-col ">
                      <button
                        className={`text-white font-semibold text-xl px-2 py-2 my-2
                  rounded-md w-8 h-8 flex items-center justify-center  ${
                    modalIsOpen4 === true ? "bg-[#377DFF]" : "bg-[#35354B] "
                  }`}
                        onClick={() => setIsOpen4(!modalIsOpen4)}
                      >
                        <FontAwesomeIcon icon={faHeartPulse} />
                      </button>
                      {/* <Tooltip content={<MapviewPopup />} arrow={false} placement="right" className='w-[359px] mt-14  bg-[#222230] dark:bg-[#222230]'> */}
                      <button
                        className="text-white font-semibold text-xl px-2 py-2 
                    bg-[#35354B] rounded-md w-8 h-8 flex items-center justify-center"
                      >
                        <FontAwesomeIcon icon={faWater} />
                      </button>
                      {/* </Tooltip> */}
                    </div>
                    <div className="absolute right-6 z-10 top-24 flex flex-col ">
                      <button
                        className={`text-white font-semibold text-xl px-2 py-2 
                rounded-md w-8 h-8 flex items-center justify-center ${
                  modalIsOpen === true ? "bg-[#377DFF]" : "bg-[#35354B] "
                }`}
                        onClick={() => setIsOpen(!modalIsOpen)}
                        // onClick={() => openModal()}
                      >
                        <FontAwesomeIcon icon={faClipboardCheck} />
                      </button>
                      <button
                        className={`text-white font-semibold text-xl px-2 py-2 my-2
                rounded-md w-8 h-8 flex items-center justify-center ${
                  modalIsOpen2 === true ? "bg-[#377DFF]" : "bg-[#35354B] "
                }`}
                        onClick={() => setIsOpen2(!modalIsOpen2)}
                      >
                        <FontAwesomeIcon icon={faTemperatureThreeQuarters} />
                      </button>
                      <button
                        className={`text-white font-semibold text-xl px-2 py-2 
                 rounded-md w-8 h-8 flex items-center justify-center ${
                   modalIsOpen3 === true ? "bg-[#377DFF]" : "bg-[#35354B] "
                 }`}
                        onClick={() => setIsOpen3(!modalIsOpen3)}
                      >
                        <FontAwesomeIcon icon={faTriangleExclamation} />
                      </button>
                    </div>
                    <div className="absolute right-6 z-10  bottom-[12.9rem] flex flex-col ">
                      <button
                        className="text-white font-semibold text-xl px-2 py-2 my-2
                bg-[#35354B] rounded-md w-8 h-8 flex items-center justify-center"
                      >
                        <FontAwesomeIcon icon={faKaaba} />
                      </button>
                      <button
                        className="text-white font-semibold text-xl px-2 py-2 
                bg-[#35354B] rounded-md w-8 h-8 flex items-center justify-center"
                      >
                        <FontAwesomeIcon icon={faCube} />
                      </button>
                    </div>
                    <div className="absolute right-6 z-10 bottom-[134px] flex flex-col rounded-md bg-[#35354B]">
                      <button
                        onClick={handleZoomIn}
                        className="text-white font-medium text-xl  px-2 py-2  w-8 h-8 flex items-center justify-center"
                      >
                        <FontAwesomeIcon icon={faPlus} size="xs" />
                      </button>
                      <button
                        onClick={handleZoomOut}
                        className="text-white font-medium  text-xl px-2 py-2  w-8 h-8 flex items-center justify-center"
                      >
                        <FontAwesomeIcon icon={faMinus} size="xs" />
                      </button>
                    </div>
                    <div
                      className={`absolute top-24 right-20 h-[70vh] overflow-x-hidden overflow-y-auto w-[600px] mapview-modal-height pr-2 ${
                        modalIsOpen === true ||
                        modalIsOpen2 === true ||
                        modalIsOpen3 === true
                          ? "block"
                          : "hidden"
                      }`}
                    >
                      <div
                        className={`modal_body w-full   ${
                          modalIsOpen === true ? "block" : "hidden"
                        }`}
                      >
                        <CodeCompliance />
                      </div>

                      <div
                        className={`modal_body  w-full mt-2  ${
                          modalIsOpen2 === true ? "block" : "hidden"
                        }`}
                      >
                        <CodeComplianceChart />
                      </div>

                      <div
                        className={`modal_body  w-full mt-2  ${
                          modalIsOpen3 === true ? "block" : "hidden"
                        }`}
                      >
                        <CalculationMethod />
                      </div>
                    </div>
                    <div
                      className={`absolute top-24 left-20 ${
                        modalIsOpen4 === true ? "block" : "hidden"
                      }`}
                    >
                      <HealthImpacts />
                    </div>
                    <div className="w-full flex absolute bottom-16  z-10">
                      <Timeline />
                    </div>
                    <div className="absolute bottom-6  z-10 left-[39rem] xl:left-[32rem] 2xl:left-[39rem] custom-position">
                      <Calendar />
                    </div>
                    <div>
                      <Gpt activeTab={activeTab} />
                    </div>
                    {lines?.map((line, index) => (
                      <Source
                        key={index}
                        type="geojson"
                        data={line}
                        lineMetrics={true}
                      >
                        <Layer
                          id={`line-${index}`}
                          type="line"
                          paint={{
                            "line-color": "red",
                            "line-width": 10,
                            "line-gradient": [
                              "interpolate",
                              ["linear"],
                              ["line-progress"],
                              ...colorCodeArray,
                            ],
                          }}
                          layout={{
                            "line-cap": "round",
                            "line-join": "round",
                          }}
                        />
                      </Source>
                    ))}
                  </>
                </Map>
              ) : (
                <div>Loading map...</div>
              )}
            </div>
          </div>
        </TabPanel>
        <TabPanel>{/* <NewMapview /> */}</TabPanel>
        <TabPanel>{/* <NewMapview /> */}</TabPanel>
      </Tabs>
    </div>
  );
};

export default NewMapview;
