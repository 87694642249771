import React, { useEffect, useState } from 'react'
import CodeCompliance from '../CodeCompliance'
import CalculationMethod from '../CalculationMethod'
import CodeComplianceChart from '../CodeComplianceChart'
import ImpactAnalysis from '../ImpactAnalysis'
import '../../assets/css/reusable/screenmonitoring.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp, faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { updateMonitoring } from '../../redux/slice/monitoring'
// import CalculationMethod from '../CalculationMethod'

const MapviewSM = () => {
  const [formData, setFormData] = useState<any>({
    monsoon: "",

  });
  const checklist = useAppSelector((state) => state.checklist);
  const dispatch = useAppDispatch();
  const monitoring = useAppSelector((state) => state.monitoring);

  const onSelectChangeChange = async (e: any) => {

    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    const matchingItem: any = options.find((item2: { name: any; }) => (item2.name) === (value));

    if (matchingItem?.id) {
      dispatch(updateMonitoring({
        ...monitoring.updatedData,
        component_horizontal_tab_id: matchingItem.id
      }))
    }

  }
  const [options, setOptions] = useState([])

  useEffect(() => {
    if (checklist?.horizontalTab?.data) {

      setOptions(checklist.horizontalTab.data)
      const matchingItem: any = options.find((item2: { name: any; }) => (item2.name) === ("Monthly test"));

      if (matchingItem?.id) {
        dispatch(updateMonitoring({
          ...monitoring.updatedData,
          component_horizontal_tab_id: matchingItem.id
        }))
      }
      setFormData({ ...formData, monsoon: "Monthly test" });
    }

  }, [checklist?.horizontalTab?.data])
  // const options=[
  //   {
  //     "id": 923,
  //     "name": "Pre monsoon test",
  //     "coordinate_id": 933,
  //     "status": null,
  //     "is_selected": "true",
  //     "created_at": "2024-06-24T12:09:26.000000Z",
  //     "updated_at": "2024-06-24T12:09:26.000000Z",
  //     "type": "predefined"
  //   },
  //   {
  //     "id": 924,
  //     "name": "Post monsoon test",
  //     "coordinate_id": 933,
  //     "status": null,
  //     "is_selected": "true",
  //     "created_at": "2024-06-24T12:09:26.000000Z",
  //     "updated_at": "2024-06-24T12:09:26.000000Z",
  //     "type": "predefined"
  //   },
  //   {
  //     "id": 925,
  //     "name": "Monthly test",
  //     "coordinate_id": 933,
  //     "status": null,
  //     "is_selected": "true",
  //     "created_at": "2024-06-24T12:09:26.000000Z",
  //     "updated_at": "2024-06-24T12:09:26.000000Z",
  //     "type": "predefined"
  //   }
  // ]
  return (
    <div className='flex flex-wrap w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full p-4  z-10 mapviewsm absolute -top-4'>

      <div className="flex flex-wrap w-2/12 justify-start">
        {/* <div className={`relative ml-4 mt-3  w-full`}>
          <FontAwesomeIcon icon={faMagnifyingGlass} className='text-[#FFF] text-sm not-italic  leading-normal absolute top-[12px] left-[17px]' />
          <input type="search" placeholder="Search" className="border-0 pl-9 placeholder:text-[#FFF] pb-2 bg-[#44444F] w-full py-2 rounded-[24px] text-[#FFF] text-sm not-italic  leading-normal " />
        </div> */}
        <select
          key="monsoon"
          id="monsoon"
          name="monsoon"
          // placeholder=""
          autoComplete="monsoon"
          value={formData.monsoon}
          onChange={onSelectChangeChange}
          className={`code-compliance-chart-arrow select-field text-xs not-italic font-medium leading-[21px] placeholder:text-[#8C98A4] placeholder:text-xs placeholder:not-italic placeholder:font-medium placeholder:leading-[21px] block w-[100%] h-[32px] rounded-md border-0  bg-white 
                        shadow-sm ring-1 ring-inset focus:ring-1 appearance-none sm:text-xs sm:leading-6 ring-gray-300 mt-3 mr-16 select-field-monitoring`}
        >
          <option className="capitalize w-[100%] text-[13px] not-italic font-medium leading-[21px]">Select</option>
          {options &&
            options?.map((opt: any) => (
              <>
                <option className="capitalize w-[100%] text-[13px] not-italic font-medium leading-[21px]">{opt?.name}</option>
              </>
            ))
          }
        </select>
      </div>
      <div className="flex flex-wrap w-3/12  justify-between mt-2">
        <div className="flex flex-wrap items-center">
          <div className="div-red mr-2"></div>
          <span className='text-[#FFF] text-sm not-italic font-bold leading-normal'>Red 75%</span>
          <span><FontAwesomeIcon icon={faArrowUp} className='text-[#F12E52] ml-1 text-sm font-[inter]' /></span>
        </div>
        <div className="flex flex-wrap items-center">
          <div className="div-amber mr-2"></div>
          <span className='text-[#FFF] text-sm not-italic font-bold leading-normal'>Amber 55%</span>
          <span><FontAwesomeIcon icon={faArrowUp} className='text-[#FF8700]  ml-1 text-sm' /></span>
        </div>
        <div className="flex flex-wrap items-center">
          <div className="div-green mr-2"></div>
          <span className='text-[#FFF] text-sm not-italic font-bold leading-normal'>Green 35%</span>
          <span><FontAwesomeIcon icon={faArrowDown} className='text-[#4CB275]  ml-1 text-sm' /></span>
        </div>

      </div>
      <div className='flex flex-wrap w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full px-4  py-3  z-10 mapviewsm '>
        <div className='flex flex-wrap w-4/12 sm:w-4/12 md:w-4/12 lg:w-4/12 xl:w-4/12 2xl:w-4/12 z-10 absolute left-4'>
          <div className="w-full"><CodeCompliance /></div>
          <div className="w-full mt-2"><CalculationMethod /></div>
          <div className="w-full mt-2"><CodeComplianceChart /></div>

        </div>
        {/* <div className='flex flex-wrap w-5/12 sm:w-5/12 md:w-5/12 lg:w-5/12 xl:w-5/12 2xl:w-5/12 relative z-0'>

        </div> */}
        <div className='flex flex-wrap w-3/12 sm:w-3/12 md:w-3/12 lg:w-3/12 xl:w-3/12 2xl:w-3/12 z-10 absolute right-4'>
          <ImpactAnalysis />

        </div>


      </div>
    </div>
  )
}

export default MapviewSM
