import React, { useEffect, useState } from "react";
import FormField from '../Forms/FormField'
// import SelectField from '../SelectField'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as yup from 'yup';
// import { FormValidation } from "../../Utils/formValidation";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import '../../assets/css/reusable/form.css'
import SelectField from "../Forms/SelectField";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { activeComponent, formPopup } from "../../redux/slice/Map";
import { addValve, editValve, fetchValveById } from "../../redux/slice/valve";
import { useLocation } from "react-router-dom";
import Loading from "../../views/pages/Loading";
interface FormData {
    type: string;
    latitude: string;
    longitude: string;
    noOfValve: string;
    size: string;
    compliance: string;
}
const ValvesDetails: any = (props: any) => {
    const components = useAppSelector((state) => state.components)
    const valve = useAppSelector((state) => state.valve);
    const dispatch = useAppDispatch()
    const [formData, setFormData] = useState<any>({
        type: "",
        latitude: "",
        longitude: "",
        noOfValve: "",
        size: "",
        compliance: ""
    });

    const [resetformData, setResetFormData] = useState<FormData>({
        type: "",
        latitude: "",
        longitude: "",
        noOfValve: "",
        size: "",
        compliance: ""
    });

    const location = useLocation();
    const typeOptions: any = [{ "value": "Select" }, { "value": "Gate valve" }, { "value": "Butterfly valve" }, { "value": "Ball valve" }, { "value": "Check valve" }, { "value": "Pressure-reducing valve" }, { "value": "Pressure-sustaining valve" }, { "value": "Pressure-relief valve" }, { "value": "Air release valve" }, { "value": "Control Valve" }]


    const convertedsystemOptions = typeOptions.map((option: { value: string; }) => option.value);
    const formSchema = yup.object({
        type: yup.string()
            .oneOf(convertedsystemOptions, 'Please select a Type of Valve from the list.') // A) Error for text-based
            .required('Please select a type of Valve from the list.'), // Compulsory
        latitude: yup.string().required("Latitude is required")
            .test('is-text-based', 'Please enter a valid latitude.', value => !isNaN(parseFloat(value)))
            .test('is-symbol-based', 'Only numeric values and symbols (-, .) are allowed.', value => /^-?\d*\.?\d*$/.test(value))
            .test('character-limit', 'Value must be a valid latitude format.', value => value.length <= 8),
        longitude: yup.string().required("Longitude is required")
            .test('is-text-based', 'Please enter a valid Longitude.', value => !isNaN(parseFloat(value)))
            .test('is-symbol-based', 'Only numeric values and symbols (-, .) are allowed.', value => /^-?\d*\.?\d*$/.test(value))
            .test('character-limit', 'Value must be a valid Longitude format.', value => value.length <= 8),
        capacity: yup.string()
            .matches(/^\d+$/, 'Please enter a numeric value for pump capacity.') // A) Error for text-based
            .matches(/^\d+$/, 'Only numeric values are allowed.') // B) Error for symbol-based
            .min(1, 'Value must be between 1 to 100000.') // C) Character limit
            .max(100000, 'Value must be between 1 to 100000.') // C) Character limit
            .required('Pump capacity is required'), // E) Compulsory
    });
    const [errors, setErrors] = useState<Partial<FormData>>({});
    const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
    const onSelectChangeChange = async (e: any) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setFormErrors({ ...formErrors, [name]: "" });
        // await FormValidation(formSchema, { ...formData, [name]: value }, setErrors);
    }
    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setFormErrors({ ...formErrors, [name]: "" });
        // await FormValidation(formSchema, { ...formData, [name]: value }, setErrors);
    };

    const valvesData = valve?.selectedValve.data;
    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        // Basic validation
        // await FormValidation(formSchema,formData,setErrors)
        let valveData = {
            project_id: location?.state?.projectData?.id,
            component_id: components?.selectedComponent?.component_id,
            type_of_valves: formData.type,
            number_of_valve: formData.noOfValve,
            size: formData.size,
            compliance_with_applicable_standards: formData.compliance,
            latitude: formData.latitude,
            longitude: formData.longitude
        }
        const errors: { [key: string]: string } = {};
        const requiredFields = ["latitude", "longitude", "type", "noOfValve", "size"];

        requiredFields.forEach((field) => {
            if (!formData[field]) {
                errors[field] = "Required";
            }
        });

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
        } else {
            valvesData ?
                dispatch(editValve({ apiParam: { ...valveData, coordinate_id: valvesData.coordinate_id, id: valvesData.id }, closeModal: dispatch(formPopup(false)) })) :
                dispatch(addValve({ apiParam: valveData, closeModal: dispatch(formPopup(false)) }))
        }
    };
    useEffect(() => {
        if (components.selectedComponent) {
            dispatch(activeComponent(components?.selectedComponent?.name))
            if (components?.selectedComponent?.id) {
                dispatch(fetchValveById(components?.selectedComponent?.id));
            }
            setFormData({
                ...formData,
                latitude: components?.selectedComponent?.latitude,
                longitude: components?.selectedComponent?.longitude
            })
        }
    }, [components.selectedComponent])

    useEffect(() => {

        if (valvesData) {
            setFormData({
                ...valvesData,
                type: valvesData.type_of_valves,
                noOfValve: valvesData.number_of_valve,
                size: valvesData.size,
                compliance: valvesData.compliance_with_applicable_standards,
                latitude: components?.selectedComponent?.latitude,
                longitude: components?.selectedComponent?.longitude
            })

        }
    }, [valvesData]);

    useEffect(() => {
        if (!valvesData) {
            setFormData(resetformData)
        }
    }, [!valvesData])
    return (
        <>
            {
                valve.editValve.load ? <Loading /> : <div className="container bg-white  mx-auto h-[85vh] text-[#1E2022]">
                    {/* <div className="h-[5%]"> */}
                    <div className="flex mb-4 justify-between items-center border-b-2 py-4 px-4">
                        <h3 className="text-base not-italic font-semibold leading-[18.9px] mt-1">Valves Details</h3>
                        <FontAwesomeIcon icon={faXmark}
                            onClick={() => { dispatch(formPopup(false)); setFormData(resetformData) }}
                            className="mt-1 hover:cursor-pointer" />
                    </div>
                    {/* </div> */}
                    <div className="form-height overflow-y-auto ">
                        <form className="grid px-4">
                            {/* <div className="text-black py-4 font-bold">
        <h3 className="">General Information</h3>
      </div> */}
                            <SelectField
                                label="Type of Valves"
                                name="type"
                                type="select"
                                placeholder="Select"
                                value={formData.type}
                                onChange={onSelectChangeChange}
                                error={formErrors.type}
                                options={typeOptions}
                                labelRequired
                            />
                            <FormField
                                label="No. of Valves (nos.)"
                                name="noOfValve"
                                type="text"
                                placeholder="Enter No. Of Valve"
                                value={formData.noOfValve}
                                onChange={handleChange}
                                error={formErrors.noOfValve}
                                labelRequired

                            />
                            <FormField
                                label="Size (mm)"
                                name="size"
                                type="text"
                                placeholder="Enter Size"
                                value={formData.size}
                                onChange={handleChange}
                                error={formErrors.size}
                                labelRequired

                            />
                            <FormField
                                label="Latitude"
                                name="latitude"
                                type="text"
                                placeholder="Enter Latitude"
                                value={formData.latitude}
                                onChange={handleChange}
                                error={formErrors.latitude}
                                labelRequired

                            />
                            <FormField
                                label="Longitude"
                                name="longitude"
                                type="text"
                                placeholder="Enter Longitude"
                                value={formData.longitude}
                                onChange={handleChange}
                                error={formErrors.longitude}
                                labelRequired

                            />



                        </form>
                    </div>
                    {/* <div className="h-[10%] flex justify-end"> */}
                    {/* <div className="basis-2/4"></div> */}
                    <div className="w-full  flex justify-end pr-4 form-footer">
                        <button
                            className=" text-[#8A96A4] bg-white my-4 pt-1 pb-1 px-4  rounded focus:outline-none focus:shadow-outline border border-solid border-[#EEF0F7] hover:bg-[#F8F7F7]  hover:scale-105"
                            type="submit"
                            onClick={() => { dispatch(formPopup(false)); setFormData(resetformData); }}
                        >
                            Cancel
                        </button>
                        <button
                            className=" ml-3 font-normal text-sm bg-[#377DFF] my-4  text-white pt-1 pb-1 px-2 rounded focus:outline-none focus:shadow-outline hover:scale-105"
                            type="submit"
                            onClick={handleSubmit}

                        >
                            {
                                valvesData ? "Update Details" : "  Save Details"
                            }
                        </button>
                    </div>
                    {/* </div> */}
                </div>
            }

        </>
    )
}
export default ValvesDetails
