import React, { useEffect, useRef, useState } from "react";
// import * as echarts from 'echarts';
import "../../assets/css/reusable/newdashboard.css";
import { Carousel } from "react-responsive-carousel";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getWaterqualityIndexData } from "../../redux/slice/monitoring";
import { useLocation } from "react-router-dom";

const DashboardChecklistGraph = () => {
  const dispatch = useAppDispatch();
  const { updatedData, waterqualityIndex } = useAppSelector(
    (state) => state.monitoring
  );
  const location = useLocation();
  const [chartData, setchartData] = useState([]);

  useEffect(() => {
    if (waterqualityIndex?.data?.data) {
      setchartData(waterqualityIndex?.data?.data);
    }
  }, [waterqualityIndex?.data?.data]);

  // const chartRefs = useRef<(HTMLDivElement | null)[]>([]);
  const onChange = (index: any, item: any) => {
    // console.log(`Changed to ${index}`, item);
  };

  const onClickItem = (index: any, item: any) => {
    // console.log(`Clicked item at ${index}`, item);
  };

  const onClickThumb = (index: any, item: any) => {
    // console.log(`Clicked thumb at ${index}`, item);
  };

  // Group data into chunks of 3 items per slide
  const chunkedData = [];
  for (let i = 0; i < chartData.length; i += 5) {
    chunkedData.push(chartData.slice(i, i + 5));
  }
  // useEffect(() => {
  //   chartData.forEach((data, index) => {
  //     const chartDom = chartRefs.current[index];
  //     if (chartDom && data.option) {
  //       const myChart = echarts.init(chartDom);
  //       myChart.setOption(data?.option);
  //     }
  //   });
  // }, [chartData]);
  // useEffect(()=>{

  //   let obj = {
  //     ...monitoring.updatedData,
  //     project_id:location.state.projectData.project_id
  //   }
  //   // console.log(location.state.projectData.project_id,obj);
  //   dispatch(getWaterqualityIndexData(obj))
  // },[monitoring.updatedData,location.state.projectData.project_id])
  useEffect(() => {
    if (location.state.projectData.project_id && updatedData) {
      let data = {
        schedule_time: updatedData.schedule_time,
        schedule_date: updatedData.schedule_date,
        project_id: location.state.projectData.project_id,
      };
      if (data.schedule_time && data.schedule_date && data.project_id) {
        dispatch(getWaterqualityIndexData(data));
      }
    }
  }, [updatedData, location.state.projectData.project_id]);

  return (
    <div className="w-full dashboard-checklist-graph">
      <Carousel
        showArrows={true}
        onChange={onChange}
        onClickItem={onClickItem}
        onClickThumb={onClickThumb}
        className="w-full"
      >
        {chunkedData.map((group, index) => (
          <div key={index} className="w-full grid grid-cols-5">
            {group.map((data: any) => (
              <div key={data?.id} className="p-3">
                <div className="w-full p-4 text-start sub-div">
                  <div>
                    <h2 className="text-[#8C98A4] text-[11.4px] not-italic font-semibold leading-[13.65px] uppercase text-wrap">
                      {data?.component_name}
                    </h2>
                    <div className="text-[#1E2022] text-[21px] not-italic font-semibold leading-[25.2px] pt-4">
                      {data?.count}
                    </div>
                  </div>
                  <div>
                    <button
                      className={`text-[#FFF] font-[Source Sans Pro] text-xs font-bold leading-3 rounded py-[8px] px-[7px]`}
                      style={{
                        backgroundColor:
                          data?.water_quality_class_index_color_code,
                      }}
                    >
                      {/^-?\d+(\.\d+)?$/.test(data?.water_quality_class_index)
                        ? `Index ${data?.water_quality_class_index}`
                        : `Class ${data?.water_quality_class_index}`}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default DashboardChecklistGraph;
