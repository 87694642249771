import React, { useEffect, useState } from "react";
import FormField from '../Forms/FormField'
// import SelectField from '../SelectField'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as yup from 'yup';
// import { FormValidation } from "../../Utils/formValidation";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import '../../assets/css/reusable/form.css'
import SelectField from "../Forms/SelectField";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { activeComponent, formPopup } from "../../redux/slice/Map";
import { addWaterQualityStation, editWaterQualityStation, fetchWaterQualityStationById } from "../../redux/slice/waterQualityStation";
import { useLocation } from "react-router-dom";
import { allRegionData, fetchCountry, fetchDistrict, fetchState } from "../../redux/slice/region";
import Loading from "../../views/pages/Loading";
interface FormData {
    name: string;
    latitude: string;
    longitude: string;
    type: string;
    competentAuthority: string;
    monitoringType: string;
    basinName: string,
    subBasinName: string,
    district: string,
    state: string,
    country: string,
    type_of_source: string
    type_of_source_value: string
}
const WaterQualityStationDetails: any = (props: any) => {
    const components = useAppSelector((state) => state.components)
    const project = useAppSelector((state) => state.project)
    const region = useAppSelector((state) => state.region)
    const waterQualityStation = useAppSelector((state) => state.waterQualityStation)

    const dispatch = useAppDispatch()
    const [form, setForm] = useState("form1");
    const [formData, setFormData] = useState<any>({
        name: "",
        latitude: "",
        longitude: "",
        type: "",
        competentAuthority: "",
        monitoringType: "",
        basinName: "",
        subBasinName: "",
        country: "",
        state: "",
        district: "",
        type_of_source: "",
        type_of_source_value: ""
    });

    const [resetformData, setResetFormData] = useState<FormData>({
        name: "",
        latitude: "",
        longitude: "",
        type: "",
        competentAuthority: "",
        monitoringType: "",
        basinName: "",
        subBasinName: "",
        country: "",
        state: "",
        district: "",
        type_of_source: "",
        type_of_source_value: ""
    });


    const [regionData, setRegionData] = useState({
        country: "",
        state: "",
        district: ""
    })

    const location = useLocation();
    const [regions, setRegions] = useState({
        country: [],
        state: [],
        district: [],
    })

    const typeOptions: any = [{ "value": "Select" }, { "value": "Manual" }, { "value": "Automatic" }]
    const convertedsystemOptions = typeOptions?.map((option: { value: string; }) => option.value);
    const formSchema = yup.object({
        name: yup.string()
            .required('Name of Station is required'),
        type: yup.string()
            .oneOf(convertedsystemOptions, 'Please select a Station Type from the list.') // A) Error for text-based
            .required('Please select a Station Type from the list.'), // Compulsory
        latitude: yup.string().required("Latitude is required")
            .test('is-text-based', 'Please enter a valid latitude.', value => !isNaN(parseFloat(value)))
            .test('is-symbol-based', 'Only numeric values and symbols (-, .) are allowed.', value => /^-?\d*\.?\d*$/.test(value))
            .test('character-limit', 'Value must be a valid latitude format.', value => value.length <= 8),
        longitude: yup.string().required("Longitude is required")
            .test('is-text-based', 'Please enter a valid Longitude.', value => !isNaN(parseFloat(value)))
            .test('is-symbol-based', 'Only numeric values and symbols (-, .) are allowed.', value => /^-?\d*\.?\d*$/.test(value))
            .test('character-limit', 'Value must be a valid Longitude format.', value => value.length <= 8),
        responsibleAgency: yup.string()
            .required('Responsible Agency is required'),
        collectionType: yup.string()
            .required('Collection Type is required'),
        basinName: yup.string()
            .required('Name of Basin is required'),
        subBasinName: yup.string()
            .required('Name of the sub-basin is required'),
        district: yup.string()
            .required('District is required'),
        state: yup.string()
            .required('State is required'),
        country: yup.string()
            .required('Country is required'),
    });
    const [errors, setErrors] = useState<Partial<FormData>>({});
    const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
    const onSelectChangeChange = async (e: any) => {
        const { name, value } = e.target;
        setFormErrors({ ...formErrors, [name]: "" });
        // setFormData({ ...formData, [e.target.name]: e.target.value });

        const selectedCountry = region.country.data.data?.find((item: any) => item.name === value);

        const selectedState = region.state.data.data?.find((item: any) => item.name === value);

        const selectedDistrict = region.district.data.data?.find((item: any) => item.name === value);
        // await FormValidation(formSchema, { ...formData, [name]: value }, setErrors);
        if (name === "country") {
            if (selectedCountry) {
                setFormData({ ...formData, [e.target.name]: selectedCountry.value });
                setRegionData({ ...regionData, [e.target.name]: selectedCountry.id });
                dispatch(fetchState({ country_id: selectedCountry.id }));
            }
        }
        if (name === "monitoringType") {
            setFormData({ ...formData, [e.target.name]: value });
        }
        if (name === "state") {
            if (selectedState) {
                setFormData({ ...formData, [e.target.name]: selectedState.value });
                setRegionData({ ...regionData, [e.target.name]: selectedState.id });
                dispatch(fetchDistrict({ state_id: selectedState.id }));
            }
        }
        if (name === "district") {
            if (selectedDistrict) {
                setFormData({ ...formData, [e.target.name]: selectedDistrict.value });
                setRegionData({ ...regionData, [e.target.name]: selectedDistrict.id });
            }
        }
        if (name === "type_of_source_value") {
            setFormData({ ...formData, [name]: value });
            setFormErrors({ ...formErrors, [name]: "" });
        }
    }

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setFormErrors({ ...formErrors, [name]: "" });
        // await FormValidation(formSchema, { ...formData, [name]: value }, setErrors);
    };

    const waterQualityStationData = waterQualityStation?.selectedWaterQualityStation?.data;


    useEffect(() => {

        if (waterQualityStationData) {
            setFormData({
                // ...waterQualityStationData,
                name: waterQualityStationData.name,
                type: waterQualityStationData.station_type,
                competentAuthority: waterQualityStationData.competent_authority,
                monitoringType: waterQualityStationData.monitoring_type,
                basinName: waterQualityStationData.name_of_basin,
                subBasinName: waterQualityStationData.name_of_sub_basin,
                type_of_source: waterQualityStationData.type_of_source,
                type_of_source_value: waterQualityStationData.type_of_source_value,
                latitude: components?.selectedComponent?.latitude,
                longitude: components?.selectedComponent?.longitude
            });
        }
    }, [waterQualityStationData])

    useEffect(() => {

        if (region.regionData && waterQualityStationData) {
            setFormData({
                ...formData,
                name: waterQualityStationData.name,
                type: waterQualityStationData.station_type,
                competentAuthority: waterQualityStationData.competent_authority,
                monitoringType: waterQualityStationData.monitoring_type,
                basinName: waterQualityStationData.name_of_basin,
                subBasinName: waterQualityStationData.name_of_sub_basin,
                district: region.regionData.district.name,
                state: region.regionData.state.name,
                country: region.regionData.country.name,
                type_of_source: waterQualityStationData.type_of_source,
                type_of_source_value: waterQualityStationData.type_of_source_value,
                latitude: components?.selectedComponent?.latitude,
                longitude: components?.selectedComponent?.longitude
            });
            setRegionData({
                district: region.regionData.district.id,
                state: region.regionData.state.id,
                country: region.regionData.country.id,
            })

        }
    }, [region.regionData, waterQualityStationData])

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        // Basic validation
        // await FormValidation(formSchema, formData, setErrors)
        let waterstationData = {
            project_id: location?.state?.projectData?.id,
            component_id: components?.selectedComponent?.component_id,
            name: formData.name,
            latitude: formData.latitude,
            longitude: formData.longitude,
            station_type: formData.type,
            competent_authority: formData.competentAuthority,
            monitoring_type: formData.monitoringType,
            name_of_basin: formData.basinName,
            name_of_sub_basin: formData.subBasinName,
            district_id: regionData.district,
            state_id: regionData.state,
            country_id: regionData.country,
            type_of_source: formData.type_of_source,
            type_of_source_value: formData.type_of_source_value,
        }

        const errors: { [key: string]: string } = {};
        const requiredFields = ["name", "latitude", "longitude", "type", "competentAuthority", "monitoringType", "basinName", "subBasinName", "district", "state", "country", "type_of_source", "type_of_source_value"];


        requiredFields.forEach((field) => {
            if (field == "district" || field == "state" || field == "country") {
                if (!regionData[field]) {
                    errors[field] = "Required";
                }
            } else {
                if (!formData[field]) {
                    errors[field] = "Required";
                }
            }

        });


        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
        } else {
            waterQualityStationData ?
                dispatch(editWaterQualityStation({ apiParam: { ...waterstationData, coordinate_id: waterQualityStationData.coordinate_id, id: waterQualityStationData?.id, state_id: regionData.state }, closeModal: dispatch(formPopup(false)) })) :
                dispatch(addWaterQualityStation({
                    apiParam: waterstationData,
                    closeModal: dispatch(formPopup(false))
                }))
        }
    }

    useEffect(() => {
        if (components.selectedComponent) {
            dispatch(activeComponent(components?.selectedComponent?.name))
            if (components?.selectedComponent?.id) {
                dispatch(fetchWaterQualityStationById(components?.selectedComponent?.id))
                // dispatch(fetchCountry());

            }
            setFormData({
                ...formData,
                latitude: components?.selectedComponent?.latitude,
                longitude: components?.selectedComponent?.longitude
            })
        }
    }, [components.selectedComponent])


    useEffect(() => {

        if (waterQualityStation?.selectedWaterQualityStation?.data) {
            const selectedCountry = region?.country?.data?.data?.find((item: any) => item.id === waterQualityStationData?.country_id);
            if (selectedCountry?.id) {
                dispatch(fetchState({ country_id: selectedCountry?.id }));
            }
            if (waterQualityStationData) {
                setFormData({
                    ...formData,
                    name: waterQualityStationData.name,
                    type: waterQualityStationData.station_type,
                    competentAuthority: waterQualityStationData.competent_authority,
                    monitoringType: waterQualityStationData.monitoring_type,
                    basinName: waterQualityStationData.name_of_basin,
                    subBasinName: waterQualityStationData.name_of_sub_basin,
                    type_of_source: waterQualityStation.type_of_source,
                    type_of_source_value: waterQualityStation.type_of_source_value,
                    // district: waterQualityStationData.district_id,
                    // state: selectedState?.name,
                    // country: selectedCountry?.name,
                    latitude: components?.selectedComponent?.latitude,
                    longitude: components?.selectedComponent?.longitude
                });
                setRegionData({
                    ...regionData,
                    country: selectedCountry?.id,
                })



            }
        }

    }, [waterQualityStation, region?.country?.data?.data, waterQualityStationData])




    useEffect(() => {
        // alert(JSON.stringify(waterQualityStationData))
        dispatch(fetchCountry());

    }, []);
    useEffect(() => {
        // alert(JSON.stringify(waterQualityStationData))
        dispatch(fetchCountry());

    }, [waterQualityStation?.selectedWaterQualityStation?.data]);

    useEffect(() => {
        // alert(JSON.stringify(waterQualityStationData))
        if (waterQualityStationData) {
            dispatch(allRegionData({ country_id: waterQualityStationData?.country_id, state_id: waterQualityStationData?.state_id, district_id: waterQualityStationData?.district_id }))
        }

    }, [waterQualityStationData]);


    useEffect(() => {
        if (region.country.data.data) {

            let data = region?.country?.data?.data?.map((item: { id: any; name: any; shortname: any; phone_code: any; status: any; created_at: any; updated_at: any; }) => ({
                ...item,
                value: item.name,
            }));

            setRegions({
                ...regions,
                country: data
            })
        }


    }, [region?.country?.data?.data]);

    useEffect(() => {
        if (region.state.data.data) {
            const stateOptions = region.state.data.data?.map((item: any) => ({
                ...item,
                value: item.name,
            }));
            setRegions(prevRegions => ({
                ...prevRegions,
                state: stateOptions,
            }));
        }
    }, [region.state.data.data]);

    useEffect(() => {
        if (region.district.data.data) {
            const districtOptions = region.district.data.data?.map((item: any) => ({
                ...item,
                value: item.name,
            }));
            setRegions(prevRegions => ({
                ...prevRegions,
                district: districtOptions,
            }));
        }
    }, [region.district.data.data]);

    useEffect(() => {
        if (!waterQualityStationData) {
            dispatch(fetchCountry());
        }
    }, [!waterQualityStationData]);
    const sourceTypeOptions: any = [{ "value": "Select" }, { "value": "River" }, { "value": "Lake" }, { "value": "Tank" }, { "value": "Reservoir" }]

    const groundTypeOptions: any = [{ "value": "Select" }, { "value": "Open Well" }, { "value": "Bore Well" }]
    const handleChangeRadio = (event: any, form: any) => {
        setForm(form)
        setFormData({
            ...formData,
            type_of_source: event.target.value,
        })
        setFormErrors({ ...formErrors, [event.target.name]: "" });
    };




    return (
        <>
            {
                waterQualityStation.editWaterQualityStation.load ? <Loading /> : <div className="container bg-white  mx-auto h-[85vh] text-[#1E2022]">
                    {/* <div className="h-[5%]"> */}

                    <div className="flex mb-4 justify-between items-center border-b-2 py-4 px-4">
                        <h3 className="text-base not-italic font-semibold leading-[18.9px] mt-1">Water Quality Station</h3>
                        <FontAwesomeIcon icon={faXmark}
                            onClick={() => { dispatch(formPopup(false)); setFormData(resetformData) }}
                            className="mt-1 pr-4 hover:cursor-pointer" />
                    </div>
                    {/* </div> */}
                    <div className="form-height overflow-y-auto ">

                        <form className="grid px-4">
                            <div className="flex flex-row text-black mb-3">
                                <h3 className="basis-5/6 font-bold text-[15px]">River Basin Details</h3>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="" className="text-[#1E2022] text-xs not-italic font-medium leading-[21px]">Type of Source <span className="text-[#ED4C78]">*</span></label> <br />
                                <div className="flex mt-3">
                                    <div className="flex items-center">
                                        <label className=" text-[#1E2022] text-xs not-italic font-normal leading-5 flex items-center" onChange={(e: any) => { handleChangeRadio(e, "form1") }}>
                                            <input type="radio" name="type_of_source" id="" checked={formData.type_of_source === 'Surface Water'} value="Surface Water" className="border-0 w-4 h-4 ml-2" /><span className="ml-2">Surface Water </span> </label>
                                    </div>
                                    <div className="flex items-center">
                                        <label className="text-[#1E2022] text-xs not-italic font-normal leading-5 flex items-center" onChange={(e: any) => { handleChangeRadio(e, "form2") }}>
                                            <input type="radio" name="type_of_source" id="" value="Ground Water" checked={formData.type_of_source === 'Ground Water'} className="border-0 w-4 h-4 ml-6" /><span className="ml-2">Ground Water</span> </label></div>
                                </div>
                                {formErrors.type_of_source && <p className="text-red-500 text-xs italic">{formErrors.type_of_source}</p>}

                            </div>
                            {
                                form === 'form1' && formData.type_of_source == 'Surface Water' ?
                                    <SelectField
                                        label="Type of Surface Water"
                                        name="type_of_source_value"
                                        type="select"
                                        placeholder="Select"
                                        value={formData.type_of_source_value}
                                        onChange={onSelectChangeChange}
                                        error={formErrors.type_of_source_value}
                                        options={sourceTypeOptions}
                                        labelRequired
                                    /> :
                                    <SelectField
                                        label="Type of Ground Water "
                                        name="type_of_source_value"
                                        type="select"
                                        placeholder="Select"
                                        value={formData.type_of_source_value}
                                        onChange={onSelectChangeChange}
                                        error={formErrors.type_of_source_value}
                                        options={groundTypeOptions}
                                        labelRequired
                                    />
                            }

                            <FormField
                                label="Name of the Basin"
                                name="basinName"
                                type="text"
                                placeholder="Enter Name of the Basin"
                                value={formData.basinName}
                                onChange={handleChange}
                                error={formErrors.basinName}
                                labelRequired
                            />
                            <FormField
                                label="Name of the sub-basin"
                                name="subBasinName"
                                type="text"
                                placeholder="Enter Name of the sub-basin"
                                value={formData.subBasinName}
                                onChange={handleChange}
                                error={formErrors.subBasinName}
                                labelRequired

                            />

                            <SelectField
                                label="Country"
                                name="country"
                                type="select"
                                placeholder="Select"
                                value={formData.country}
                                onChange={(e: any) => { onSelectChangeChange(e) }}
                                error={formErrors.country}
                                labelRequired
                                options={regions.country}

                            />

                            <SelectField
                                label="State"
                                name="state"
                                type="select"
                                placeholder="Select"
                                value={formData.state}
                                onChange={onSelectChangeChange}
                                error={formErrors.state}
                                options={regions.state}
                                labelRequired

                            />

                            <SelectField
                                label="District"
                                name="district"
                                type="select"
                                placeholder="Select"
                                value={formData.district}
                                onChange={onSelectChangeChange}
                                error={formErrors.district}
                                options={regions.district}
                                labelRequired

                            />

                            <div className="flex flex-row text-black mb-3">
                                <h3 className="basis-5/6 font-bold text-[15px]">Station Details</h3>
                            </div>
                            <FormField
                                label="Name of Station"
                                name="name"
                                type="text"
                                placeholder="Enter Name of Station"
                                value={formData.name}
                                onChange={handleChange}
                                error={formErrors.name}
                                labelRequired

                            />
                            <FormField
                                label="Latitude"
                                name="latitude"
                                type="text"
                                placeholder="Enter Latitude"
                                value={formData.latitude}
                                onChange={handleChange}
                                error={formErrors.latitude}
                                labelRequired

                            />
                            <FormField
                                label="Longitude"
                                name="longitude"
                                type="text"
                                placeholder="Enter Longitude"
                                value={formData.longitude}
                                onChange={handleChange}
                                error={formErrors.longitude}
                                labelRequired

                            />
                            {/* <SelectField
                        label="Station Type"
                        name="type"
                        type="select"
                        placeholder="Select"
                        value={formData.type}
                        onChange={onSelectChangeChange}
                        error={formErrors.type}
                        options={typeOptions}
                    /> */}
                            <FormField
                                label="Station Type"
                                name="type"
                                type="text"
                                placeholder="Enter Station Type"
                                value={formData.type}
                                onChange={handleChange}
                                error={formErrors.type}
                                labelRequired

                            />
                            <FormField
                                label="Competent Authority"
                                name="competentAuthority"
                                type="text"
                                placeholder="Enter Competent Authority"
                                value={formData.competentAuthority}
                                onChange={handleChange}
                                error={formErrors.competentAuthority}
                                labelRequired

                            />
                            <SelectField
                                label="Monitoring type"
                                name="monitoringType"
                                type="select"
                                placeholder="Select"
                                value={formData.monitoringType}
                                onChange={onSelectChangeChange}
                                error={formErrors.monitoringType}
                                options={typeOptions}
                                labelRequired

                            />

                        </form>
                    </div>
                     {/* <div className="h-[10%] flex justify-end"> */}
                    {/* <div className="basis-2/4"></div> */}
                    <div className="w-full  flex justify-end pr-8 form-footer">
                        <button
                            className=" text-[#8A96A4] bg-white my-4 pt-1 pb-1 px-4  rounded focus:outline-none focus:shadow-outline border border-solid border-[#EEF0F7] hover:bg-[#F8F7F7]  hover:scale-105"
                            type="submit"
                            onClick={() => { dispatch(formPopup(false)); setFormData(resetformData) }}
                        >
                            Cancel
                        </button>
                        <button
                            className=" ml-3 font-normal text-sm bg-[#377DFF] my-4  text-white pt-1 pb-1 px-2 rounded focus:outline-none focus:shadow-outline hover:scale-105"
                            type="submit"
                            onClick={handleSubmit}
                        >
                            {
                                waterQualityStationData ? "Update Details" : "Save Details"
                            }

                        </button>
                    </div>
                    {/* </div> */}
                </div>
            }

        </>
    )
}
export default WaterQualityStationDetails
