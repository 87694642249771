import React, { useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';

interface DataRow {
    id: number;
    title: string;
    year: number;
    isSelected: boolean;
}

const Network: React.FC = () => {
    const columns: TableColumn<DataRow>[] = [
        { name: 'Title', selector: row => row.title, sortable: true },
        { name: 'Year', selector: row => row.year, sortable: true, right: true },
    ];

    const data: DataRow[] = [
        { id: 1, title: 'Conan the Barbarian', year: 1982, isSelected: true },
        { id: 2, title: 'The Terminator', year: 1984, isSelected: false },
        { id: 3, title: 'Predator', year: 1987, isSelected: true },
    ];

    const paginationComponentOptions = {
        rowsPerPageText: 'Rows per page:',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };

    const rowSelectCriteria = (row: DataRow) => true;
    return (
        <DataTable<DataRow>
            title="Movie List"
            columns={columns}
            data={data}
            pagination
            paginationTotalRows={data.length}
            paginationComponentOptions={paginationComponentOptions}
            selectableRows
            selectableRowSelected={rowSelectCriteria}
            // highlightOnHover
            // striped
            onSelectedRowsChange={(state) => console.log(state.selectedRows)}
        />
    );
};

export default Network;
