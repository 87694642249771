import React, { useState } from "react";
import PageTitle from "../PageTitle";
import "../../assets/css/reusable/screenmonitoring.css";
// import SelectField from '../Forms/SelectField'
import ReactECharts from "echarts-for-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faLightEmergency,
  faLightEmergencyOn,
} from "@fortawesome/pro-regular-svg-icons";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { fetchMonitoringStats } from "../../redux/slice/monitoring";
interface FormData {
  standard: string;
}

const CodeComplianceChart = () => {
  const monitoring = useAppSelector((state) => state.monitoring);
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<FormData>({
    standard: "",
  });

  const onSelectChangeChange = async (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    let data = {
      ...monitoring.updatedData,
      standard: value == "USEPA" ? "EPA" : value,
    };
    dispatch(fetchMonitoringStats(data));
    // await FormValidation(formSchema, { ...formData, [name]: value }, setErrors);
  };
  const options = [
    { value: "BIS" },
    { value: "WHO" },
    { value: "USEPA" },
    { value: "R&D" },
  ];

  // code fot chart
  const option1 = {
    color: ["#EF746D", "#F0F0F0"],
    tooltip: {
      trigger: "item",
      className: "custom-tooltip",
    },
    series: [
      {
        name: "Above Permissible Limit",
        type: "pie",
        radius: ["60%", "90%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 10,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          // { value: monitoring?.screenStats?.data?.getSeverityGraph?.original.data.GraterthanLimit, name: '' },
          // { value: 100 - monitoring?.screenStats?.data?.getSeverityGraph?.original.data.GraterthanLimit, name: '' }

          {
            value:
              monitoring?.screenStats?.data?.getSeverityGraph?.original.data
                .GraterthanLimit ?? 0,
            name: "",
          },
          {
            value:
              100 -
                monitoring?.screenStats?.data?.getSeverityGraph?.original.data
                  .GraterthanLimit ?? 0,
            name: "",
          },
        ],
      },
    ],
  };
  const option2 = {
    color: ["#1784EB", "#F0F0F0"],
    tooltip: {
      trigger: "item",
    },
    series: [
      {
        name: "Below Permissible Limit",
        type: "pie",
        radius: ["60%", "90%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 10,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          // { value: monitoring?.screenStats?.data?.getSeverityGraph?.original.data.CriticalLimit, name: '' },
          // { value: 100 - monitoring?.screenStats?.data?.getSeverityGraph?.original.data.CriticalLimit, name: '' }
          {
            value:
              monitoring?.screenStats?.data?.getSeverityGraph?.original.data
                .CriticalLimit ?? 0,
            name: "",
          },
          {
            value:
              100 -
              (monitoring?.screenStats?.data?.getSeverityGraph?.original.data
                .CriticalLimit ?? 0),
            name: "",
          },
        ],
      },
    ],
  };
  const option3 = {
    color: ["#429482", "#F0F0F0"],
    tooltip: {
      trigger: "item",
    },
    series: [
      {
        name: "Within Permissible Limit",
        type: "pie",
        radius: ["60%", "90%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 10,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          // { value: monitoring?.screenStats?.data?.getSeverityGraph?.original.data.WithinLimit, name: '' },
          // { value: 100 - monitoring?.screenStats?.data?.getSeverityGraph?.original.data.WithinLimit, name: '' }

          {
            value:
              monitoring?.screenStats?.data?.getSeverityGraph?.original.data
                .WithinLimit ?? 0,
            name: "",
          },
          {
            value:
              100 -
              (monitoring?.screenStats?.data?.getSeverityGraph?.original.data
                .WithinLimit ?? 0),
            name: "",
          },
        ],
      },
    ],
  };
  return (
    // bg-[#5a5a62]
    // backdrop-blur-sm border-[#5a5a62] border border-solid
    <div className="flex flex-wrap w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full p-4 rounded-[7.452px]  code-compliance-chart h-[254px] bg-[#222230] text-[#FFF] border-transparent">
      <div className="flex flex-wrap w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full border-b border-[#4A4A68] h-10">
        <div className="w-1/3">
          {/* <PageTitle title="Code Compliance" className="text-[#FFF]"></PageTitle> */}
          <h1 className="text-[#FFF] font-bold text-[22px] mt-1">
            Severity scale
          </h1>
        </div>
        <div className="w-1/3 flex flex-wrap justify-end -mt-2">
          <span className="text-[11px] not-italic font-medium leading-normal py-4 text-[#D2D2DF] -ml-4">
            Choose Water Quality Standard
          </span>
        </div>
        <div className="w-1/3 flex flex-wrap">
          <span className="ml-3 mr-1 w-full">
            {/* <SelectField
                            label="Type of structure"
                            name="type"
                            type="select"
                            placeholder="Select"
                            value={formData.type}
                            onChange={onSelectChangeChange}
                            // error={errors.type}
                            options={options}
                            // labelRequired
                        /> */}

            {/* <label htmlFor="country" className="block text-[#1E2022] text-xs not-italic font-medium leading-[21px]">
                            Sampling Frequency<span className="text-[#ED4C78]">* </span>
                        </label> */}

            <div className="w-full relative"></div>
            <select
              key="standard"
              id="standard"
              name="standard"
              // placeholder=""
              autoComplete="standard"
              value={formData.standard}
              onChange={onSelectChangeChange}
              className={`code-compliance-chart-arrow select-field text-xs not-italic font-medium leading-[21px] placeholder:text-[#8C98A4] placeholder:text-xs placeholder:not-italic placeholder:font-medium placeholder:leading-[21px] block w-[100%] h-[32px] rounded-md border-0   
                        shadow-sm ring-1 ring-inset focus:ring-1 appearance-none sm:text-xs sm:leading-6 ring-[#4A4A68] select-field bg-[#4A4A68]`}
            >
              <option className="capitalize w-[100%] text-[10px] not-italic font-medium leading-[21px]">
                Select
              </option>
              {options.map((opt) => (
                <>
                  <option className="capitalize w-[100%] text-[10px] not-italic font-medium leading-[21px]">
                    {opt.value}
                  </option>
                </>
              ))}
            </select>
          </span>
        </div>
      </div>
      <div className="flex flex-wrap w-full items-center justify-around">
        <div>
          <div className="relative">
            <div className="font-[inter] text-[14px] not-italic font-bold leading-[21px] absolute h-full w-full flex items-center justify-center">
              {monitoring?.screenStats?.data?.getSeverityGraph?.original.data
                .GraterthanLimit
                ? monitoring?.screenStats?.data?.getSeverityGraph?.original.data
                    .GraterthanLimit
                : "0"}
              %
            </div>
            <ReactECharts
              option={option1}
              style={{ height: 130, width: "100%" }}
            />
          </div>
          <div className="flex justify-center font-[inter] text-[12px] not-italic font-medium leading-[12px] mt-4">
            Above Permissible Limit
          </div>
        </div>

        <div>
          <div className="relative">
            <div className="font-[inter] text-[14px] not-italic font-bold leading-[21px] absolute h-full w-full flex items-center justify-center">
              {monitoring?.screenStats?.data?.getSeverityGraph?.original.data
                ?.CriticalLimit
                ? monitoring.screenStats.data.getSeverityGraph.original.data
                    .CriticalLimit
                : "0"}
              %
            </div>
            <ReactECharts
              option={option2}
              style={{ height: 130, width: "100%" }}
            />
          </div>
          <div className="flex justify-center font-[inter] text-[12px] not-italic font-medium leading-[12px] mt-4">
            Below Permissible Limit
          </div>
        </div>

        <div>
          <div className="relative">
            <div className="font-[inter] text-[14px] not-italic font-bold leading-[21px] absolute h-full w-full flex items-center justify-center">
              {monitoring?.screenStats?.data?.getSeverityGraph?.original.data
                .WithinLimit
                ? monitoring?.screenStats?.data?.getSeverityGraph?.original.data
                    .WithinLimit
                : "0"}
              %
            </div>
            <ReactECharts
              option={option3}
              style={{ height: 130, width: "100%" }}
            />
          </div>
          <div className="flex justify-center font-[inter] text-[12px] not-italic font-medium leading-[12px] mt-4">
            Within Permissible Limit
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodeComplianceChart;
