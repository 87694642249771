import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DataServer } from "../../../config.axios";

export interface ChatResponse {
    message: string;
}

interface ChatState {
    chat: {
        load: boolean;
        data: ChatResponse[];
    };
}

const initialState = {
    chat: {
        load: false,
        data: [],
    },
} as ChatState;

export const chatSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {
        resetChatData: (state) => {
            state.chat = initialState.chat;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchChatResponse.pending, (state) => {
                state.chat.load = true;
            })
            .addCase(fetchChatResponse.fulfilled, (state, action) => {
                state.chat.data.push(action.payload);
                state.chat.load = false;
            })
            .addCase(fetchChatResponse.rejected, (state) => {
                state.chat.load = false;
            });
    },
});

export const { resetChatData } = chatSlice.actions;
export default chatSlice.reducer;

export const fetchChatResponse = createAsyncThunk(
    "chat/fetchChatResponse",
    async (message: string, thunkAPI) => {
        try {
            const { data } = await DataServer.post("/api/generate-response", {
                message: message
            });
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);
