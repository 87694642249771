import React from 'react'
// import MapviewSM from '../MapViewSM'
import '../../assets/css/reusable/screenmonitoring.css'
import MapDashboard from '../MapDashboard'

const Mapview = ({ activeTab }: any) => {
  return (
    <div className='w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full mapview'>
      <div className='w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full'>
        <MapDashboard activeTab={activeTab} />

      </div>
      
    </div>
  )
}

export default Mapview
 
