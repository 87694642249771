import React, { useEffect, useState } from "react";
// import '../../../assets/css/reusable/screenmonitoring.css'
import Header from "../../../layout/appHeader/header";
import Dashboard from "../../../reusable/DashboardTab";
import Mapview from "../../../reusable/MapView";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  fetchCompliance,
  fetchMonitoringStats,
} from "../../../redux/slice/monitoring";
import { useLocation } from "react-router-dom";

const ScreenMonitoring = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState("tab1");
  const monitoring = useAppSelector((state) => state.monitoring);

  useEffect(() => {
    let data = {
      ...monitoring.updatedData,

      // schedule_date: 2024-02-04
      // schedule_time: 16:17
      // coordinate_id: 978
      // component_horizontal_tab_id: 1015
    };
    dispatch(fetchMonitoringStats(data));
    // dispatch(fetchCompliance(data))
  }, [monitoring.updatedData]);

  return (
    <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full screen-monitoring h-[95vh]">
      <div>
        {" "}
        <Header />
      </div>
      <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full flex px-4 relative z-50">
        <div className="w-2/12 absolute top-3 right-3">
          <div className="tab w-full rounded-full ">
            <div
              className={`${
                activeTab === "tab1"
                  ? "tablinks active bg-[#44444F] text-[#FFF]"
                  : "tablinks mb-[4px]"
              } w-1/2 py-[5px] px-[10px]`}
              onClick={() => {
                setActiveTab("tab1");
              }}
            >
              Mapview
            </div>
            <div
              className={`${
                activeTab === "tab2"
                  ? "tablinks active bg-[#1784EB] text-[#FFF]"
                  : "tablinks mb-[4px]"
              } w-1/2  py-[5px] px-[10px]`}
              onClick={() => {
                setActiveTab("tab2");
              }}
            >
              Dashboard
            </div>
            <div
              className={`${
                activeTab === "tab3" ? "tablinks" : "tablinks mb-[4px] "
              }  w-[14%] px-4 py-[5px] mx-2 i-btn`}
            >
              {/* onClick={() => { setActiveTab('tab3') }} */}i
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex ">
        <div
          className={activeTab === "tab1" ? "tabcontent active" : "tabcontent"}
        >
          <Mapview activeTab={activeTab} />
        </div>
        <div
          className={`${
            activeTab === "tab2" ? "tabcontent active" : "tabcontent"
          }`}
        >
          <Dashboard activeTab={activeTab} />
        </div>
        {/* <div className={`${activeTab === 'tab3' ? "tabcontent active" : "tabcontent"}`}>
                    <h1>i</h1>
                </div> */}
      </div>
    </div>
  );
};

export default ScreenMonitoring;
