import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DataServer } from "../../../config.axios";
import { toast } from "react-toastify";
export interface checklistParameterCategories {
    id: number,
    name: string,
}
export interface checklistSubCategories {
    id: number,
    type: string,
    category_id: number,
    subcategory_id: any,
    name: string
}
export interface horizontalTab {
    id: number,
    name: string,
    coordinate_id: number,
    status: any,
    is_selected: boolean,
    type: string
}
export interface checklistParameterInterface {
    test_location: string,
    id: number
}
export interface checklistDataInterface {
    coordinate_id: number,
    category_id: number,
    sub_category_id: any,
    component_checklist_horizontal_tab_id: number,
    occurrence: string,
    frequency: number
    checklist_parameter_id: Array<checklistParameterInterface>
    deleted_parameters: Array<any>,
    timeline: Array<any>
    Population_served: string
}

interface ChecklistState {
    checklistCategory: {
        load: boolean;
        data: Array<checklistParameterCategories>;
    };
    fetchChecklistData: any;
    checklistCategoryData?: checklistParameterCategories;
    checklistsubCategoryData?: checklistParameterCategories;
    checklistSubCategory: {
        load: boolean;
        data: Array<checklistSubCategories>;
    };
    checklistSubParameter: {
        load: boolean;
        data: Array<checklistSubCategories>;
    };
    checklistSubCategorySelected?: checklistSubCategories;
    horizontalTab: {
        load: boolean;
        data: Array<horizontalTab>;
    };
    horizontalTabSelected?: horizontalTab;
    treatmenthorizontalTab: {
        load: boolean;
        data: Array<horizontalTab>;
    };
    treatmenthorizontalTabSelected?: horizontalTab;
    checklistData: checklistDataInterface


}
const initialState = {
    checklistCategory: {
        load: false,
        data: [],
    },
    checklistCategoryData: {
        id: 1,
        name: "General",
    },
    checklistsubCategoryData: {
        id: "",
        name: "",
    },
    checklistSubCategory: {
        load: false,
        data: [],
    },
    checklistSubParameter: {
        load: false,
        data: [],
    },
    checklistSubCategorySelected: {
        id: null,
        type: "",
        category_id: "",
        subcategory_id: null,
        name: ""
    },
    horizontalTab: {
        load: false,
        data: [],
    },
    horizontalTabSelected: {
        id: null,
        name: "",
        coordinate_id: "",
        status: "",
        is_selected: false,
        type: ""
    },
    treatmenthorizontalTab: {
        load: false,
        data: [],
    },
    treatmenthorizontalTabSelected: {
        id: null,
        name: "",
        coordinate_id: "",
        status: "",
        is_selected: false,
        type: ""
    },
    checklistData: {
        coordinate_id: "",
        category_id: "",
        sub_category_id: '',
        component_checklist_horizontal_tab_id: "",
        occurrence: "",
        frequency: "",
        checklist_parameter_id: [],
        deleted_parameters: [],
        timeline: [],
        population_served: ""
    }

} as unknown as ChecklistState;
export const checklistSlice = createSlice({
    name: "checklist",
    initialState,
    reducers: {
        fetchChecklistSelected: (state: any, action: any) => {
            state.checklistCategoryData = action.payload;
        },
        fetchChecklistSubSelected: (state: any, action: any) => {
            state.checklistSubCategoryData = action.payload;
        },
        fetchHorizontalSelected: (state: any, action: any) => {
            state.horizontalTabSelected = action.payload;
        },
        fetchTreatmentHorizontalSelected: (state: any, action: any) => {
            state.treatmenthorizontalTabSelected = action.payload;
        },
        updateChecklist: (state: any, action: any) => {
            state.checklistData = action.payload;
        },
        resetChecklist: (state: any) => {
            state.checklistData = initialState.checklistData;
        },
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchChecklistCategory.pending, (state: ChecklistState) => {
                state.checklistCategory.load = true;
            })
            .addCase(fetchChecklistCategory.fulfilled, (state: ChecklistState, action: any) => {
                state.checklistCategory.data = action.payload;
                state.checklistCategory.load = false;
            })
            .addCase(fetchChecklistCategory.rejected, (state: ChecklistState) => {
                state.checklistCategory.load = false;
            })
            .addCase(fetchChecklistSubCategory.pending, (state: ChecklistState) => {
                state.checklistSubCategory.load = true;
            })
            .addCase(fetchChecklistSubCategory.fulfilled, (state: ChecklistState, action: any) => {
                state.checklistSubCategory.data = action.payload;
                state.checklistSubCategory.load = false;
                state.checklistSubParameter.data = initialState.checklistSubParameter.data;
            })
            .addCase(fetchChecklistSubCategory.rejected, (state: ChecklistState) => {
                state.checklistSubCategory.load = false;
            })
            .addCase(fetchHorizontalTab.pending, (state: ChecklistState) => {
                state.horizontalTab.load = true;
            })
            .addCase(fetchHorizontalTab.fulfilled, (state: ChecklistState, action: any) => {
                state.horizontalTab.data = action.payload;
                state.horizontalTabSelected = action.payload[0]
                state.horizontalTab.load = false;
            })
            .addCase(fetchHorizontalTab.rejected, (state: ChecklistState) => {
                state.horizontalTab.load = false;
            })
            .addCase(fetchHorizontalTabSelcted.pending, (state: ChecklistState) => {
                state.horizontalTab.load = true;
            })
            .addCase(fetchHorizontalTabSelcted.fulfilled, (state: ChecklistState, action: any) => {
                state.horizontalTab.data = action.payload;
                // state.horizontalTabSelected = action.payload[0]
                state.horizontalTab.load = false;
            })
            .addCase(fetchHorizontalTabSelcted.rejected, (state: ChecklistState) => {
                state.horizontalTab.load = false;
            })
            .addCase(fetchTreatmentHorizontalTab.pending, (state: ChecklistState) => {
                state.treatmenthorizontalTab.load = true;
            })
            .addCase(fetchTreatmentHorizontalTab.fulfilled, (state: ChecklistState, action: any) => {
                state.treatmenthorizontalTab.data = action.payload;
                state.treatmenthorizontalTabSelected = action.payload[0]
                state.treatmenthorizontalTab.load = false;
            })
            .addCase(fetchTreatmentHorizontalTab.rejected, (state: ChecklistState) => {
                state.treatmenthorizontalTab.load = false;
            })
            .addCase(fetchChecklistSubCategoryParameter.pending, (state: ChecklistState) => {
                state.checklistSubParameter.load = true;
            })
            .addCase(fetchChecklistSubCategoryParameter.fulfilled, (state: ChecklistState, action: any) => {
                state.checklistSubParameter.data = action.payload;
                state.checklistSubParameter.load = false;
                state.checklistSubCategory.data = initialState.checklistSubCategory.data
            })
            .addCase(fetchChecklistSubCategoryParameter.rejected, (state: ChecklistState) => {
                state.checklistSubParameter.load = false;
            })
            .addCase(fetchChecklist.pending, (state: ChecklistState) => {
                // state.fetchChecklist.load = true;
            })
            .addCase(fetchChecklist.fulfilled, (state: ChecklistState, action: any) => {
                state.fetchChecklistData = action.payload == null ? state.fetchChecklistData : action.payload
                // state.fetchChecklist.load = false;
            })
            .addCase(fetchChecklist.rejected, (state: ChecklistState) => {
                // state.fetchChecklist.load = false;
            })

    },
});
export const { fetchChecklistSelected, fetchChecklistSubSelected, fetchHorizontalSelected, fetchTreatmentHorizontalSelected, updateChecklist, resetChecklist } = checklistSlice.actions;

export default checklistSlice.reducer;
export const fetchChecklistCategory = createAsyncThunk(
    "checklist/fetchChecklistCategory",
    async () => {
        try {
            const { data } = await DataServer.get("api/checklist-parameter-categories");
            return data;
        } catch (error) {
            return error;
        }
    }
);

export const fetchChecklistSubCategory = createAsyncThunk(
    "checklist/fetchChecklistSubCategory",
    async (param: number, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/checklist-parameter-subcategories", {
                category_id: param
            });
            return data;
        } catch (error) {
            return error;
        }
    }
);

export const fetchHorizontalTab = createAsyncThunk(
    "checklist/fetchHorizontalTab",
    async (param: number, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/component-checklist-horizontal-tab-list", {
                coordinate_id: param
            });
            return data;
        } catch (error) {
            return error;
        }
    }
);

export const fetchHorizontalTabSelcted = createAsyncThunk(
    "checklist/fetchHorizontalTabSelcted",
    async (param: number, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/component-checklist-horizontal-tab-list", {
                coordinate_id: param
            });
            return data;
        } catch (error) {
            return error;
        }
    }
);

export const fetchTreatmentHorizontalTab = createAsyncThunk(
    "checklist/fetchTreatmentHorizontalTab",
    async (param: number, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/component-checklist-horizontal-tab-list-to-select", {
                coordinate_id: param
            });

            return data;
        } catch (error) {
            return error;
        }
    }
);

export const updatehorozontalTab = createAsyncThunk(
    "checklist/updatehorozontalTab",
    async (param: Array<number>, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/update-component-checklist-horizontal-tab", {
                id: param
            });
            return data;
        } catch (error) {
            return error;
        }
    }
);

export const checklistAssignUser = createAsyncThunk(
    "checklist/checklistAssignUser",
    async (param: any, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/checklist-assigned-user", param);
            return data;
        } catch (error) {
            return error;
        }
    }
);

export const checklistStore = createAsyncThunk(
    "checklist/checklistStore",
    async (param: any, thunkAPI) => {
        try {
            let paramdata: any = { ...param }
            if (param.occurrence == "Daily") {
                paramdata.timeline = ["Daily"]
            }
            const { data } = await DataServer.post("api/store-selected-checklist", paramdata);
            toast.success(data.message, {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });
            thunkAPI.dispatch(fetchChecklist({
                coordinate_id: paramdata.coordinate_id,
                category_id: paramdata.category_id,
                sub_category_id: paramdata.sub_category_id ? paramdata.sub_category_id : null,
                component_checklist_horizontal_tab_id: paramdata?.component_checklist_horizontal_tab_id ? paramdata?.component_checklist_horizontal_tab_id : null,


            }))
            return data;
        } catch (error) {
            return error;
        }
    }
);

export const fetchChecklistSubCategoryParameter = createAsyncThunk(
    "checklist/fetchChecklistSubCategoryParameter",
    async (param: number, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/checklist-parameters",
                param
            )
            return data;
        } catch (error) {
            return error;
        }
    }
);


export const finalChecklist = createAsyncThunk(
    "checklist/finalChecklist",
    async (param: any, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/final-submit-checklist", {
                coordinate_id: param
            });
            toast.success(data.message, {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });
            return data;
        } catch (error) {
            return error;
        }
    }
);


export const fetchChecklist = createAsyncThunk(
    "checklist/fetchChecklist",
    async (param: any, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/get-selected-checklist", param);
            // toast.success(data.message, {
            //     position: "top-right",
            //     autoClose: 5000,
            //     theme: "dark",
            // });
            return data;
        } catch (error) {
            return error;
        }
    }
);

export const addChecklistFlag = createAsyncThunk(
    "checklist/addChecklistFlag",
    async (param: any, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/add-checklist-flag", { component_checklist_horizontal_tab_id: param.horizontalId });
            toast.success(data.message, {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });
            thunkAPI.dispatch(fetchHorizontalTabSelcted(param.componentId))
            return data;
        } catch (error) {
            return error;
        }
    }
);


