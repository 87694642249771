import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { fetchComponents } from "../Map";
import { fetchCoordinates } from "../coordinate";

export interface PumpingStationData {
    component_id: number,
        coordinate_id: number,
        pump_capacity: number,
        level: number,
        area: number,
        id: number
}

interface PumpingStationsState {
    pumpingStations: {
        load: boolean;
        data: Array<PumpingStationData>;
        error: Array<PumpingStationData>;
    };
    selectedPumpingStation?: PumpingStationData;
    editPumpingStation: {
        load: boolean;
    };
}
const initialState = {
    pumpingStations: {
        load: false,
        data: [],
        error: []
    },
    selectedPumpingStation: {
        coordinate_id: "",
        pump_capacity: "",
        level: "",
        area: "",
        id: ""
    },
    editPumpingStation: {
        load: false,
    },
    deletePumpingStation: {
        load: false,
    },
} as unknown as PumpingStationsState;

export const pumpingStationsSlice = createSlice({
    name: "pumpingStations",
    initialState,
    reducers: {
        resetPumpingStationData: (state: PumpingStationsState) => {
            state.selectedPumpingStation = initialState.selectedPumpingStation;
            state.pumpingStations.error = []
        },
        updateData: (state: PumpingStationsState, action: { payload: PumpingStationData }) => {
            state.selectedPumpingStation = action.payload;
        },
        addPumpingStationError: (state: PumpingStationsState, action: any) => {
            state.pumpingStations.error = action.payload;
        },
        addPumpingStationSuccess: (state: PumpingStationsState, action: any) =>{
            if(action.payload){
                state.pumpingStations.data = [ {...action.payload},...state.pumpingStations.data];
                }
                state.pumpingStations.load = false;
        },
        editPumpingStationSuccess: (state: PumpingStationsState, action: any) => {
            if (action.payload) {
                
                const editedPumpingStationIndex = state.pumpingStations.data.findIndex(pumpingStation => pumpingStation.id === action.payload.id);
                
                if (editedPumpingStationIndex !== -1) {
                    state.pumpingStations.data[editedPumpingStationIndex] = { ...action.payload };
                }
            }
            state.pumpingStations.load = false;
        },
        deletePumpingStationSuccess: (state: PumpingStationsState, action: any) => {
            if (action.payload) {
                const pumpingStationIdToDelete = action.payload.id;
                const indexToDelete = state.pumpingStations.data.findIndex(pumpingStation => pumpingStation.id === pumpingStationIdToDelete);
                if (indexToDelete !== -1) {
                    state.pumpingStations.data.splice(indexToDelete, 1);
                }
            }
            state.pumpingStations.load = false;
        }
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchPumpingStations.pending, (state: PumpingStationsState) => {
                state.pumpingStations.load = true;
            })
            .addCase(fetchPumpingStations.fulfilled, (state: PumpingStationsState, action: any) => {
                state.pumpingStations.data = action.payload;
                state.pumpingStations.load = false;
            })
            .addCase(fetchPumpingStations.rejected, (state: PumpingStationsState) => {
                state.pumpingStations.load = false;
            })
            .addCase(fetchPumpingStationById.pending, (state: PumpingStationsState) => {
                state.editPumpingStation.load = true;
            })
            .addCase(fetchPumpingStationById.fulfilled, (state: PumpingStationsState, action: any) => {
                state.selectedPumpingStation = action.payload;
                state.editPumpingStation.load = false;
            })
            .addCase(fetchPumpingStationById.rejected, (state: PumpingStationsState) => {
                state.editPumpingStation.load = false;
            });
    },
});
export const { updateData, resetPumpingStationData, addPumpingStationError,addPumpingStationSuccess,editPumpingStationSuccess,deletePumpingStationSuccess } = pumpingStationsSlice.actions;
export default pumpingStationsSlice.reducer;

export const fetchPumpingStations = createAsyncThunk(
    "pumpingStations/fetchPumpingStations",
    async (param: any, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("/api/user-wise-pumpingStation-list", {
                user_id: param
            });
            // thunkAPI.dispatch(toggleLoading());
            return data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const fetchPumpingStationById = createAsyncThunk(
    "pumpingStations/fetchPumpingStationById",
    async (param:any , thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/fetch-pumping-station-details", {
                coordinate_id: param
            });
            // thunkAPI.dispatch(toggleLoading());
            return data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const addPumpingStation = createAsyncThunk(
    "pumpingStations/addPumpingStation",
    async (param: { apiParam: any, closeModal: any }, thunkAPI) => {
        try {
            const coordinates = await DataServer.post(
                "api/coordinates",
                {component_id:param.apiParam.component_id,latitude:param.apiParam.latitude,longitude:param.apiParam.longitude}
            );
            const { data } = await DataServer.post(
                "api/pumping-station",
                {...param.apiParam,coordinate_id:coordinates.data.data.id}
            );
            thunkAPI.dispatch(fetchComponents({ apiParam: { id :param.apiParam.project_id,current_step:"mapping" } }))
            thunkAPI.dispatch(fetchCoordinates(param.apiParam.project_id))
            

            toast.success('Pumping Station Added Successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });
            param.closeModal();
                // thunkAPI.dispatch(addPumpingStationSuccess({...data.data,role:param.apiParam.role,id:data.data.id}));
            return {...data.data};
        } catch (error: any) {
            thunkAPI.dispatch(addPumpingStationError(error));
            return error;
        }
    }
);

export const editPumpingStation = createAsyncThunk(
    "pumpingStations/editPumpingStation",
    async (param: { apiParam: any, closeModal: any }, thunkAPI) => {
        try {
           const { data } = await DataServer.post("api/update-pumping-station-details", param.apiParam);
            // thunkAPI.dispatch(toggleLoading());
            thunkAPI.dispatch(editPumpingStationSuccess({...data.data}));
            toast.success('Pumping Station Updated Successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });
            param.closeModal();

            

            return data.data;
        } catch (error:any) {
            // thunkAPI.dispatch(toggleLoading());
            // thunkAPI.dispatch(ErrorMessage(error));
            thunkAPI.dispatch(addPumpingStationError(error));
            return error;
        }
    }
);

export const deletePumpingStation = createAsyncThunk(
    "pumpingStations/deletePumpingStation",
    async (param: { apiParam: any }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/delete-pumpingStation", {
                id: param.apiParam.id
            });
            // thunkAPI.dispatch(toggleLoading());
            param.apiParam.closeModal();
            thunkAPI.dispatch(deletePumpingStationSuccess({...data.data,role:param.apiParam.role,id:param.apiParam.id}))
            toast.success('Pumping Station deleted Successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });

            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            // thunkAPI.dispatch(ErrorMessage(error));
            return error;
        }
    }
);
