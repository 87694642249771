import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { fetchComponents } from "../Map";
import { fetchCoordinates } from "../coordinate";

export interface ValveData {
    component_id: number,
    coordinate_id: number,
    type_of_valves: string,
    number_of_valve: number,
    size: number
    compliance_with_applicable_standards: string,
    id: number
}

interface ValvesState {
    valves: {
        load: boolean;
        data: Array<ValveData>;
        error: Array<ValveData>;
    };
    selectedValve?: ValveData;
    editValve: {
        load: boolean;
    };
}
const initialState = {
    valves: {
        load: false,
        data: [],
        error: []
    },
    selectedValve: {
        component_id: "",
        coordinate_id: "",
        type_of_valves: "",
        number_of_valve: "",
        size: "",
        compliance_with_applicable_standards: "",
        id: ""
    },
    editValve: {
        load: false,
    },
    deleteValve: {
        load: false,
    },
} as unknown as ValvesState;

export const valvesSlice = createSlice({
    name: "valves",
    initialState,
    reducers: {
        resetValveData: (state: ValvesState) => {
            state.selectedValve = initialState.selectedValve;
            state.valves.error = []
        },
        updateData: (state: ValvesState, action: { payload: ValveData }) => {
            state.selectedValve = action.payload;
        },
        addValveError: (state: ValvesState, action: any) => {
            state.valves.error = action.payload;
        },
        addValveSuccess: (state: ValvesState, action: any) => {
            if (action.payload) {
                state.valves.data = [{ ...action.payload }, ...state.valves.data];
            }
            state.valves.load = false;
        },
        editValveSuccess: (state: ValvesState, action: any) => {
            if (action.payload) {

                const editedValveIndex = state.valves.data.findIndex(valve => valve.id === action.payload.id);

                if (editedValveIndex !== -1) {
                    state.valves.data[editedValveIndex] = { ...action.payload };
                }
            }
            state.valves.load = false;
        },
        deleteValveSuccess: (state: ValvesState, action: any) => {
            if (action.payload) {
                const valveIdToDelete = action.payload.id;
                const indexToDelete = state.valves.data.findIndex(valve => valve.id === valveIdToDelete);
                if (indexToDelete !== -1) {
                    state.valves.data.splice(indexToDelete, 1);
                }
            }
            state.valves.load = false;
        }
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchValves.pending, (state: ValvesState) => {
                state.valves.load = true;
            })
            .addCase(fetchValves.fulfilled, (state: ValvesState, action: any) => {
                state.valves.data = action.payload;
                state.valves.load = false;
            })
            .addCase(fetchValves.rejected, (state: ValvesState) => {
                state.valves.load = false;
            })
            .addCase(fetchValveById.pending, (state: ValvesState) => {
                state.editValve.load = true;
            })
            .addCase(fetchValveById.fulfilled, (state: ValvesState, action: any) => {
                state.selectedValve = action.payload;
                state.editValve.load = false;
            })
            .addCase(fetchValveById.rejected, (state: ValvesState) => {
                state.editValve.load = false;
            });
    },
});
export const { updateData, resetValveData, addValveError, addValveSuccess, editValveSuccess, deleteValveSuccess } = valvesSlice.actions;
export default valvesSlice.reducer;

export const fetchValves = createAsyncThunk(
    "valves/fetchValves",
    async (param: any, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("/api/user-wise-valve-list", {
                user_id: param
            });
            // thunkAPI.dispatch(toggleLoading());
            return data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const fetchValveById = createAsyncThunk(
    "valves/fetchValveById",
    async (param: any, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/edit-valves", {
                coordinate_id: param
            });
            // thunkAPI.dispatch(toggleLoading());
            return data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const addValve = createAsyncThunk(
    "valves/addValve",
    async (param: { apiParam: any, closeModal: any }, thunkAPI) => {
        try {
            const coordinates = await DataServer.post(
                "api/coordinates",
                { component_id: param.apiParam.component_id, latitude: param.apiParam.latitude, longitude: param.apiParam.longitude }
            );

            const { data } = await DataServer.post(
                "api/valves",
                { ...param.apiParam, coordinate_id: coordinates.data.data.id }
            );
            thunkAPI.dispatch(fetchComponents({ apiParam: { id :param.apiParam.project_id,current_step:"mapping" } }))
            thunkAPI.dispatch(fetchCoordinates(param.apiParam.project_id))


            toast.success('Valve Added Successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });
            param.closeModal();
            // thunkAPI.dispatch(addValveSuccess({...data.data,role:param.apiParam.role,id:data.data.id}));
            return { ...data.data };
        } catch (error: any) {
            thunkAPI.dispatch(addValveError(error));
            return error;
        }
    }
);

export const editValve = createAsyncThunk(
    "valves/editValve",
    async (param: { apiParam: any, closeModal: any }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/update-valves", param.apiParam);
            // thunkAPI.dispatch(toggleLoading());
            thunkAPI.dispatch(editValveSuccess({ ...data.data, role: param.apiParam.role }));

            toast.success('Valve Updated Successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });
            param.closeModal();
            return data.data;
        } catch (error: any) {
            // thunkAPI.dispatch(toggleLoading());
            // thunkAPI.dispatch(ErrorMessage(error));
            thunkAPI.dispatch(addValveError(error));
            return error;
        }
    }
);

export const deleteValve = createAsyncThunk(
    "valves/deleteValve",
    async (param: { apiParam: any }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/delete-valve", {
                id: param.apiParam.id
            });
            // thunkAPI.dispatch(toggleLoading());
            param.apiParam.closeModal();
            thunkAPI.dispatch(deleteValveSuccess({ ...data.data, role: param.apiParam.role, id: param.apiParam.id }))
            toast.success('Valve deleted Successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });

            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            // thunkAPI.dispatch(ErrorMessage(error));
            return error;
        }
    }
);
