import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';

export interface CoordinateData {
    component_id: number,
    coordinate_id: number,
    type_of_coordinate: string,
    access_point: string,
    dimension: number,
    area: number,
    id: number
}

interface CoordinatesState {
    coordinates: {
        load: boolean;
        data: Array<CoordinateData>;
        error: Array<CoordinateData>;
    };
    selectedCoordinate?: CoordinateData;
    editCoordinate: {
        load: boolean;
    };
}
const initialState = {
    coordinates: {
        load: false,
        data: [],
        error: []
    },
    selectedCoordinate: {
        component_id: "",
        coordinate_id: "",
        type_of_coordinate: "",
        access_point: "",
        dimension: "",
        area: "",
        id: ""
    },
    editCoordinate: {
        load: false,
    },
    deleteCoordinate: {
        load: false,
    },
} as unknown as CoordinatesState;

export const coordinatesSlice = createSlice({
    name: "coordinates",
    initialState,
    reducers: {
        resetCoordinateData: (state: CoordinatesState) => {
            state.selectedCoordinate = initialState.selectedCoordinate;
            state.coordinates = initialState.coordinates
            state.coordinates.error = []
        },
        updateData: (state: CoordinatesState, action: { payload: CoordinateData }) => {
            state.selectedCoordinate = action.payload;
        },
        addCoordinateError: (state: CoordinatesState, action: any) => {
            state.coordinates.error = action.payload;
        },
        addCoordinateSuccess: (state: CoordinatesState, action: any) => {
            if (action.payload) {
                state.coordinates.data = [{ ...action.payload }, ...state.coordinates.data];
            }
            state.coordinates.load = false;
        },
        editCoordinateSuccess: (state: CoordinatesState, action: any) => {
            if (action.payload) {

                const editedCoordinateIndex = state.coordinates.data.findIndex(coordinate => coordinate.id === action.payload.id);

                if (editedCoordinateIndex !== -1) {
                    state.coordinates.data[editedCoordinateIndex] = { ...action.payload };
                }
            }
            state.coordinates.load = false;
        },
        deleteCoordinateSuccess: (state: CoordinatesState, action: any) => {
            if (action.payload) {
                const coordinateIdToDelete = action.payload.id;
                const indexToDelete = state.coordinates.data.findIndex(coordinate => coordinate.id === coordinateIdToDelete);
                if (indexToDelete !== -1) {
                    state.coordinates.data.splice(indexToDelete, 1);
                }
            }
            state.coordinates.load = false;
        }
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchCoordinates.pending, (state: CoordinatesState) => {
                state.coordinates.load = true;
            })
            .addCase(fetchCoordinates.fulfilled, (state: CoordinatesState, action: any) => {
                state.coordinates.data = action.payload.data == null ? initialState.coordinates.data : action.payload;
                state.coordinates.load = false;
            })
            .addCase(fetchCoordinates.rejected, (state: CoordinatesState) => {
                state.coordinates.load = false;
            })
            .addCase(fetchCoordinateById.pending, (state: CoordinatesState) => {
                state.editCoordinate.load = true;
            })
            .addCase(fetchCoordinateById.fulfilled, (state: CoordinatesState, action: any) => {
                state.selectedCoordinate = action.payload;
                state.editCoordinate.load = false;
            })
            .addCase(fetchCoordinateById.rejected, (state: CoordinatesState) => {
                state.editCoordinate.load = false;
            });
    },
});
export const { updateData, resetCoordinateData, addCoordinateError, addCoordinateSuccess, editCoordinateSuccess, deleteCoordinateSuccess } = coordinatesSlice.actions;
export default coordinatesSlice.reducer;

export const fetchCoordinates = createAsyncThunk(
    "coordinates/fetchCoordinates",
    async (param: any, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("/api/project-wise-coordinates", {
                project_id: param
            });
            // thunkAPI.dispatch(toggleLoading());
            return data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const fetchCoordinateById = createAsyncThunk(
    "coordinates/fetchCoordinateById",
    async (param: any, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            // const { data } = await DataServer.post("api/fetch-coordinate", {
            //     id: param.apiParam.id
            // });
            // thunkAPI.dispatch(toggleLoading());
            return param;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const addCoordinate = createAsyncThunk(
    "coordinates/addCoordinate",
    async (param: any, thunkAPI) => {
        try {
            const coordinates = await DataServer.post(
                "api/coordinates",
                { component_id: param.component_id, latitude: param.latitude, longitude: param.longitude }
            );

            const { data } = await DataServer.post(
                "api/coordinate",
                { ...param, coordinate_id: coordinates.data.data.id }
            );
            toast.success('Coordinate added successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });
            // thunkAPI.dispatch(addCoordinateSuccess({...data.data,role:param.apiParam.role,id:data.data.id}));
            return { ...data.data };
        } catch (error: any) {
            thunkAPI.dispatch(addCoordinateError(error));
            return error;
        }
    }
);

export const editCoordinate = createAsyncThunk(
    "coordinates/editCoordinate",
    async (param: { apiParam: any, closeModal: any }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/update-coordinate", param.apiParam);
            // thunkAPI.dispatch(toggleLoading());
            thunkAPI.dispatch(editCoordinateSuccess({ ...data.data, role: param.apiParam.role }));
            param.closeModal();

            toast.success('Coordinate updated successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });

            return data.data;
        } catch (error: any) {
            // thunkAPI.dispatch(toggleLoading());
            // thunkAPI.dispatch(ErrorMessage(error));
            thunkAPI.dispatch(addCoordinateError(error));
            return error;
            // console.log("Error", error);
        }
    }
);

export const deleteCoordinate = createAsyncThunk(
    "coordinates/deleteCoordinate",
    async (param: { apiParam: any }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/delete-coordinate", {
                id: param.apiParam.id
            });
            // thunkAPI.dispatch(toggleLoading());
            param.apiParam.closeModal();
            thunkAPI.dispatch(deleteCoordinateSuccess({ ...data.data, role: param.apiParam.role, id: param.apiParam.id }))
            toast.success('Coordinate deleted successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });

            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            // thunkAPI.dispatch(ErrorMessage(error));
            return error;
        }
    }
);
