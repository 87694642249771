import React from 'react'
import PageTitle from '../PageTitle'
import ImpactAnalysis from '../ImpactAnalysis'
// import MapDashboard from '../MapDashboard'
import mapimg from '../../assets/images/Basemap image.png'
import CalculationMethod from '../CalculationMethod'
import CodeCompliance from '../CodeCompliance'
import CodeComplianceChart from '../CodeComplianceChart'
import '../../assets/css/reusable/screenmonitoring.css'
import MapDashboard from '../MapDashboard'
import Gpt from '../GptScreen'



const Dashboard = ({activeTab}:any) => {
  

    return (
        <div className='w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full bg-[#F3F3F9] px-4 dashboard pb-4 '>
            <div className='w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full font-[inter]'>
                <PageTitle title='AquaGPT Analysis Dashboard' />
            </div>
            <div className='w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full flex flex-wrap'>
                <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2  flex flex-wrap pr-2  ">
                    <div className='w-full bg-white rounded-md p-3 h-[45vh]'>
                  
                        <MapDashboard activeTab={activeTab} />
                        
                       
                    </div>

                </div>
                <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2  flex flex-wrap pl-2">
                    <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2  flex flex-wrap pr-2">
                        <div className="w-full flex justify-between items-center bg-[#FFF] py-[4px] px-4 rounded-lg mb-3">

                            <h1 className='text-[#262626] text-[14px] not-italic font-medium leading-normal  font-[inter]'>Water Quality Classification</h1>

                            <button className='bg-[#DFF0FA] text-[#1784EB] text-[15.29px] not-italic font-semibold leading-normal py-[8px] px-[13px] rounded-lg'>Class A</button>
                        </div>
                        <CalculationMethod />
                    </div>
                    <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2  flex flex-wrap pl-2">
                        <ImpactAnalysis />
                    </div>
                </div>
            </div>
            <div className='w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full flex flex-wrap mt-4 h-[33vh]'>
                <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2  flex flex-wrap pr-2">
                    <CodeCompliance />
                </div>
                <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2  flex flex-wrap pl-2">
                    <CodeComplianceChart />
                </div>
            </div>
            <div>
                  <Gpt/>
                    </div>
        </div>
    )
}

export default Dashboard
