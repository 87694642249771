import React, { useEffect, useState } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import { navbarList, itemProp } from "../../Utils/navbarList";
import "../../assets/css/layout/sidebar.css";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  fetchCurrentPermission,
  fetchPermission,
} from "../../redux/slice/Permission";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faGrid2 } from "@fortawesome/pro-light-svg-icons";
import { faG } from "@fortawesome/pro-solid-svg-icons";

const MainSidebar = ({ collapsed }: any) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { permission, Currentpermission, Sidebarpermission } = useAppSelector(
    (state) => state.permission
  );
  const [filterArray, setFilterdata]: any = useState([]);

  useEffect(() => {
    if (location.pathname.includes("assign-permission")) {
      document.getElementById("rolemanagement")?.classList.add("ps-active");
    } else {
      document.getElementById("rolemanagement")?.classList.remove("ps-active");
    }
  }, [location]);

  useEffect(() => {
    if (
      location.pathname.includes("Schedule") ||
      location.pathname.includes("Test-Category") ||
      location.pathname.includes("Test")
    ) {
      document.getElementById("view-schedule")?.classList.add("ps-active");
    } else {
      document.getElementById("view-schedule")?.classList.remove("ps-active");
    }
  }, [location]);

  useEffect(() => {
    dispatch(fetchPermission());
  }, []);

  useEffect(() => {
    const sidebarData = Sidebarpermission?.data?.data;
    const permissionData = permission?.data;
    if (sidebarData && permissionData) {
      const processedPermissions = Object.keys(sidebarData).reduce(
        (acc: any, key: any) => {
          acc[key] = Object.keys(sidebarData[key]).map((id) => {
            // Ensure permissionData[key] exists before accessing [id]
            if (permissionData[key]) {
              return permissionData[key][id];
            } else {
              return undefined;
            }
          });
          return acc;
        },
        {}
      );

      // Logging processedPermissions to see its structure
      dispatch(fetchCurrentPermission(processedPermissions));

      const filteredItems = navbarList.filter((item) => {
        const itemPermissions = processedPermissions[item.title];
        return itemPermissions && itemPermissions.includes("Read"); // Example: Only show items the user can read
      });

      setFilterdata(filteredItems);
    }
  }, [Sidebarpermission?.data?.data, permission?.data]);

  return (
    // <div className='w-6'>
    <Sidebar collapsed={collapsed} className="px-3 mt-3">
      <Menu>
{/*         <MenuItem
          icon={<FontAwesomeIcon icon={faGrid2} />}
          id="dashboard"
          key={`dashboard_1`}
          active={location.pathname.includes(`dashboard`)}
          component={<Link to={"/dashboard"} />}

          // onClick={() => handleItemClick(value.title)}
          // selected={location.pathname === `${value.path}`}
        >
          Dashboard
        </MenuItem> */}
        {/* <MenuItem
          icon={<FontAwesomeIcon icon={faBell} />}
          id="alerts_solution"
          key={`alerts_solution`}
          active={location.pathname.includes(`Alerts-Solution`)}
          component={<Link to={'/Alerts-Solution'} />}

        // onClick={() => handleItemClick(value.title)}
        // selected={location.pathname === `${value.path}`}
        >
          Alerts Solution Hub
        </MenuItem> */}
{/*         {filterArray &&
          filterArray.map((item: itemProp, index: number) => (
            <MenuItem
              icon={item.icon}
              id={item.classname}
              key={`${item.classname}_${index}`}
              active={location.pathname.includes(`${item.urlCommonWord}`)}
              component={<Link to={item.path} />}

              // onClick={() => handleItemClick(value.title)}
              // selected={location.pathname === `${value.path}`}
            >
              {item.title}
            </MenuItem>
          ))} */}
        <MenuItem
          icon={<FontAwesomeIcon icon={faG} />}
          id="aqua-gpt"
          key={`aqua-gpt`}
          active={location.pathname.includes(`Aqua-GPT`)}
          component={<Link to={"/Aqua-GPT"} />}

          // onClick={() => handleItemClick(value.title)}
          // selected={location.pathname === `${value.path}`}
        >
          Aqua GPT
        </MenuItem>
      </Menu>
    </Sidebar>
    // </div>
  );
};

export default MainSidebar;
