import { FC, useState } from "react";
// import Header from "./appHeader/header";
import Sidebar from "./appHeader/MainSidebar";
import React from "react";
import "../assets/css/layout/app-header.css";
import { ToastContainer } from "react-toastify";
import "../assets/css/reusable/toast.css";
import Header from "./appHeader/header";
import MapSidebar from "./appHeader/MapSidebar";
import { useLocation } from "react-router-dom";
import InspectorSidebar from "./appHeader/InspectorSidebar";

interface layout {
  children: JSX.Element;
}

const LayoutContainer: FC<layout> = ({ children }) => {
  const navigation = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  // const [toggled, setToggled] = useState(false);
  return (
    <div>
      {activeTab === 1 ? null : (
        <Header collapsed={collapsed} setCollapsed={setCollapsed} />
      )}

      <div
        className={`flex ${
          navigation?.pathname?.includes("map")
            ? "app-header2"
            : "app-header" && navigation?.pathname?.includes("SourceOfIntake")
            ? "app-header2"
            : "app-header" && navigation?.pathname?.includes("StorageReservior")
            ? "app-header2"
            : "app-header" &&
              navigation?.pathname?.includes("TreatmentFacility")
            ? "app-header2"
            : "app-header" &&
              navigation?.pathname?.includes("ComponentSelection")
            ? "app-header2"
            : "app-header"
        } `}
      >
        {/* <Sidebar /> */}
        {/* {
          navigation?.pathname?.includes("map")  ? <MapSidebar></MapSidebar> : <Sidebar />
        } */}
        {/* {
          navigation?.pathname?.includes("map") ||
            navigation?.pathname?.includes("SourceOfIntake") ||
            navigation?.pathname?.includes("StorageReservior") ||
            navigation?.pathname?.includes("TreatmentFacility") ||
            navigation?.pathname?.includes("ComponentSelection")
            ? <MapSidebar /> : <Sidebar />
        }
        {
        navigation?.pathname?.includes("Project") ?
        <InspectorSidebar/>: <></>
        } */}

        {
          activeTab === 1 ? null : navigation.pathname.includes("map") ||
            navigation.pathname.includes("SourceOfIntake") ||
            navigation.pathname.includes("StorageReservior") ||
            navigation.pathname.includes("TreatmentFacility") ||
            navigation.pathname.includes("ComponentSelection") ||
            navigation.pathname.includes("WaterQualityStation") ||
            navigation.pathname.includes("Checklist") ? (
            <MapSidebar />
          ) : (
            // : navigation.pathname.includes("Project") ||
            //   navigation.pathname.includes("Schedule")||
            //   navigation.pathname.includes("TestSummary")||
            //   navigation.pathname.includes("Test")||
            //   navigation.pathname.includes("Calender")||
            //   navigation.pathname.includes("Screen-Monitoring")
            // ? <InspectorSidebar />
            <Sidebar collapsed={collapsed} />
          )
          // ?navigation.pathname.includes("ScreenMonitoring"):
          // <></>
        }

        <div
          className={`${collapsed ? "collapsed-width" : "content-width "} ${
            activeTab === 1 ? "mapview-width" : "content-width"
          }`}
        >
          {/* <AlertComponent />
          <SweetAlertComponent /> */}
          <div
            className={`main-content 
          ${navigation?.pathname?.includes("map") ? "" : "py-4 px-4"} ${
              activeTab === 1 ? "padding-div" : ""
            } `}
          >
            {React.cloneElement(children, { activeTab, setActiveTab })}
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutContainer;
