// src/NetworkUpload.js
import React, { useRef, useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { uploadNetwork } from '../../../redux/slice/Map';

Modal.setAppElement('#root'); // Important for accessibility
const backgroundColor = 'rgba(23, 49, 74, 0.93)';

const customStyles:any = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: "rgba(23, 49, 74, 0.93)", // Dark blue color with transparency
    color: 'white', // Text color
    padding: '40px',
    borderRadius: '10px',
    border: 'none',
    textAlign: 'center', // Center-align text
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "16px",
    zIndex: 1001,
  },
  overlay: {
    backgroundColor: backgroundColor, // Same dark blue color for the overlay
    zIndex: 1000,
    cursor:"pointer"
  },
};

function NetworkUpload({ isOpen, onRequestClose,projectData }:any) {
  const [file,setFile] = useState("");
  const dispatch = useAppDispatch()
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const profile = useAppSelector((state) => state.profile);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const allowedTypes = ['application/json', 'application/zip', 'application/octet-stream', 'text/csv'];

      if (!allowedTypes.includes(file.type) && !file.name.match(/\.(geojson|shp|shx|dbf|inp|csv|zip)$/i)) {
        toast.error('Unsupported File Type Detected.', {
          position: "top-right",
          autoClose: 5000,
          theme: "dark",
        });
        return;
      }

      let uploaddata = {
        file:file,
project_id:projectData.projectData.id,
project_name:projectData.projectData.project_name,
user_id:profile.profile.data.id
      }
      dispatch(uploadNetwork(uploaddata))

      // Handle the supported uploaded files here

    }
};

const handleClick = () => {
  if (fileInputRef.current) {
    fileInputRef.current.click();
  }
};
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="File Upload Modal"
    >
       <div onClick={handleClick} >
        <h2>Drag and drop your files or click here to select them</h2>
        <p>Supported files: GeoJSON, Shapefile (at least: SHP, SHX, and DBF), EPANET (INP), CSV and ZIP.</p>
        <p>Press ESC to go back.</p>
        <input
          type="file"
          className="hidden"
          ref={fileInputRef}
          onChange={handleImageUpload}
          accept=".geojson,.shp,.shx,.dbf,.inp,.csv,.zip"
        />
      </div>
      
     
      {/* <pre>{JSON.stringify(file)}</pre> */}
    </Modal>
  );
}

export default NetworkUpload;
