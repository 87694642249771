import React, { useEffect, useState } from "react";
import FormField from '../Forms/FormField'
// import SelectField from '../SelectField'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as yup from 'yup';
// import { FormValidation } from "../../Utils/formValidation";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import '../../assets/css/reusable/form.css'
import SelectField from "../Forms/SelectField";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { activeComponent, formPopup } from "../../redux/slice/Map";
import { addStorageReservoir, editStorageReservoir, fetchStorageReservoirById } from "../../redux/slice/storageReservoir";
import { useLocation } from "react-router-dom";
import Loading from "../../views/pages/Loading";
interface FormData {
    type: string;
    latitude: string;
    longitude: string;
    capacity: string;
    material: string;
    wallThickness: string;
    stagingHeight: string,
    dimension_width: string;
    dimension_length: string;
}
const StorageReservoirs: any = (props: any) => {
    const components = useAppSelector((state) => state.components);
    // const storageReserviors=useAppSelector((state)=>state.storageReserviors);
    const storageReservoir = useAppSelector((state) => state.storageReservoir);
    const dispatch = useAppDispatch();
    const [formData, setFormData] = useState<any>({
        type: "",
        latitude: "",
        longitude: "",
        capacity: "",
        material: "",
        wallThickness: "",
        stagingHeight: "",
        dimension_width: "",
        dimension_length: ""
    });

    const [resetformData, setResetFormData] = useState<FormData>({
        type: "",
        latitude: "",
        longitude: "",
        capacity: "",
        material: "",
        wallThickness: "",
        stagingHeight: "",
        dimension_width: "",
        dimension_length: ""
    });

    const location = useLocation();
    const typeOptions: any = [{ "value": "Select" }, { "value": "Clean water / Raw Water reservoir" }, { "value": "Balancing reservoir (clean/raw water)" }, { "value": "Elevated Storage Reservoir (ESR)" },
    { "value": "Ground-level Storage Reservoir (GSR)" }
    ]
    const convertedsystemOptions = typeOptions.map((option: { value: string; }) => option.value);
    const formSchema = yup.object({
        type: yup.string()
            .oneOf(convertedsystemOptions, 'Please select a Type of Reservoirs from the list.') // A) Error for text-based
            .required('Please select a main type from the list.'), // Compulsory
        latitude: yup.string().required("Latitude is required")
            .test('is-text-based', 'Please enter a valid latitude.', value => !isNaN(parseFloat(value)))
            .test('is-symbol-based', 'Only numeric values and symbols (-, .) are allowed.', value => /^-?\d*\.?\d*$/.test(value))
            .test('character-limit', 'Value must be a valid latitude format.', value => value.length <= 8),
        longitude: yup.string().required("Longitude is required")
            .test('is-text-based', 'Please enter a valid Longitude.', value => !isNaN(parseFloat(value)))
            .test('is-symbol-based', 'Only numeric values and symbols (-, .) are allowed.', value => /^-?\d*\.?\d*$/.test(value))
            .test('character-limit', 'Value must be a valid Longitude format.', value => value.length <= 8),
        capacity: yup.string()
            .matches(/^\d+$/, 'Please enter a numeric value for pump capacity.') // A) Error for text-based
            .matches(/^\d+$/, 'Only numeric values are allowed.') // B) Error for symbol-based
            .min(1, 'Value must be between 1 to 100000.') // C) Character limit
            .max(100000, 'Value must be between 1 to 100000.') // C) Character limit
            .required('Pump capacity is required'), // E) Compulsory
    });
    const [errors, setErrors] = useState<Partial<FormData>>({});
    const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
    const onSelectChangeChange = async (e: any) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setFormErrors({ ...formErrors, [name]: "" });
        // await FormValidation(formSchema, { ...formData, [name]: value }, setErrors);
    }
    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setFormErrors({ ...formErrors, [name]: "" });
        // await FormValidation(formSchema, { ...formData, [name]: value }, setErrors);
    };

    const storageReserviorData = storageReservoir?.selectedStorageReservoir.data;
    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        // Basic validation
        // await FormValidation(formSchema,formData,setErrors)
        let storageData = {
            project_id: location?.state?.projectData?.id,
            component_id: components?.selectedComponent?.component_id,
            type_of_reservoir: formData.type,
            capacity: formData.capacity,
            material: formData.material,
            wall_thickness: formData.wallThickness,
            latitude: formData.latitude,
            longitude: formData.longitude,
            dimension_length: formData.dimension_length,
            dimension_width: formData.dimension_width,
            staging_height:formData.stagingHeight

        }

        const errors: { [key: string]: string } = {};
        let requiredFields = ["capacity", "material", "wallThickness", "stagingHeight", "type", "latitude", "longitude", "dimension_length", "dimension_width"];
        if(formData.type !== "Elevated Storage Reservoir (ESR)"){
            requiredFields = requiredFields.filter((e: string) => e !== 'stagingHeight')
        }

        requiredFields.forEach((field) => {
            if (!formData[field]) {
                errors[field] = "Required";
            }
        });


        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
        } else {
                
            storageReserviorData ?
                dispatch(editStorageReservoir({ apiParam: { ...storageData, coordinate_id: storageReserviorData.coordinate_id, id: storageReserviorData.id }, closeModal: dispatch(formPopup(false)) })) :
                dispatch(addStorageReservoir({ apiParam: storageData, closeModal: dispatch(formPopup(false)) }))
        }
    };
    useEffect(() => {
        if (components.selectedComponent) {
            dispatch(activeComponent(components?.selectedComponent?.name))
            if (components?.selectedComponent?.id) {
                dispatch(fetchStorageReservoirById(components?.selectedComponent?.id));
            }

            setFormData({
                ...formData,
                latitude: components?.selectedComponent?.latitude,
                longitude: components?.selectedComponent?.longitude
            })
        }
    }, [components.selectedComponent])

    useEffect(() => {

        if (storageReserviorData) {
            setFormData({
                ...storageReserviorData,
                type: storageReserviorData.type_of_reservoir,
                capacity: storageReserviorData.capacity,
                material: storageReserviorData.material,
                compliance: storageReserviorData.compliance_with_applicable_standards,
                latitude: components?.selectedComponent?.latitude,
                longitude: components?.selectedComponent?.longitude,
                dimension_length: storageReserviorData.dimension_length,
                dimension_width: storageReserviorData.dimension_width,
                wallThickness: storageReserviorData.wall_thickness,
                stagingHeight:storageReserviorData.staging_height

            })
        }
    }, [storageReserviorData]);
    useEffect(() => {
        if (!storageReserviorData) {
            setFormData(resetformData)
        }
    }, [!storageReserviorData])
    return (
        <>
            {
                storageReservoir.editStorageReservoir.load ? <Loading /> : <div className="container bg-white  mx-auto  h-[85vh] text-[#1E2022]">
                    {/* <div className="h-[5%]"> */}
                    <div className="flex mb-4 justify-between items-center border-b-2 py-4 px-4">
                        <h3 className="text-base not-italic font-semibold leading-[18.9px] mt-1">Storage Reservoirs Details</h3>
                        <FontAwesomeIcon icon={faXmark}
                            onClick={() => { dispatch(formPopup(false)); setFormData(resetformData) }}
                            className="mt-1 pr-4 hover:cursor-pointer" />
                    </div>
                    {/* </div> */}
                    <div className="form-height overflow-y-auto ">
                        <form className="grid px-4">
                            {/* <div className="text-black py-4 font-bold">
        <h3 className="">General Information</h3>
      </div> */}
                            <SelectField
                                label="Type of Reservoir"
                                name="type"
                                type="select"
                                placeholder="Select"
                                value={formData.type}
                                onChange={onSelectChangeChange}
                                error={formErrors.type}
                                options={typeOptions}
                                labelRequired
                            />
                            <FormField
                                label="Latitude"
                                name="latitude"
                                type="text"
                                placeholder="Enter Latitude"
                                value={formData.latitude}
                                onChange={handleChange}
                                error={formErrors.latitude}
                                labelRequired
                            />
                            <FormField
                                label="Longitude"
                                name="longitude"
                                type="text"
                                placeholder="Enter Longitude"
                                value={formData.longitude}
                                onChange={handleChange}
                                error={formErrors.longitude}
                                labelRequired
                            />
                            <div>
                                <div className="mb-4 text-[#1E2022]">

                                    <label className="block text-[#1E2022] text-xs not-italic font-medium leading-[21px] mb-2 " htmlFor={"dimension"}>
                                        {"Dimension [L(m) * W(m)]"} <span className="text-[#ED4C78]">{"*"}</span>
                                    </label>

                                    <input
                                        readOnly={false}
                                        className={`
     focus:border-indigo-600 focus:ring-0 appearance-none bg-white rounded  w-[40%] py-2 px-3 text-xs text-gray-700 leading-tight  hover:border-[#8C98A4] placeholder:text-[#8C98A4] placeholder:text-xs placeholder:not-italic placeholder:font-medium placeholder:leading-[21px] border border-solid border-gray-300 `}
                                        // $
                                        // {error ? "border-red-500" : "border-gray-300"} 

                                        id={"dimension_length"}
                                        name={"dimension_length"}
                                        type="input"
                                        placeholder="Enter length"
                                        value={formData.dimension_length}
                                        onChange={handleChange}
                                    />

                                    <span className="w-[10%] pl-[6px] pr-[6px]">X</span>
                                    <input
                                        readOnly={false}
                                        className={`
     focus:border-indigo-600 focus:ring-0 appearance-none bg-white rounded  w-[40%] py-2 px-3 text-xs text-gray-700 leading-tight  hover:border-[#8C98A4] placeholder:text-[#8C98A4] placeholder:text-xs placeholder:not-italic placeholder:font-medium placeholder:leading-[21px] border border-solid border-gray-300 ml-[3px]`}
                                        // $
                                        // {error ? "border-red-500" : "border-gray-300"} 

                                        id={"dimension_width"}
                                        name={"dimension_width"}
                                        type="input"
                                        placeholder="Enter width"
                                        value={formData.dimension_width}
                                        onChange={handleChange}
                                    />
                                    {/* {formData.dimension_length && <p className="text-red-500 text-xs italic">{formData.dimension_length}</p>} */}
                                    {formErrors.dimension_width && <p className="text-red-500 text-xs italic">{formErrors.dimension_width}</p>}


                                </div>

                            </div>
                            <FormField
                                label="Capacity (cu. m)"
                                name="capacity"
                                type="text"
                                placeholder="Enter Capacity"
                                value={formData.capacity}
                                onChange={handleChange}
                                error={formErrors.capacity}
                                labelRequired
                            />
                            <FormField
                                label="Material"
                                name="material"
                                type="text"
                                placeholder="Enter Material"
                                value={formData.material}
                                onChange={handleChange}
                                error={formErrors.material}
                                labelRequired
                            />
                            <FormField
                                label="Wall Thickness (mm)"
                                name="wallThickness"
                                type="text"
                                placeholder="Enter Wall Thickness"
                                value={formData.wallThickness}
                                onChange={handleChange}
                                error={formErrors.wallThickness}
                                labelRequired
                            />
                            {
                                formData.type == "Elevated Storage Reservoir (ESR)" ?
                                    <FormField
                                        label="Staging Height (m)"
                                        name="stagingHeight"
                                        type="text"
                                        placeholder="Enter Staging Height"
                                        value={formData.stagingHeight}
                                        onChange={handleChange}
                                        error={formErrors.stagingHeight

                                        }
                                        labelRequired
                                    /> : <></>
                            }
                        </form>
                    </div>
                    {/* <div className="h-[10%] flex justify-end"> */}
                    {/* <div className="basis-2/4"></div> */}
                    <div className="w-full  flex justify-end pr-8 form-footer">
                        <button
                            className=" text-[#8A96A4] bg-white my-4 pt-1 pb-1 px-4  rounded focus:outline-none focus:shadow-outline border border-solid border-[#EEF0F7] hover:bg-[#F8F7F7]  hover:scale-105"
                            type="submit"
                            onClick={() => { dispatch(formPopup(false)); setFormData(resetformData) }}
                        >
                            Cancel
                        </button>
                        <button
                            className=" ml-3 font-normal text-sm bg-[#377DFF] my-4  text-white pt-1 pb-1 px-2 rounded focus:outline-none focus:shadow-outline hover:scale-105"
                            type="submit"
                            onClick={handleSubmit}
                        >
                            {
                                storageReserviorData ? "Update Details" : "Save Details"
                            }

                        </button>
                    </div>
                    {/* </div> */}
                </div>
            }

        </>
    )
}
export default StorageReservoirs
