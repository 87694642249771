import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { fetchCoordinates } from "../coordinate";
import { fetchComponents } from "../Map";

export interface RisingGravityMainData {
    component_id: number,
    coordinate_id: number,
    type_of_main: string,
    diameter: number,
    routing: number,
    access_points: number,
    id: number
}

interface RisingGravityMainsState {
    risingGravityMains: {
        load: boolean;
        data: Array<RisingGravityMainData>;
        error: Array<RisingGravityMainData>;
    };
    selectedRisingGravityMain?: RisingGravityMainData;
    editRisingGravityMain: {
        load: boolean;
    };
}
const initialState = {
    risingGravityMains: {
        load: false,
        data: [],
        error: []
    },
    selectedRisingGravityMain: {
        component_id: "",
        coordinate_id: "",
        type_of_main: "",
        diameter: "",
        routing: "",
        access_points: "",
        id: ""
    },
    editRisingGravityMain: {
        load: false,
    },
    deleteRisingGravityMain: {
        load: false,
    },
} as unknown as RisingGravityMainsState;

export const risingGravityMainsSlice = createSlice({
    name: "risingGravityMains",
    initialState,
    reducers: {
        resetRisingGravityMainData: (state: RisingGravityMainsState) => {
            state.selectedRisingGravityMain = initialState.selectedRisingGravityMain;
            state.risingGravityMains.error = []
        },
        updateData: (state: RisingGravityMainsState, action: { payload: RisingGravityMainData }) => {
            state.selectedRisingGravityMain = action.payload;
        },
        addRisingGravityMainError: (state: RisingGravityMainsState, action: any) => {
            state.risingGravityMains.error = action.payload;
        },
        addRisingGravityMainSuccess: (state: RisingGravityMainsState, action: any) => {
            if (action.payload) {
                state.risingGravityMains.data = [{ ...action.payload }, ...state.risingGravityMains.data];
            }
            state.risingGravityMains.load = false;
        },
        editRisingGravityMainSuccess: (state: RisingGravityMainsState, action: any) => {
            if (action.payload) {

                const editedRisingGravityMainIndex = state.risingGravityMains.data.findIndex(risingGravityMain => risingGravityMain.id === action.payload.id);

                if (editedRisingGravityMainIndex !== -1) {
                    state.risingGravityMains.data[editedRisingGravityMainIndex] = { ...action.payload };
                }
            }
            state.risingGravityMains.load = false;
        },
        deleteRisingGravityMainSuccess: (state: RisingGravityMainsState, action: any) => {
            if (action.payload) {
                const risingGravityMainIdToDelete = action.payload.id;
                const indexToDelete = state.risingGravityMains.data.findIndex(risingGravityMain => risingGravityMain.id === risingGravityMainIdToDelete);
                if (indexToDelete !== -1) {
                    state.risingGravityMains.data.splice(indexToDelete, 1);
                }
            }
            state.risingGravityMains.load = false;
        }
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchRisingGravityMains.pending, (state: RisingGravityMainsState) => {
                state.risingGravityMains.load = true;
            })
            .addCase(fetchRisingGravityMains.fulfilled, (state: RisingGravityMainsState, action: any) => {
                state.risingGravityMains.data = action.payload;
                state.risingGravityMains.load = false;
            })
            .addCase(fetchRisingGravityMains.rejected, (state: RisingGravityMainsState) => {
                state.risingGravityMains.load = false;
            })
            .addCase(fetchRisingGravityMainById.pending, (state: RisingGravityMainsState) => {
                state.editRisingGravityMain.load = true;
            })
            .addCase(fetchRisingGravityMainById.fulfilled, (state: RisingGravityMainsState, action: any) => {
                state.selectedRisingGravityMain = action.payload;
                state.editRisingGravityMain.load = false;
            })
            .addCase(fetchRisingGravityMainById.rejected, (state: RisingGravityMainsState) => {
                state.editRisingGravityMain.load = false;
            });
    },
});
export const { updateData, resetRisingGravityMainData, addRisingGravityMainError, addRisingGravityMainSuccess, editRisingGravityMainSuccess, deleteRisingGravityMainSuccess } = risingGravityMainsSlice.actions;
export default risingGravityMainsSlice.reducer;

export const fetchRisingGravityMains = createAsyncThunk(
    "risingGravityMains/fetchRisingGravityMains",
    async (param: any, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("/api/user-wise-risingGravityMain-list", {
                user_id: param
            });
            // thunkAPI.dispatch(toggleLoading());
            return data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const fetchRisingGravityMainById = createAsyncThunk(
    "risingGravityMains/fetchRisingGravityMainById",
    async (param: any, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/edit-rising-or-gravity-main",
                { coordinate_id: param }
            );

            // thunkAPI.dispatch(toggleLoading());
            return data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const addRisingGravityMain = createAsyncThunk(
    "risingGravityMains/addRisingGravityMain",
    async (param: { apiParam: any, closeModal: any }, thunkAPI) => {
        try {
            const coordinates = await DataServer.post(
                "api/coordinates",
                { component_id: param.apiParam.component_id, latitude: param.apiParam.latitude, longitude: param.apiParam.longitude }
            );

            const { data } = await DataServer.post(
                "api/rising-or-gravity-main",
                { ...param.apiParam, coordinate_id: coordinates.data.data.id }
            );
            thunkAPI.dispatch(fetchComponents({ apiParam: { id: param.apiParam.project_id, current_step: "mapping" } }))
            thunkAPI.dispatch(fetchCoordinates(param.apiParam.project_id))


            toast.success('Rising / Gravity Main Added Successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });
            param.closeModal();
            // thunkAPI.dispatch(addRisingGravityMainSuccess({...data.data,role:param.apiParam.role,id:data.data.id}));
            return { ...data.data };
        } catch (error: any) {
            thunkAPI.dispatch(addRisingGravityMainError(error));
            return error;
        }
    }
);

export const editRisingGravityMain = createAsyncThunk(
    "risingGravityMains/editRisingGravityMain",
    async (param: { apiParam: any, closeModal: any }, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/update-rising-or-gravity-main", param.apiParam);
            // thunkAPI.dispatch(toggleLoading());
            thunkAPI.dispatch(editRisingGravityMainSuccess({ ...data.data }));

            toast.success('Rising Gravity Main Updated Successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });
            param.closeModal();

            return data.data;
        } catch (error: any) {
            // thunkAPI.dispatch(toggleLoading());
            // thunkAPI.dispatch(ErrorMessage(error));
            thunkAPI.dispatch(addRisingGravityMainError(error));
            return error;
        }
    }
);

export const deleteRisingGravityMain = createAsyncThunk(
    "risingGravityMains/deleteRisingGravityMain",
    async (param: { apiParam: any }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/delete-risingGravityMain", {
                id: param.apiParam.id
            });
            // thunkAPI.dispatch(toggleLoading());
            param.apiParam.closeModal();
            thunkAPI.dispatch(deleteRisingGravityMainSuccess({ ...data.data, role: param.apiParam.role, id: param.apiParam.id }))
            toast.success('Rising Gravity Main deleted successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });

            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            // thunkAPI.dispatch(ErrorMessage(error));
            return error;
        }
    }
);
