import React, { useState } from "react";
import FormField from '../Forms/FormField'
// import SelectField from '../SelectField'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as yup from 'yup';
// import { FormValidation } from "../../Utils/formValidation";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import '../../assets/css/reusable/form.css'
import SelectField from "..//Forms/SelectField";
import { formPopup } from "../../redux/slice/Map";
import { useAppDispatch } from "../../hooks";
// import { useAppDispatch } from "../../../../../hooks";
interface FormData {
    type: string;
    latitude: string;
    longitude: string;
    accessPoint: string;
    diameter: string;
    compliance: string;
}
const Mains: any = (props: any) => {
    const dispatch = useAppDispatch();
    const [formData, setFormData] = useState<FormData>({
        type: "",
        latitude: "",
        longitude: "",
        accessPoint: "",
        diameter: "",
        compliance: ""
    });
    const [resetformData, setResetFormData] = useState<FormData>({
        type: "",
        latitude: "",
        longitude: "",
        accessPoint: "",
        diameter: "",
        compliance: ""
    });
    const typeOptions: any = [{ "value": "Select" }, { "value": "Valve chamber" }, { "value": "Meter chamber" }, { "value": "Manholes" }, { "value": "Flush chamber" },
    { "value": "Air release chamber" }, { "value": "Pressure control chamber" }, { "value": "Sampling chamber" }, { "value": "Control chamber" }]
    const convertedsystemOptions = typeOptions.map((option: { value: string; }) => option.value);
    const formSchema = yup.object({
        type: yup.string()
            .oneOf(convertedsystemOptions, 'Please select a Type of Valve from the list.') // A) Error for text-based
            .required('Please select a type of Valve from the list.'), // Compulsory
        latitude: yup.string().required("Latitude is required")
            .test('is-text-based', 'Please enter a valid latitude.', value => !isNaN(parseFloat(value)))
            .test('is-symbol-based', 'Only numeric values and symbols (-, .) are allowed.', value => /^-?\d*\.?\d*$/.test(value))
            .test('character-limit', 'Value must be a valid latitude format.', value => value.length <= 8),
        longitude: yup.string().required("Longitude is required")
            .test('is-text-based', 'Please enter a valid Longitude.', value => !isNaN(parseFloat(value)))
            .test('is-symbol-based', 'Only numeric values and symbols (-, .) are allowed.', value => /^-?\d*\.?\d*$/.test(value))
            .test('character-limit', 'Value must be a valid Longitude format.', value => value.length <= 8),
        accessPoint: yup.string()
            .matches(/^\d+$/, 'Please enter a numeric value.') // A) Error for text-based
            .matches(/^\d+$/, 'Only numeric values are allowed.') // B) Error for symbol-based
            .min(1, 'Value must be between 1 to 100000.') // C) Character limit
            .max(100000, 'Value must be between 1 to 100000.') // C) Character limit
            .required('Pump capacity is required'), // E) Compulsory
        diameter: yup.string()
            .matches(/^\d+$/, 'Please enter a numeric value.') // A) Error for text-based
            .matches(/^\d+$/, 'Only numeric values are allowed.') // B) Error for symbol-based
            .min(1, 'Value must be between 1 to 100000.') // C) Character limit
            .max(100000, 'Value must be between 1 to 100000.') // C) Character limit
            .required('Pump capacity is required'), // E) Compulsory
        compliance: yup.string()
            .matches(/^\d+$/, 'Please enter a numeric value.')
            .matches(/^\d+$/, 'Only numeric values are allowed.')
            .min(1, 'Value must be between 1 to 100000.')
            .max(100000, 'Value must be between 1 to 100000.')
            .required('Pump capacity is required'),
    });
    const [errors, setErrors] = useState<Partial<FormData>>({});
    const onSelectChangeChange = async (e: any) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [e.target.name]: e.target.value });
        // await FormValidation(formSchema, { ...formData, [name]: value }, setErrors);
    }
    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        // await FormValidation(formSchema, { ...formData, [name]: value }, setErrors);
    };
    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        // Basic validation
        // await FormValidation(formSchema,formData,setErrors)
    };
    return (
        <>
            <div className="container bg-white  mx-auto h-[85vh] text-[#1E2022]">
                {/* <div className="h-[5%]"> */}
                <div className="flex mb-4 justify-between items-center border-b-2 py-4 px-4">
                    <h3 className="text-base not-italic font-semibold leading-[18.9px]">Mains Details</h3>
                    <FontAwesomeIcon icon={faXmark}
                        onClick={() => { dispatch(formPopup(false)); setFormData(resetformData) }}
                        className="mt-1 hover:cursor-pointer" />
                </div>
                {/* </div> */}
                <div className="h-[85%] overflow-y-auto ">
                    <form className="grid px-4">
                        {/* <div className="text-black py-4 font-bold">
            <h3 className="">General Information</h3>
          </div> */}
                        <SelectField
                            label="Type of Mains"
                            name="type"
                            type="select"
                            placeholder="Select"
                            value={formData.type}
                            onChange={onSelectChangeChange}
                            error={errors.type}
                            options={typeOptions}
                        />
                        <FormField
                            label="Latitude"
                            name="latitude"
                            type="text"
                            placeholder="Enter Latitude"
                            value={formData.latitude}
                            onChange={handleChange}
                            error={errors.latitude}
                        />
                        <FormField
                            label="Longitude"
                            name="longitude"
                            type="text"
                            placeholder="Enter Longitude"
                            value={formData.longitude}
                            onChange={handleChange}
                            error={errors.longitude}
                        />
                        <FormField
                            label="Access Point"
                            name="accessPoint"
                            type="text"
                            placeholder="Enter No. Of Valve"
                            value={formData.accessPoint}
                            onChange={handleChange}
                            error={errors.accessPoint}
                        />
                        <FormField
                            label="Diameter"
                            name="diameter"
                            type="text"
                            placeholder="Enter Size"
                            value={formData.diameter}
                            onChange={handleChange}
                            error={errors.diameter}
                        />
                        <FormField
                            label="Compliance with applicable standards"
                            name="compliance"
                            type="text"
                            placeholder="Enter Compliance"
                            value={formData.compliance}
                            onChange={handleChange}
                            error={errors.compliance}
                        />
                    </form>
                </div>
                {/* <div className="h-[10%] flex justify-end"> */}
                {/* <div className="basis-2/4"></div> */}
                <div className="w-full h-[10%] flex justify-end pr-4 form-footer">
                    <button
                        className=" text-[#8A96A4] bg-white my-4 pt-1 pb-1 px-4  rounded focus:outline-none focus:shadow-outline border border-solid border-[#EEF0F7] hover:bg-[#F8F7F7]  hover:scale-105"
                        type="submit"
                        onClick={() => { dispatch(formPopup(false)); setFormData(resetformData) }}
                    >
                        Cancel
                    </button>
                    <button
                        className=" ml-3 font-normal text-sm bg-[#377DFF] my-4  text-white pt-1 pb-1 px-2 rounded focus:outline-none focus:shadow-outline hover:scale-105"
                        type="submit"
                        onClick={handleSubmit}
                    >
                        Save Details
                    </button>
                </div>
                {/* </div> */}
            </div>
        </>
    )
}
export default Mains
