import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DataServer } from "../../../config.axios";

export interface ChatResponse {
    message: string;
}

interface ChatState {
    parameterAlerts: {
        load: boolean;
        data: ChatResponse[];
    };
    parameterRadioButton:any;
    storedSolution: {
        load: boolean,
        data: [],
    },
}

const initialState = {
    parameterAlerts: {
        load: false,
        data: [],
    },
    parameterRadioButton:[],
    storedSolution: {
        load: false,
        data: [],
    },
} as ChatState;

export const chatSlice = createSlice({
    name: "alerts",
    initialState,
    reducers: {
        parameterRadioData: (state:any, action:any) => {
            state.parameterRadioButton = [ {...action.payload},...state.parameterRadioButton];
            // state.parameterRadioButton = state.parameterRadioData.push(...action.payload)
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchparametersAlerts.pending, (state) => {
                state.parameterAlerts.load = true;
            })
            .addCase(fetchparametersAlerts.fulfilled, (state, action) => {
                state.parameterAlerts.data=action.payload;
                state.parameterAlerts.load = false;
            })
            .addCase(fetchparametersAlerts.rejected, (state) => {
                state.parameterAlerts.load = false;
            })
            .addCase(storedPreparedSolution.pending, (state) => {
                state.storedSolution.load = true;
            })
            .addCase(storedPreparedSolution.fulfilled, (state, action) => {
                state.storedSolution.data=action.payload;
                state.storedSolution.load = false;
            })
            .addCase(storedPreparedSolution.rejected, (state) => {
                state.storedSolution.load = false;
            });
    },
});

export const { parameterRadioData } = chatSlice.actions;
export default chatSlice.reducer;

export const fetchparametersAlerts = createAsyncThunk(
    "alerts/fetchparametersAlerts",
    async (params: any, thunkAPI) => {
        try {
            const { data } = await DataServer.post("/api/parameter-alerts", {
                ...params
            });
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const storedPreparedSolution = createAsyncThunk(
    "alerts/storedPreparedSolution",
    async (params: any, thunkAPI) => {
        try {
            const { data } = await DataServer.post("/api/stored-prepared-solution", {
                ...params
            });
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);
