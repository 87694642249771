import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartMixed } from "@fortawesome/pro-solid-svg-icons";
import {
  faBell,
  faFiles,
  faGrid2,
  faUser,
  faUserShield,
  faVialCircleCheck,
} from "@fortawesome/pro-light-svg-icons";

export interface itemProp {
  id: number;
  title: string;
  path: string;
  icon: any;
  child: itemProp[];
  classname: string;
  urlCommonWord?: string;
}

export const navbarList: itemProp[] = [
  // {
  //     id: 1,
  //     title: "Dashboard",
  //     path: "/dashboard",
  //     icon: <FontAwesomeIcon icon={faGrid2} />,
  //     child: [],
  //     classname: "dashboard",
  //     urlCommonWord: "dashboard"
  // },
  {
    id: 2,
    title: "User Management",
    path: "/user-management",
    icon: <FontAwesomeIcon icon={faUser} />,
    child: [],
    classname: "usermanagement",
    urlCommonWord: "user",
  },
  {
    id: 3,
    title: "Role Management",
    path: "/role-management",
    icon: <FontAwesomeIcon icon={faUserShield} />,
    child: [],
    classname: "rolemanagement",
    urlCommonWord: "role",
  },
  {
    id: 4,
    title: "Project Management",
    path: "/project-management",
    icon: <FontAwesomeIcon icon={faFiles} />,
    child: [],
    classname: "projectmanagement",
    urlCommonWord: "project",
  },
  {
    id: 5,
    title: "Screen Monitoring",
    path: "/Screen-Monitoring",
    icon: <FontAwesomeIcon icon={faFiles} />,
    child: [],
    classname: "screenmonitoring",
    urlCommonWord: "Screen",
  },
  {
    id: 6,
    title: "Alerts Solution Hub",
    path: "/Alerts-Solution",
    icon: <FontAwesomeIcon icon={faBell} />,
    child: [],
    classname: "alerts_solution",
    urlCommonWord: "Alerts-Solution",
  },
  {
    id: 7,
    title: "View Schedule",
    path: "/View-Schedule",
    icon: <FontAwesomeIcon icon={faBell} />,
    child: [],
    classname: "view-schedule",
    urlCommonWord: "View-Schedule",
  },
];
export const inspectorNavbarList: itemProp[] = [
  {
    id: 1,
    title: "Dashboard",
    path: "/dashboard",
    icon: <FontAwesomeIcon icon={faGrid2} />,
    child: [],
    classname: "dashboard",
    urlCommonWord: "dashboard",
  },
  // {
  //     id: 2,
  //     title: "Projects",
  //     path: "/Project",
  //     icon: <FontAwesomeIcon icon={faFiles}  />,
  //     child: [],
  //     classname: "project",
  //     urlCommonWord: "project"
  // },
  {
    id: 3,
    title: "Screen Monitoring",
    path: "/Screen-Monitoring",
    icon: <FontAwesomeIcon icon={faUserShield} />,
    child: [],
    classname: "screenmonitoring",
    urlCommonWord: "Screen",
  },
  // {
  //     id: 4,
  //     title: "Test Summary",
  //     path: "/TestSummary",
  //     icon: <FontAwesomeIcon icon={faUserShield} />,
  //     child: [],
  //     classname: "testsummary",
  //     urlCommonWord: "TestSummary"
  // },
];
