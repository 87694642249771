import React, { useEffect, useState } from 'react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// import { mapNavbarList, itemProp } from '../../utils/mapNavBarList';
import "../../assets/css/layout/sidebar.css";
import { useAppDispatch, useAppSelector } from '../../hooks';
import { activeComponent, fetchComponents, formPopup, selectedComponent } from '../../redux/slice/Map';
import { faArrowUpFromWaterPump, faTankWater, faSquareRing, faPipe, faWater, faArrowUpFromGroundWater, faPipeSection, faHouseFloodWaterCircleArrowRight, faPipeValve, faHouseWater } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SourceDetails from '../../reusable/Form/sourceDetails';
import IntakeStucture from '../../reusable/Form/intakStructure';
import PumpingStation from '../../reusable/Form/pumpingStation';
import RisingDetails from '../../reusable/Form/risingDetails';
import StorageReservoirs from '../../reusable/Form/storageReservoirs';
import TreatmentFacility from '../../reusable/Form/treatmentFacility';
import ChambersDetails from '../../reusable/Form/chambersDetails';
import ValvesDetails from '../../reusable/Form/valvesDetails';
import PipesDetails from '../../reusable/Form/pipesDetails';
import WaterQualityStationDetails from '../../reusable/Form/waterQualityStationDetails';
import Mains from '../../reusable/Form/Mains';
import { faCircleArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import NetworkUpload from '../../views/pages/NetworkUpload';
const MapSidebar = () => {
  let { componentTab, active } = useAppSelector((state) => state.components);
  const [activeButton, setActiveButton] = useState(null);
  const [componentData, setComponent]: any = useState();
  // const [active,setActive]:any = React.useState()
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const components = useAppSelector((state) => state.components);
  const project = useAppSelector((state) => state.project);

  useEffect(() => {
    if (location?.state?.projectData?.id) {
      dispatch(fetchComponents({ apiParam: { id: location?.state?.projectData?.id, current_step: "mapping " } }))

    }
  }, [location?.state?.projectData?.id]);

  // useEffect(() => {
  //   if (location?.state?.projectData?.id) {
  //     dispatch(fetchComponents({ apiParam: { id: location?.state?.projectData?.id, current_step: "mapping " } }))

  //   }
  // }, []);

  const handleItemClick = (index: any, item: any) => {
    if (componentTab == "mapping") {
      dispatch(activeComponent(item.name))
      // setActiveButton(index === activeButton ? index : index);  
      dispatch(selectedComponent({ item: item, current: true }))
      dispatch(formPopup(false))
    }
  }

  const backToDashboard = () => {
    navigate(-1)
  }
  const Element = [
    { name: "Source of Intake", icon: faWater, component: <SourceDetails /> },
    { name: "Intake Structure", icon: faArrowUpFromGroundWater, component: <IntakeStucture /> },
    { name: "Pumping Station", icon: faArrowUpFromWaterPump, component: <PumpingStation /> },
    { name: "Rising / Gravity Main", icon: faPipeSection, component: <RisingDetails /> },
    { name: "Treatment Facility", icon: faHouseFloodWaterCircleArrowRight, component: <TreatmentFacility /> },
    { name: "Storage Reservoirs", icon: faTankWater, component: <StorageReservoirs /> },
    { name: "Chambers", icon: faSquareRing, component: <ChambersDetails /> },
    { name: "Valves", icon: faPipeValve, component: <ValvesDetails /> },
    { name: "Pipes", icon: faPipe, component: <PipesDetails /> },
    { name: "Water Quality Station", icon: faHouseWater, component: <WaterQualityStationDetails /> },
    { name: "Mains", icon: faWater, component: <Mains /> },
    { name: "River Basin", icon: faWater, component: <SourceDetails /> },
  ]

  useEffect(() => {
    if (components?.component?.data) {
      const newArray1: any[] = components?.component?.data.map((item1: { name: any; }) => {
        const matchingItem = Element.find((item2: { name: any; }) => (item2.name.toLowerCase()) === (item1.name.toLowerCase()));
        return {
          ...item1,
          icon: matchingItem ? matchingItem.icon : null,
          component: matchingItem ? matchingItem.component : null
        };
      });

      setComponent(newArray1)
    } else {
      setComponent()
    }

  }, [components.component?.data])





  // Call the merge function with your arrays




  return (
    <div className='map-sidebar text-[#132144] '>

      <Sidebar className='px-3 mt-3 relative'>
        <Menu>
          <MenuItem onClick={backToDashboard} className='border-b pb-1'><FontAwesomeIcon icon={faCircleArrowLeft} /><span className='ml-3'>Back To Dashboard</span></MenuItem>
          
         {componentData &&
            componentData.map((item: any, index: number) => (
              <>
              {
                componentTab == "mapping" ?
              
              <MenuItem
                // icon={item.icon}
                key={item.id}
                // key={`${item.classname}_${index}`}
                // active={location.pathname.includes(`${item.urlCommonWord}`)}
                // component={<Link to={item.path} />}
                className={`sidebar-menuitem ${item.name === active ? 'active-btn hover:bg-[#E1EDFF]' : 'not-active'}`}
                onClick={() => handleItemClick(index, item)}
              // selected={location.pathname === `${value.path}`}
              >
                <div className="flex relative sidebartest">
                  <div><FontAwesomeIcon icon={item.icon ? item.icon : faWater} className='h-4 w-4 mr-2' /></div>
                  <div>{item.name}</div>
                  {
                    item.count <= 0 ? null : <div className='bg-[#377DFF] px-[6px] py-1 text-white rounded-[100%] text-[12px] leading-[12px] absolute right-0'>{item.count}</div>
                  }


                </div>
              </MenuItem> :
              <MenuItem
              // icon={item.icon}
              key={item.id}
              // key={`${item.classname}_${index}`}
              // active={location.pathname.includes(`${item.urlCommonWord}`)}
              // component={<Link to={item.path} />}
              className={`sidebar-menuitem ${item.name === active ? 'active-btn hover:bg-[#E1EDFF]' : 'not-active checklist-nav'}`}
              onClick={() => handleItemClick(index, item)}
            // selected={location.pathname === `${value.path}`}
            >
              <div className="flex relative sidebartest">
                <div><FontAwesomeIcon icon={item.icon ? item.icon : faWater} className='h-4 w-4 mr-2' /></div>
                <div>{item.name}</div>
                {
                  item.count <= 0 ? null : <div className='bg-[#377DFF] px-[6px] py-1 text-white rounded-[100%] text-[12px] leading-[12px] absolute right-0'>{item.count}</div>
                }


              </div>
            </MenuItem>

              }
              </>

            ))}

          <MenuItem onClick={openModal} className='bg-[#E1EDFF] last-list'><span className='ml-3 text-[12px] text-center text-[#377DFF]'>Import Water Supply Network</span></MenuItem>
        </Menu>

      </Sidebar>
      <NetworkUpload projectData={location?.state} isOpen={modalIsOpen} onRequestClose={closeModal} />
    </div>
  )
}
export default MapSidebar;
