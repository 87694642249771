import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DataServer } from "../../../config.axios";
import axios from "axios";

export interface RegionData {
    country: any[];
    state: any[];
    district: any[];

}
// export interface CountryData{
//     name:string,
//     id:number
// }
interface RegionState {
    country: {
        load: boolean;
        data: Array<RegionData>;
        error: Array<RegionData>;
    };
    state: {
        load: boolean;
        data: Array<RegionData>;
        error: Array<RegionData>;
    };
    district: {
        load: boolean;
        data: Array<RegionData>;
        error: Array<RegionData>;
    };
    regionData: {
        country: object,
        state: object,
        district: object,
    },

}
const initialState = {
    country: {
        load: false,
        data: [],
        error: []
    },
    state: {
        load: false,
        data: [],
        error: []
    },
    district: {
        load: false,
        data: [],
        error: []
    },
    regionData: {
        country: {},
        state: {},
        district: {},
    },

} as RegionState;

export const regionSlice = createSlice({
    name: "region",
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchCountry.pending, (state: RegionState) => {
                state.country.load = true;
            })
            .addCase(fetchCountry.fulfilled, (state: RegionState, action: any) => {
                state.country.data = action.payload;
                state.country.load = false;
            })
            .addCase(fetchCountry.rejected, (state: RegionState) => {
                state.country.load = false;
            })
            .addCase(fetchState.pending, (state: RegionState) => {
                state.state.load = true;
            })
            .addCase(fetchState.fulfilled, (state: RegionState, action: any) => {
                state.state.data = action.payload;
                state.state.load = false;
            })
            .addCase(fetchState.rejected, (state: RegionState) => {
                state.state.load = false;
            })
            .addCase(fetchDistrict.pending, (state: RegionState) => {
                state.district.load = true;
            })
            .addCase(fetchDistrict.fulfilled, (state: RegionState, action: any) => {
                state.district.data = action.payload;
                state.district.load = false;
            })
            .addCase(fetchDistrict.rejected, (state: RegionState) => {
                state.district.load = false;
            })
            .addCase(allRegionData.pending, (state: RegionState) => {
                // state.district.load = true;
            })
            .addCase(allRegionData.fulfilled, (state: RegionState, action: any) => {
                state.regionData = action.payload;
                // state.district.load = false;
            })
            .addCase(allRegionData.rejected, (state: RegionState) => {
                // state.district.load = false;
            })
    }
})

export default regionSlice.reducer;

export const fetchCountry = createAsyncThunk(
    "region/fetchCountry",
    async (thunkAPI) => {
        try {
            const { data } = await DataServer.get("api/CountryData");
            return data;
        } catch (error) {
            return error;
        }
    }
);
export const fetchState = createAsyncThunk(
    "region/fetchState",
    async (param: any, thunkAPI) => {
        try {
            const response = await axios.get(
                'https://mellow-atoll-erlkqypoe1cy.vapor-farm-c1.com/api/StateData',
                {
                    params: param,
                    headers: { 'Content-Type': 'application/json' }
                }
            );
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);
export const fetchDistrict = createAsyncThunk(
    "region/fetchDistrict",
    async (param: any, thunkAPI) => {

        try {
            const response = await axios.get(
                'https://mellow-atoll-erlkqypoe1cy.vapor-farm-c1.com/api/DistrictData',
                {
                    params: param,
                    headers: { 'Content-Type': 'application/json' }
                }
            );
            return response.data;
        } catch (error) {
            return error;
        }
    }
);

export const allRegionData = createAsyncThunk(
    "region/allRegionData",
    async (param: any, thunkAPI) => {

        try {
            const countryData = await DataServer.get("api/CountryData");
            const selectedCountry = countryData.data.data.find((item: any) => item.id === param?.country_id);

            const stateData = await axios.get(
                'https://mellow-atoll-erlkqypoe1cy.vapor-farm-c1.com/api/StateData',
                {
                    params: { country_id: param?.country_id },
                    headers: { 'Content-Type': 'application/json' }
                }
            );

            const selectedState = stateData.data.data?.find((item: any) => item.id === param?.state_id);

            const districtData = await axios.get(
                'https://mellow-atoll-erlkqypoe1cy.vapor-farm-c1.com/api/DistrictData',
                {
                    params: { state_id: param?.state_id },
                    headers: { 'Content-Type': 'application/json' }
                }
            );
            const selectedDistrict = districtData.data.data?.find((item: any) => item.id === param?.district_id);
            thunkAPI.dispatch(fetchDistrict({ state_id: selectedState.id }));
            return {
                country: selectedCountry,
                state: selectedState,
                district: selectedDistrict
            };
        } catch (error) {
            return error;
        }
    }
);
