import React, { useEffect, useState } from "react";
import PageTitle from "../PageTitle";
import "../../assets/css/reusable/screenmonitoring.css";
import { useAppSelector } from "../../hooks";

const CalculationMethod = () => {
  const monitoring = useAppSelector((state) => state.monitoring);
  const [calculationData, setCalculationData]: any = useState(null);
  useEffect(() => {
    if (monitoring.screenStats.data?.getWaterQualityIndex) {
      setCalculationData(
        transformData(monitoring.screenStats.data.getWaterQualityIndex)
      );
    } else {
      setCalculationData(transformData());
    }
  }, [monitoring.screenStats.data?.getWaterQualityIndex]);
  const classifyRange = (range: any) => {
    if (range >= 63 && range <= 100) {
      return "Good to Excellent";
    } else if (range >= 50 && range < 63) {
      return "Medium to Good";
    } else if (range >= 38 && range < 50) {
      return "Bad";
    } else if (range < 38) {
      return "Bad to Very Bad";
    } else {
      return "-"; // for handling null or undefined values
    }
  };

  const transformData = (data?: any) => {
    return [
      {
        id: 1,
        name: "NSFWQI",
        range: data?.nfs_wqi || "N/A", // Assuming you might have a value here, or default to "N/A"
        // name: data.nfs_category || "Unknown", // Assuming you might have a value here, or default to "Unknown"
        btndata: data?.nfs_category || "-",
        color: data?.nfs_color_code,
      },
      {
        id: 2,
        name: "Weighted Arithmetic",
        range: data?.weighted_arithmetic_wqi || "N/A",
        //name: data.weighted_arithmetic_category || "Unknown",
        btndata: data?.weighted_arithmetic_category || "-",
        color: data?.weighted_arithmetic_color_code,
      },
      {
        id: 3,
        name: "Fuzzy Logic",
        range: data?.fuzzy_logic_wqi || "N/A",
        //name: data.fuzzy_logic_category || "Unknown",
        btndata: data?.fuzzy_logic_category || "-",
        color: data?.fuzzy_logic_color_code,
      },
    ];
  };
  return (
    <div className="bg-[#222230] rounded-md px-4 py-3 code-compliance-chart">
      <h1 className="text-[#FFF] font-bold text-[22px] border-b border-[#4A4A68] pb-2">
        Water Quality Index{" "}
      </h1>
      <div className="p-4">
        {calculationData &&
          calculationData.map((data: any) => (
            <div className="grid grid-cols-3 text-[#FFF] text-[12px] font-medium leading-normal tracking-[0.5px]">
              <p className="inline-flex items-center">{data.name}</p>
              <p className="inline-flex items-center justify-center">
                {data.range}
              </p>
              <button
                style={{ backgroundColor: data.color }}
                className={`inline-flex items-center justify-end text-[10px] font-semibold p-2 rounded-md text-[#FFF]`}
              >
                {data.btndata}
              </button>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CalculationMethod;
