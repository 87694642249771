import { toast } from "react-toastify";
import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export interface RoleData {
    role: string;
    id: any;
    permissionAssign: number;
}

interface RoleState {
    roles: {
        load: boolean;
        data: Array<RoleData>;
        error: Array<RoleData>;
    };
    roleData?: RoleData;
    editRole: {
        load: boolean;
    };
}
const initialState = {
    roles: {
        load: false,
        data: [],
        error: []
    },
    roleData: {
        role: "",
        id: "",
        permissionAssign: 0
    },
    editRole: {
        load: false,
    },
    deleteRole: {
        load: false,
    },
} as RoleState;

export const roleSlice = createSlice({
    name: "roles",
    initialState,
    reducers: {
        resetRoleData: (state: RoleState) => {
            state.roleData = initialState.roleData;
            state.roles.error = []
        },
        updateRoleData: (state: RoleState, action: { payload: RoleData }) => {
            state.roleData = action.payload;
        },
        addRoleError: (state: RoleState, action: any) => {
            state.roles.error = action.payload;
        },
        addRoleSuccess: (state: RoleState, action: any) => {
            if (action.payload) {
                state.roles.data = [{ ...action.payload }, ...state.roles.data];
            }
            state.roles.load = false;
        },
        editRoleSuccess: (state: RoleState, action: any) => {
            if (action.payload) {

                const editedUserIndex = state.roles.data.findIndex(role => role.id === action.payload.id);
                let index = { ...state.roles.data[editedUserIndex] }
                if (editedUserIndex !== -1) {
                    state.roles.data[editedUserIndex] = { ...action.payload, permissionAssign: state.roles.data[editedUserIndex].permissionAssign }
                }
            }
            state.roles.load = false;
        },
        deleteRoleSuccess: (state: RoleState, action: any) => {
            if (action.payload) {
                const userIdToDelete = action.payload.id;
                const indexToDelete = state.roles.data.findIndex(role => role.id === userIdToDelete);
                if (indexToDelete !== -1) {
                    state.roles.data.splice(indexToDelete, 1);
                }
            }
            state.roles.load = false;
        }
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchRoles.pending, (state: RoleState) => {
                state.roles.load = true;
            })
            .addCase(fetchRoles.fulfilled, (state: RoleState, action: any) => {
                state.roles.data = action.payload;
                state.roles.load = false;
            })
            .addCase(fetchRoles.rejected, (state: RoleState) => {
                state.roles.load = false;
            })
            .addCase(fetchRoleById.pending, (state: RoleState) => {
                state.editRole.load = true;
            })
            .addCase(fetchRoleById.fulfilled, (state: RoleState, action: any) => {
                state.roleData = action.payload;
                state.editRole.load = false;
            })
            .addCase(fetchRoleById.rejected, (state: RoleState) => {
                state.editRole.load = false;
            })
            .addCase(selectedRoles.pending, (state: RoleState, action: any) => {
                state.roleData = action.payload;
            })
            .addCase(selectedRoles.fulfilled, (state: RoleState, action: any) => {
                state.roleData = action.payload;
                state.roleData = action.payload;
            })
            .addCase(selectedRoles.rejected, (state: RoleState, action: any) => {
                state.roleData = action.payload;
            });
    },
});
export const { updateRoleData, resetRoleData, addRoleError, editRoleSuccess, deleteRoleSuccess, addRoleSuccess } = roleSlice.actions;
export default roleSlice.reducer;

export const fetchRoles = createAsyncThunk(
    "roles/fetchRoles",
    async (param, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.get("api/roles");
            // thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);


export const addRole = createAsyncThunk(
    "roles/addRole",
    async (param: { apiParam: any, closeModal: any }, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/roles", {
                role: param.apiParam.role
            });
            param.closeModal();

            toast.success('Role added successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });
            thunkAPI.dispatch(addRoleSuccess({ ...data.data, id: data.data.id, permissionAssign: 0 }));

            return data;

        } catch (error: any) {
            thunkAPI.dispatch(addRoleError(error));
            return error;
        }
    }
);

export const fetchRoleById = createAsyncThunk(
    "roles/fetchRoleById",
    async (param: { apiParam: { id: any } }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/edit-roles", {
                id: param.apiParam.id
            });
            // thunkAPI.dispatch(toggleLoading());
            return data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const updateRole = createAsyncThunk(
    "roles/updateRole",
    async (param: { apiParam: any, closeModal: any }, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/update-roles", {
                role: param.apiParam.role,
                id: param.apiParam.id
            });
            thunkAPI.dispatch(editRoleSuccess({ ...data.data, id: param.apiParam.id }));
            param.closeModal();

            toast.success('Role updated successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });


            return data;
        } catch (error: any) {
            thunkAPI.dispatch(addRoleError(error));
            return error;
        }
    }
);

export const deleteRole = createAsyncThunk(
    "roles/deleteRole",
    async (param: { apiParam: any }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/delete-roles", {
                id: param.apiParam.id
            });
            // thunkAPI.dispatch(toggleLoading());
            param.apiParam.closeModal();

            toast.success('Role deleted successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });
            thunkAPI.dispatch(deleteRoleSuccess({ ...data.data, id: param.apiParam.id }))


            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            // thunkAPI.dispatch(ErrorMessage(error));
            return error;
        }
    }
);

export const selectedRoles = createAsyncThunk(
    "roles/selectedRoles",
    async (param, thunkAPI) => {
        try {
            
            return param
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);
