import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { fetchComponents } from "../Map";
import { fetchCoordinates } from "../coordinate";

export interface StorageReservoirData {
    component_id: number,
    coordinate_id: number,
    type_of_reservoir: string,
    capacity: number,
    material: string,
    wall_thickness: number,
    id: number
}

interface StorageReservoirsState {
    storageReservoirs: {
        load: boolean;
        data: Array<StorageReservoirData>;
        error: Array<StorageReservoirData>;
    };
    selectedStorageReservoir?: StorageReservoirData;
    editStorageReservoir: {
        load: boolean;
    };
}
const initialState = {
    storageReservoirs: {
        load: false,
        data: [],
        error: []
    },
    selectedStorageReservoir: {
        component_id: "",
        coordinate_id: "",
        type_of_reservoir: "",
        capacity: "",
        material: "",
        wall_thickness: "",
        id: ""
    },
    editStorageReservoir: {
        load: false,
    },
    deleteStorageReservoir: {
        load: false,
    },
} as unknown as StorageReservoirsState;

export const storageReservoirsSlice = createSlice({
    name: "storageReservoirs",
    initialState,
    reducers: {
        resetStorageReservoirData: (state: StorageReservoirsState) => {
            state.selectedStorageReservoir = initialState.selectedStorageReservoir;
            state.storageReservoirs.error = []
        },
        updateData: (state: StorageReservoirsState, action: { payload: StorageReservoirData }) => {
            state.selectedStorageReservoir = action.payload;
        },
        addStorageReservoirError: (state: StorageReservoirsState, action: any) => {
            state.storageReservoirs.error = action.payload;
        },
        addStorageReservoirSuccess: (state: StorageReservoirsState, action: any) => {
            if (action.payload) {
                state.storageReservoirs.data = [{ ...action.payload }, ...state.storageReservoirs.data];
            }
            state.storageReservoirs.load = false;
        },
        editStorageReservoirSuccess: (state: StorageReservoirsState, action: any) => {
            if (action.payload) {

                const editedStorageReservoirIndex = state.storageReservoirs.data.findIndex(storageReservoir => storageReservoir.id === action.payload.id);

                if (editedStorageReservoirIndex !== -1) {
                    state.storageReservoirs.data[editedStorageReservoirIndex] = { ...action.payload };
                }
            }
            state.storageReservoirs.load = false;
        },
        deleteStorageReservoirSuccess: (state: StorageReservoirsState, action: any) => {
            if (action.payload) {
                const storageReservoirIdToDelete = action.payload.id;
                const indexToDelete = state.storageReservoirs.data.findIndex(storageReservoir => storageReservoir.id === storageReservoirIdToDelete);
                if (indexToDelete !== -1) {
                    state.storageReservoirs.data.splice(indexToDelete, 1);
                }
            }
            state.storageReservoirs.load = false;
        }
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchStorageReservoirs.pending, (state: StorageReservoirsState) => {
                state.storageReservoirs.load = true;
            })
            .addCase(fetchStorageReservoirs.fulfilled, (state: StorageReservoirsState, action: any) => {
                state.storageReservoirs.data = action.payload;
                state.storageReservoirs.load = false;
            })
            .addCase(fetchStorageReservoirs.rejected, (state: StorageReservoirsState) => {
                state.storageReservoirs.load = false;
            })
            .addCase(fetchStorageReservoirById.pending, (state: StorageReservoirsState) => {
                state.editStorageReservoir.load = true;
            })
            .addCase(fetchStorageReservoirById.fulfilled, (state: StorageReservoirsState, action: any) => {
                state.selectedStorageReservoir = action.payload;
                state.editStorageReservoir.load = false;
            })
            .addCase(fetchStorageReservoirById.rejected, (state: StorageReservoirsState) => {
                state.editStorageReservoir.load = false;
            });
    },
});
export const { updateData, resetStorageReservoirData, addStorageReservoirError, addStorageReservoirSuccess, editStorageReservoirSuccess, deleteStorageReservoirSuccess } = storageReservoirsSlice.actions;
export default storageReservoirsSlice.reducer;

export const fetchStorageReservoirs = createAsyncThunk(
    "storageReservoirs/fetchStorageReservoirs",
    async (param: any, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("/api/user-wise-storageReservoir-list", {
                user_id: param
            });
            // thunkAPI.dispatch(toggleLoading());
            return data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const fetchStorageReservoirById = createAsyncThunk(
    "storageReservoirs/fetchStorageReservoirById",
    async (param: any, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/edit-storage-reservoir", {
                coordinate_id: param
            });
            // thunkAPI.dispatch(toggleLoading());
            return data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const addStorageReservoir = createAsyncThunk(
    "storageReservoirs/addStorageReservoir",
    async (param: { apiParam: any, closeModal: any }, thunkAPI) => {
        try {
            const coordinates = await DataServer.post(
                "api/coordinates",
                { component_id: param.apiParam.component_id, latitude: param.apiParam.latitude, longitude: param.apiParam.longitude }
            );
            const { data } = await DataServer.post(
                "api/storage-reservoir",
                { ...param.apiParam, coordinate_id: coordinates.data.data.id }
            );
            thunkAPI.dispatch(fetchComponents({ apiParam: { id: param.apiParam.project_id, current_step: "mapping" } }))
            thunkAPI.dispatch(fetchCoordinates(param.apiParam.project_id))


            toast.success('Storage Reservoir Added Successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });
            param.closeModal();
            // thunkAPI.dispatch(addStorageReservoirSuccess({...data.data,role:param.apiParam.role,id:data.data.id}));
            return { ...data.data };
        } catch (error: any) {
            thunkAPI.dispatch(addStorageReservoirError(error));
            return error;
        }
    }
);

export const editStorageReservoir = createAsyncThunk(
    "storageReservoirs/editStorageReservoir",
    async (param: { apiParam: any, closeModal: any }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/update-storage-reservoir", param.apiParam);
            // thunkAPI.dispatch(toggleLoading());
            thunkAPI.dispatch(editStorageReservoirSuccess({ ...data.data, role: param.apiParam.role }));


            toast.success('Storage Reservoir Updated Successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });
            param.closeModal();
            return data.data;
        } catch (error: any) {
            // thunkAPI.dispatch(toggleLoading());
            // thunkAPI.dispatch(ErrorMessage(error));
            thunkAPI.dispatch(addStorageReservoirError(error));
            return error;
        }
    }
);

export const deleteStorageReservoir = createAsyncThunk(
    "storageReservoirs/deleteStorageReservoir",
    async (param: { apiParam: any }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/delete-storageReservoir", {
                id: param.apiParam.id
            });
            // thunkAPI.dispatch(toggleLoading());
            param.apiParam.closeModal();
            thunkAPI.dispatch(deleteStorageReservoirSuccess({ ...data.data, role: param.apiParam.role, id: param.apiParam.id }))
            toast.success('Storage Reservoir deleted successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });

            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            // thunkAPI.dispatch(ErrorMessage(error));
            return error;
        }
    }
);
