import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
interface ComponentState {
    component: {
        load: boolean;
        data: [];
    };
    selectedComponent: {
        load: boolean;
        data: {};
        error: Array<any>;
    };
    network: {
        load: boolean;
        data: any;
    };
    current: boolean,
    form: boolean,
    componentTab: string,
    active: string
}
export const componentSlice = createSlice({
    name: "component",
    initialState: {
        component: {
            load: false,
            data: [],
        },
        network: {
            load: false,
            data: {}
        },
        selectedComponent: {
            load: false,
            data: {},
            error: []
        },
        current: false,
        form: false,
        componentTab: "mapping",
        active: "null"
    } as ComponentState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchComponents.pending, (state: ComponentState) => {
                state.component.load = true;
            })
            .addCase(fetchComponents.fulfilled, (state: ComponentState, action: any) => {
                state.component.data = action.payload;
                state.component.load = false;
            })
            .addCase(fetchComponents.rejected, (state: ComponentState) => {
                state.component.load = false;
            })
            .addCase(selectedComponent.pending, (state: ComponentState, action: any) => {
                state.selectedComponent = action.payload;
            })
            .addCase(selectedComponent.fulfilled, (state: ComponentState, action: any) => {
                state.selectedComponent = action.payload.item;
                state.current = action.payload.current
            })
            .addCase(selectedComponent.rejected, (state: ComponentState, action: any) => {
                state.selectedComponent = action.payload;
                state.current = false
            })
            .addCase(currentStateChange.pending, (state: ComponentState, action: any) => {
                state.current = false
            })
            .addCase(currentStateChange.fulfilled, (state: ComponentState, action: any) => {
                state.current = action.payload
            })
            .addCase(currentStateChange.rejected, (state: ComponentState, action: any) => {

                state.current = false
            })
            .addCase(formPopup.pending, (state: ComponentState, action: any) => {
                state.form = false
            })
            .addCase(formPopup.fulfilled, (state: ComponentState, action: any) => {
                state.form = action.payload
            })
            .addCase(formPopup.rejected, (state: ComponentState, action: any) => {
                state.form = false
            })
            .addCase(activeComponent.pending, (state: ComponentState, action: any) => {
                // state.active = false
            })
            .addCase(activeComponent.fulfilled, (state: ComponentState, action: any) => {
                state.active = action.payload
            })
            .addCase(activeComponent.rejected, (state: ComponentState, action: any) => {
                // state.active = false
            })
            .addCase(componentMaping.pending, (state: ComponentState, action: any) => {
                state.componentTab = "mapping"
            })
            .addCase(componentMaping.fulfilled, (state: ComponentState, action: any) => {
                state.componentTab = action.payload
            })
            .addCase(componentMaping.rejected, (state: ComponentState, action: any) => {
                state.componentTab = "mapping"
            })
            .addCase(fetchNetwork.pending, (state: ComponentState, action: any) => {
                state.network.load = true
            })
            .addCase(fetchNetwork.fulfilled, (state: ComponentState, action: any) => {

                state.network.data = action.payload;
                state.network.load = false;
            })
            .addCase(fetchNetwork.rejected, (state: ComponentState, action: any) => {
                state.network.load = false
            })

    },
});
export default componentSlice.reducer;
export const fetchComponents = createAsyncThunk(
    "component/fetchComponents",
    async (param: { apiParam: any }) => {
        try {
            const { data } = await DataServer.post("api/project-wise-components", param.apiParam);
            return data.data;
        } catch (error) {
        }
    }
);

export const selectedComponent = createAsyncThunk(
    "component/selectedComponent",
    async (param: any) => {
        try {
            return param
        } catch (error) {
            return error;
        }
    }
);

export const currentStateChange = createAsyncThunk(
    "component/currentStateChange",
    async (param: any) => {
        try {
            return param
        } catch (error) {
            return error;
        }
    }
);

export const formPopup = createAsyncThunk(
    "component/formPopup",
    async (param: any) => {
        try {
            return param
        } catch (error) {
            return error;
        }
    }
);

export const activeComponent = createAsyncThunk(
    "component/activeComponent",
    async (param: any) => {
        try {
            return param
        } catch (error) {
            return error;
        }
    }
);

export const componentMaping = createAsyncThunk(
    "component/componentMaping",
    async (param: any) => {
        try {
            return param
        } catch (error) {
            return error;
        }
    }
);

export const uploadNetwork = createAsyncThunk(
    "component/uploadNetwork",
    async (param: any, thunkAPI) => {
        try {
            // const { data } = await DataServer.post("api/upload-network", param);
            const { data } = await DataServer.post("api/upload-network", param, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            await thunkAPI.dispatch(fetchNetwork({ "project_id": param.project_id }))
            // return data;
        } catch (error) {
        }
    }
);

export const fetchNetwork = createAsyncThunk(
    "component/fetchNetwork",
    async (param: any) => {
        try {
            const { data } = await DataServer.post("api/fetch-projectwise-uploaded-network", param);

            return data;
        } catch (error) {
        }
    }
);