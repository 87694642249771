import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useNavigate, Navigate, redirect } from "react-router-dom";
import { toast } from 'react-toastify';
import { selectedComponent } from "../Map";

export interface ProjectData {
    id: number;
    company_id: number;
    project_name: string;
    scheme_id: string;
    create_type: string;
    created_by: number;
    managed_by: string;
    capacity: number;
    unit: string;
    description: string;
}

interface ProjectsState {
    projects: {
        load: boolean;
        data: Array<ProjectData>;
        error: Array<ProjectData>;
    };
    selectedProject?: ProjectData;
    editProject: {
        load: boolean;
    };
    selectedProjectRedirect: boolean
}
const initialState = {
    projects: {
        load: false,
        data: [],
        error: []
    },
    selectedProject: {
        id: "",
        company_id: "",
        project_name: "",
        scheme_id: "",
        create_type: "",
        created_by: "",
        managed_by: "",
        capacity: "",
        unit: "",
        description: "",
    },
    editProject: {
        load: false,
    },
    deleteProject: {
        load: false,
    },
    selectedProjectRedirect: false
} as unknown as ProjectsState;

export const projectsSlice = createSlice({
    name: "projects",
    initialState,
    reducers: {
        resetProjectData: (state: ProjectsState) => {
            state.selectedProject = initialState.selectedProject;
            state.projects.error = []
        },
        updateData: (state: ProjectsState, action: { payload: ProjectData }) => {
            state.selectedProject = action.payload;
        },
        addProjectError: (state: ProjectsState, action: any) => {
            state.projects.error = action.payload;
        },
        addProjectSuccess: (state: ProjectsState, action: any) => {
            if (action.payload) {
                state.projects.data = [{ ...action.payload }];
                state.selectedProject = action.payload;
                state.selectedProjectRedirect = true
            }
            state.projects.load = false;
        },
        addProjectRedirect: (state: ProjectsState, action: any) => {

            state.selectedProjectRedirect = false

        },
        editProjectSuccess: (state: ProjectsState, action: any) => {
            if (action.payload) {

                const editedProjectIndex = state.projects.data.findIndex(project => project.id === action.payload.id);

                if (editedProjectIndex !== -1) {
                    state.projects.data[editedProjectIndex] = { ...action.payload };
                }
            }
            state.projects.load = false;
        },
        deleteProjectSuccess: (state: ProjectsState, action: any) => {
            if (action.payload) {
                const projectIdToDelete = action.payload.id;
                const indexToDelete = state.projects.data.findIndex(project => project.id === projectIdToDelete);
                if (indexToDelete !== -1) {
                    state.projects.data.splice(indexToDelete, 1);
                }
            }
            state.projects.load = false;
        },
        redirectUrl: (state: any) => {
            state.selectedProjectRedirect = false
        }
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchProjects.pending, (state: ProjectsState) => {
                state.projects.load = true;
            })
            .addCase(fetchProjects.fulfilled, (state: ProjectsState, action: any) => {
                state.projects.data = action.payload;
                state.projects.load = false;
            })
            .addCase(fetchProjects.rejected, (state: ProjectsState) => {
                state.projects.load = false;
            })
            .addCase(fetchProjectById.pending, (state: ProjectsState) => {
                state.editProject.load = true;
            })
            .addCase(fetchProjectById.fulfilled, (state: ProjectsState, action: any) => {
                state.selectedProject = action.payload;
                state.editProject.load = false;
            })
            .addCase(fetchProjectById.rejected, (state: ProjectsState) => {
                state.editProject.load = false;
            });
    },
});
export const { updateData, resetProjectData, addProjectError, addProjectSuccess, editProjectSuccess, deleteProjectSuccess, redirectUrl } = projectsSlice.actions;
export default projectsSlice.reducer;

export const fetchProjects = createAsyncThunk(
    "projects/fetchProjects",
    async (param: any, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("/api/user-wise-project-list", {
                user_id: param
            });
            // thunkAPI.dispatch(toggleLoading());
            return data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const fetchProjectById = createAsyncThunk(
    "projects/fetchProjectById",
    async (param: any, thunkAPI) => {
        try {
            return param;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const addProject = createAsyncThunk(
    "projects/addProject",
    async (param: any, thunkAPI) => {
        try {
            const { data } = await DataServer.post(
                "api/project",
                param
            );

            toast.success('Project created successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });
            const predefined = await DataServer.post(
                "/api/store-predefined-components",
                { project_id: data.data.id }
            );

            //   return navigate
            thunkAPI.dispatch(addProjectSuccess({ ...data.data, id: data.data.id }));

            return { ...data.data };
        } catch (error: any) {
            thunkAPI.dispatch(addProjectError(error));
            return error;
        }
    }
);

export const editProject = createAsyncThunk(
    "projects/editProject",
    async (param: { apiParam: any, closeModal: any }, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/update-project", param.apiParam);
            // thunkAPI.dispatch(toggleLoading());
            thunkAPI.dispatch(editProjectSuccess({ ...data.data, role: param.apiParam.role }));
            param.closeModal();

            toast.success('Project updated successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });

            return data.data;
        } catch (error: any) {
            // thunkAPI.dispatch(toggleLoading());
            // thunkAPI.dispatch(ErrorMessage(error));
            thunkAPI.dispatch(addProjectError(error));
            return error;
        }
    }
);

export const deleteProject = createAsyncThunk(
    "projects/deleteProject",
    async (param: { apiParam: any }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/delete-project", {
                id: param.apiParam.id
            });
            // thunkAPI.dispatch(toggleLoading());
            param.apiParam.closeModal();
            thunkAPI.dispatch(deleteProjectSuccess({ ...data.data, role: param.apiParam.role, id: param.apiParam.id }))
            toast.success('Project deleted successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });

            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            // thunkAPI.dispatch(ErrorMessage(error));
            return error;
        }
    }
);
