import { combineReducers } from "@reduxjs/toolkit";
import Users from "./slice/Users";
import Role from "./slice/Role";
import Permission from "./slice/Permission";
import Component from "./slice/Map";
import Profile from "./slice/Profile";
import Project from "./slice/Project";
import IntakeStructure from "./slice/IntakeStructure";
import pumpingStation from "./slice/pumpingStation";
import chamber from "./slice/chamber";
import risingGravityMain from "./slice/risingGravityMain";
import storageReservoir from "./slice/storageReservoir";
import waterQualityStation from "./slice/waterQualityStation";
import valve from "./slice/valve";
import pipe from "./slice/pipe";
import coordinate from "./slice/coordinate";
import SourceOfIntake from "./slice/SourceOfIntake";
import TreatmentFacility from "./slice/TreatmentFacility";
import region from "./slice/region";
import checklist from "./slice/checklist";
import inspectorChecklist from "./slice/inspectorChecklist";
import monitoring from "./slice/monitoring";
import Gpt from "./slice/Gpt";
import alerts from "./slice/alerts";



export default (history: any) =>
    combineReducers({
        users: Users,
        roles: Role,
        permission:Permission,
         profile:Profile,
         components:Component,
         project:Project,
         intakeStructure:IntakeStructure,
         pumpingStation:pumpingStation,
         chamber:chamber,
         risingGravityMain:risingGravityMain,
         storageReservoir:storageReservoir,
         waterQualityStation:waterQualityStation,
         valve:valve,
         pipe:pipe,
         coordinate:coordinate,
         sourceOfIntake:SourceOfIntake,
         treatmentFacility:TreatmentFacility,
         region:region,
         checklist:checklist,
         inspectorChecklist:inspectorChecklist,
         monitoring:monitoring,
         chat: Gpt,
         alerts:alerts
    });

export const resetEnhancer =
    (rootReducer: Function) => (state: any, action: any) => {
        if (action.type !== "user/userLogout") return rootReducer(state, action);
        const resetState = rootReducer(undefined, {});
        resetState.router = state.router;
        return resetState;
    };
