import React, { useEffect, useState } from "react";
import FormField from '../Forms/FormField'
// import SelectField from '../SelectField'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as yup from 'yup';
// import { FormValidation } from "../../Utils/formValidation";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import '../../assets/css/reusable/form.css'
import SelectField from "../Forms/SelectField";
// import { useDispatch } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { activeComponent, formPopup } from "../../redux/slice/Map";
import { useLocation } from "react-router-dom";
import { addTreatmentFacility, editTreatmentFacility, fetchTreatmentFacilityById } from "../../redux/slice/TreatmentFacility";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import Loading from "../../views/pages/Loading";
interface FormData {
    type: string;
    latitude: string;
    longitude: string;
    capacity: string;
    hours: string;
    owner: string;
    site: string;
    predefined_process: Array<any>
    custom_process: Array<any>
    process: string;
}
const TreatmentFacility: any = (props: any) => {
    const dispatch = useAppDispatch();
    const [processes, setProcesses] = useState<string[]>([]);
    const [customProcesses, setCustomProcesses] = useState<string[]>([]);
    const [resetformData, setResetFormData] = useState<FormData>({
        type: "",
        latitude: "",
        longitude: "",
        capacity: "",
        hours: "",
        owner: "",
        site: "",
        predefined_process: [],
        process: "",
        custom_process: []
    });
    const [formData, setFormData] = useState<any>({
        type: "",
        latitude: "",
        longitude: "",
        capacity: "",
        hours: "",
        owner: "",
        site: "",
        predefined_process: [],
        process: "",
        custom_process: []
    });
    const location = useLocation();
    const components = useAppSelector((state: { components: any; }) => state.components)
    const treatmentFacility = useAppSelector((state: { treatmentFacility: any; }) => state.treatmentFacility)
    const typeOptions: any = [{ "value": "Select" }, { "value": "Conventional WTP" }, { "value": "Unconventional WTP" }]
    const convertedsystemOptions = typeOptions.map((option: { value: string; }) => option.value);
    const formSchema = yup.object({
        type: yup.string()
            .oneOf(convertedsystemOptions, 'Please select a main type from the list.') // A) Error for text-based
            .required('Please select a main type from the list.'), // Compulsory
        latitude: yup.string().required("Latitude is required")
            .test('is-text-based', 'Please enter a valid latitude.', value => !isNaN(parseFloat(value)))
            .test('is-symbol-based', 'Only numeric values and symbols (-, .) are allowed.', value => /^-?\d*\.?\d*$/.test(value))
            .test('character-limit', 'Value must be a valid latitude format.', value => value.length <= 8),
        longitude: yup.string().required("Longitude is required")
            .test('is-text-based', 'Please enter a valid Longitude.', value => !isNaN(parseFloat(value)))
            .test('is-symbol-based', 'Only numeric values and symbols (-, .) are allowed.', value => /^-?\d*\.?\d*$/.test(value))
            .test('character-limit', 'Value must be a valid Longitude format.', value => value.length <= 8),
        capacity: yup.string()
            .matches(/^\d+$/, 'Please enter a numeric value for pump capacity.') // A) Error for text-based
            .matches(/^\d+$/, 'Only numeric values are allowed.') // B) Error for symbol-based
            .min(1, 'Value must be between 1 to 100000.') // C) Character limit
            .max(100000, 'Value must be between 1 to 100000.') // C) Character limit
            .required('Pump capacity is required'), // E) Compulsory
    });
    const [errors, setErrors] = useState<Partial<FormData>>({});
    const onSelectChangeChange = async (e: any) => {
        // const { name, value } = e.target;
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        // setFormData({ ...formData, [e.target.name]: e.target.value });
        // await FormValidation(formSchema, formData, setErrors)
    }
    const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});

    const predefinedArray = [
        { id: 596, name: "Presedimentation" },
        { id: 597, name: "Aeration" },
        { id: 598, name: "Coagulation" },
        { id: 599, name: "Flocculation" },
        { id: 600, name: "Sedimentation" },
        { id: 601, name: "Filtration" },
        { id: 602, name: "Disinfection" }
    ];

    // Helper function to check if process is in predefinedArray
    const isProcessPredefined = (processName: any) => {
        return predefinedArray.some(predefinedProcess => predefinedProcess.name === processName);
    };

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setFormErrors({ ...formErrors, [name]: "" });
        // setFormData((prevFormData) => ({
        //     ...prevFormData,
        //     [name]: value,
        // }));
        // await FormValidation(formSchema, { ...formData, [name]: value }, setErrors);
    };

    const handleChangeCheckbox = async (e: React.ChangeEvent<HTMLInputElement>) => {

        // await FormValidation(formSchema, { ...formData, [name]: value }, setErrors);
        const { value, checked } = e.target;

        setFormData((prev: any) => {
            let updatedProcesses;
            if (checked) {
                updatedProcesses = [...prev.predefined_process, value];
            } else {
                updatedProcesses = prev.predefined_process.filter((process: any) => process !== value);
            }
            return { ...prev, predefined_process: updatedProcesses };
        });
    };

    const handleChangeCheckboxCustum = async (e: React.ChangeEvent<HTMLInputElement>) => {

        // await FormValidation(formSchema, { ...formData, [name]: value }, setErrors);
        const { value, checked } = e.target;

        setFormData((prev: any) => {
            let updatedProcesses;
            if (checked) {
                updatedProcesses = [...prev.custom_process, value];
            } else {
                updatedProcesses = prev.custom_process.filter((process: any) => process !== value);
            }
            return { ...prev, custom_process: updatedProcesses };
        });
    };
    const treatmentFacilitydata = treatmentFacility.selectedTreatmentFacility.data
    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        // Basic validation
        // await FormValidation(formSchema, formData, setErrors)

        const treatmentFacility = {
            project_id: location?.state?.projectData?.id,
            component_id: components?.selectedComponent?.component_id,
            latitude: formData.latitude,
            longitude: formData.longitude,
            type_of_project: formData.type,
            capacity: formData.capacity,
            working_hours: formData.hours,
            predefined_process: formData.predefined_process,
            project_owner: formData.owner,
            site_area: formData.site,
            custom_process: formData.custom_process
        }

        const errors: { [key: string]: string } = {};
        const requiredFields = ["type", "latitude", "longitude", "capacity", "hours", "site","predefined_process"]; // Add other required field names here

        requiredFields.forEach((field) => {
            if (!formData[field]) {
                errors[field] = "Required";
            }
            if (field == "predefined_process") {
                if(formData[field].length < 1){
                    // alert(1)
                errors[field] = "Select Atleast One Process ";
                }
                
            }
        });

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
        } else {
            // Submit the form
            treatmentFacilitydata ?
                dispatch(editTreatmentFacility({ apiParam: { ...treatmentFacility, coordinate_id: treatmentFacilitydata.TreatmentFacilityData.coordinate_id, id: treatmentFacilitydata.TreatmentFacilityData.id }, closeModal: dispatch(formPopup(false)) }))
                :
                dispatch(addTreatmentFacility({ apiParam: treatmentFacility, closeModal: dispatch(formPopup(false)) }))
        }
    };



    const handleAddProcess = () => {
        if (formData.process.trim() !== '' && !processes.includes(formData.process.trim())) {
            setCustomProcesses((prevProcesses) => [...prevProcesses, formData.process.trim()]);
            setFormData((prevFormData: any) => ({ ...prevFormData, process: '' }));
        }
        // else {
        //     setErrors({ process: 'Process name cannot be empty or duplicate' });
        // }
    };
    const handleRemoveProcess = (event: any, processToRemove: any) => {
        event.preventDefault();
        setProcesses((prevProcesses) => prevProcesses.filter(process => process !== processToRemove));
    };
    useEffect(() => {
        if (components.selectedComponent) {
            dispatch(activeComponent(components?.selectedComponent?.name))

            if (components?.selectedComponent?.id) {
                dispatch(fetchTreatmentFacilityById(components?.selectedComponent?.id))
            }
            setFormData({
                ...formData,
                latitude: components?.selectedComponent?.latitude,
                longitude: components?.selectedComponent?.longitude,

            })

        }
    }, [components.selectedComponent])

    useEffect(() => {
        let process = treatmentFacility?.selectedTreatmentFacility?.data?.TreatmentFacilityProcessData.map((item: { name: any; }) => item.name)
        if (treatmentFacility?.selectedTreatmentFacility?.data?.TreatmentFacilityData) {
            setFormData({
                ...formData,

                type: treatmentFacilitydata.TreatmentFacilityData.type_of_project,
                capacity: treatmentFacilitydata.TreatmentFacilityData.capacity,
                hours: treatmentFacilitydata.TreatmentFacilityData.working_hours,
                owner: treatmentFacilitydata.TreatmentFacilityData.project_owner,
                site: treatmentFacilitydata.TreatmentFacilityData.site_area,
                predefined_process: process || [],
                latitude: components?.selectedComponent?.latitude,
                longitude: components?.selectedComponent?.longitude

            })

            treatmentFacilitydata.TreatmentFacilityProcessData.map((item: any) => {
                if (item.name.trim() !== '' && !processes.includes(item.name.trim())) {
                    setProcesses((prevProcesses) => [...prevProcesses, item.name.trim()]);
                    setFormData((prevFormData: any) => ({ ...prevFormData, process: '' }));
                }
            })
        }
    }, [treatmentFacilitydata])

    useEffect(() => {
        if (treatmentFacilitydata?.TreatmentFacilityProcessData) {
            const array3 = treatmentFacilitydata.TreatmentFacilityProcessData.filter((item1: { name: string; }) => !predefinedArray.some(item2 => item2.name === item1.name));
            array3.map((item: { name: string; }) => {
                setCustomProcesses((prevProcesses) => [...prevProcesses, item.name]);
            })
        }
    }, [treatmentFacilitydata?.TreatmentFacilityProcessData])

    useEffect(() => {
        setCustomProcesses(() => []);
    }, [])


    const isChecked = (value: any) => {
        return formData.predefined_process.includes(value);
    };
    const isCheckedCustum = (value: any) => {
        return formData.predefined_process.includes(value);
    };

    useEffect(() => {
        if (!treatmentFacilitydata) {
            setFormData(resetformData)
        }
    }, [!treatmentFacilitydata]);

    const combinedArray = [
        ...predefinedArray.map(item => item.name),
        ...processes.filter(process => !predefinedArray.some(predefined => predefined.name === process))
    ];

    const isProcessChecked = (processName: any) => {
        return processes.includes(processName);
    };


    return (
        <>
            {
                treatmentFacility.editTreatmentFacility.load ? <Loading /> : <div className="container bg-white  mx-auto h-[85vh] text-[#1E2022]">
                    {/* <div className="h-[5%]"> */}
                    <div className="flex mb-4 justify-between items-center border-b-2 py-4 px-4">
                        <h3 className="text-base not-italic font-semibold leading-[18.9px] mt-1">Treatment Facility Details</h3>
                        <FontAwesomeIcon icon={faXmark}
                            onClick={() => { setProcesses([]); dispatch(formPopup(false)); setFormData(resetformData); setCustomProcesses(() => []); }}
                            className="mt-1 pr-4 hover:cursor-pointer" />
                    </div>
                    {/* </div> */}
                    <div className="form-height overflow-y-auto ">
                        <form className="grid px-4" onSubmit={handleSubmit}>
                            {/* <div className="text-black py-4 font-bold">
                                <h3 className="">General Information</h3>
                            </div> */}

                            <FormField
                                label="Latitude"
                                name="latitude"
                                type="text"
                                placeholder="Enter Latitude"
                                value={formData.latitude}
                                onChange={handleChange}
                                error={formErrors.latitude}
                                labelRequired
                            />
                            <FormField
                                label="Longitude"
                                name="longitude"
                                type="text"
                                placeholder="Enter Longitude"
                                value={formData.longitude}
                                onChange={handleChange}
                                error={formErrors.longitude}
                                labelRequired
                            />
                            <FormField
                                label="Capacity (MLD)"
                                name="capacity"
                                type="text"
                                placeholder="Enter Capacity"
                                value={formData.capacity}
                                onChange={handleChange}
                                error={formErrors.capacity}
                                labelRequired
                                isRequired={true}
                            />
                            <FormField
                                label="Working Hours (hrs)"
                                name="hours"
                                type="text"
                                placeholder="Enter Working Hours"
                                value={formData.hours}
                                onChange={handleChange}
                                error={formErrors.hours}
                                labelRequired
                            />
                            <FormField
                                label="Project Owner "
                                name="owner"
                                type="text"
                                placeholder="Enter Project Owner"
                                value={formData.owner}
                                onChange={handleChange}
                                error={formErrors.owner}
                            />
                            <FormField
                                label="Site Area (sq.m)"
                                name="site"
                                type="text"
                                placeholder="Enter Site Area"
                                value={formData.site}
                                onChange={handleChange}
                                error={formErrors.site}
                                labelRequired
                            />
                            <SelectField
                                label="Type of Project"
                                name="type"
                                // type="select"
                                // placeholder="Select"
                                value={formData.type}
                                onChange={handleChange}
                                error={formErrors.type}
                                options={typeOptions}
                                labelRequired
                                isRequired={true}
                            />
                            <div className="text-black w-full mb-3">
                                <label htmlFor="" className="text-sm not-italic font-medium leading-5">Select Process Available At Plant<span className="text-[#ED4C78]">{"*"}</span></label>
                                <div className="w-full flex flex-wrap text-start pl-2 pt-3">

                                    {
                                        treatmentFacilitydata?.TreatmentFacilityProcessData?.length < 0 ?
                                            // {
                                            combinedArray.map((process, index) => (
                                                <div className={`lg:w-1/2 ${index % 2 === 0 ? 'pl-1 pb-3' : ''}`} key={process}>
                                                    <label htmlFor={process} className="text-xs not-italic font-normal leading-5 flex items-center">
                                                        <input
                                                            disabled={!isProcessPredefined(process) ? true : false}
                                                            onChange={handleChangeCheckbox}
                                                            type="checkbox"
                                                            name={process}
                                                            value={process}
                                                            id={process}
                                                            className="rounded focus:ring-0 focus:ring-white mr-3"
                                                            defaultChecked={isProcessChecked(process) == true ? true : false}
                                                        />
                                                        <span>{process}</span>
                                                        {!isProcessPredefined(process) && (
                                                            <span className="ml-2">
                                                                <button onClick={(event) => handleRemoveProcess(event, process)}>
                                                                    <FontAwesomeIcon icon={faXmark} className="text-[#ED4C78]" />
                                                                </button>
                                                            </span>
                                                        )}
                                                    </label>
                                                </div>
                                            ))
                                            // }
                                            // processes?.map((process, index) => (
                                            //     <div className={`lg:w-1/2 ${index % 2 === 0 ? 'pl-1 pb-3' : ''}`} key={process}>
                                            //         <label htmlFor={process} className="text-xs not-italic font-normal leading-5 flex items-center">
                                            //             <input
                                            //                 disabled={!isProcessPredefined(process) ? true : false}
                                            //                 onChange={handleChangeCheckbox}
                                            //                 type="checkbox"
                                            //                 name={process}
                                            //                 value={process}
                                            //                 id={process}
                                            //                 className="rounded focus:ring-0 focus:ring-white mr-3"
                                            //                 // onClick={}
                                            //                 // checked={isChecked(process)}
                                            //                 defaultChecked={treatmentFacilitydata ? true : false}
                                            //             />
                                            //             <span>{process}</span>
                                            //             <span className="ml-2">
                                            //                 {/* <button onClick={() => handleRemoveProcess(process)}><FontAwesomeIcon icon={faXmark} className="text-[#ED4C78]" /></button> */}
                                            //                 {!isProcessPredefined(process) && (
                                            //                     <span className="ml-2">
                                            //                         <button onClick={(event) => handleRemoveProcess(event, process)}>
                                            //                             <FontAwesomeIcon icon={faXmark} className="text-[#ED4C78]" />
                                            //                         </button>
                                            //                     </span>
                                            //                 )}
                                            //             </span>

                                            //         </label>
                                            //     </div>
                                            // ))
                                            :
                                            <div className="contents">
                                                <div className="lg:w-1/2 pb-3 text-start">
                                                    <label htmlFor="Presedimentation" className=" text-xs not-italic font-normal leading-5 flex items-center">
                                                        <input id="Presedimentation" disabled={treatmentFacilitydata ? true : false} onChange={handleChangeCheckbox} checked={isChecked("Presedimentation")} type="checkbox" name="Presedimentation" value="Presedimentation" className="rounded focus:ring-0 focus:ring-white ml-0 mr-3" />
                                                        <span>Presedimentation</span>
                                                    </label>
                                                </div>
                                                <div className="lg:w-1/2 pl-1">
                                                    <label htmlFor="Aeration" className=" text-xs not-italic font-normal leading-5 flex items-center">
                                                        <input id="Aeration" disabled={treatmentFacilitydata ? true : false} onChange={handleChangeCheckbox} type="checkbox" checked={isChecked("Aeration")} name="Aeration" value="Aeration" className="rounded focus:ring-0 focus:ring-white mr-3" />
                                                        <span>Aeration</span></label>
                                                </div>
                                                <div className="lg:w-1/2 pb-3">
                                                    <label htmlFor="Coagulation" className="text-xs not-italic font-normal leading-5 flex items-center">
                                                        <input id="Coagulation" disabled={treatmentFacilitydata ? true : false} onChange={handleChangeCheckbox} type="checkbox" name="Coagulation" checked={isChecked("Coagulation")} value="Coagulation" className="rounded focus:ring-0 focus:ring-white mr-3" />
                                                        <span>Coagulation</span></label>
                                                </div>
                                                <div className="lg:w-1/2 pl-1">
                                                    <label htmlFor="Flocculation" className="text-xs not-italic font-normal leading-5 flex items-center">
                                                        <input id="Flocculation" disabled={treatmentFacilitydata ? true : false} onChange={handleChangeCheckbox} type="checkbox" name="Flocculation" checked={isChecked("Flocculation")} value="Flocculation" className="rounded focus:ring-0 focus:ring-white mr-3" />
                                                        <span>Flocculation</span> </label>
                                                </div>
                                                <div className="lg:w-1/2 pb-3">
                                                    <label htmlFor="Sedimentation" className="text-xs not-italic font-normal leading-5 flex items-center">
                                                        <input id="Sedimentation" disabled={treatmentFacilitydata ? true : false} onChange={handleChangeCheckbox} type="checkbox" name="Sedimentation" checked={isChecked("Sedimentation")} value="Sedimentation" className="rounded focus:ring-0 focus:ring-white mr-3" />
                                                        <span>Sedimentation</span></label>
                                                </div>
                                                <div className="lg:w-1/2 pl-1">
                                                    <label htmlFor="Filtration" className="text-xs not-italic font-normal leading-5 flex items-center">
                                                        <input id="Filtration" disabled={treatmentFacilitydata ? true : false} onChange={handleChangeCheckbox} type="checkbox" name="Filtration" checked={isChecked("Filtration")} value="Filtration" className="rounded focus:ring-0 focus:ring-white mr-3 " />
                                                        <span>Filtration</span> </label>
                                                </div>
                                                <div className="lg:w-1/2">
                                                    <label htmlFor="Disinfection" className="text-xs not-italic font-normal leading-5 flex items-center">
                                                        <input id="Disinfection" disabled={treatmentFacilitydata ? true : false} onChange={handleChangeCheckbox} type="checkbox" name="Disinfection" checked={isChecked("Disinfection")} value="Disinfection" className="rounded focus:ring-0 focus:ring-white mr-3" />
                                                        <span>Disinfection</span> </label>
                                                </div>
                                                {formErrors.predefined_process && <p className="text-red-500 text-xs italic">{formErrors.predefined_process}</p>}

                                                {
                                                    customProcesses?.map((process: any, index: any) => {
                                                        return <div className="lg:w-1/2">
                                                            <label htmlFor="Disinfection" className="text-xs not-italic font-normal leading-5 flex items-center">
                                                                <input
                                                                    disabled={treatmentFacilitydata ? true : false}
                                                                    onChange={handleChangeCheckbox}
                                                                    type="checkbox"
                                                                    name={process}
                                                                    value={process}
                                                                    id={process}
                                                                    className="rounded focus:ring-0 focus:ring-white mr-3"
                                                                    // onClick={}
                                                                    checked={isChecked(process)}
                                                                />
                                                                <span>{process}</span>
                                                            </label>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                    }

                                </div>
                            </div>
                            <div className="w-full flex">
                                <div className=" mr-2">
                                    <FormField
                                        label="Do you want to add more unit process"
                                        name="process"
                                        type="text"
                                        placeholder="Enter process name"
                                        value={formData.process}
                                        onChange={handleChange}
                                        error={errors.process}

                                    />

                                </div>
                                <div className="rounded-sm bg-[#EFF1F7] h-9 w-9 flex justify-center items-center mt-[48px]" onClick={handleAddProcess}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </div>
                            </div>

                        </form>
                    </div>
                    {/* <div className="h-[10%] flex justify-end"> */}
                    {/* <div className="basis-2/4"></div> */}
                    <div className="w-full  flex justify-end pr-8 form-footer">
                        <button
                            className=" text-[#8A96A4] bg-white my-4 pt-1 pb-1 px-4  rounded focus:outline-none focus:shadow-outline border border-solid border-[#EEF0F7] hover:bg-[#F8F7F7]  hover:scale-105"
                            type="submit"
                            onClick={() => { dispatch(formPopup(false)); setFormData(resetformData); setCustomProcesses(() => []); }}
                        >
                            Cancel
                        </button>
                        <button
                            className=" ml-3 font-normal text-sm bg-[#377DFF] my-4  text-white pt-1 pb-1 px-2 rounded focus:outline-none focus:shadow-outline hover:scale-105"
                            type="submit"
                            onClick={handleSubmit}
                        >
                            {treatmentFacilitydata ? "Update Details" : "Save Details"}
                        </button>
                    </div>
                    {/* </div> */}
                </div>
            }


        </>
    )
}
export default TreatmentFacility
