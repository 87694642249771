// import React from 'react'
import {
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  ReactPortal,
  useEffect,
  useState,
} from "react";
import manimg from "../../assets/images/ManAnatomyMuscle2.png";
// import PageTitle from '../PageTitle'
import "../../assets/css/reusable/tab.css";
import { Button, Popover } from "flowbite-react";
import { useAppSelector } from "../../hooks";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

const ImpactAnalysis = () => {
  // const [activeTab, setActiveTab] = useState("tab1");
  const monitoring = useAppSelector((state) => state.monitoring);

  const [shortTerm, setSortTerm]: any = useState(null);
  const [longTerm, setLongTerm] = useState(null);

  useEffect(() => {
    let short = monitoring?.screenStats?.data?.HealthEffects;
    let long = monitoring?.screenStats?.data?.HealthEffects;
    if (short) {
      setSortTerm(monitoring?.screenStats?.data?.HealthEffects?.["Short Term"]);
    }
    if (long) {
      setLongTerm(monitoring?.screenStats?.data?.HealthEffects?.["Long Term"]);
    }
    // console.log('short',short)
  }, [monitoring?.screenStats?.data?.HealthEffects]);

  return (
    <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full impact-analysis bg-[#35354B] p-4 rounded-lg imapct-analysis">
      {/* <PageTitle title=""></PageTitle> */}
      {/* <h1 className="font-bold leading-[21px] text-[14px] font-[inter] not-italic text-[#FFF]">
        Impact Analysis
      </h1> */}
      <Tabs>
        <div className="w-full flex justify-center items-center relative">
          <TabPanel>
            <div className={``}>
              <img src={manimg} alt="" className="h-full mb-5" />
              <div className="grid grid-cols-2 gap-5 w-full absolute z-10 top-3 left-0">
                {shortTerm &&
                  Object.entries(shortTerm).map(([key, value]: any) => (
                    <div key={key}>
                      <Popover
                        className="mt-6"
                        content={
                          <div className="w-40  rounded-sm text-xs  border-gray-200 bg-slate-100">
                            {value.length >= 0 &&
                              value?.map((effect: any, index: any) => (
                                <div className="px-3 py-2 text-[#000]">
                                  {effect}
                                </div>
                                //   <li key={index}>{effect}</li>
                              ))}
                          </div>
                        }
                        trigger="hover"
                        arrow={false}
                        placement="right"
                      >
                        {key !== "" ? (
                          <div className="bg-[#E7EFFF] p-3 text-[#000] text-xs rounded-md">
                            {key}
                          </div>
                        ) : (
                          <></>
                        )}
                      </Popover>
                    </div>
                  ))}
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className={`$`}>
              <img src={manimg} alt="" className="h-full mb-5" />
              <div className="grid grid-cols-2 gap-5 w-full absolute z-10 top-3 left-0">
                {longTerm &&
                  Object.entries(longTerm).map(([key, value]: any) => (
                    <div key={key}>
                      <Popover
                        className="mt-6"
                        content={
                          <div className="w-40  rounded-sm text-xs  border-gray-200 bg-slate-100">
                            {/* <pre>{JSON.stringify(value.length>= 0)}</pre> */}
                            {value.length >= 0 &&
                              value?.map((effect: any, index: any) => (
                                <div className="px-3 py-2 text-[#000]">
                                  {effect}
                                </div>
                                //   <li key={index}>{effect}</li>
                              ))}
                          </div>
                        }
                        trigger="hover"
                        arrow={false}
                        placement="right"
                      >
                        {key === "" ? (
                          <></> // Render nothing if key is an empty array
                        ) : (
                          <div className="bg-[#E7EFFF] p-3 text-[#000] text-xs rounded-md">
                            {key}
                          </div>
                        )}
                      </Popover>
                    </div>
                  ))}
              </div>
            </div>
          </TabPanel>
        </div>
        <div className="flex flex-wrap w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full">
          <TabList>
            <div className="bg-[#4A4A68] rounded-2xl text-[#FFF] py-[3px] ">
              <Tab>SHORT TERM</Tab>
              <Tab>LONG TERM</Tab>
            </div>
          </TabList>
        </div>
      </Tabs>
    </div>
  );
};

export default ImpactAnalysis;
