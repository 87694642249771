import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { fetchComponents } from "../Map";
import { fetchCoordinates } from "../coordinate";

export interface WaterQualityStationData {
    component_id: number,
    coordinate_id: number,
    name: string,
    station_type: string,
    competent_authority: string,
    monitoring_type: string,
    id: number
}

interface WaterQualityStationsState {
    valves: {
        load: boolean;
        data: Array<WaterQualityStationData>;
        error: Array<WaterQualityStationData>;
    };
    selectedWaterQualityStation?: WaterQualityStationData;
    editWaterQualityStation: {
        load: boolean;
    };
}
const initialState = {
    valves: {
        load: false,
        data: [],
        error: []
    },
    selectedWaterQualityStation: {
        coordinate_id: "",
        name: "",
        station_type: "",
        competent_authority: "",
        monitoring_type: "",
        id: ""
    },
    editWaterQualityStation: {
        load: false,
    },
    deleteWaterQualityStation: {
        load: false,
    },
} as unknown as WaterQualityStationsState;

export const valvesSlice = createSlice({
    name: "valves",
    initialState,
    reducers: {
        resetWaterQualityStationData: (state: WaterQualityStationsState) => {
            state.selectedWaterQualityStation = initialState.selectedWaterQualityStation;
            state.valves.error = []
        },
        updateData: (state: WaterQualityStationsState, action: { payload: WaterQualityStationData }) => {
            state.selectedWaterQualityStation = action.payload;
        },
        addWaterQualityStationError: (state: WaterQualityStationsState, action: any) => {
            state.valves.error = action.payload;
        },
        addWaterQualityStationSuccess: (state: WaterQualityStationsState, action: any) => {
            if (action.payload) {
                state.valves.data = [{ ...action.payload }, ...state.valves.data];
            }
            state.valves.load = false;
        },
        editWaterQualityStationSuccess: (state: WaterQualityStationsState, action: any) => {
            if (action.payload) {

                const editedWaterQualityStationIndex = state.valves.data.findIndex(valve => valve.id === action.payload.id);

                if (editedWaterQualityStationIndex !== -1) {
                    state.valves.data[editedWaterQualityStationIndex] = { ...action.payload };
                }
            }
            state.valves.load = false;
        },
        deleteWaterQualityStationSuccess: (state: WaterQualityStationsState, action: any) => {
            if (action.payload) {
                const valveIdToDelete = action.payload.id;
                const indexToDelete = state.valves.data.findIndex(valve => valve.id === valveIdToDelete);
                if (indexToDelete !== -1) {
                    state.valves.data.splice(indexToDelete, 1);
                }
            }
            state.valves.load = false;
        }
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchWaterQualityStations.pending, (state: WaterQualityStationsState) => {
                state.valves.load = true;
            })
            .addCase(fetchWaterQualityStations.fulfilled, (state: WaterQualityStationsState, action: any) => {
                state.valves.data = action.payload;
                state.valves.load = false;
            })
            .addCase(fetchWaterQualityStations.rejected, (state: WaterQualityStationsState) => {
                state.valves.load = false;
            })
            .addCase(fetchWaterQualityStationById.pending, (state: WaterQualityStationsState) => {
                state.editWaterQualityStation.load = true;
            })
            .addCase(fetchWaterQualityStationById.fulfilled, (state: WaterQualityStationsState, action: any) => {
                state.selectedWaterQualityStation = action.payload;
                state.editWaterQualityStation.load = false;
            })
            .addCase(fetchWaterQualityStationById.rejected, (state: WaterQualityStationsState) => {
                state.editWaterQualityStation.load = false;
            });
    },
});
export const { updateData, resetWaterQualityStationData, addWaterQualityStationError, addWaterQualityStationSuccess, editWaterQualityStationSuccess, deleteWaterQualityStationSuccess } = valvesSlice.actions;
export default valvesSlice.reducer;

export const fetchWaterQualityStations = createAsyncThunk(
    "valves/fetchWaterQualityStations",
    async (param: any, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("/api/user-wise-valve-list", {
                user_id: param
            });
            // thunkAPI.dispatch(toggleLoading());
            return data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const fetchWaterQualityStationById = createAsyncThunk(
    "valves/fetchWaterQualityStationById",
    async (param: any, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/edit-water-quality-station", {
                coordinate_id: param
            });
            // thunkAPI.dispatch(toggleLoading());
            return data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const addWaterQualityStation = createAsyncThunk(
    "valves/addWaterQualityStation",
    async (param: { apiParam: any, closeModal: any }, thunkAPI) => {
        try {
            const coordinates = await DataServer.post(
                "api/coordinates",
                { component_id: param.apiParam.component_id, latitude: param.apiParam.latitude, longitude: param.apiParam.longitude }
            );

            const { data } = await DataServer.post(
                "api/water-quality-station",
                { ...param.apiParam, coordinate_id: coordinates.data.data.id }
            );
            thunkAPI.dispatch(fetchComponents({ apiParam: { id: param.apiParam.project_id, current_step: "mapping" } }))
            thunkAPI.dispatch(fetchCoordinates(param.apiParam.project_id))


            toast.success('Water Quality Station Added Successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });
            param.closeModal();
            // thunkAPI.dispatch(addWaterQualityStationSuccess({...data.data,role:param.apiParam.role,id:data.data.id}));
            return { ...data.data };
        } catch (error: any) {
            thunkAPI.dispatch(addWaterQualityStationError(error));
            return error;
        }
    }
);

export const editWaterQualityStation = createAsyncThunk(
    "valves/editWaterQualityStation",
    async (param: { apiParam: any, closeModal: any }, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/update-water-quality-station", param.apiParam);
            // thunkAPI.dispatch(toggleLoading());
            thunkAPI.dispatch(editWaterQualityStationSuccess({ ...data.data, role: param.apiParam.role }));

            toast.success('Water Quality Station Updated Successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });
            param.closeModal();

            return data.data;
        } catch (error: any) {
            thunkAPI.dispatch(addWaterQualityStationError(error));
            return error;
            // console.log("Error", error);
        }
    }
);

export const deleteWaterQualityStation = createAsyncThunk(
    "valves/deleteWaterQualityStation",
    async (param: { apiParam: any }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/delete-valve", {
                id: param.apiParam.id
            });
            // thunkAPI.dispatch(toggleLoading());
            param.apiParam.closeModal();
            thunkAPI.dispatch(deleteWaterQualityStationSuccess({ ...data.data, role: param.apiParam.role, id: param.apiParam.id }))
            toast.success('Water Quality Station deleted successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });

            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            // thunkAPI.dispatch(ErrorMessage(error));
            return error;
            // console.log("Error", error);
        }
    }
);
