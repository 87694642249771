import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../assets/css/reusable/newscreenmonitoring.css";
import {
  faArrowLeft,
  faMagnifyingGlass,
} from "@fortawesome/pro-regular-svg-icons";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useState } from "react";
import NewDashboard from "../../../reusable/NewDashboardTab";
import NewMapview from "../../../reusable/NewMapview";
import { useNavigate } from "react-router-dom";
import { Menu } from "@headlessui/react";
import profile_img from "../../../assets/images/profile.png";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { faBell } from "@fortawesome/pro-light-svg-icons";
import { fetchProjectById } from "../../../redux/slice/Project";

const Screenmonitoring = ({ activeTab, setActiveTab }: any) => {
  const navigate = useNavigate();
  // const dispatch = useAppDispatch()
  const navigateToProjects = () => {
    navigate("/Screen-Monitoring");
  };

  const [profiles, setProfiles]: any = useState();
  const profile = useAppSelector((state) => state.profile);
  // const handleClick=(projectdata: any)=>{
  //     dispatch(fetchProjectById(projectdata))
  // }
  return (
    <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full screen-monitoring">
      <div
        className={`w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full `}
      >
        <div
          className={`text-sm not-italic font-normal leading-[22px] project-text ${
            activeTab === 1 ? "hidden" : "block px-2"
          }`}
        >
          <span className="cursor-pointer" onClick={navigateToProjects}>
            Projects /{" "}
          </span>
          <span className="dashboard-text">Dashboard</span>
        </div>
        <Tabs
          onSelect={(index) => setActiveTab(index)}
          selectedIndex={activeTab}
        >
          <div
            className={`w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full my-2 flex items-center ${
              activeTab === 1 ? "absolute -top-2 z-50 p-4" : ""
            }`}
          >
            <div
              className={`w-1/3 sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3 2xl:w-1/3 flex items-center ${
                activeTab === 0 ? "px-2" : null
              }`}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                className={` h-[24px] w-[22px] ${
                  activeTab === 1 ? "text-[#FFF] pr-1" : "text-[#121C2D] pr-2"
                }`}
                onClick={() =>
                  activeTab === 1 ? setActiveTab(0) : navigateToProjects()
                }
              />
              <span
                className={`text-[#121C2D] text-2xl not-italic font-bold leading-normal  ${
                  activeTab === 1 ? "hidden" : "block"
                }`}
              >
                {" "}
                Dashboard
              </span>
              <div
                className={`flex ml-[10px] ${
                  activeTab === 1 ? "block" : "hidden"
                }`}
              >
                <div className="relative ml-4 my-2 ">
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    className="text-[#FFF] absolute top-3 left-3 text-sm"
                  />
                  <input
                    type="search"
                    placeholder="Search"
                    className="border border-[#35354B] pl-9 placeholder:text-[#FFF] placeholder:text-[14px] py-[6px] rounded-[28px] bg-[#35354B]"
                  />
                </div>
              </div>
            </div>

            <div
              className={`w-2/3 sm:w-2/3 md:w-2/3 lg:w-2/3 xl:w-2/3 2xl:w-2/3 flex justify-end items-center ${
                activeTab === 0 ? "px-2" : null
              }`}
              // ${activeTab === 1 ? 'justify-between' : 'justify-end'}
            >
              <TabList
                className={`border rounded-[9999px] py-[3.5px] px-[2px] mr-3 ${
                  activeTab === 1
                    ? " bg-[#35354B] border-none focus:bottom-1"
                    : "bg-white"
                }`}
                key={activeTab}
              >
                <Tab>Dashboard</Tab>
                <Tab
                //  onClick={handleClick}
                >
                  Mapview
                </Tab>
              </TabList>
              {/* <div
                className={`relative grid content-end bg-[#FFF] px-3 py-[6px] rounded-full ${
                  activeTab === 1 ? "block" : "hidden"
                } mx-5`}
              >
                <FontAwesomeIcon icon={faBell} size="lg" className="mt-[8px]" />
                <div className="bg-[#FF4C4C] rounded-[100%] w-[18px] h-[18px] text-[#FFF] absolute top-[2px] left-5 text-[10px]  flex items-center justify-center">
                  20
                </div>
              </div> */}
              <Menu
                as="div"
                className={`relative inline-block text-left ${
                  activeTab === 1 ? "block" : "hidden"
                }`}
              >
                <div>
                  <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 items-center bg-transparent text-sm font-semibold text-gray-900  hover:bg-transparent">
                    <div className="flex items-center justify-start mt-2">
                      <img src={profile_img} alt="" className="mr-3" />
                      <div className="text-left">
                        <h6 className="font-bold text-[14px] text-[#FFF] capitalize">
                          {profile?.profile?.data?.first_name}{" "}
                          {profile?.profile?.data?.last_name}
                        </h6>
                        <span className="font-normal text-[#FFF] text-[12px]">
                          {profile?.profile?.data?.role}
                        </span>
                      </div>
                    </div>
                    {/* <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon> */}
                  </Menu.Button>
                </div>
              </Menu>
            </div>
          </div>
          <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full">
            <TabPanel>
              <NewDashboard />
            </TabPanel>
            <TabPanel>
              <NewMapview activeTab={activeTab} setActiveTab={setActiveTab} />
            </TabPanel>
          </div>
        </Tabs>
      </div>
    </div>
  );
};

export default Screenmonitoring;
