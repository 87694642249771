import React from "react";
import EChartsReact from "echarts-for-react";

interface DataItem {
  id: number;
  sampling_date: string;
  sampling_time: string;
  parameter_name: string;
  observation: string;
  [key: string]: any; // Allows extra unknown properties
}

interface ChartProps {
  data: DataItem[]; // Expect an array of DataItem from props
}

const PopupComparisonChart = ({ data }: ChartProps) => {
  // Extract unique sampling times
  const samplingTimes = Array.from(
    new Set(data.map((item) => item.sampling_time))
  ); // Unique sampling times

  // Extract unique parameter names
  const parameters = Array.from(
    new Set(data.map((item) => item.parameter_name))
  );

  // Map observations for each parameter based on sampling times
  const seriesData = parameters.map((parameter) => ({
    name: parameter,
    type: "line",
    smooth: true, // Make the lines smooth
    lineStyle: {
      color: "#fff", // Line color (customize as needed)
      width: 2, // Line width
      type: "solid", // Solid line style
    },
    data: data
      .filter((item) => item.parameter_name === parameter)
      .map((item) => parseFloat(item.observation)),
  }));

  // Chart configuration
  const option = {
    xAxis: {
      type: "value", // Treat the x-axis as numeric
      min: 0, // Start from 0 (0:00 hours)
      max: 24, // End at 24 (24:00 hours)
      interval: 6, // Set intervals every 4 hours (0, 4, 8, 12, etc.)
      axisLine: {
        show: false, // Hide the x-axis main line
      },
      axisLabel: {
        color: "#fff", // Axis label color
      },
      splitLine: {
        show: true, // Show horizontal grid lines
        lineStyle: {
          color: "#334E68", // Gridline color
        },
      },
    },
    yAxis: {
      type: "value", // Numeric values on the y-axis (for observations)
      axisLine: {
        show: false, // Hide the x-axis main line
      },
      axisLabel: {
        color: "#fff", // Axis label color
        show: false, // Hide the y-axis label
      },
      splitLine: {
        show: true, // Show horizontal grid lines
        lineStyle: {
          color: "#334E68", // Gridline color
        },
      },
    },
    tooltip: {
      trigger: "axis", // Show tooltip on axis hover
      borderColor: "#ccc", // Border color
      borderWidth: 1, // Border width
      padding: [8, 10], // Padding around the content
      textStyle: {
        fontSize: 10, // Font size
      },
      position: (point: number[]) => {
        return [point[0] + 10, point[1] - 10]; // Adjust position to be closer to the data point
      },
    },
    series: seriesData, // Use the series data created above
  };

  return (
    <EChartsReact
      option={option}
      className="w-full relative z-[999] h-[200px]"
      style={{ height: `200px` }}
    />
  );
};

export default PopupComparisonChart;
