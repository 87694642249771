import React, { useEffect, useState } from "react";
import FormField from '../Forms/FormField'
import SelectField from '../Forms/SelectField'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as yup from 'yup';
// import { FormValidation } from "../../Utils/formValidation";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import '../../assets/css/reusable/form.css'
import { useAppDispatch, useAppSelector } from "../../hooks";
import { activeComponent, formPopup } from "../../redux/slice/Map";
import { addSourceOfIntake, editSourceOfIntake, fetchSourceOfIntakeById } from "../../redux/slice/SourceOfIntake";
import { useLocation } from "react-router-dom";
import { fetchCountry, fetchState } from "../../redux/slice/region";
import Loading from "../../views/pages/Loading";

interface FormData {
  name: string;
  latitude: string;
  longitude: string;
  sourceType: string;
  groundType: string;
  waterBodyType: string;
  basinName: string,
  subBasinName: string,
  city: string,
  country: string,
  hfl: string,
  mwl: string,
  // lsl: string,
  // mddl: string,
  // dsl: string,
  // bottomLevel: string,
  topLevel: string,
  width: string,
  averageRainfall: string,
  averagePrecipitation: string,
  currentTemperature: string,
  seasonalTemperature: string,
  annualTemperature: string,
  atmosphericTempAverage: string,
  volumnOfWaterAvailable: string,
  atmosphericTempcurrent: string,
  overall_basin_area: string,
  type_of_source_value: string

}

const SourceOfIntake: any = (props: any) => {
  const components = useAppSelector((state) => state.components)
  const sourceOfIntake = useAppSelector((state) => state.sourceOfIntake)
  const dispatch = useAppDispatch();
  const region = useAppSelector((state) => state.region)
  const [form, setForm] = useState("form1");
  const [formData, setFormData] = useState<any>({
    name: "",
    latitude: "",
    longitude: "",
    sourceType: "",
    groundType: "",
    waterBodyType: "",
    basinName: "",
    subBasinName: "",
    city: "",
    country: "",
    hfl: "",
    mwl: "",
    // lsl: "",
    // mddl: "",
    // dsl: "",
    // bottomLevel: "",
    topLevel: "",
    width: "",
    averageRainfall: "",
    averagePrecipitation: "",
    currentTemperature: "",
    seasonalTemperature: "",
    annualTemperature: "",
    atmosphericTempAverage: "",
    atmosphericTempcurrent: "",
    volumnOfWaterAvailable: "",
    overall_basin_area: "",
    type_of_source_value: ""

  });
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [resetformData, setResetFormData] = useState<FormData>({
    name: "",
    latitude: "",
    longitude: "",
    sourceType: "",
    groundType: "",
    waterBodyType: "",
    basinName: "",
    subBasinName: "",
    city: "",
    country: "",
    hfl: "",
    mwl: "",
    // lsl: "",
    // mddl: "",
    // dsl: "",
    // bottomLevel: "",
    topLevel: "",
    width: "",
    averageRainfall: "",
    averagePrecipitation: "",
    currentTemperature: "",
    seasonalTemperature: "",
    annualTemperature: "",
    atmosphericTempAverage: "",
    atmosphericTempcurrent: "",
    volumnOfWaterAvailable: "",
    overall_basin_area: "",
    type_of_source_value: ""
  });
  const location = useLocation();
  const [regions, setRegions] = useState({
    country: [{ name: "India", id: 101 }
    ],
  })
  const [regionData, setRegionData] = useState({
    country: "",
    state: "",
    district: ""
  })

  const sourceTypeOptions: any = [{ "value": "Select" }, { "value": "River" }, { "value": "Lake" }, { "value": "Tank" }, { "value": "Reservoir" }]

  const groundTypeOptions: any = [{ "value": "Select" }, { "value": "Open Well" }, { "value": "Bore Well" }]
  const convertedsystemOptions = sourceTypeOptions.map((option: { value: string; }) => option.value);
  const waterBodyTypeOptions: any = [{ "value": "Select" }, { "value": "Perennial" }, { "value": "Seasonal" }]
  const convertedwaterBodyTypeOptions = waterBodyTypeOptions.map((option: { value: string; }) => option.value);

  const formSchema = yup.object({
    name: yup.string()
      .matches(/^[a-zA-Z0-9\s]+$/, 'Please enter a valid intake source name. Special characters are not allowed.') // A) Error for text-based
      .matches(/^[^\W_]+$/, 'Symbols are not allowed.') // B) Error for symbol-based
      .min(3, 'Name must be between 3 to 100 characters.') // C) Character limit
      .max(100, 'Name must be between 3 to 100 characters.') // C) Character limit
      .required('Name of the intake source is required'), // E) Compulsory
    sourceType: yup.string()
      .oneOf(convertedsystemOptions, 'Please select a Type of source from the list.') // A) Error for text-based
      .required('Please select a Type of source from the list.'), // Compulsory
    waterBodyType: yup.string()
      .oneOf(convertedwaterBodyTypeOptions, 'Please select a Type of source from the list.'), // A) Error for text-based
    //   .required('Please select a Type of source from the list.'), // Compulsory
    latitude: yup.string().required("Latitude is required")
      .test('is-text-based', 'Please enter a valid latitude.', value => !isNaN(parseFloat(value)))
      .test('is-symbol-based', 'Only numeric values and symbols (-, .) are allowed.', value => /^-?\d*\.?\d*$/.test(value))
      .test('character-limit', 'Value must be a valid latitude format.', value => value.length <= 8),
    longitude: yup.string().required("Longitude is required")
      .test('is-text-based', 'Please enter a valid Longitude.', value => !isNaN(parseFloat(value)))
      .test('is-symbol-based', 'Only numeric values and symbols (-, .) are allowed.', value => /^-?\d*\.?\d*$/.test(value))
      .test('character-limit', 'Value must be a valid Longitude format.', value => value.length <= 8),
    // responsibleAgency: yup.string()
    //   .required('Responsible Agency is required'),
    // collectionType: yup.string()
    //   .required('Collection Type is required'),
    basinName: yup.string()
      .required('Name of Basin is required'),
    subBasinName: yup.string()
      .required('Name of the sub-basin is required'),
    city: yup.string()
      .matches(/^[a-zA-Z\s]+$/, 'Please enter the city or region name. Special characters are not allowed.') // A) Error for text-based
      .matches(/^[^\W_]+$/, 'Symbols are not allowed.') // B) Error for symbol-based
      .min(3, 'Name must be between 3 to 100 characters.') // C) Character limit
      .max(100, 'Name must be between 3 to 100 characters.') // C) Character limit
      .required('City/Region is required'), // Compulsory
    // district: yup.string()
    //   .required('District is required'),
    // state: yup.string()
    //   .required('State is required'),
    country: yup.string()
      .required('Country is required'),
    hfl: yup.string()
      .required('Please enter the Highest Flood Level (HFL) in meters.')
      .matches(/^\d+(\.\d+)?$/, 'Please enter the Highest Flood Level (HFL) in meters.') // Text-based Error
      .matches(/^\d+(\.\d+)?$/, 'Only numbers and decimal points are allowed.') // Symbol-based Error
      .max(10, 'Maximum 10 characters allowed.'), // Character Limit
    mwl: yup.string()
      .matches(/^\d+(\.\d+)?$/, 'Please enter the Maximum Water Level (MWL) in meters.') // Text-based Error
      .matches(/^\d+(\.\d+)?$/, 'Only numeric values and decimal points are allowed.') // Symbol-based Error
      .max(10, 'Maximum 10 characters allowed.') // Character Limit
      .required('Maximum Water Level (MWL) is required'), // Compulsory
    lsl: yup.string()
      .matches(/^\d+(\.\d+)?$/, 'Please enter the Lowest Storage Level (LSL) in meters.') // Text-based Error
      .matches(/^\d+(\.\d+)?$/, 'Only numeric values and decimal points are allowed.') // Symbol-based Error
      .max(10, 'Maximum 10 characters allowed.') // Character Limit
      .required('Lowest Storage Level (LSL) is required'), // Compulsory
    mddl: yup.string()
      .matches(/^\d+(\.\d+)?$/, 'Please enter the Minimum Drawdown Level (MDDL) in meters.') // Text-based Error
      .matches(/^\d+(\.\d+)?$/, 'Only numeric values and decimal points are allowed.') // Symbol-based Error
      .max(10, 'Maximum 10 characters allowed.') // Character Limit
      .required('Minimum Drawdown Level (MDDL) is required'), // Compulsory
    dsl: yup.string()
      .matches(/^\d+(\.\d+)?$/, 'Please enter the Dead Storage Level (DSL) in meters.') // Text-based Error
      .matches(/^\d+(\.\d+)?$/, 'Only numeric values and decimal points are allowed.') // Symbol-based Error
      .max(10, 'Maximum 10 characters allowed.') // Character Limit
      .required('Dead Storage Level (DSL) is required'), // Compulsory
    bottomLevel: yup.string()
      .matches(/^\d+(\.\d+)?$/, 'Please enter the Bottom Level in meters.') // Text-based Error
      .matches(/^\d+(\.\d+)?$/, 'Only numeric values and decimal points are allowed.') // Symbol-based Error
      .max(10, 'Maximum 10 characters allowed.') // Character Limit
      .required('Bottom Level is required'), // Compulsory
    topLevel: yup.string()
      .matches(/^\d+(\.\d+)?$/, 'Please enter the Top Level of the adjacent structure in meters, if applicable.') // Text-based Error
      .matches(/^\d+(\.\d+)?$/, 'Only numeric values and decimal points are allowed.') // Symbol-based Error
      .max(10, 'Maximum 10 characters allowed.'), // Character Limit
    width: yup.string()
      .matches(/^\d+(\.\d+)?$/, 'Please enter the Width of the water body in meters.') // Text-based Error
      .matches(/^\d+(\.\d+)?$/, 'Only numeric values and decimal points are allowed.') // Symbol-based Error
      .max(10, 'Maximum 10 characters allowed.') // Character Limit
      .required('Width of Water Body is required'), // Compulsory
    averageRainfall: yup.string()
      .matches(/^\d+(\.\d+)?$/, 'Please enter the Average Rainfall in mm/year, if known.') // Text-based Error
      .matches(/^\d+(\.\d+)?$/, 'Use only numbers and decimal points.') // Symbol-based Error
      .max(10, 'Maximum 10 characters allowed.'), // Character Limit
    atmosphericTempcurrent: yup.string()
      .matches(/^[-+]?\d+(\.\d+)?$/, 'Please enter the Current/Seasonal Atmospheric Temperature in degrees Celsius, if known.') // Text-based Error
      .matches(/^[-+]?\d+(\.\d+)?$/, 'Use only numbers, decimal points, and minus sign for negative values.') // Symbol-based Error
      .max(6, 'Maximum 6 characters allowed.'), // Character Limit
    atmosphericTempAverage: yup.string()
      .matches(/^[-+]?\d+(\.\d+)?$/, 'Please enter the Average/Annual Atmospheric Temperature in degrees Celsius, if known.') // Text-based Error
      .matches(/^[-+]?\d+(\.\d+)?$/, 'Use only numbers, decimal points, and minus sign for negative values.') // Symbol-based Error
      .max(6, 'Maximum 6 characters allowed.'), // Character Limit
    volumnOfWaterAvailable: yup.string()
      .matches(/^\d+(\.\d+)?$/, 'Please enter the Volume of Water Available in cubic meters per second (cumec).') // Text-based Error
      .matches(/^\d+(\.\d+)?$/, 'Use only numbers and decimal points.') // Symbol-based Error
      .max(10, 'Maximum 10 characters allowed.') // Character Limit
      .required('Volume of Water Available is required'), // Compulsory
  });

  const [errors, setErrors] = useState<Partial<FormData>>({});
  const onSelectChangeChange = async (e: any) => {
    const { name, value } = e.target;
    const selectedCountry = region?.country?.data?.data?.find((item: any) => item.name === value);

    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
    if (name == 'country') {

      setRegionData({ ...regionData, [e.target.name]: selectedCountry.id });
    }

    // await FormValidation(formSchema, { ...formData, [name]: value }, setErrors);

  }


  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });

    // await FormValidation(formSchema, { ...formData, [name]: value }, setErrors);

  };
  const sourcedata = sourceOfIntake.selectedSourceOfIntake.data

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    // Basic validation
    // await FormValidation(formSchema, formData, setErrors)
    let sourceData = {
      project_id: location?.state?.projectData?.id,
      component_id: components?.selectedComponent?.component_id,
      country_id: regionData.country,
      name: formData.name,
      type_of_source: formData.sourceType,
      type_of_water_body: formData.waterBodyType,
      basin: formData.basinName,
      sub_basin: formData.subBasinName,
      city: formData.city,
      highest_flood_level: formData.hfl,
      maximum_water_level: formData.mwl,
      // lowest_storage_level: formData.lsl,
      // minimum_drawdown_level: formData.mddl,
      // dead_storage_level: formData.dsl,
      // bottom_level: formData.bottomLevel,
      top_level_of_the_adjacent_structure: formData.topLevel,
      width_of_water_body: formData.width,
      average_rainfall: formData.averagePrecipitation,
      atmospheric_temperature_current_of_seasonal: formData.atmosphericTempcurrent,
      atmospheric_temperature_average_annual: formData.atmosphericTempAverage,
      volume_of_water_available_in_cumec: formData.volumnOfWaterAvailable,
      overall_basin_area: formData.overall_basin_area,
      latitude: formData.latitude,
      longitude: formData.longitude,
      average_perecipitation: formData.averagePrecipitation,
      current_temperature: formData.currentTemperature,
      seasonal_average_temperature: formData.seasonalTemperature,
      annual_average_temperature: formData.annualTemperature,
      type_of_source_value: formData.type_of_source_value
    }

    const errors: { [key: string]: string } = {};
    const requiredFields = ["name", "latitude", "longitude", "sourceType", "type_of_source_value", "waterBodyType", "basinName", "country", "city", "volumnOfWaterAvailable", "averagePrecipitation"]; // Add other required field names here

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        errors[field] = "Required";
      }
    });
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      // Submit the form
      sourcedata ? dispatch(editSourceOfIntake({ apiParam: { ...sourceData, coordinate_id: sourcedata.coordinate_id, id: sourcedata.id, country_id: regionData.country }, closeModal: dispatch(formPopup(false)) })) :
        dispatch(addSourceOfIntake({ apiParam: { ...sourceData }, closeModal: dispatch(formPopup(false)) }))

    }

  };

  useEffect(() => {

    if (components.selectedComponent) {
      dispatch(activeComponent(components?.selectedComponent?.name))
      if (components?.selectedComponent?.id) {
        dispatch(fetchSourceOfIntakeById(components?.selectedComponent?.id))
      }
      setFormData({
        ...formData,
        latitude: components?.selectedComponent?.latitude,
        longitude: components?.selectedComponent?.longitude
      })
    }
  }, [components.selectedComponent])

  useEffect(() => {
    dispatch(fetchCountry());
  }, []);

  

  useEffect(() => {
    if (region.country.data.data) {
      let data = region?.country?.data?.data?.map((item: { name: any, id: any }) => ({
        ...item,
        value: item.name,

      }));

      setRegions({
        ...regions,
        country: data
      })
    }


  }, [region?.country?.data?.data]);
  useEffect(() => {

    const selectedCountry = region?.country?.data?.data?.find((item: any) => item.id === sourcedata?.country_id);
    if (sourcedata) {
      setFormData({
        ...sourcedata,
        name: sourcedata.name,
        sourceType: sourcedata.type_of_source,
        waterBodyType: sourcedata.type_of_water_body,
        basinName: sourcedata.basin,
        subBasinName: sourcedata.sub_basin,
        city: sourcedata.city,
        country: selectedCountry?.name,
        hfl: sourcedata.highest_flood_level,
        mwl: sourcedata.maximum_water_level,
        // lsl: sourcedata.lowest_storage_level,
        // mddl: sourcedata.minimum_drawdown_level,
        // dsl: sourcedata.dead_storage_level,
        // bottomLevel: sourcedata.bottom_level,
        topLevel: sourcedata.top_level_of_the_adjacent_structure,
        width: sourcedata.width_of_water_body,
        averageRainfall: sourcedata.average_rainfall,
        averagePrecipitation: sourcedata.average_perecipitation,
        currentTemperature: sourcedata.current_temperature,
        seasonalTemperature: sourcedata.seasonal_average_temperature,
        annualTemperature: sourcedata.annual_average_temperature,
        atmosphericTempAverage: sourcedata.atmospheric_temperature_average_annual,
        atmosphericTempcurrent: sourcedata.atmospheric_temperature_current_of_seasonal,
        volumnOfWaterAvailable: sourcedata.volume_of_water_available_in_cumec,
        overall_basin_area: sourcedata.overall_basin_area,
        type_of_source_value: sourcedata.type_of_source_value,
        latitude: formData?.latitude,
        longitude: formData?.longitude

      });
      setRegionData({
        ...regionData,
        country: selectedCountry?.id,
      })
    }

  }, [sourcedata])



  const handleChangeRadio = (event: any, form: any) => {
    setForm(form)
    setFormData({
      ...formData,
      sourceType: event.target.value,
    })
    setFormErrors({ ...formErrors, [event.target.name]: "" });
  };

  useEffect(() => {
    if (sourcedata?.type_of_source) {
      let value = (sourcedata.type_of_source == "Surface Water") ? "form1" : "form2"
      setForm(value)
    }


  }, [sourcedata?.type_of_source])

  useEffect(() => {
    if (!sourcedata) {
      setFormData(resetformData)
    }
  }, [!sourcedata])


  return (
    <>
      {
        sourceOfIntake.editSourceOfIntake.load ? <Loading /> : <div className="container bg-white  mx-auto h-[85vh] text-[#1E2022]">
          {/* <div className=""> */}
          <div className="flex mb-4 justify-between items-center border-b-2 py-4 px-4">
            <h3 className="text-base not-italic font-semibold leading-[18.9px] mt-1">Source information</h3>

            <FontAwesomeIcon icon={faXmark} onClick={() => { dispatch(formPopup(false)); setFormData(resetformData); }} className="mt-1 pr-4 hover:cursor-pointer" />
          </div>
          {/* </div> */}
          <div className=" form-height overflow-y-auto ">

            <h3 className="mb-4 px-4 text-[15px] not-italic font-semibold leading-[18.9px]">General Information </h3>
            <form className="grid px-4 text-[#1E2022]">
              <FormField
                label="Name of the intake source"
                name="name"
                type="text"
                placeholder="Enter Name of the intake source"
                value={formData.name}
                onChange={handleChange}
                error={formErrors.name}
                labelRequired
              />
              <div className="mb-4">
                <label htmlFor="" className="text-[#1E2022] text-xs not-italic font-medium leading-[21px]">Type of Source <span className="text-[#ED4C78]">*</span></label> <br />
                <div className="flex mt-3">
                  <div className="flex items-center">
                    <label className=" text-[#1E2022] text-xs not-italic font-normal leading-5 flex items-center" onChange={(e: any) => { handleChangeRadio(e, "form1") }}>
                      <input type="radio" name="sourceType" id="" checked={formData.sourceType === 'Surface Water'} value="Surface Water" className="border-0 w-4 h-4 ml-2" /><span className="ml-2">Surface Water </span> </label>
                  </div>
                  <div className="flex items-center">
                    <label className="text-[#1E2022] text-xs not-italic font-normal leading-5 flex items-center" onChange={(e: any) => { handleChangeRadio(e, "form2") }}>
                      <input type="radio" name="sourceType" id="" value="Ground Water" checked={formData.sourceType === 'Ground Water'} className="border-0 w-4 h-4 ml-6" /><span className="ml-2">Ground Water</span> </label></div>
                </div>
                {formErrors.sourceType && <p className="text-red-500 text-xs italic">{formErrors.sourceType}</p>}

              </div>
              {
                form === 'form1' ?
                  <SelectField
                    label="Type of Surface Water "
                    name="type_of_source_value"
                    type="select"
                    placeholder="Select"
                    value={formData.type_of_source_value}
                    onChange={onSelectChangeChange}
                    error={formErrors.type_of_source_value}
                    options={sourceTypeOptions}
                    labelRequired
                  /> :
                  <SelectField
                    label="Type of Ground Water "
                    name="type_of_source_value"
                    type="select"
                    placeholder="Select"
                    value={formData.type_of_source_value}
                    onChange={onSelectChangeChange}
                    error={formErrors.type_of_source_value}
                    options={groundTypeOptions}
                    labelRequired
                  />
              }
              {/* <pre>{JSON.stringify(formData.latitude)}</pre> */}
              <FormField
                label="Latitude"
                name="latitude"
                type="text"
                placeholder="Enter Latitude"
                value={formData.latitude}
                onChange={handleChange}
                error={formErrors.latitude}
                labelRequired
              />
              <FormField
                label="Longitude"
                name="longitude"
                type="text"
                placeholder="Enter Longitude"
                value={formData.longitude}
                onChange={handleChange}
                error={formErrors.longitude}
                labelRequired
              />
              <SelectField
                label="Type of Water body"
                name="waterBodyType"
                type="select"
                placeholder="Select"
                value={formData.waterBodyType}
                onChange={onSelectChangeChange}
                error={formErrors.waterBodyType}
                options={waterBodyTypeOptions}
                labelRequired
              />

              <FormField
                label="Basin"
                name="basinName"
                type="text"
                placeholder="Enter Basin"
                value={formData.basinName}
                onChange={handleChange}
                error={formErrors.basinName}
                labelRequired
              />
              <FormField
                label="Sub basin"
                name="subBasinName"
                type="text"
                placeholder="Enter Sub basin"
                value={formData.subBasinName}
                onChange={handleChange}
                error={errors.subBasinName}
              />

              <SelectField
                label="Country"
                name="country"
                type="select"
                placeholder="Select"
                value={formData.country}
                onChange={(e: any) => { onSelectChangeChange(e) }}
                error={formErrors.country}
                labelRequired
                options={regions.country}

              />
              <FormField
                label="City / Region"
                name="city"
                type="text"
                placeholder="Enter City"
                value={formData.city}
                onChange={handleChange}
                error={formErrors.city}
                labelRequired
              />

              <div className="flex flex-row text-black mb-3">
                <h3 className="basis-5/6 font-bold text-base">Water Body Characteristics</h3>
              </div>
              <FormField
                label="Highest Flood Level (HFL) (m)"
                name="hfl"
                type="text"
                placeholder="Enter Highest Flood Level (HFL)"
                value={formData.hfl}
                onChange={handleChange}
                error={errors.hfl}
              />
              <FormField
                label="Maximum Water Level (MWL) (m)"
                name="mwl"
                type="text"
                placeholder="Enter Maximum Water Level (MWL)"
                value={formData.mwl}
                onChange={handleChange}
                error={errors.mwl}
              />
              {/* <FormField
                label="Lowest Storage Level (LSL) (m)"
                name="lsl"
                type="text"
                placeholder="Enter Lowest Storage Level (LSL)"
                value={formData.lsl}
                onChange={handleChange}
                error={errors.lsl}
              /> */}
              {/* <FormField
                label="Minimum Drawdown Level (MDDL) (m)"
                name="mddl"
                type="text"
                placeholder="Enter Minimum Drawdown Level (MDDL)"
                value={formData.mddl}
                onChange={handleChange}
                error={errors.mddl}
              /> */}
              {/* <FormField
                label="Dead Storage Level (DSL)"
                name="dsl"
                type="text"
                placeholder="Enter Dead Storage Level (DSL)"
                value={formData.dsl}
                onChange={handleChange}
                error={errors.dsl}
              /> */}
              {/* <FormField
                label="Bottom Level"
                name="bottomLevel"
                type="text"
                placeholder="Enter Bottom Level"
                value={formData.bottomLevel}
                onChange={handleChange}
                error={errors.bottomLevel}
              /> */}
              <FormField
                label="Top Level of the adjacent structure (m)"
                name="topLevel"
                type="text"
                placeholder="Enter Top Level of the adjacent structure"
                value={formData.topLevel}
                onChange={handleChange}
                error={errors.topLevel}
              />
              <FormField
                label="Width of water body (m)"
                name="width"
                type="text"
                placeholder="Enter Width of water body"
                value={formData.width}
                onChange={handleChange}
                error={errors.width}
              />
              <FormField
                label="Average Precipitation (mm)"
                name="averagePrecipitation"
                type="text"
                placeholder="Enter Average Precipitation"
                value={formData.averagePrecipitation}
                onChange={handleChange}
                error={formErrors.averagePrecipitation}
                labelRequired
              />
              <FormField
                label="Volume of water available (cu. m)"
                name="volumnOfWaterAvailable"
                type="text"
                placeholder="Enter Volume of water available (in cumec)"
                value={formData.volumnOfWaterAvailable}
                onChange={handleChange}
                error={formErrors.volumnOfWaterAvailable}
                labelRequired
              />
              <FormField
                label="Overall basin area (sq. m)"
                name="overall_basin_area"
                type="text"
                placeholder="Enter Overall basin area (Only for Rivers)"
                value={formData.overall_basin_area}
                onChange={handleChange}
                error={errors.overall_basin_area}
              />
              <div className="flex flex-row text-black mb-3">
                <h3 className="basis-5/6 font-bold text-base">Atmospheric temperature</h3>
              </div>
              <FormField
                label="Current temperature (°C)"
                name="currentTemperature"
                type="text"
                placeholder="Enter Current temperature"
                value={formData.currentTemperature}
                onChange={handleChange}
                error={errors.currentTemperature}
              />
              <FormField
                label="Seasonal Average temperature (°C)"
                name="seasonalTemperature"
                type="text"
                placeholder="Enter Seasonal Average temperature"
                value={formData.seasonalTemperature}
                onChange={handleChange}
                error={errors.seasonalTemperature}
              />
              <FormField
                label="Annual Average temperature (°C)"
                name="annualTemperature"
                type="text"
                placeholder="Enter Annual Average temperature"
                value={formData.annualTemperature}
                onChange={handleChange}
                error={errors.annualTemperature}
              />





            </form>
          </div>
          {/* <div className="h-[10%] flex justify-end"> */}
          {/* <div className="basis-2/4"></div> */}
          <div className="w-full  flex justify-end pr-8  form-footer">
            <button
              className=" text-[#8A96A4] bg-white my-4 pt-1 pb-1 px-4  rounded focus:outline-none focus:shadow-outline border border-solid border-[#EEF0F7] hover:bg-[#f8f7f7]  hover:scale-105"
              type="submit"
              onClick={() => { dispatch(formPopup(false)); setFormData(resetformData); }}
            >
              Cancel
            </button>
            <button
              className=" ml-3 font-normal text-sm bg-[#377DFF] my-4  text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline hover:scale-105"
              type="submit"
              onClick={handleSubmit}
            >
              {sourcedata ? "Update Details" : "Save Details"}
            </button>
          </div>
          {/* </div> */}


        </div>
      }

    </>
  )
}

export default SourceOfIntake;
