import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { fetchComponents } from "../Map";
import { fetchCoordinates } from "../coordinate";

export interface PipeData {
    component_id: number,
    coordinate_id: number,
    diameter: number,
    material: string,
    length: number,
    id: number
}

interface PipesState {
    pipes: {
        load: boolean;
        data: Array<PipeData>;
        error: Array<PipeData>;
    };
    selectedPipe?: PipeData;
    editPipe: {
        load: boolean;
    };
}
const initialState = {
    pipes: {
        load: false,
        data: [],
        error: []
    },
    selectedPipe: {
        component_id: "",
        coordinate_id: "",
        diameter: "",
        type: "",
        material: "",
        length: "",
        id: ""

    },
    editPipe: {
        load: false,
    },
    deletePipe: {
        load: false,
    },
} as unknown as PipesState;

export const pipesSlice = createSlice({
    name: "pipes",
    initialState,
    reducers: {
        resetPipeData: (state: PipesState) => {
            state.selectedPipe = initialState.selectedPipe;
            state.pipes.error = []
        },
        updateData: (state: PipesState, action: { payload: PipeData }) => {
            state.selectedPipe = action.payload;
        },
        addPipeError: (state: PipesState, action: any) => {
            state.pipes.error = action.payload;
        },
        addPipeSuccess: (state: PipesState, action: any) => {
            if (action.payload) {
                state.pipes.data = [{ ...action.payload }, ...state.pipes.data];
            }
            state.pipes.load = false;
        },
        editPipeSuccess: (state: PipesState, action: any) => {
            if (action.payload) {

                const editedPipeIndex = state.pipes.data.findIndex(pipe => pipe.id === action.payload.id);

                if (editedPipeIndex !== -1) {
                    state.pipes.data[editedPipeIndex] = { ...action.payload };
                }
            }
            state.pipes.load = false;
        },
        deletePipeSuccess: (state: PipesState, action: any) => {
            if (action.payload) {
                const pipeIdToDelete = action.payload.id;
                const indexToDelete = state.pipes.data.findIndex(pipe => pipe.id === pipeIdToDelete);
                if (indexToDelete !== -1) {
                    state.pipes.data.splice(indexToDelete, 1);
                }
            }
            state.pipes.load = false;
        }
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchPipes.pending, (state: PipesState) => {
                state.pipes.load = true;
            })
            .addCase(fetchPipes.fulfilled, (state: PipesState, action: any) => {
                state.pipes.data = action.payload;
                state.pipes.load = false;
            })
            .addCase(fetchPipes.rejected, (state: PipesState) => {
                state.pipes.load = false;
            })
            .addCase(fetchPipeById.pending, (state: PipesState) => {
                state.editPipe.load = true;
            })
            .addCase(fetchPipeById.fulfilled, (state: PipesState, action: any) => {
                state.selectedPipe = action.payload;
                state.editPipe.load = false;
            })
            .addCase(fetchPipeById.rejected, (state: PipesState) => {
                state.editPipe.load = false;
            });
    },
});
export const { updateData, resetPipeData, addPipeError, addPipeSuccess, editPipeSuccess, deletePipeSuccess } = pipesSlice.actions;
export default pipesSlice.reducer;

export const fetchPipes = createAsyncThunk(
    "pipes/fetchPipes",
    async (param: any, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("/api/user-wise-pipe-list", {
                user_id: param
            });
            // thunkAPI.dispatch(toggleLoading());
            return data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const fetchPipeById = createAsyncThunk(
    "pipes/fetchPipeById",
    async (param: any, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/edit-pipes", {
                coordinate_id: param
            });
            // thunkAPI.dispatch(toggleLoading());
            return data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const addPipe = createAsyncThunk(
    "pipes/addPipe",
    async (param: { apiParam: any, closeModal: any }, thunkAPI) => {
        try {
            const coordinates = await DataServer.post(
                "api/coordinates",
                { component_id: param.apiParam.component_id, latitude: param.apiParam.latitude, longitude: param.apiParam.longitude }
            );
            if (coordinates.data.data.id) {
                const { data } = await DataServer.post(
                    "api/pipes",
                    { ...param.apiParam, coordinate_id: coordinates.data.data.id }
                );
                thunkAPI.dispatch(fetchComponents({ apiParam: { id: param.apiParam.project_id, current_step: "mapping" } }))
                thunkAPI.dispatch(fetchCoordinates(param.apiParam.project_id))


                toast.success('Pipe Added Successfully!', {
                    position: "top-right",
                    autoClose: 5000,
                    theme: "dark",
                });
                param.closeModal();
                // thunkAPI.dispatch(addPipeSuccess({...data.data,role:param.apiParam.role,id:data.data.id}));
                return { ...data.data };
            }
        } catch (error: any) {
            thunkAPI.dispatch(addPipeError(error));
            return error;
        }
    }
);

export const editPipe = createAsyncThunk(
    "pipes/editPipe",
    async (param: { apiParam: any, closeModal: any }, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/update-pipes", param.apiParam);
            // thunkAPI.dispatch(toggleLoading());
            thunkAPI.dispatch(editPipeSuccess({ ...data.data, role: param.apiParam.role }));


            toast.success('Pipe Updated Successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });
            param.closeModal();
            return data.data;
        } catch (error: any) {
            thunkAPI.dispatch(addPipeError(error));
            return error;
        }
    }
);

export const deletePipe = createAsyncThunk(
    "pipes/deletePipe",
    async (param: { apiParam: any }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/delete-pipe", {
                id: param.apiParam.id
            });
            param.apiParam.closeModal();
            thunkAPI.dispatch(deletePipeSuccess({ ...data.data, role: param.apiParam.role, id: param.apiParam.id }))
            toast.success('Pipe deleted Successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });

            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            // thunkAPI.dispatch(ErrorMessage(error));
            return error;
        }
    }
);
