import React, { useEffect, useState } from "react";
import FormField from '../Forms/FormField'
// import SelectField from '../SelectField'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as yup from 'yup';
// import { FormValidation } from "../../Utils/formValidation";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import '../../assets/css/reusable/form.css'
import SelectField from "../Forms/SelectField";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { activeComponent, formPopup } from "../../redux/slice/Map";
import { addPumpingStation } from "../../redux/slice/pumpingStation";
import { addRisingGravityMain, editRisingGravityMain, fetchRisingGravityMainById } from "../../redux/slice/risingGravityMain";
import { useLocation } from "react-router-dom";
import Loading from "../../views/pages/Loading";
interface FormData {
    type: string;
    latitude: string;
    longitude: string;
    diameter: string;
    routing: string;
    access: string;
}
const RisingDetails: any = (props: any) => {
    const components = useAppSelector((state) => state.components)
    const risingGravityMain = useAppSelector((state) => state.risingGravityMain)
    const risingGravityMainData = risingGravityMain?.selectedRisingGravityMain?.data
    const dispatch = useAppDispatch();
    const [formData, setFormData] = useState<any>({
        type: "",
        latitude: "",
        longitude: "",
        diameter: "",
        routing: "",
        access: "",
    });
    const [resetformData, setResetFormData] = useState<FormData>({
        type: "",
        latitude: "",
        longitude: "",
        diameter: "",
        routing: "",
        access: "",
    });
    const location = useLocation();
    const typeOptions: any = [{ "value": "Select" }, { "value": "Rising Main" }, { "value": "Gravity Main" }]
    const convertedtypeOptions = typeOptions.map((option: { value: string; }) => option.value);
    const formSchema = yup.object({
        type: yup.string()
            .oneOf(convertedtypeOptions, 'Please select a main type from the list.') // A) Error for text-based
            .required('Please select a main type from the list.'), // Compulsory
        latitude: yup.string().required("Latitude is required")
            .test('is-text-based', 'Please enter a valid latitude.', value => !isNaN(parseFloat(value)))
            .test('is-symbol-based', 'Only numeric values and symbols (-, .) are allowed.', value => /^-?\d*\.?\d*$/.test(value))
            .test('character-limit', 'Value must be a valid latitude format.', value => value.length <= 8),
        longitude: yup.string().required("Longitude is required")
            .test('is-text-based', 'Please enter a valid Longitude.', value => !isNaN(parseFloat(value)))
            .test('is-symbol-based', 'Only numeric values and symbols (-, .) are allowed.', value => /^-?\d*\.?\d*$/.test(value))
            .test('character-limit', 'Value must be a valid Longitude format.', value => value.length <= 8),
        diameter: yup.string()
            .matches(/^\d+(\.\d+)?$/, 'Please enter a numeric value for diameter.') // A) Error for text-based
            .matches(/^\d+(\.\d+)?$/, 'Only numeric values are allowed.') // B) Error for symbol-based
            .test('is-valid-range', 'Value must be between 0.1 to 50.', value => {
                if (typeof value === 'string' && value.trim() !== '') {
                    const floatValue: number = parseFloat(value);
                    return floatValue >= 0.1 && floatValue <= 50;
                }
                return true; // Allow empty values
            }) // C) Character limit
            .required('Diameter is required'), // Compulsory
        routing: yup.string()
            .required('Routing is required.'), // Compulsory
        access: yup.string()
            .required('Access Points is required.'),
    });
    const [errors, setErrors] = useState<Partial<FormData>>({});
    const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
    const onSelectChangeChange = async (e: any) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setFormErrors({ ...formErrors, [name]: "" });
        // await FormValidation(formSchema, { ...formData, [name]: value }, setErrors);
    }
    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setFormErrors({ ...formErrors, [name]: "" });
        // await FormValidation(formSchema, { ...formData, [name]: value }, setErrors);
    };
    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        // await FormValidation(formSchema, formData, setErrors)
        let pumpingData = {
            project_id: location?.state?.projectData?.id,
            component_id: components?.selectedComponent?.component_id,
            type_of_main: formData.type,
            diameter: formData.diameter,
            routing: formData.routing,
            access_points: formData.access,
            latitude: formData.latitude,
            longitude: formData.longitude
        }

        const errors: { [key: string]: string } = {};
        const requiredFields = ["latitude", "longitude", "type", "diameter"];

        requiredFields.forEach((field) => {
            if (!formData[field]) {
                errors[field] = "Required";
            }
        });

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
        } else {
            risingGravityMainData ? dispatch(editRisingGravityMain({ apiParam: { ...pumpingData, coordinate_id: risingGravityMainData.coordinate_id, id: risingGravityMainData.id }, closeModal: dispatch(formPopup(false)) })) :
                dispatch(addRisingGravityMain({ apiParam: pumpingData, closeModal: dispatch(formPopup(false)) }))
        }
    };

    useEffect(() => {
        if (components.selectedComponent) {
            dispatch(activeComponent(components?.selectedComponent?.name))
            if (components?.selectedComponent?.id) {
                dispatch(fetchRisingGravityMainById(components?.selectedComponent?.id))
            }
            setFormData({
                ...formData,
                latitude: components?.selectedComponent?.latitude,
                longitude: components?.selectedComponent?.longitude
            })
        }
    }, [components.selectedComponent])

    useEffect(() => {
        if (risingGravityMainData) {
            setFormData({
                ...risingGravityMainData,
                capacity: risingGravityMainData.pump_capacity,
                type: risingGravityMainData.type_of_main,
                access: risingGravityMainData.access_points,
                latitude: components?.selectedComponent?.latitude,
                longitude: components?.selectedComponent?.longitude

            })
        }

    }, [risingGravityMain]);

    useEffect(() => {
        if (!risingGravityMainData) {
            setFormData(resetformData)
        }
    }, [!risingGravityMainData])
    return (
        <>
            {
                risingGravityMain.editRisingGravityMain.load ? <Loading /> : <div className="container bg-white  mx-auto h-[85vh] text-[#1E2022]">
                    {/* <div className="h-[5%]"> */}
                    <div className="flex mb-4 justify-between items-center border-b-2 py-4 px-4">
                        <h3 className="text-base not-italic font-semibold leading-[18.9px] mt-1">Rising / Gravity Main Details</h3>
                        <FontAwesomeIcon icon={faXmark}
                            onClick={() => { dispatch(formPopup(false)); setFormData(resetformData) }}
                            className=" mt-1 hover:cursor-pointer" />
                    </div>
                    {/* </div> */}
                    <div className="form-height overflow-y-auto ">
                        <form className="grid px-4">
                            {/* <div className="text-black py-4 font-bold">
        <h3 className="">General Information</h3>
      </div> */}
                            <SelectField
                                label="Type of Main "
                                name="type"
                                type="select"
                                placeholder="Select"
                                value={formData.type}
                                onChange={onSelectChangeChange}
                                error={formErrors.type}
                                options={typeOptions}
                                labelRequired
                            />
                            <FormField
                                label="Latitude"
                                name="latitude"
                                type="text"
                                placeholder="Enter Latitude"
                                value={formData.latitude}
                                onChange={handleChange}
                                error={formErrors.latitude}
                                labelRequired
                            />
                            <FormField
                                label="Longitude"
                                name="longitude"
                                type="text"
                                placeholder="Enter Longitude"
                                value={formData.longitude}
                                onChange={handleChange}
                                error={formErrors.longitude}
                                labelRequired
                            />
                            <FormField
                                label="Diameter (mm)"
                                name="diameter"
                                type="text"
                                placeholder="Enter Diameter"
                                value={formData.diameter}
                                onChange={handleChange}
                                error={formErrors.diameter}
                                labelRequired
                            />

                            <FormField
                                label="Access Points"
                                name="access"
                                type="text"
                                placeholder="Enter Access Points"
                                value={formData.access}
                                onChange={handleChange}
                                error={errors.access}
                            />
                        </form>
                    </div>
                    {/* <div className="h-[10%] flex justify-end"> */}
                    {/* <div className="basis-2/4"></div> */}
                    <div className="w-full flex justify-end pr-4 form-footer">
                        <button
                            className=" text-[#8A96A4] bg-white my-4 pt-1 pb-1 px-4  rounded focus:outline-none focus:shadow-outline border border-solid border-[#EEF0F7] hover:bg-[#F8F7F7]  hover:scale-105"
                            type="submit"
                            onClick={() => { dispatch(formPopup(false)); setFormData(resetformData) }}
                        >
                            Cancel
                        </button>
                        <button
                            className=" ml-3 font-normal text-sm bg-[#377DFF] my-4  text-white pt-1 pb-1 px-2 rounded focus:outline-none focus:shadow-outline hover:scale-105"
                            type="submit"
                            onClick={handleSubmit}
                        >
                            {risingGravityMainData ? "Update Details" : "Save Details"}

                        </button>
                    </div>
                    {/* </div> */}
                </div>
            }

        </>
    )
}
export default RisingDetails
