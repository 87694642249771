import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';

export interface UserData {
    first_name: string;
    last_name: string;
    designation: string;
    email: string;
    role_id: number|undefined;
    authentication_user_info: any;
    role:string
    id:any
}

interface UsersState {
    users: {
        load: boolean;
        data: Array<UserData>;
        error: Array<UserData>;
    };
    usersStatus:boolean;
    userData?: UserData;
    editUser: {
        load: boolean;
    };
}
const initialState = {
    users: {
        load: false,
        data: [],
        error: []
    },
    userData: {
        first_name: "",
        last_name: "",
        designation: "",
        email: "",
        role_id: undefined,
        authentication_user_info: "",
        role:"",
        id:""
    },
    usersStatus:false,
    editUser: {
        load: false,
    },
    deleteUser: {
        load: false,
    },
} as UsersState;

export const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        resetUserData: (state: UsersState) => {
            state.userData = initialState.userData;
            state.users.error = []
        },
        updateData: (state: UsersState, action: { payload: UserData }) => {
            state.userData = action.payload;
        },
        addUserError: (state: UsersState, action: any) => {
            state.users.error = action.payload;
        },
        addUserSuccess: (state: UsersState, action: any) =>{
            if(action.payload){
                state.users.data = [ {...action.payload},...state.users.data];
                }
                state.userData= action.payload
                state.users.load = false;
                state.usersStatus = true;
        },
        editUserSuccess: (state: UsersState, action: any) => {
            if (action.payload) {
                
                const editedUserIndex = state.users.data.findIndex(user => user.id === action.payload.id);
                
                if (editedUserIndex !== -1) {
                    state.users.data[editedUserIndex] = { ...action.payload };
                }
            }
            state.users.load = false;
        },
        deleteUserSuccess: (state: UsersState, action: any) => {
            if (action.payload) {
                const userIdToDelete = action.payload.id;
                const indexToDelete = state.users.data.findIndex(user => user.id === userIdToDelete);
                if (indexToDelete !== -1) {
                    state.users.data.splice(indexToDelete, 1);
                }
            }
            state.users.load = false;
        }
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchUsers.pending, (state: UsersState) => {
                state.users.load = true;
            })
            .addCase(fetchUsers.fulfilled, (state: UsersState, action: any) => {
                state.users.data = action.payload;
                state.users.load = false;
            })
            .addCase(fetchUsers.rejected, (state: UsersState) => {
                state.users.load = false;
            })
            .addCase(fetchUserById.pending, (state: UsersState) => {
                state.editUser.load = true;
            })
            .addCase(fetchUserById.fulfilled, (state: UsersState, action: any) => {
                state.userData = action.payload;
                state.editUser.load = false;
            })
            .addCase(fetchUserById.rejected, (state: UsersState) => {
                state.editUser.load = false;
            })
            .addCase(inviteUserServer.pending, (state: UsersState) => {
                state.editUser.load = true;
            })
            .addCase(inviteUserServer.fulfilled, (state: UsersState, action: any) => {
                // state.userData = action.payload;
                state.usersStatus = false;
                state.editUser.load = false;
            })
            .addCase(inviteUserServer.rejected, (state: UsersState) => {
                state.editUser.load = false;
            });
    },
});
export const { updateData, resetUserData, addUserError,addUserSuccess,editUserSuccess,deleteUserSuccess } = usersSlice.actions;
export default usersSlice.reducer;

export const fetchUsers = createAsyncThunk(
    "users/fetchUsers",
    async (param, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.get("api/users");
            // thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const fetchUserById = createAsyncThunk(
    "users/fetchUserById",
    async (param: { apiParam: { id: any } }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/fetch-user", {
                id: param.apiParam.id
            });
            // thunkAPI.dispatch(toggleLoading());
            return data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const addUser = createAsyncThunk(
    "users/addUser",
    async (param: { apiParam: any, closeModal: any }, thunkAPI) => {
        try {
            const { data } = await DataServer.post(
                "api/users",
                param.apiParam
            );
            

            toast.success('User added successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });
                thunkAPI.dispatch(addUserSuccess({...data.data,role:param.apiParam.role,id:data.data.id}));
            return {...data.data,role:param.apiParam.role};
        } catch (error: any) {
            thunkAPI.dispatch(addUserError(error));
            return error;
        }
    }
);

export const editUser = createAsyncThunk(
    "users/editUser",
    async (param: { apiParam: any, closeModal: any }, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/update-user", param.apiParam);
            // thunkAPI.dispatch(toggleLoading());
            thunkAPI.dispatch(editUserSuccess({...data.data,role:param.apiParam.role}));
            param.closeModal();

            toast.success('User updated successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });

            return data.data;
        } catch (error:any) {
            // thunkAPI.dispatch(toggleLoading());
            // thunkAPI.dispatch(ErrorMessage(error));
            thunkAPI.dispatch(addUserError(error));
            return error;
        }
    }
);

export const deleteUser = createAsyncThunk(
    "users/deleteUser",
    async (param: { apiParam: any }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/delete-user", {
                id: param.apiParam.id
            });
            // thunkAPI.dispatch(toggleLoading());
            param.apiParam.closeModal();
            thunkAPI.dispatch(deleteUserSuccess({...data.data,role:param.apiParam.role,id:param.apiParam.id}))
            toast.success('User deleted successfully!', {
                position: "top-right",
                autoClose: 5000,
                theme: "dark",
            });

            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            // thunkAPI.dispatch(ErrorMessage(error));
            return error;
        }
    }
);

export const inviteUserServer = createAsyncThunk(
    "users/inviteUserServer",
    async (param:any, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("/api/invite-user-for-registration", { email: param.email, role_name: param.role_name });
            // thunkAPI.dispatch(toggleLoading());
            return data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);
