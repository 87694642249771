import React, { useEffect, useState } from "react";
import PageTitle from "../PageTitle";
import { Menu, Transition } from "@headlessui/react";
import Datatable from "../DataTable";
import { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilterList } from "@fortawesome/pro-regular-svg-icons";
import { faArrowDown, faArrowUp } from "@fortawesome/pro-light-svg-icons";
// import '../../assets/css/reusable/screenmonitoring.css'
import { useAppSelector } from "../../hooks";

const CodeCompliance = () => {
  const monitoring = useAppSelector((state) => state.monitoring);
  const [tableData, setTableData] = useState<any>([]);

  useEffect(() => {
    if (monitoring?.screenStats?.data?.getCodeCompliance?.original?.data) {
      setTableData(
        monitoring?.screenStats?.data.getCodeCompliance?.original?.data
      );
    }
  }, [monitoring?.screenStats?.data?.getCodeCompliance?.original?.data]);
  const columns = [
    {
      name: "Parameter",
      selector: (row: any) => row.parameter_name,
      // cell: (row: any) => <span>
      //   {row.id}
      // </span>,
      // sortable: true,
    },
    {
      name: "Unit",
      selector: (row: any) => row.unit,
      cell: (row: any) => <span>{row.unit}</span>,
    },
    {
      name: "Obs. Value",
      selector: (row: any) => row.observation,
      cell: (row: any) => <div className="text-center">{row.observation}</div>,
    },
    {
      name: (
        <div className="flex items-center">
          BIS{" "}
          <span className="ml-1 font-bold text-[#FFF] text-xs not-italic leading-3">
            60%{" "}
          </span>
        </div>
      ),
      selector: (row: any) => row.BIS,
      cell: (row: any) => <div className="text-center">{row.BIS}</div>,
    },
    {
      name: (
        <div className="flex items-center">
          WHO{" "}
          <span className="ml-1 font-bold text-[#FFF] text-xs not-italic leading-3">
            30%{" "}
          </span>
        </div>
      ),
      selector: (row: any) => row.WHO,
      cell: (row: any) => <div className="text-center">{row.WHO}</div>,
    },
    {
      name: (
        <div className="flex items-center">
          USEPA{" "}
          <span className="ml-1 font-bold text-[#FFF] text-xs not-italic leading-3">
            30%{" "}
          </span>
        </div>
      ),
      selector: (row: any) => row.EPA,
      cell: (row: any) => <div className="text-center">{row.EPA}</div>,
    },
    {
      name: (
        <div className="flex items-center">
          R&D{" "}
          <span className="ml-1 font-bold text-[#FFF] text-xs not-italic leading-3">
            30%{" "}
          </span>
        </div>
      ),
      selector: (row: any) => `${row.rd}`,
      cell: (row: any) => <div className="text-center">{row.rd}</div>,
    },
  ];

  return (
    <div className="px-4 pb-3 rounded-md code-compliance bg-[#222230] border-[#222230] text-[#FFF] h-[254px] overflow-hidden flex flex-col">
      <div className="sticky top-0 z-10 bg-[#222230] pt-3">
        <div className="flex items-center justify-between border-b border-[#4A4A68] pb-3 sticky top-0">
          <h3 className="text-sm font-bold">Code Compliance</h3>
          {/* <PageTitle title="Code Compliance"></PageTitle> */}
          <div className="flex items-center gap-2.5">
            <div className="flex items-center gap-1.5">
              <h3 className="text-xs text-[#FFF] font-medium">Above Value</h3>
              <span className="custom-green rounded w-5 h-5"></span>
            </div>
            <div className="flex items-center gap-1.5">
              <h3 className="text-xs text-[#FFF] font-medium">Below Value</h3>
              <span className="bg-[#F75D5FA3] rounded w-5 h-5"></span>
            </div>
            {/* <div className="flex items-center px-2 mapview-text-white">
          <FontAwesomeIcon icon={faFilterList} />
          </div> */}
          </div>
        </div>
      </div>

      {/* <div className="mapview-style">
        <Datatable
          columns={columns}
          data={tableData}
          // data={[]}
          title=""
          className="mt-2"
        ></Datatable>
      </div> */}

      <div className="overflow-auto mt-2 scrollbar">
        <table className="w-full text-xs text-left text-white">
          <thead className="bg-[#4A4A68] sticky top-0 z-10">
            <tr>
              <th scope="col" className="font-light rounded-l px-4 py-1.5 ">
                Parameter
              </th>
              <th scope="col" className="text-center font-light p-1.5 ">
                Unit
              </th>
              <th scope="col" className="text-center font-light p-1.5 ">
                Obs Value
              </th>
              <th scope="col" className="text-center font-light p-1.5 ">
                <span>BIS</span>
                <span className="ml-1.5 font-bold">60%</span>
              </th>
              <th scope="col" className="text-center font-light p-1.5 ">
                <span>WHO</span>
                <span className="ml-1.5 font-bold">60%</span>
              </th>
              <th scope="col" className="text-center font-light p-1.5 ">
                <span>USEPA</span>
                <span className="ml-1.5 font-bold">60%</span>
              </th>
              <th
                scope="col"
                className="rounded-r text-center font-light p-1.5 "
              >
                <span>R&D</span> <span className="font-bold">60%</span>
              </th>
            </tr>
          </thead>
          <tbody className="tbl-body">
            {tableData.map((row: any) => (
              <tr key={row.id}>
                <td className="px-4 py-2 w-[110px] font-medium">
                  {row?.parameter_name ?? "-"}
                </td>
                <td className="p-2 w-[53px] text-center font-medium">
                  {row?.unit ?? "-"}
                </td>
                <td className="p-2 font-medium text-center w-[71px]">
                  {row.observation ?? "-"}
                </td>
                <td className="p-2 font-medium text-center w-[71px]">
                  {row.BIS ?? "-"}
                </td>
                <td className="p-2 font-medium text-center w-[71px]">
                  {row.WHO ?? "-"}
                </td>
                <td className="p-2 font-medium text-center w-[88px]">
                  {row.EPA ?? "-"}
                </td>
                <td className="p-2 font-medium text-center w-[71px]">10</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CodeCompliance;
